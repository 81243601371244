.hidden {
  visibility: hidden;
}
.active {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  position: absolute;
  visibility: visible;
  opacity: 1;
  transition: visibility 0.5s, opacity 0.5s linear;
  z-index: 1;
}
.overlay {
  background-color: rgba(35, 36, 37, 0.6);
  position: fixed;
  width: 100%;
  height: 145%;
  z-index: 6;
}
.container {
  width: 860px;
  height: auto;
  background-color: white;
  transform: translate(-50%, -50%);
  transition: all 0.9s ease-out;
  transform: translateX(0px);
  margin: 120px auto 0;
  box-sizing: border-box;
}
.hiddenContainer {
  max-width: 658px;
  background-color: white;
  position: relative;
  top: 30%;
  left: 15%;
  border-radius: 4%;
  transform: translate(-50%, -50%);
  padding-left: 36px;
  padding-right: 36px;
  transition: all 0.9s ease-out;
  transform: translateX(700px);
}
.block {
  display: flex;
  flex-direction: column;
}
.btnClose {
  position: fixed;
  top: 30px;
  right: 30px;
  background-image: url('../../../images/plusPopup.svg');
  background-repeat: no-repeat;
  background-size: 40px;
  box-sizing: border-box;
  background-color: transparent;
  width: 40px;
  height: 40px;
  border: none;
  cursor: pointer;
  transition: 0.3s;
}
.btnClose:hover {
  opacity: 0.6;
  transition: 0.3s;
}
.img {
  margin-top: 80px;
  margin-bottom: 0;
  width: 118px;
  height: 102px;
}
.text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  width: 399px;
  text-align: center;
  margin-top: 0px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
  text-decoration: none;
  word-wrap: break-word;
}

.title {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 49px;
  text-align: center;
  margin-bottom: 0;
  margin-top: 0px;
  margin-bottom: 18px;
}

.btnOk {
  background: #3852de;
  border-radius: 8px;
  width: 179px;
  height: 64px;
  cursor: pointer;
  color: white;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  box-sizing: border-box;
  border: none;
  margin-bottom: 80px;
  margin-top: 28px;
  margin-right: auto;
  margin-left: auto;
  border: #3852de 1px solid;
  transition: 0.3s;
}
.btnOk:hover {
  color: #3852de;
  background: #ffffff;
  border: #3852de 1px solid;
  transition: 0.3s;
}
.linkDiv {
  display: flex;
  margin-left: 20px;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
}
.linksImg {
  margin-right: 8px;
}
.linkText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-decoration-line: underline;
  color: rgba(35, 35, 35, 0.71);
}

.imgCopy {
  position: relative;
  top: 5px;
  width: 22px;
  height: 22px;
  cursor: pointer;
}

@media screen and (max-width: 1439px) {
  .container {
    width: 621px;
    margin: 120px auto 0;
  }
  .img {
    margin-top: 50px;
    width: 66px;
    height: 74px;
  }

  .title {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 10px;
  }
  .text {
    font-size: 18px;
    line-height: 22px;
    width: 399px;
  }

  .btnOk {
    width: 158px;
    height: 54px;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 65px;
    margin-top: 28px;
  }
  .btnClose {
    top: 20px;
    right: 20px;
  }
}

@media screen and (max-width: 1023px) {
  .container {
    width: 476px;
    margin: 120px auto 0;
  }
  .img {
    margin-top: 40px;
    width: 48px;
    height: 54px;
  }

  .title {
    font-size: 24px;
    line-height: 27px;
    margin-bottom: 8px;
  }
  .text {
    font-size: 14px;
    line-height: 17px;
    width: 334px;
  }

  .btnOk {
    width: 107px;
    height: 40px;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 40px;
    margin-top: 20px;
  }
  .btnClose {
    top: 20px;
    right: 20px;
    background-size: 26px;
    width: 26px;
    height: 26px;
  }

  .linkDiv {
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;
  }
  .linksImg {
    margin-top: 15px;
    margin-right: 6px;
    width: 14px;
    height: 14px;
  }
  .linkText {
    font-size: 14px;
    line-height: 16px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    width: 290px;
    margin: 120px auto 0;
  }
  .img {
    margin-top: 30px;
    width: 48px;
    height: 54px;
  }

  .title {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 8px;
  }
  .text {
    font-size: 14px;
    line-height: 17px;
    width: 189px;
  }

  .btnOk {
    width: 91px;
    height: 35px;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 30px;
    margin-top: 14px;
  }
  .btnClose {
    top: 16px;
    right: 16px;
    background-size: 20px;
    width: 20px;
    height: 20px;
  }
  .linksImg {
    margin-top: 12px;
    margin-right: 6px;
    width: 14px;
    height: 14px;
  }
  .linkText {
    font-size: 12px;
    line-height: 14px;
  }

  .divCopy {
    width: 250px;
    margin: auto;
  }
  .imgCopy {
    position: relative;
    top: 2px;
    width: 12px;
    height: 12px;
    cursor: pointer;
  }
}
