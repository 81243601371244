.instructor {
  display: flex;
  height: auto;
  min-height: calc(100vh - 212px);
}
.ErrorGlobal {
  margin: auto;
  margin-bottom: 200px;
}
.nav {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  width: 585px;
  margin-bottom: 160px;
  position: sticky;
  top: 20px;
  margin-left: -10px;
}
.nav .button{
  width: auto;
  padding-right: 15px;
  padding-left: 0px;
  margin-left: 215px;
}
.nav .button:hover{
  background-color: #F1F3FF;
  border-radius: 6px;
  
}
.nav .buttonNotes{
  width: auto;
  padding-right: 50px;
  padding-left: 0px;
  margin-left: 215px;
}
.nav .buttonNotes:hover{
  background-color: #F1F3FF;
  border-radius: 6px;
}
.navBlock {
  display: block;
}
.main {
  margin-left: 10px;
}
.button {
  margin-top: 0;
  text-align: left;
  margin-left: 195px;
  background-color: #fff;
  border: none;
  cursor: pointer;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 31px;
  margin-bottom: 5px;
  position: relative;
  padding: 0;
  width: 230px;
  padding-left: 18px;
  height: 67px;
  display: flex;
  align-items: center;
  padding: 20px;
}

.buttonActive {
  margin-top: 0;
  text-align: left;
  margin-left: 195px;
  background-color: #fff;
  border: none;
  cursor: pointer;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 31px;
  margin-bottom: 5px;
  position: relative;
  padding: 0;
  width: 230px;
  padding-left: 18px;
  height: 67px;
  border-radius: 6px;
  display: flex;
  align-items: center;
}

.pActive {
  background-color: #f6f6f6;
  height: auto;
  padding: 14px 16px 14px 10px;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  z-index: 15;
}
.pActiveNotes {
  background-color: #f6f6f6;
  height: auto;
  padding: 14px 50px 14px 10px;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  z-index: 15;
}
.img {
  width: 30px;
  height: 30px;
  position: relative;
  background-color: #ffffff00;
  padding: 14px 10px 14px 14px;
}
.imgActive {
  width: 30px;
  height: 30px;
  position: relative;
  background-color: #f6f6f6;
  padding: 14px 0 14px 16px;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  z-index: 15;
}
.record {
  display: inline-flex;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  position: absolute;
  justify-content: center;
  width: 100%;
}
.permissions {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.Preloader {
  position: absolute;
  left: 50%;
  top: 20%;
}
.navFooter {
  display: none;
}
.link {
  text-decoration: none;
  cursor:default;
}
.notessvg {
  width: 23px;
  height: 26px;
  position: absolute;
  right: 205px;
  bottom: 24px;
  cursor: pointer;
  z-index: 16;
  transition: fill 0.2s ease-in-out;
  &:hover {
  rect {
    fill: #3852DE;
}
path {
    fill: white
}
}}
.buttonNotes{
  margin-top: 0;
  text-align: left;
  margin-left: 195px;
  background-color: #fff;
  border: none;
  cursor: pointer;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 31px;
  margin-bottom: 5px;
  position: relative;
  padding: 0;
  width: 230px;
  padding-left: 18px;
  height: 67px;
  display: flex;
  align-items: center;
  padding: 20px;
}
@media screen and (max-width: 1919px) {
  .instructor {
    max-width: 1919px;
    min-height: 700px;
  }
  .nav {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    width: 420px;
    margin-bottom: 160px;
    position: sticky;
    top: 20px;
  }
  .button {
    margin-top: 0;
    text-align: left;
    margin-left: 105px;
    background-color: #fff;
    border: none;
    cursor: pointer;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 5px;
    position: relative;
    padding: 0;
    width: 160px;
    padding-left: 7px;
    height: 52px;
  }
  .buttonActive {
    margin-top: 0;
    text-align: left;
    margin-left: 105px;
    border: none;
    cursor: pointer;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 5px;
    position: relative;
    padding: 0;
    width: 160px;
    padding-left: 7px;
    height: 52px;
  }
  .buttonNotes {
    margin-left: 105px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 5px;
    width: 160px;
    padding-left: 7px;
    height: 52px;
  }
  .nav .button{
    padding-right: 12px;
    margin-left: 112px;
  }
  .nav .buttonNotes{
    padding-right: 40px;
    margin-left: 112px;
  }
  .img {
    width: 24px;
    height: 24px;
    position: relative;
    padding-left: 12px;
  }
  .imgActive {
    width: 24px;
    height: 24px;
    position: relative;
    background-color: #f6f6f6;
    padding: 14px 0 14px 12px;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }
  .pActive {
    background-color: #f6f6f6;
    height: auto;
    padding: 14px 12px 14px 10px;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }
  .pActiveNotes{
    background-color: #f6f6f6;
    height: auto;
    padding: 14px 40px 14px 10px;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
    z-index: 15;
  }
  .notessvg {
    width: 20px;
    height: 23px;
    bottom: 19px;
    right: 175px;
  }
}

@media screen and (max-width: 1439px) {
  .instructor {
    max-width: 1439px;
  }
  .nav {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    width: 350px;
    margin-bottom: 160px;
  }
  .button {
    margin-top: 0;
    text-align: left;
    margin-left: 50px;
    background-color: #fff;
    border: none;
    cursor: pointer;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 5px;
    position: relative;
    padding: 0;
    width: 160px;
    padding-left: 7px;
    height: 52px;
  }
  .buttonActive {
    margin-top: 0;
    text-align: left;
    margin-left: 50px;
    border: none;
    cursor: pointer;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 5px;
    position: relative;
    padding: 0;
    width: 160px;
    padding-left: 7px;
    height: 52px;
  }

  .img {
    width: 24px;
    height: 24px;
    position: relative;
    padding-left: 12px;
  }
  .nav .button{
    padding-right: 12px;
    margin-left: 57px;
  }
  .nav .buttonNotes{
    padding-right: 40px;
    margin-left: 57px;
  }
  .imgActive {
    width: 24px;
    height: 24px;
    position: relative;
    background-color: #f6f6f6;
    padding: 14px 0 14px 12px;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }
  .pActive {
    background-color: #f6f6f6;
    height: auto;
    padding: 14px 12px 14px 10px;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }
  .main {
    margin-left: -25px;
  }
  .notessvg {
    bottom: 19px;
    right: 160px;
  }
}
@media screen and (max-width: 1279px) {
  .nav {
    display: none;
  }
  .main {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
  .instructor {
    display: flex;
    margin: 0 auto;
    justify-content: center;
  }
  .record {
    display: inline-flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    position: relative;
    justify-content: center;
    width: 100%;
  }
}
@media screen and (max-width: 1023px) {
  .main {
    margin-bottom: 120px;
  }
}
@media screen and (max-width: 767px) {
  .instructor {
    display: block;
  }
  .main {
    margin-left: 30px;
    margin-right: 0px;
    margin: 0 auto;
  }
  .navFooter {
    display: flex;
    margin-left: -30px;
    background-color: #fff;
    border: none;
    position: sticky;
    width: 100%;
    height: 44px;
    bottom: 0px;
    left: 2px;
    right: 2px;
    box-shadow: 0px 3px 46px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  .buttonFooter {
    background-color: #fff;
    border: none;
    margin: 0 auto;
    width: 20%;
    cursor: pointer;
    padding: 0;
  }
  .buttonActiveFooter {
    background-color: #f6f6f6;
    border: none;

    margin: 0 auto;
    width: 20%;
    cursor: pointer;
    padding: 0;
  }
  .navFooterHelper {
    height: 0px;
  }
  .imgFooter {
    width: 13px;
    height: 14px;
    margin-top: 6px;
    margin-bottom: 2px;
  }
  .imgActiveFooter {
    width: 13px;
    height: 14px;
    margin-top: 6px;
    margin-bottom: 2px;
  }
  .pActiveFooter {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 4px;
    margin-top: 0;
    margin-bottom: 8px;
  }
  .Preloader {
    position: absolute;
    left: 45%;
    top: 20%;
  }
}
