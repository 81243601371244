.Collaboration {
  min-height: 80vh;
  margin: 0 195px;
}
.Preloader {
  position: absolute;
  left: 50%;
  top: 30%;
}
@media screen and (max-width: 1919px) {
  .Collaboration {
    max-width: 1919px;
    margin: 0 105px;
  }
}

@media screen and (max-width: 1439px) {
  .Collaboration {
    max-width: 1439px;
    margin: 0 50px;
  }
}

@media screen and (max-width: 1279px) {
  .Collaboration {
    width: 916px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1023px) {
  .Collaboration {
    max-width: 689px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  .Collaboration {
    max-width: 290px;
    margin: 0 auto;
  }
}
