.Download {
  display: block;
  height: auto;
  min-height: calc(100vh - 212px);
  margin-left: 195px;
  width: 916px;
}
.title {
  margin-top: 60px;
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 67px;
  margin-bottom: 40px;
  text-align: left;
}
.mainSection {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 880px;
}
.Input {
  height: 60px;
  width: 880px;
}
.Input::placeholder {
  opacity: 0.5;
}
.Button {
  margin-top: 40px;
  width: 260px;
  height: 64px;
}
.Button:disabled {
  background-color: #3852de !important;
  opacity: 0.3;
}
.Text {
  color: #ff522d;
  margin-left: 0;
  position: absolute;
  top: 66px;
}
/* /////////////////////////////////////////////////////////////////////////////////////////// */

@media screen and (max-width: 1919px) {
  .Download {
    margin-left: 35px;
    width: 916px;
  }
  .title {
    margin-top: 50px;
    font-size: 44px;
    line-height: 49px;
    margin-bottom: 30px;
  }
  .Button {
    margin-top: 40px;
    width: 230px;
    height: 54px;
  }
}

/* /////////////////////////////////////////////////////////////////////////////////////////// */

@media screen and (max-width: 1439px) {
  .Download {
    margin-left: -40px;
    width: 816px;
  }
  .title {
    margin-top: 50px;
    font-size: 44px;
    line-height: 49px;
    margin-bottom: 30px;
  }
  .Input {
    height: 50px;
  }
  .mainSection {
    width: 780px;
    text-align: left;
  }
  .Button {
    width: 200px;
    height: 44px;
  }
  .Text {
    top: 56px;
    font-size: 14px;
    line-height: 17px;
  }
}
/* /////////////////////////////////////////////////////////////////////////////////////////// */

@media screen and (max-width: 1279px) {
  .Download {
    display: block;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    justify-content: space-around;
    margin: 0 auto;
    width: 916px;
  }
  .title {
    margin-top: 40px;
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 26px;
  }
  .mainSection {
    width: 880px;
  }
}

/* /////////////////////////////////////////////////////////////////////////////////////////// */

@media screen and (max-width: 1023px) {
  .Download {
    display: block;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    justify-content: space-around;
    margin: 0 auto;
    width: 688px;
  }
  .title {
    margin-top: 30px;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 20px;
  }
  .Input {
    height: 40px;
    width: 600px;
  }
  .mainSection {
    width: 665px;
    text-align: left;
  }
  .Button {
    width: 140px;
    height: 44px;
  }
  .Text {
    top: 46px;
    font-size: 12px;
    line-height: 17px;
  }
}

/* /////////////////////////////////////////////////////////////////////////////////////////// */

@media screen and (max-width: 767px) {
  .Download {
    width: 290px;
    height: auto;
  }
  .title {
    margin-top: 28px;
    font-size: 24px;
    line-height: 27px;
    margin-bottom: 16px;
  }
  .Input {
    height: 40px;
    width: 280px;
  }
  .mainSection {
    width: 280px;
    text-align: left;
  }
  .Button {
    width: 140px;
    height: 44px;
    margin-top: 20px;
  }
  .Text {
    display: none;
  }
}
