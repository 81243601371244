.ProjectsHeader {
  display: flex;

  gap: 8px;
  padding: 12px;
  background-color: #ecefff;
  border-radius: 8px;

  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .ProjectsHeader{
    display: none;
  }
}