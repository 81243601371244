.Button {
  position: relative;
  display: inline-flex;

  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
  text-decoration: none;
  vertical-align: middle;
  border: none;
  outline: none;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  text-transform: lowercase;

  transition: all ease-in-out 0.1s;

  border-radius: 6px;
}

.s {
  padding: 4px 8px;

  font-size: 14px;
  line-height: 14px;
}

.m {
  padding: 6px 12px;

  font-size: 16px;
  line-height: 18px;
}

.l {
  padding: 8px 16px;

  font-size: 24px;
  line-height: 24px;
}

.default {
  background: none;
  border: none;
}

.primary {
  background: #3852de;
  color: #ffffff;
}

.primary:hover {
  background: #2a3ca5;
}

.primary:active {
  background: #1d2a71;
}

.secondary {
  background: #ecefff;
  color: #3852de;
}

.secondary:hover {
  background: #dde0ef;
}

.secondary:active {
  background: #d7dae9;
}

.disabled,
.disabled:hover,
.disabled:active {
  color: #d7d7db;
  background: #c1c1c2;
}
