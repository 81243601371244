.interests {
  display: block;
  width: 1140px;
  margin-bottom: 180px;
}
.titleSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 67px;
  margin-top: 50px;
  margin-bottom: 40px;
}
.inputArea {
  display: flex;
  width: 100%;
}
.borderFilterDiv {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}
.borderFilter {
  width: 100%;
  height: 53px;
  border: 1px solid rgba(35, 35, 35, 0.2);
  border-radius: 8px;
  margin-right: 14px;
  display: flex;
  margin: auto;
  margin-left: 0;
}
.img {
  margin: 14.5px 8px 13.5px 23px;
  width: 24px;
  height: 24px;
}
.hobbyDiv {
  display: flex;
}
.input {
  border: none;
  width: 100%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  line-height: 17px;
  color: #818181;
  margin: auto;
  margin-left: 0;
}
.input:hover::placeholder{
  color: #B3B3B3;
}
.filterLevelOption {
  width: 134px;
  padding-left: 70px;
}
.filterLevelDiv {
  border-left: 1px solid rgba(35, 35, 35, 0.2);
  border-right: 1px solid rgba(35, 35, 35, 0.2);
  height: 100%;
  width: 100px;
  display: flex;
  position: relative;
  cursor: pointer;
  padding-left: 19px;
  padding-right: 10px;
}
.filterLevel {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: auto 0px;
  text-align: left;
  width: 100px;
}
.imgSelect {
  width: 24px;
  height: 24px;
  margin: auto 0;
}
.optionLevelDiv {
  position: absolute;
  width: 120px;
  height: 138px;
  background-color: rgb(255, 255, 255);
  top: 55px;
  left: 3px;
  transition: 0.7s;
  z-index: 3;
  box-shadow: 2px 4px 23px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}
.optionDiscriptionDiv {
  position: absolute;
  width: 180px;
  height: 115px;
  background-color: rgb(255, 255, 255);
  top: 55px;
  left: 1px;
  transition: 0.7s;
  z-index: 3;
  box-shadow: 2px 4px 23px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}
.optionLevelOver {
  position: absolute;
  width: 500px;
  height: 500px;
  left: -255px;
  top: -50px;
  z-index: -2;
  opacity: 0;
}
.optionRow {
  display: flex;
  height: 27px;
  padding-left: 10px;
  align-items: center;
}
.optionRow:hover {
  cursor: pointer;
  color: #434343;
}
.optionRow:first-child {
  margin-top: 15px;
}
.optionRow:last-child {
  margin-bottom: 20px;
}
.imgOption {
  width: 28px;
  height: 10px;
  margin-right: 6px;
}
.optionLevel {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}
.filterDescriptionDiv {
  height: 100%;
  width: 160px;
  display: flex;
  position: relative;
  padding-left: 19px;
  padding-right: 10px;
  cursor: pointer;
}
.filterDescription {
  display: flex;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: auto 0px;
  text-align: left;
  width: 160px;
}
.imgBut {
  background-color: #3852de;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: rgb(255, 255, 255);
  border-radius: 8px;
  border: none;
  width: 150px;
  height: 53px;
  cursor: pointer;
  transition: 0.5s;
  padding-bottom: 2px;
}
.imgBut:hover {
  box-sizing: border-box;
  color: #3852de;
  background-color: #fff;
  border: 1px solid #3852de;
  transition: 0.5s;
}
.trHobbies {
  background-color: #ecefff;
  border-radius: 8px;
  width: 1140px;
  height: 59px;
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.hobbyTh {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
  width: 450px;
  text-align: left;
  padding-left: 10px;
  padding-right: 16px;
}
.levelTh {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
  width: 150px;
  text-align: left;
}
.descriptionTh {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
  width: 100px;
  text-align: center;
}
.list {
  height: auto;
  border-bottom: 1px solid rgba(35, 35, 35, 0.2);
  width: 1140px;
  display: flex;
}
.list:last-of-type {
  border-bottom: none;
}
.hobby {
  width: 455px;
  text-align: left;
  margin-left: 10px;
  align-items: center;
  margin-right: 10px;
  margin-top: 18px;
  margin-bottom: 18px;
}
.hobbyType {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
  margin-top: 0px;
  margin-bottom: 0;
}
.hobbyName {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin-top: 7px;
  margin-bottom: 0;
}
.level {
  display: flex;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  width: 80px;
  text-align: left;
  margin-top: 18px;
  margin-bottom: 18px;
  margin-right: 75px;
  height: 28px;
  align-items: center;
  height: auto;
}
.description {
  display: flex;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: left;
  padding-left: 0px;
  width: 380px;
  margin: 18px 0;
  align-items: center;
  height: auto;
}
.worning {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-top: 150px;
}

@media screen and (max-width: 1919px) {
  .interests {
    display: block;
    width: 915px;
    margin-bottom: 180px;
  }
  .titleSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .title {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 49px;
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .inputArea {
    display: flex;
    width: 100%;
  }
  .borderFilter {
    width: 100%;
    height: 44px;
    border: 1px solid rgba(35, 35, 35, 0.2);
    border-radius: 8px;
    margin-right: 6px;
    display: flex;
    margin: auto;
    margin-left: 0;
  }
  .img {
    margin: 10.5px 8px 13.5px 23px;
    width: 24px;
    height: 24px;
  }
  .input {
    border: none;
    width: 100%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #818181;
    margin: auto;
    margin-left: 0;
  }

  .filterLevelOption {
    width: 134px;
    padding-left: 70px;
  }
  .filterLevelDiv {
    border-left: 1px solid rgba(35, 35, 35, 0.2);
    border-right: 1px solid rgba(35, 35, 35, 0.2);
    height: 100%;
    width: 100px;
    display: flex;
    position: relative;
    cursor: pointer;
  }
  .filterLevel {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin: auto 0px;
    text-align: left;
    width: 100px;
  }
  .imgSelect {
    width: 24px;
    height: 24px;
    margin: auto 0;
  }
  .optionLevelDiv {
    position: absolute;
    width: 123px;
    height: 138px;
    background-color: rgb(255, 255, 255);
    top: 50px;
    left: 3px;
    transition: 0.7s;
    z-index: 3;
    box-shadow: 2px 4px 23px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
  }
  .optionDiscriptionDiv {
    position: absolute;
    width: 180px;
    height: 115px;
    background-color: rgb(255, 255, 255);
    top: 50px;
    left: 1px;
    transition: 0.7s;
    z-index: 3;
    box-shadow: 2px 4px 23px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
  }
  .optionLevelOver {
    position: absolute;
    width: 500px;
    height: 500px;
    left: -285px;
    top: -50px;
    z-index: -2;
    opacity: 0;
  }
  .optionRow {
    display: flex;
    height: 27px;
    padding-left: 10px;
    align-items: center;
  }
  .optionRow:first-child {
    margin-top: 15px;
  }
  .optionRow:last-child {
    margin-bottom: 20px;
  }
  .imgOption {
    width: 28px;
    height: 10px;
    margin-right: 6px;
  }
  .optionLevel {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
  .filterDescriptionDiv {
    height: 100%;
    width: 160px;
    display: flex;
    position: relative;
    cursor: pointer;
  }
  .filterDescription {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin: auto 0px;
    text-align: left;
    width: 160px;
  }
  .imgBut {
    box-sizing: border-box;
    background-color: #3852de;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: rgb(255, 255, 255);
    border-radius: 8px;
    border: none;
    width: 111px;
    height: 45px;
    cursor: pointer;
    transition: 0.5s;
  }
  .imgBut:hover {
    box-sizing: border-box;
    color: #3852de;
    background-color: #fff;
    border: 1px solid #3852de;
    transition: 0.5s;
  }
  .trHobbies {
    background-color: #ecefff;
    border-radius: 8px;
    width: 915px;
    height: 43px;
    display: flex;
    align-items: center;
    margin-top: 30px;
  }
  .hobbyTh {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    width: 370px;
    text-align: left;
    padding-left: 10px;
    padding-right: 16px;
  }
  .levelTh {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    width: 110px;
    text-align: left;
  }
  .descriptionTh {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    width: 100px;
    text-align: center;
  }
  .list {
    height: auto;
    border-bottom: 1px solid rgba(35, 35, 35, 0.2);
    width: 915px;
    display: flex;
  }
  .list:last-of-type {
    border-bottom: none;
  }
  .hobby {
    width: 370px;
    text-align: left;
    margin-left: 10px;
    align-items: center;
    margin-right: 10px;
    margin-top: 18px;
    margin-bottom: 18px;
  }
  .hobbyType {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    margin-top: 0px;
    margin-bottom: 0;
  }
  .hobbyName {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin-top: 7px;
    margin-bottom: 0;
  }
  .level {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    width: 80px;
    text-align: left;
    /* margin-top: 28px; */
    margin-right: 40px;
    height: auto;
  }
  .description {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    padding-left: 0px;
    width: 380px;
    margin: 18px 0;
    height: auto;
  }
  .worning {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-top: 150px;
  }
}

@media screen and (max-width: 1439px) {
  .interests {
    width: 880px;
  }
  .trHobbies {
    width: 880px;
  }
  .hobbyTh {
    width: 380px;
  }
  .levelTh {
    width: 130px;
  }
  .descriptionTh {
    width: 100px;
  }
  .list {
    width: 880px;
  }
  .hobby {
    width: 395px;
    text-align: left;
    margin-left: 10px;
    align-items: center;
    margin-right: 0px;
    margin-top: 18px;
    margin-bottom: 18px;
  }
  .hobbyType {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
    margin-top: 0px;
    margin-bottom: 0;
  }
  .hobbyName {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    margin-top: 7px;
    margin-bottom: 0;
  }
  .level {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    width: 80px;
    text-align: left;
    /* margin-top: 28px; */
    margin-right: 55px;
    height: auto;
  }
  .description {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    padding-left: 0px;
    width: 380px;
    margin: 18px 0;
    height: auto;
  }
}
@media screen and (max-width: 1279px) {
  .hobbyType {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
  }
  .hobbyName {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
  .interests {
    width: 916px;
  }
  .trHobbies {
    width: 916px;
  }
  .hobby {
    width: 345px;
    margin-right: 50px;
  }
  .description {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-left: 55px;
  }
  .descriptionTh {
    margin-left: 55px;
  }
  .list {
    width: 916px;
  }
  .trHobbies {
    margin-top: 20px;
  }
  .title {
    margin-top: 40px;
  }
}
@media screen and (max-width: 1023px) {
  .descriptionTh {
    margin-left: 0px;
  }
  .interests {
    width: 689px;
  }

  .title {
    margin-top: 30px;
    font-size: 32px;
    line-height: 36px;
  }
  .borderFilter {
    /* width: 582px; */
    width: 100%;
    height: 38px;
  }
  .img {
    margin: 8.5px 8px 9.5px 13px;
    width: 20px;
    height: 20px;
  }
  .input {
    /* width: 220px; */
    width: 100%;
  }

  .imgBut {
    width: 101px;
    height: 40px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

  .trHobbies {
    width: 689px;
  }
  .hobbyTh {
    width: 270px;
    font-size: 16px;
    line-height: 18px;
  }
  .levelTh {
    width: 90px;
    font-size: 16px;
    line-height: 18px;
  }
  .descriptionTh {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    width: 100px;
    text-align: center;
  }
  .list {
    width: 689px;
  }
  .hobby {
    width: 270px;
    margin-right: 15px;
  }
  .hobbyType {
    font-size: 18px;
    line-height: 20px;
  }
  .hobbyName {
    font-size: 14px;
    line-height: 17px;
    width: 247px;
  }
  .level {
    width: 60px;
    margin-right: 35px;
  }
  .description {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    padding-left: 0px;
    width: 380px;
    margin: 18px 0;
    height: auto;
  }
  .optionLevelOver {
    position: absolute;
    width: 400px;
    height: 500px;
    left: -185px;
    top: -50px;
    z-index: -2;
    opacity: 0;
  }

  .optionLevelDiv {
    top: 40px;
  }
  .optionDiscriptionDiv {
    top: 40px;
  }
}

@media screen and (max-width: 767px) {
  .interests {
    width: 290px;
    margin-left: -15px;
    margin: 0 auto 100px;
    min-height: 730px;
  }
  .title {
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
    margin-top: 28px;
    margin-bottom: 16px;
  }
  .inputArea {
    display: block;
    width: 100%;
    position: relative;
  }
  .borderFilter {
    width: 100%;
    height: 44px;
    border: 1px solid rgba(35, 35, 35, 0.2);
    margin-right: 0px;
    display: block;
    margin: auto;
    margin-left: 0;
    margin-right: 0px;
  }
  .img {
    margin: 13.5px 8px 15.5px 13px;
    width: 20px;
    height: 20px;
  }
  .imgSelect {
    width: 20px;
    height: 20px;
  }
  .input {
    border: none;
    width: 100%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    margin: auto;
    margin-left: 0;
    margin-bottom: 17px;
  }
  .imgBut {
    background-color: #3852de;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgb(255, 255, 255);
    border-radius: 8px;
    border: none;
    width: 100%;
    height: 45px;
    cursor: pointer;
    transition: 0.5s;
    position: absolute;
    top: 100px;
    left: 0;
  }
  .filterLevelDiv {
    border-left: none;
    border-right: none;
    height: 100%;
    display: flex;
    position: relative;
    cursor: pointer;
    width: 40%;
  }
  .filterLevel {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    border: none;
    margin: auto 0px;
    text-align: left;
    width: 120px;
    left: -18px;
    position: relative;
  }

  .trHobbies {
    display: none;
  }
  .hobbyTh {
    width: 270px;
  }
  .levelTh {
    width: 90px;
  }
  .descriptionTh {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    width: 100px;
    text-align: center;
  }
  .list {
    width: 280px;
    display: block;
    margin-left: 0px;
  }
  .hobby {
    width: 220px;
    margin-right: 00px;
    margin-left: 0;
  }
  .hobbyType {
    font-size: 18px;
    line-height: 20px;
    font-size: 16px;
    line-height: 18px;
  }
  .hobbyName {
    font-size: 14px;
    line-height: 17px;
  }
  .level {
    width: 60px;
    height: 20px;
    margin-right: 0px;
    margin-left: 10px;
  }
  .description {
    font-size: 12px;
    line-height: 15px;
    height: auto;
    margin-top: 0;
    width: 220px;
    margin-bottom: 14px;
  }
  .optionLevelOver {
    position: absolute;
    width: 250px;
    height: 600px;
    left: -75px;
    top: -150px;
    z-index: -2;
    opacity: 0;
  }
  .filterDescriptionDiv {
    position: absolute;
    width: auto;
    height: auto;
    background-color: rgb(255, 255, 255);
    top: 50px;
    left: 90px;
    transition: 0.7s;
    z-index: 3;
    box-shadow: none;
    border-radius: 6px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .filterDescription {
    width: auto;
    margin-right: 5px;
  }
  .filterLevelDiv {
    position: absolute;
    width: auto;
    height: auto;
    background-color: rgb(255, 255, 255);
    top: 50px;
    left: -10px;
    transition: 0.7s;
    z-index: 3;
    box-shadow: none;
    border-radius: 6px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .filterLevel {
    width: auto;
    margin-right: 0px;
    left: -6px;
  }
  .searchSection {
    margin-bottom: 58px;
  }
  .optionLevelDiv {
    left: -2px;
  }
}
