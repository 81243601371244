.ContactUs {
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 90;
    top: 0px;
    left: 0;
    display: flex;
    transition: all 1s ease-out;;
  }
  .ContactUsHidden {
    position: fixed;
    left: 0;
    z-index: 15;
    top: 0px;
    transition: all 1s ease-out;
  }
  
  .popup {
    position: fixed;
    width: 750px;
    height: auto;
    background: #ffffff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.6s;
    transform: translate(-50%, -50%) scale(1);
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  .popupHidden {
    position: fixed;
    width: 750px;
    height: auto;
    background: #ffffff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.6s;
    transform: translate(-50%, -50%) scale(0);
  }
  .img {
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
  }
  .img:hover {
    opacity: 0.7;
  }
  .mainDiv{
    text-align: left;
    margin: auto;
  }
  .title {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 45px;
    margin-top: 60px;
    margin-bottom: 0;
  }

  .text{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-top: 24px;
  }
.input{
  width: 542px;
  height: 50px;
  box-sizing: border-box;
  padding: 15px 50px 15px 20px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  border: 1px solid rgba(35, 35, 35, 0.2);
border-radius: 8px;
}
.inputArea{
  width: 542px;
  height: 127px;
  box-sizing: border-box;
  padding: 15px 50px 15px 20px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  border: 1px solid rgba(35, 35, 35, 0.2);
  border-radius: 8px;
  display: flex;
  text-align:start;
  resize: none;
}
.inputArea:focus {
  outline: none !important;
  border:1px solid rgba(35, 35, 35, 0.2);
}
.button{
  width: 160px;
  height: 54px;
  background: #3852DE;
  border-radius: 8px;
  border: none;
  display: flex;
  margin: 30px auto 60px;
  cursor: pointer;
  &:disabled{
    background: #878788ba;
    cursor:auto;
  }

  p{
    color: #FFFFFF;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin: auto;
  }
}
.button:hover {
  background: #3046c2;
}
button[disabled]:hover {
  background: #878788ba;
}
@media screen and (max-width: 1919px) {
  
  .popup {
    width: 691px;
  }
  .popupHidden {
    width: 691px;
  }
  .img {
    right: 30px;
    top: 30px;
  }

  .title {
    font-size: 34px;
    line-height: 38px;
    margin-top: 60px;
  }

  .text{
margin-top: 24px;
font-size: 18px;
line-height: 22px;
  }
.input{
  width: 542px;
  height: 45px;
  font-size: 14px;
  line-height: 17px;
}
.inputArea{
  width: 542px;
  height: 108px;
  font-size: 14px;
  line-height: 17px;
}
.inputArea:focus {
  outline: none !important;
  border:1px solid rgba(35, 35, 35, 0.2);
}
.button{
  width: 160px;
  height: 54px;
  margin: 30px auto 60px;
  cursor: pointer;
  p{
    font-size: 18px;
    line-height: 22px;
  }

}}

@media screen and (max-width: 1279px) {
  
  .popup {
    width: 599px;
  }
  .popupHidden {
    width: 599px;
  }
  .img {
    right: 30px;
    top: 30px;
    width: 40px;
height: 40px;
  }

  .title {
    font-size: 28px;
    line-height: 31px;
    margin-top: 52px;
  }

  .text{
margin-top: 24px;
font-size: 16px;
line-height: 19px;
  }
.input{
  width: 473px;
  height: 45px;
  font-size: 14px;
  line-height: 17px;
}
.inputArea{
  width: 473px;
  height: 108px;
  font-size: 14px;
  line-height: 17px;
}
.inputArea:focus {
  outline: none !important;
  border:1px solid rgba(35, 35, 35, 0.2);
}
.button{
  width: 160px;
  height: 52px;
  margin: 24x auto 54px;
  cursor: pointer;
  p{
    font-size: 18px;
    line-height: 22px;
  }

}

}
  @media screen and (max-width: 1023px) {
  
    .popup {
      width: 573px;
    }
    .popupHidden {
      width: 573px;
    }
    .img {
      right: 20px;
      top: 20px;
      width: 26px;
height: 26px;
    }
  
    .title {
      font-size: 24px;
      line-height: 27px;
      margin-top: 43px;
    }
  
    .text{
  margin-top: 18px;
  font-size: 14px;
  line-height: 17px;
    }
  .input{
    width: 432px;
    height: 45px;
    font-size: 14px;
    line-height: 17px;
  }
  .inputArea{
    width: 432px;
    height: 108px;
    font-size: 14px;
    line-height: 17px;
  }
  .inputArea:focus {
    outline: none !important;
    border:1px solid rgba(35, 35, 35, 0.2);
  }
  .button{
    width: 160px;
    height: 52px;
    margin: 18x auto 44px;
    cursor: pointer;
    p{
      font-size: 16px;
      line-height: 19px;
    }
  
  }
  
  }
  @media screen and (max-width: 767px) {
  
    .popup {
      width: 290px;;
    }
    .popupHidden {
      width: 290px;
    }
    .img {
      right: 10px;
      top: 10px;
      width: 26px;
height: 26px;
    }
  
    .title {
      font-size: 20px;
      line-height: 22px;
      margin-top: 28px;
    }
  
    .text{
  margin-top: 14px;
  font-size: 14px;
  line-height: 17px;
    }
  .input{
    width: 260px;
    height: 40px;
    font-size: 14px;
    line-height: 17px;
  }
  .inputArea{
    width: 260px;
    height: 108px;
    font-size: 14px;
    line-height: 17px;
  }
  .inputArea:focus {
    outline: none !important;
    border:1px solid rgba(35, 35, 35, 0.2);
  }
  .button{
    width: 260px;
    height: 39px;
    margin: 14x auto 28px;
    cursor: pointer;
    p{
      font-size: 14px;
      line-height: 17px;
    }
  
  }
  }
  