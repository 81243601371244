.Checkbox {
  display: flex;
  align-items: center;

  .control {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    margin-right: 12px;
    border: 1px solid #3852de;
    outline: none;
    cursor: pointer;
  }

  .control.checked {
    background-color: #3852de;
    position: relative;
  }

  .control.checked::before {
    content: url('../../../images/checkboxTick.svg');
    font-size: 14px;
    color: #fff;
    position: absolute;
    right: 1px;
    top: 1px;
  }

  .control:disabled {
    border-color: #c0c0c0;
    background-color: #c0c0c0;
  }

  .label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-transform: lowercase;
  }

  .control:disabled + .label {
    color: #c0c0c0;
  }

}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 767px) {
  .label {
    font-size: 14px !important;
  }
  .control {
    width: 14px !important;
    height: 14px !important;
    margin-right: 8px !important;
  }
  .control.checked::before {
    content: url('../../../images/checkboxTick.svg');
    font-size: 12px !important;
    color: #fff;
    position: absolute;
    right: -1px !important;
    top: 0px !important;
  }

}