.Eduwiki{
display: flex;
justify-content: space-between;
}
.leftSide{
margin-top: 333px;
margin-left: 195px;
}
.title{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 44px;
    line-height: 56px;
    margin: 0;
    margin-bottom: 60px;
}
.mainText{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
    margin: 0;
    margin-bottom: 30px;
    width: 620px;
}
.text{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    margin: 0;
    width: 620px;
}
.rightSide{
    background: #3852DE;
    border-radius: 20px 0px 0px 20px;
    position: relative;
    top: 140px;
    right: 0;
    width: 815px;
    height: 699px;
}
.eduwikiImg{
    width: 780px;
    height: 557px;
    position: absolute;
    top: 71px;
    right: 195px;
}





    /* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */
    @media screen and (max-width: 1919px) {
    .Eduwiki{
    display: flex;
    justify-content: space-between;
    }
    .leftSide{
    margin-top: 277px;
    margin-left: 105px;
    }
    .title{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 56px;
        margin: 0;
        margin-bottom: 50px;
    }
    .mainText{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 34px;
        margin: 0;
        margin-bottom: 22px;
        width: 506px;
    }
    .text{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
        margin: 0;
        width: 506px;
    }
    .rightSide{
        background: #3852DE;
        border-radius: 20px 0px 0px 20px;
        position: relative;
        top: 150px;
        right: 0;
        width: 637px;
        height: 603px;
    }
    .eduwikiImg{
        width: 679px;
        height: 485px;
        position: absolute;
        top: 59px;
        right: 59px;
    }

}


@media screen and (max-width: 1439px) {
    .leftSide{
        margin-top: 236px;
        margin-left: 50px;
        }
        .title{
            font-size: 32px;
            line-height: 40px;
            margin-bottom: 40px;
        }
        .mainText{
            font-size: 24px;
            line-height: 29px;
            margin-bottom: 18px;
            width: 480px;
        }
        .text{
            font-size: 18px;
            line-height: 24px;
            width: 480px;
        }
        .rightSide{
            top: 100px;
            right: 0;
            width: 532px;
            height: 500px;
        }
        .eduwikiImg{
            width: 532px;
            height: 500px;
            top: 0px;
            right: 50px;
        }
    }
    
    /* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */
    @media screen and (max-width: 1279px) {
    .leftSide{
        margin-top: 167px;
        margin-left: 54px;
        }
        .title{
            font-size: 26px;
            line-height: 40px;
            margin-bottom: 36px;
        }
        .mainText{
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 16px;
            width: 370px;
        }
        .text{
            font-size: 16px;
            line-height: 20px;
            width: 370px;
        }
        .rightSide{
            top: 70px;
            right: 0;
            width: 444px;
            height: 394px;
        }
        .eduwikiImg{
            width: 448px;
            height: 321px;
            top: 37px;
            right: 54px;
        }
    }
    /* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
    @media screen and (max-width: 1023px) {
    .leftSide{
        margin-top: 136x;
        margin-left: 40px;
        }
    .title{
        margin-top: -30px;
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 30px;
    }
    .mainText{
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 12px;
        width: 334px;
    }
    .text{
        font-size: 14px;
        line-height: 18px;
        width: 334px;
    }
    .rightSide{
        top: 80px;
        right: 0;
        width: 298px;
        height: 276px;
    }
    .eduwikiImg{
        width: 305px;
        height: 219px;
        top: 29px;
        right: 40px;
    }
    }
    
    /* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
    @media screen and (max-width: 767px) {
    .Eduwiki{
    width: 290px;
    display: flex;
    flex-direction: column;
    margin: -100px auto 0;
    }
    .leftSide{
        margin-top: 60x;
        margin-left: 0px;
        }
    .title{
        margin-top: 0px;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 20px;
    }
    .mainText{
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 10px;
        width: 290px;
    }
    .text{
        font-size: 14px;
        line-height: 18px;
        width: 290px;
    }
    .rightSide{
        top: 26px;
        right: -85px;
        width: 219px;
        height: 242px;
    }
    .eduwikiImg{
        width: 276px;
        height: 198px;
        top: 22px;
        right: 15px;
    }
    }