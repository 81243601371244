.PersonalFitback {
  display: block;
  margin-top: 20px;
  margin-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}
.selectDiv {
  display: flex;
  align-items: center;
}
.flexRow {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 1120px;
  align-items: center;
  margin-bottom: 20px;
}
.flexRowBottom {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 1120px;
  margin-top: -30px;
}
.feedbackToggle:hover{
  background-color: #2B46D4;
}
.selectText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin: 0;
  padding: 0;
  margin-right: 14px;
}
.select {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.toggleDiv {
  position: relative;
}
.feedbackToggle {
  background: #3852de;
  border-radius: 32px;
  height: 35px;
  align-items: center;
  display: flex;
  box-sizing: border-box;
  padding: 8px 14px;
  margin-right: 6px;
  margin-top: 6px;
  position: relative;
  top: -2px;
  cursor: pointer;
}
.feedbackToggleDIV {
  position: absolute;
  width: auto;
  height: auto;
  background-color: rgb(255, 255, 255);
  top: 48px;
  left: 5px;
  transition: 0.7s;
  z-index: 3;
  box-shadow: 2px 4px 23px rgba(0, 0, 0, 0.112);
  border-radius: 6px;
  padding-top: 15px;
  padding-bottom: 10px;
  min-width: max-content;
}
.imgSelect {
  cursor: pointer;
  color: rgb(255, 255, 255);
  margin-left: 10px;
  path{
    stroke: white;
  }
}
.feedbackTextButtonList {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  margin: auto 15px auto 10px;
  text-align: left;
  cursor: pointer;
}
.feedbackTextButtonList:hover {
  background-color: #F1F3FF;
}
.feedbackTextButton {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin: auto;
  position: relative;
  top: -2px;
}
.feedbackToggleOne{
  color: #000;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  }
.counterText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #818181;
  margin-right: 8;
}
.counter {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  width: 31px;
  margin-right: 19px;
  position: relative;
  margin-left: 5px;
}
.counterStudents{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  width: 31px;
  margin-right: 19px;
  position: relative;
  margin-left: 5px;
}
.sectionIssue {
  display: flex;
  width: 1120px;
  flex-wrap: wrap;
  align-items: start;
  margin-top: 20px;
  gap: 34px 30px;
  margin-bottom: 42px;
}

.particularIssue {
  width: 350.67px;
  height: auto;
  margin-right: 0px;
  margin-bottom: 0px;
  align-items: start;
}

.questionName {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  min-height: 40px;
}
.hints {
  display: flex;
  align-items: center;
}
.hintColorGreen {
  background-color: #0ddc65;
  border-radius: 4px;
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
.hintColorGreen2 {
  background-color: #0d7663;
  border-radius: 4px;
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
.hintColorYellow {
  background-color: #fecd1a;
  border-radius: 4px;
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
.hintColorGreenOrange {
  background-color: #ff9900;
  border-radius: 4px;
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
.hintColorGreenRed {
  background-color: #ff613f;
  border-radius: 4px;
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.hintText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-right: 12px;
}
.hintText:last-of-type {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-right: 0px;
}
.link {
  display: flex;
  align-items: center;
}
.img {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}
.linkGrey {
  color: #818181;
  margin-right: 12px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

.linkText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #000;
}
.linkText:hover {
  color: #4B63E1;
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@media screen and (max-width: 1919px) {
  .PersonalFitback {
    display: block;
    margin-top: 0px;
    margin-left: 15px;
  }

  .flexRow {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 891px;
    align-items: center;
    margin-bottom: 30px;
  }
  .flexRowBottom {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 891px;
    margin-top: -30px;
  }

  .selectText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    margin: 0;
    padding: 0;
    margin-right: 14px;
  }
  .select {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .toggleDiv {
    position: relative;
  }
  .feedbackToggle {
    background: #3852de;
    border-radius: 32px;
    height: 35px;
    align-items: center;
    display: flex;
    box-sizing: border-box;
    padding: 8px 14px;
    margin-right: 6px;
    margin-top: 6px;
    position: relative;
    top: -2px;
  }
  .feedbackToggleDIV {
    position: absolute;
    width: auto;
    height: auto;
    background-color: rgb(255, 255, 255);
    top: 48px;
    left: 5px;
    transition: 0.7s;
    z-index: 3;
    box-shadow: 2px 4px 23px rgba(0, 0, 0, 0.112);
    border-radius: 6px;
    padding-top: 15px;
    padding-bottom: 10px;
  }
  .imgSelect {
    cursor: pointer;
    color: rgb(255, 255, 255);
    margin-left: 10px;
  }
  .feedbackTextButtonList {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    margin: auto 15px auto 10px;
    text-align: left;
    cursor: pointer;
  }
  .feedbackTextButton {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    margin: auto;
    top: 0;
  }
  .feedbackToggleOne{
    font-size: 18px;
    line-height: 19px;
    }
  .counterText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #818181;
    margin-right: 8;
  }
  .counter {
    font-size: 18px;
    line-height: 22px;
    width: 31px;
    margin-right: 19px;
    top:-1px;
  }
  .counterStudents{
    font-size: 18px;
    line-height: 22px;
    width: 31px;
    margin-right: 19px;
    margin-left: 8px;
    top:-1px;
    margin-left: 5px;
  }
  .sectionIssue {
    display: flex;
    width: 906px;
    flex-wrap: wrap;
    align-items: start;
    margin-top: 0;
    gap: 24px;
    margin-bottom: 30px;
  }

  .particularIssue {
    width: 282.33px;
    height: auto;
    margin-right: 0px;
    margin-bottom: 0px;
    align-items: start;
  }

  .questionName {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    min-height: 40px;
  }
  .hints {
    display: flex;
    align-items: center;
  }
  .hintColorGreen {
    background-color: #0ddc65;
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  .hintColorGreen2 {
    background-color: #0d7663;
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  .hintColorYellow {
    background-color: #fecd1a;
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  .hintColorGreenOrange {
    background-color: #ff9900;
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  .hintColorGreenRed {
    background-color: #ff613f;
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }

  .hintText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-right: 12px;
  }
  .link {
    display: flex;
    align-items: center;
  }
  .img {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }
  .linkGrey {
    color: #818181;
    margin-right: 12px;
  }

  .linkText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #000;
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@media screen and (max-width: 1439px) {
  .PersonalFitback {
    display: block;
    margin-top: 0px;
    margin-left: 15px;
  }

  .flexRow {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 884px;
    align-items: center;
    margin-bottom: 30px;
  }
  .flexRowBottom {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 884px;
    margin-top: -30px;
  }

  .selectText {
    font-size: 18px;
    line-height: 22px;
    margin: 0;
    padding: 0;
    margin-right: 14px;
  }
  .select {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .toggleDiv {
    position: relative;
  }
  .feedbackToggle {
    background: #3852de;
    border-radius: 32px;
    height: 35px;
    align-items: center;
    display: flex;
    box-sizing: border-box;
    padding: 8px 14px;
    margin-right: 6px;
    margin-top: 6px;
    position: relative;
    top: -2px;
  }
  .feedbackToggleDIV {
    position: absolute;
    width: auto;
    height: auto;
    background-color: rgb(255, 255, 255);
    top: 48px;
    left: 5px;
    transition: 0.7s;
    z-index: 3;
    box-shadow: 2px 4px 23px rgba(0, 0, 0, 0.112);
    border-radius: 6px;
    padding-top: 15px;
    padding-bottom: 10px;
  }
  .imgSelect {
    cursor: pointer;
    color: rgb(255, 255, 255);
    margin-left: 10px;
  }
  .feedbackTextButtonList {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    margin: auto 15px auto 10px;
    text-align: left;
    cursor: pointer;
  }
  .feedbackTextButton {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    margin: auto;
    top: 0;
  }
  .feedbackToggleOne{
    font-size: 16px;
    line-height: 17px;
    }
  .counterText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #818181;
    margin-right: 8;
  }
  .counter {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    width: 31px;
    margin-right: 19px;
  }
  .counterStudents{
    font-size: 18px;
    line-height: 22px;
    width: 31px;
    margin-right: 19px;

  }
  .sectionIssue {
    display: flex;
    width: 891px;
    flex-wrap: wrap;
    align-items: start;
    gap: 30px;
    margin-bottom: 30px;
  }

  .particularIssue {
    width: 263px;
    height: auto;
    margin-right: 0px;
    margin-bottom: 0px;
    align-items: start;
  }

  .questionName {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    min-height: 40px;
  }
  .hints {
    display: flex;
    align-items: center;
  }
  .hintColorGreen {
    background-color: #0ddc65;
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  .hintColorGreen2 {
    background-color: #0d7663;
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  .hintColorYellow {
    background-color: #fecd1a;
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  .hintColorGreenOrange {
    background-color: #ff9900;
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  .hintColorGreenRed {
    background-color: #ff613f;
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }

  .hintText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-right: 12px;
  }
  .link {
    display: flex;
    align-items: center;
  }
  .img {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }
  .linkGrey {
    color: #818181;
    margin-right: 12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  .linkText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000;
  }
}

//////////////////////////////////////////////////////////////////////

@media screen and (max-width: 1023px) {
  .PersonalFitback {
    display: block;
    margin-top: 0px;
    margin-left: 15px;
    width: 688px;
  }

  .flexRow {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 650px;
    align-items: center;
    margin-bottom: 30px;
  }
  .flexRowBottom {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 650px;
    margin-top: -30px;
  }

  .selectText {
    display: none;
  }
  .select {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .toggleDiv {
    position: relative;
  }
  .feedbackToggle {
    background: #3852de;
    border-radius: 32px;
    height: 35px;
    align-items: center;
    display: flex;
    box-sizing: border-box;
    padding: 8px 14px;
    margin-right: 6px;
    margin-top: 6px;
    position: relative;
    top: -2px;
  }
  .feedbackToggleDIV {
    position: absolute;
    width: auto;
    height: auto;
    background-color: rgb(255, 255, 255);
    top: 48px;
    left: 5px;
    transition: 0.7s;
    z-index: 3;
    box-shadow: 2px 4px 23px rgba(0, 0, 0, 0.112);
    border-radius: 6px;
    padding-top: 15px;
    padding-bottom: 10px;
  }
  .imgSelect {
    cursor: pointer;
    color: rgb(255, 255, 255);
    margin-left: 10px;
  }
  .feedbackTextButtonList {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    margin: auto 15px auto 10px;
    text-align: left;
    cursor: pointer;
  }
  .feedbackTextButtonList:hover {
    background-color: rgba(245, 241, 241, 0.797);
  }
  .feedbackTextButton {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    margin: auto;
    top: 0;
  }
  .feedbackToggleOne{
    font-size: 14px;
    line-height: 19px;
    }
  .counterText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #818181;
    margin-right: 8;
  }
  .counter {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    width: 31px;
    margin-right: 19px;
    top: 1px;
  }
  .counterStudents{
    font-size: 16px;
    line-height: 19px;
    width: 31px;
    margin-right: 19px;
    top: 1px;
  }
  .sectionIssue {
    display: flex;
    width: 666px;
    flex-wrap: wrap;
    align-items: start;
    margin-top: 0;
    margin-bottom: 16px;
    grid-template: 16px;
    gap: 15px;
  }

  .particularIssue {
    width: 207px;
    height: auto;
    margin-right: 0px;
    margin-bottom: 0px;
    align-items: start;
  }

  .questionName {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    min-height: 40px;
  }
  .hints {
    display: none;
  }
  .hintColorGreen {
    background-color: #0ddc65;
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  .hintColorGreen2 {
    background-color: #0d7663;
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  .hintColorYellow {
    background-color: #fecd1a;
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  .hintColorGreenOrange {
    background-color: #ff9900;
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  .hintColorGreenRed {
    background-color: #ff613f;
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }

  .hintText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-right: 12px;
  }
  .link {
    display: flex;
    align-items: center;
  }
  .img {
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }
  .linkGrey {
    color: #818181;
    margin-right: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  .linkText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000;
  }
}

//////////////////////////////////////////////////////////////////////

@media screen and (max-width: 767px) {
  .PersonalFitback {
    display: block;
    background: rgba(246, 246, 246, 0.69);
    width: 320px;
    padding-left: 15px;
    margin-left: -15px;
    margin-top: -15px;
  }

  .flexRow {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 305px;
    align-items: center;
    margin-bottom: 30px;
  }
  .flexRowBottom {
    display: block;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 305px;
    margin-top: -30px;
  }

  .selectText {
    display: none;
  }
  .select {
    display: block;
    align-items: center;
    flex-wrap: wrap;
  }
  .selectDiv {
    display: flex;
    margin-top: 5px;
  }
  .toggleDiv {
    position: relative;
  }
  .feedbackToggle {
    background: #3852de;
    border-radius: 32px;
    height: auto;
    align-items: center;
    display: flex;
    box-sizing: border-box;
    padding: 8px 14px;
    margin-right: 6px;
    margin-top: 26px;
    position: relative;
    top: -2px;
    margin-bottom: 15px;
  }
  .feedbackToggleDIV {
    position: absolute;
    width: auto;
    height: auto;
    background-color: rgb(255, 255, 255);
    top: 48px;
    left: 5px;
    transition: 0.7s;
    z-index: 3;
    box-shadow: 2px 4px 23px rgba(0, 0, 0, 0.112);
    border-radius: 6px;
    padding-top: 15px;
    padding-bottom: 10px;
  }
  .imgSelect {
    cursor: pointer;
    color: rgb(255, 255, 255);
    margin-left: 10px;
  }
  .feedbackTextButtonList {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin: auto 15px auto 10px;
    text-align: left;
    cursor: pointer;
    max-width: 260px;
  }
  .feedbackTextButtonList:hover {
    background-color: rgba(245, 241, 241, 0.797);
  }
  .feedbackTextButton {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    margin: auto;
    top: 0;
  }

  .counterText {
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #818181;
    margin-right: 8;
  }
  .counter {
    margin: 0;
    font-size: 16px;
    line-height: 19px;
    width: 31px;
    margin-right: 19px;
    margin-left: 5px;
  }
  .counterStudents{
    margin-left: 5px;
  }
  .sectionIssue {
    display: flex;
    width: 290px;
    flex-wrap: wrap;
    align-items: start;
    margin-top: 0;
    margin-bottom: 0;
  }

  .particularIssue {
    width: 290px;
    height: auto;
    margin-right: 0px;
    margin-bottom: 0px;
    align-items: start;
  }

  .questionName {
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    min-height: auto;
    margin-bottom: 9px;
  }
  .hints {
    display: none;
  }
  .hintColorGreen {
    background-color: #0ddc65;
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  .hintColorGreen2 {
    background-color: #0d7663;
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  .hintColorYellow {
    background-color: #fecd1a;
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  .hintColorGreenOrange {
    background-color: #ff9900;
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  .hintColorGreenRed {
    background-color: #ff613f;
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }

  .hintText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-right: 12px;
  }
  .link {
    display: none;
  }
  .img {
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }
  .linkGrey {
    color: #818181;
    margin-right: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  .linkText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000;
  }
}
