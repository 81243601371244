.Team {
  margin-left: 10px;
}
.titleTeam {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: left;
}
.blocks {
  display: flex;
  width: 1120px;
  height: auto;
  flex-wrap: wrap;
  gap: 14px;
}
.block {
  width: 269.5px;
  height: auto;
  background: rgba(236, 239, 255, 0.57);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 20px;
  margin-right: 0px;
  margin-bottom: 14px;
  text-decoration: none;
}
.block:last-of-type {
  width: 265.5px;
  height: auto;
  background: rgba(236, 239, 255, 0.57);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 20px;
  margin-right: 0px;
  margin-bottom: 14px;
}
.block:hover{
  background-color: #EBEEFF;
  .textNeme{
    color: #2B46D4;;
  }
}
.block:last-of-type:hover{
  background-color: #EBEEFF;
  .textNeme{
    color: #2B46D4;;
  }
}
.flex {
  display: flex;
}

.img {
  padding: 0;
  margin: 0;
  margin-right: 8px;
  margin-bottom: 8px;
}
.textRole {
  padding: 0;
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #232323;
}

.textNeme {
  padding: 0;
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  color: #232323;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@media screen and (max-width: 1919px) {
  .titleTeam {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    text-align: left;
    margin-bottom: 25px;
  }
  .blocks {
    display: flex;
    width: 906px;
    height: auto;
    flex-wrap: wrap;
    gap: 14px;
    margin-bottom: 14px;
  }
  .block {
    width: 213.25px;
    height: auto;
    background: rgba(236, 239, 255, 0.57);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 20px 13px 20px 20px;
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .block:last-of-type {
    width: 213.25px;
    height: auto;
    background: rgba(236, 239, 255, 0.57);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 20px 13px 20px 20px;
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .flex {
    display: flex;
  }

  .img {
    padding: 0;
    margin: 0;
    margin-right: 6px;
    margin-bottom: 8px;
  }
  .textRole {
    padding: 0;
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
  }

  .textNeme {
    padding: 0;
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
  }
}

//////////////////////////////////////////////////////////////////////

@media screen and (max-width: 1439px) {
  .titleTeam {
    font-size: 18px;
    line-height: 22px;
    margin-top: 16px;
  }
  .blocks {
    width: 896px;
    gap: 12px;
    margin-bottom: 14px;
  }
  .block {
    width: 215px;
    padding: 20px 13px 20px 20px;
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .block:last-of-type {
    width: 215px;
    margin-right: 0px;
    margin-bottom: 0px;
    padding: 20px 13px 20px 20px;
  }

  .img {
    padding: 0;
    margin: 0;
    margin-right: 6px;
    margin-bottom: 8px;
  }
  .textRole {
    padding: 0;
    margin: 0;
    font-size: 16px;
    line-height: 19px;
  }

  .textNeme {
    padding: 0;
    margin: 0;
    font-size: 16px;
    line-height: 19px;
  }
}

//////////////////////////////////////////////////////////////////////

@media screen and (max-width: 1023px) {
  .Team {
    margin-left: 0px;
  }
  .titleTeam {
    font-size: 16px;
    line-height: 19px;
  }
  .blocks {
    width: 690px;
    gap: 12px;
  }
  .block {
    width: 215px;
    padding: 20px 17px 20px 20px;
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .block:last-of-type {
    width: 215px;
    padding: 20px 17px 20px 20px;
    margin-right: 0px;
    margin-bottom: 0px;
  }

  .img {
    padding: 0;
    margin: 0;
    margin-right: 6px;
    margin-bottom: 8px;
  }
  .textRole {
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: 17px;
  }

  .textNeme {
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: 17px;
  }
}

//////////////////////////////////////////////////////////////////////

@media screen and (max-width: 767px) {
  .Team {
    display: block;
    background: rgba(246, 246, 246, 0.69);
    width: 305px;
    padding-left: 15px;
    margin-left: -15px;
  }
  .titleTeam {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 14px;
    margin-top: 0;
    padding-top: 16px;
    margin-top: -10px;
    margin-bottom: -20px;
  }
  .blocks {
    width: 290px;
    margin-top: 32px;
    gap:6px;
  }
  .block {
    width: 290px;
    padding: 20px 17px 20px 20px;
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .block:last-of-type {
    width: 290px;
    padding: 20px 17px 20px 20px;
    margin-right: 0px;
    margin-bottom: 6px;
  }

  .img {
    padding: 0;
    margin: 0;
    margin-right: 6px;
    margin-bottom: 8px;
  }
  .textRole {
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: 17px;
  }

  .textNeme {
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: 17px;
  }
}
