.projectItem{
    min-width: 1140px;
    height: auto;
    background: rgba(247, 247, 247, 0.74);
    border-radius: 8px;
    box-sizing: border-box;
    padding: 30px;
    text-align: left;
    margin-top: 30px;
    margin-right: 175px;

}
.projectItem:hover{
    background: rgba(241, 240, 240, 0.635);
    cursor: pointer;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@media screen and (max-width: 1919px) {
    .projectItem{
        min-width: 915px;
        padding: 30px;
        margin-top: 20px;
        margin-right: 80px;
    }
}


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1439px) {
    .projectItem{
        min-width: 880px;
        padding: 30px;
        margin-top: 20px;
        margin-right: 30px;
    }
}


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1279px) {
    .projectItem{
        width: 916px;
        padding: 30px;
        margin-top: 18px;
    }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1023px) {
    .projectItem{
        width: 688px;
        max-width: 688px;
        min-width: 688px;
        padding: 20px;
        margin-top: 16px;
    }
}



/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 767px) {
    .projectItem{
        width: 290px;
        max-width: 290px;
        min-width: 290px;
        padding: 14px;
        margin-top: 10px;
    }
}