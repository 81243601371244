.CookiesPopup {
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 110px;
  background-color: #3852de;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: space-around;
  transform: translateY(0px);
  transition: 0.7s;
}
.CookiesPopupHidden {
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 110px;
  background-color: #3852de;
  z-index: 20;
  display: none;
  align-items: center;
  justify-content: space-around;
  transform: translateY(520px);
  transition: 0.7s;
}
.text {
  color: #ffffff;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  width: 1199px;
}
.button {
  width: 180px;
  height: 53px;
  background: #ecefff;
  border-radius: 8px;
  color: #3852de;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  border: none;
  cursor: pointer;
}
.link {
  color: #ffffff;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}
.button:hover{
  background-color: #D7DCF8;
}
@media screen and (max-width: 1919px) {
  .CookiesPopup {
    height: 110px;
  }
  .CookiesPopupHidden {
    height: 110px;
  }
  .text {
    font-size: 18px;
    line-height: 22px;
    width: 1009px;
  }
  .link {
    font-size: 18px;
    line-height: 22px;
  }
  .button {
    width: 159px;
    height: 53px;
    font-size: 20px;
    line-height: 24px;
  }
}

@media screen and (max-width: 1439px) {
  .CookiesPopup {
    height: 103px;
  }
  .CookiesPopupHidden {
    height: 103px;
  }
  .text {
    font-size: 16px;
    line-height: 19px;
    width: 900px;
  }
  .link {
    font-size: 16px;
    line-height: 19px;
  }
  .button {
    width: 143px;
    height: 51px;
    font-size: 18px;
    line-height: 22px;
  }
}

@media screen and (max-width: 1279px) {
  .CookiesPopup {
    height: 97px;
  }
  .CookiesPopupHidden {
    height: 97px;
  }
  .text {
    font-size: 14px;
    line-height: 17px;
    width: 702px;
  }
  .link {
    font-size: 14px;
    line-height: 17px;
  }
  .button {
    width: 125px;
    height: 43px;
    font-size: 16px;
    line-height: 19px;
  }
}
@media screen and (max-width: 1023px) {
  .CookiesPopup {
    height: 77px;
  }
  .CookiesPopupHidden {
    height: 77px;
  }
  .text {
    font-size: 14px;
    line-height: 17px;
    width: 492px;
  }
  .link {
    font-size: 14px;
    line-height: 17px;
  }
  .button {
    width: 109px;
    height: 43px;
    font-size: 16px;
    line-height: 19px;
  }
}
@media screen and (max-width: 767px) {
  .CookiesPopup {
    height: 164px;
    display: block;
    padding-bottom: 30px;
    text-align: center;
  }
  .CookiesPopupHidden {
    height: 164px;
    display: none;
  }
  .text {
    padding-top: 19px;
    font-size: 14px;
    line-height: 17px;
    width: 290px;
    text-align: center;
    margin: 19px auto 13px;
  }
  .button {
    width: 109px;
    height: 39px;
    font-size: 16px;
    line-height: 19px;
  }
}
