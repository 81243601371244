.TAEvaluation {
  display: block;
  height: auto;
  min-height: calc(100vh - 212px);
  margin: 60px 195px;
  text-align: left;
  min-height: 700px;
}
.titleText {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 49px;
  width: 1300px;
}
.Preloader {
  position: absolute;
  left: 48%;
  top: 20%;
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1919px) {
  .TAEvaluation {
    margin: 50px 105px;
  }
  .titleText {
    font-size: 34px;
    line-height: 38px;
    width: 916px;
  }
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1439px) {
  .TAEvaluation {
    margin: 50px 50px;
  }
  .titleText {
    font-size: 34px;
    line-height: 38px;
    width: 916px;
  }
}
/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1279px) {
  .main {
    width: 916px;
    margin: auto;
  }
  .TAEvaluation {
    margin: 40px auto;
  }
  .titleText {
    font-size: 34px;
    line-height: 38px;
    width: 916px;
  }
}
/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1023px) {
  .TAEvaluation {
    margin: 30px auto;
  }
  .titleText {
    font-size: 24px;
    line-height: 27px;
    width: 688px;
  }
  .main {
    width: 688px;
  }
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 767px) {
  .main {
    width: 320px;
    margin: auto;
  }
  .TAEvaluation {
    margin: 28px auto;
  }
  .titleText {
    font-size: 20px;
    line-height: 22px;
    width: 287px;
  }
  .mobileWidth {
    width: 290px;
    margin: 0 auto;
  }
}
