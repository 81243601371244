.RPD {
  display: block;
  height: auto;
  min-height: calc(100vh - 212px);
  margin-left: 195px;
}
.preloader {
  top: 200px;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  position: absolute;
  visibility: visible;
  opacity: 1;
  transition: visibility 0.5s, opacity 0.5s linear;
  z-index: 1;
}
.title {
  margin-top: 60px;
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 67px;
  margin-bottom: 40px;
  text-align: left;
}
.steps {
  display: block;
  width: 1140px;
  margin-bottom: 30px;
}
.stepsHeader {
  display: flex;
  height: 71px;
  background-color: #f7f7f7;
  border-radius: 10px 10px 0px 0px;
  align-items: center;
  position: relative;
  width: 100%;
}
.stepsNumber {
  background: #3852de;
  border-radius: 50px;
  width: 41px;
  height: 41px;
  position: relative;
  margin-right: 12px;
  margin-left: 20px;
}
.stepsNumberText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  position: relative;
  top: -17px;
  color: white;
}
.headerTitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
}
.stepsBody {
  border-bottom: 1px #d6d6d6 solid;
  border-left: 1px #d6d6d6 solid;
  border-right: 1px #d6d6d6 solid;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.arrow {
  position: absolute;
  right: 20px;
}
.inputDiv {
  display: flex;
  flex-direction: column;
  width: 600px;
  position: relative;
}
.chevron {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 52px;
  right: 5px;
  cursor: pointer;
}
.inputText {
  width: 510px;
  height: 60px;
  border: 1px solid rgba(35, 35, 35, 0.2);
  border-radius: 6px;
  text-align: left;
  margin: 30px 20px 0px;
  padding-left: 20px;
  padding-right: 70px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-overflow: ellipsis;
  cursor: pointer;
}
.listSearch {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  padding: 18px 30px;
  margin-top: 10px;
  margin-left: 20px;
  max-width: 540px;
  margin-bottom: 10px;
  max-height: 300px;
  overflow-y: auto;
  text-overflow: ellipsis;
  max-height: 253px;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.07);
}
.listSearchHidden {
  visibility: hidden;
  margin-top: 30px;
}

.itemsText {
  width: auto;
}
.itemsText:hover {
  cursor: pointer;
}

.studyPlansDiv {
  display: flex;
  margin-left: 20px;
  position: relative;
  height: 100%;
  position: relative;
}
.checkbox {
  cursor: pointer;
  z-index: 1;
  position: relative;
  top: -1px;
  height: 26px;
  width: 26px;
}
.checkbox2 {
  position: absolute;
  top: 6px;
  cursor: pointer;
}
.checkboxAct {
  margin-right: -2px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.stepsBody2 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.checkboxText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin: 15px;
  text-align: left;
  margin-top: 8px;
  margin-bottom: 8px;
}
.checkboxTextDiv {
  display: flex;
  width: 100%;
  margin-left: 22px;
}
.courseActual {
  width: 77px;
  height: 31px;
  border-radius: 42px;
  background: rgba(56, 82, 222, 0.11);
  position: relative;
  top: 4px;
}
.textActual {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #3852de;
  position: relative;
  top: -10px;
}
.courseNoActual {
  width: 119px;
  height: 31px;
  border-radius: 42px;
  background: rgba(35, 35, 35, 0.11);
  position: relative;
  top: 4px;
}
.textNoActual {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  position: relative;
  top: -10px;
}
.radioDiv {
  display: flex;
  flex-direction: column;
  margin-left: 0;
  margin-top: 0px;
  margin-bottom: 30px;
  height: auto;
}
.radioinputDiv {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-bottom: 10px;
  align-items: center;
  height: 24px;
}

.radiolabel {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin-left: 11.5px;
  cursor: pointer;
}
.inputUrlDiv {
  display: flex;
  margin-left: 0;
  flex-direction: column;
  position: relative;
}
.inputUrl {
  width: 560px;
  height: 60px;
  border: 1px solid #818181;
  border-radius: 6px;
  margin-left: 20px;
  margin-bottom: 21px;
  padding-left: 20px;
  padding-right: 20px;
  color: #818181;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-overflow: ellipsis;
}
.inputUrlRed {
  width: 560px;
  height: 60px;
  border: 1px solid #818181;
  border-radius: 6px;
  margin-left: 20px;
  margin-bottom: 21px;
  padding-left: 20px;
  padding-right: 20px;
  color: #ff613f;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-overflow: ellipsis;
}
.inputUrlError {
  margin-left: 20px;
  text-align: left;
  position: absolute;
  color: rgb(255, 0, 0);
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  top: 62px;
}

.dropArea {
  display: block;
  width: 1095px;
  height: 270px;
  border: 2px dashed #818181;
  border-radius: 8px;
  margin-bottom: 21px;
  margin-left: 21px;
  box-sizing: border-box;
}
.dropArea2 {
  display: block;
  width: 1095px;
  height: 270px;
  border: 2px dashed #3852de;
  border-radius: 8px;
  margin-bottom: 21px;
  margin-left: 21px;
  box-sizing: border-box;
}
.fileImg {
  width: 46px;
  height: 46px;
  margin-top: 85px;
}
.fileTextDrop {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-top: 23px;
}
.fileTextDrop2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-top: 23px;
  color: #3852de;
}
.filesText {
  display: flex;
  align-items: center;
  margin-top: -21px;
}

.textDropInvalid {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}
.checkboxTranslate {
  display: flex;
  margin-left: 20px;
  margin-bottom: 31px;
}
.labelTranslate {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin-left: 11px;
}
.buttonDiv {
  display: flex;
}
.button {
  background: #3852de;
  border-radius: 8px;
  width: 372px;
  height: 64px;
  margin-left: 0;
  margin-bottom: 180px;
  position: relative;
  left: 0;
  cursor: pointer;
  margin-top: 5px;
  border: 1px solid #3852de;
  color: white;
  transition: 0.3s;
}
.buttonDis {
  background: #3852de;
  border-radius: 8px;
  width: 372px;
  height: 64px;
  margin-left: 0;
  margin-bottom: 180px;
  position: relative;
  left: 0;
  opacity: 0.3;
  margin-top: 5px;
  border: 1px solid #3852de;
  color: white;
}
.buttonText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  position: relative;
  top: -6px;
}
/* .button:hover{
    background: #ffffff;
    color: #3852DE;
    transition: 0.3s;
} */
.linkDiv {
  display: flex;
  margin-left: 20px;
  margin-top: 5px;
}
.linksImg {
  margin-right: 8px;
}
.linkText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-decoration-line: underline;
  color: rgba(35, 35, 35, 0.71);
}
.fileImgDrop {
  width: 46px;
  height: 46px;
  margin-top: 66px;
  margin-bottom: 0;
}
.fileTextFormat {
  margin-top: 23px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 0;
}
.fileTextError {
  color: #ff613f;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: 8px;
  margin-bottom: 0;
  width: 201px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.spanDeleteFile {
  margin-top: 31px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  align-items: center;
  cursor: pointer;
  position: relative;
  top: -6px;
}
.cross {
  margin-top: 31px;
  position: relative;
  top: 0px;
  margin-right: 7px;
  width: 11.32px;
  height: 11.32px;
}
.fileTextDrop {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin: auto;
  margin-top: 23px;
  width: 364px;
  z-index: -10;
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
}
.fileTextDrop2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin-top: 23px;
  color: #3852de;
  z-index: -10;
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
}
.fileImg {
  width: 46px;
  height: 46px;
  margin-top: 85px;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -10;
}
.dropFild {
  z-index: 18;
  position: relative;
}
.prelouderDiscipline {
  position: relative;
  height: 70px;
  top: -30px;
  left: -30px;
}
/* /////////////////////////////////////////////////////////////////////////////////////////// */

@media screen and (max-width: 1919px) {
  .RPD {
    margin-left: 35px;
  }
  .title {
    margin-top: 50px;
    font-size: 44px;
    line-height: 49px;
    margin-bottom: 30px;
  }
  .steps {
    width: 915px;
    margin-bottom: 20px;
  }
  .stepsHeader {
    height: 71px;
  }
  .stepsNumber {
    width: 41px;
    height: 41px;
    position: relative;
    margin-right: 12px;
    margin-left: 20px;
  }
  .stepsNumberText {
    font-size: 20px;
    line-height: 24px;
    top: -12px;
  }
  .headerTitle {
    font-size: 20px;
    line-height: 24px;
  }

  .inputDiv {
    display: flex;
    flex-direction: column;
    width: 475px;
    position: relative;
  }
  .chevron {
    top: 51px;
  }
  .inputText {
    width: 385px;
    height: 60px;
    font-size: 16px;
    line-height: 19px;
  }
  .listSearch {
    font-size: 12px;
    margin-left: 20px;
    width: 415px;
  }
  .checkboxText {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin: 15px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .courseActual {
    width: 77px;
    height: 31px;
    top: 4px;
  }
  .textActual {
    font-size: 16px;
    line-height: 19px;

    top: -10px;
  }
  .courseNoActual {
    width: 119px;
    height: 31px;
    top: 4px;
  }
  .textNoActual {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    top: -10px;
  }
  .radioDiv {
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .radiolabel {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }
  .inputUrl {
    width: 435px;
    height: 60px;
    margin-left: 20px;
    margin-bottom: 21px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 18px;
    line-height: 22px;
  }
  .inputUrlRed {
    width: 435px;
    height: 60px;
    margin-left: 20px;
    margin-bottom: 21px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 18px;
    line-height: 22px;
  }
  .inputUrlError {
    margin-left: 20px;
    text-align: left;
    position: absolute;
    color: rgb(255, 0, 0);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    top: 62px;
  }

  .dropArea {
    width: 870px;
    height: 238px;
    margin-bottom: 21px;
    margin-left: 20px;
  }
  .dropArea2 {
    width: 870px;
    height: 238px;
    margin-bottom: 21px;
    margin-left: 20px;
  }
  .fileImg {
    width: 36px;
    height: 36px;
    margin-top: 70px;
  }
  .fileTextDrop {
    font-size: 24px;
    line-height: 29px;
    margin-top: 23px;
  }
  .fileTextDrop2 {
    font-size: 24px;
    line-height: 29px;
    margin-top: 23px;
  }
  .filesText {
    display: flex;
    align-items: center;
    margin-top: -21px;
  }

  .textDropInvalid {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
  .checkboxTranslate {
    display: flex;
    margin-left: 20px;
    margin-bottom: 31px;
  }
  .labelTranslate {
    font-size: 20px;
    line-height: 24px;
    margin-left: 11px;
  }
  .buttonDiv {
    display: flex;
  }
  .button {
    width: 372px;
    height: 64px;
    margin-left: 0;
    margin-bottom: 160px;
    left: 0;
    margin-top: 5px;
  }
  .buttonDis {
    width: 372px;
    height: 64px;
    margin-left: 0;
    margin-bottom: 160px;
    left: 0;
    margin-top: 5px;
  }
  .buttonText {
    font-size: 22px;
    line-height: 27px;
    top: -6px;
  }
  .fileImgDrop {
    margin-top: 40px;
  }
}
/* /////////////////////////////////////////////////////////////////////////////////////////// */

@media screen and (max-width: 1439px) {
  .RPD {
    margin-left: -40px;
  }
  .title {
    margin-top: 50px;
    font-size: 44px;
    line-height: 49px;
    margin-bottom: 30px;
  }
  .steps {
    width: 880px;
    margin-bottom: 20px;
  }
  .stepsHeader {
    height: 71px;
  }
  .stepsNumber {
    width: 36px;
    height: 36px;
    margin-right: 12px;
    margin-left: 20px;
  }
  .stepsNumberText {
    font-size: 18px;
    line-height: 22px;
    top: -12px;
  }
  .headerTitle {
    font-size: 18px;
    line-height: 22px;
  }

  .inputDiv {
    display: flex;
    flex-direction: column;
    width: 460px;
    position: relative;
  }
  .chevron {
    top: 51px;
  }
  .inputText {
    width: 365px;
    height: 60px;
    font-size: 16px;
    line-height: 19px;
  }
  .listSearch {
    font-size: 12px;
    margin-left: 20px;
    width: 395px;
  }
  .checkboxText {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }
  .courseActual {
    width: 77px;
    height: 31px;
    top: 4px;
  }
  .textActual {
    font-size: 16px;
    line-height: 19px;

    top: -10px;
  }
  .courseNoActual {
    width: 119px;
    height: 31px;
    top: 4px;
  }
  .textNoActual {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    top: -10px;
  }
  .radioDiv {
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .radiolabel {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }
  .inputUrl {
    width: 415px;
    height: 60px;
    margin-left: 20px;
    margin-bottom: 21px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 18px;
    line-height: 22px;
  }
  .inputUrlRed {
    width: 415px;
    height: 60px;
    margin-left: 20px;
    margin-bottom: 21px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 18px;
    line-height: 22px;
  }
  .inputUrlError {
    margin-left: 20px;
    text-align: left;
    position: absolute;
    color: rgb(255, 0, 0);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    top: 62px;
  }
  .dropArea {
    width: 835px;
    height: 170px;
    margin-bottom: 21px;
    margin-left: 20px;
  }
  .dropArea2 {
    width: 835px;
    height: 170px;
    margin-bottom: 21px;
    margin-left: 20px;
  }
  .fileImg {
    width: 36px;
    height: 36px;
    margin-top: 49px;
  }
  .fileTextDrop {
    font-size: 18px;
    line-height: 22px;
    margin-top: 14px;
    width: 300px;
    top: 90px;
  }
  .fileTextDrop2 {
    font-size: 18px;
    line-height: 22px;
    margin-top: 14px;
    top: 90px;
  }
  .filesText {
    display: flex;
    align-items: center;
    margin-top: -21px;
  }

  .textDropInvalid {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
  .checkboxTranslate {
    display: flex;
    margin-left: 20px;
    margin-bottom: 31px;
  }
  .labelTranslate {
    font-size: 20px;
    line-height: 24px;
    margin-left: 11px;
  }
  .buttonDiv {
    display: flex;
  }
  .button {
    width: 372px;
    height: 64px;
    margin-left: 0;
    margin-bottom: 140px;
    left: 0;
    margin-top: 5px;
  }
  .buttonDis {
    width: 372px;
    height: 64px;
    margin-left: 0;
    margin-bottom: 140px;
    left: 0;
    margin-top: 5px;
  }
  .buttonText {
    font-size: 22px;
    line-height: 27px;
    top: -6px;
  }

  .linkDiv {
    display: flex;
    margin-left: 20px;
    margin-top: 5px;
  }
  .linksImg {
    margin-right: 8px;
    width: 20px;
    height: 20px;
    margin-top: 16px;
  }
  .linkText {
    font-size: 16px;
    line-height: 19px;
  }

  .fileTextFormat {
    margin-top: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0;
    text-overflow: ellipsis;
  }
  .fileImgDrop {
    width: 30px;
    height: 40px;
    margin-top: 15px;
    margin-bottom: 0;
  }
  .spanDeleteFile {
    top: -16px;
  }
}

/* /////////////////////////////////////////////////////////////////////////////////////////// */

@media screen and (max-width: 1279px) {
  .RPD {
    display: block;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    justify-content: space-around;
    margin: 0 auto;
    width: 916px;
  }
  .title {
    margin-top: 40px;
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 26px;
  }
  .steps {
    width: 916px;
    margin-bottom: 20px;
  }
  .stepsHeader {
    height: 71px;
  }
  .stepsNumber {
    width: 36px;
    height: 36px;
    margin-right: 12px;
    margin-left: 20px;
  }
  .stepsNumberText {
    font-size: 18px;
    line-height: 22px;
    top: -12px;
  }
  .headerTitle {
    font-size: 18px;
    line-height: 22px;
  }

  .inputDiv {
    display: flex;
    flex-direction: column;
    width: 506px;
    position: relative;
  }
  .chevron {
    top: 51px;
  }
  .inputText {
    width: 405px;
    height: 60px;
    font-size: 16px;
    line-height: 19px;
  }
  .listSearch {
    font-size: 12px;
    margin-left: 20px;
    width: 435px;
  }
  .arrow {
    position: absolute;
    right: 20px;
    width: 24px;
    height: 24px;
  }
  .checkboxText {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }
  .courseActual {
    width: 77px;
    height: 31px;
  }
  .textActual {
    font-size: 16px;
    line-height: 19px;
    top: -10px;
  }
  .courseNoActual {
    width: 119px;
    height: 31px;
  }
  .textNoActual {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    top: -10px;
  }

  .radiolabel {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }
  .inputUrl {
    width: 455px;
    height: 60px;
    margin-left: 20px;
    margin-bottom: 21px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 18px;
    line-height: 22px;
  }
  .inputUrlRed {
    width: 455px;
    height: 60px;
    margin-left: 20px;
    margin-bottom: 21px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 18px;
    line-height: 22px;
  }
  .inputUrlError {
    margin-left: 20px;
    text-align: left;
    position: absolute;
    color: rgb(255, 0, 0);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    top: 62px;
  }
  .dropArea {
    width: 872px;
    height: 270px;
    margin-bottom: 21px;
    margin-left: 20px;
  }
  .dropArea2 {
    width: 872px;
    height: 270px;
    margin-bottom: 21px;
    margin-left: 20px;
  }
  .fileImg {
    width: 36px;
    height: 36px;
    margin-top: 85px;
  }
  .fileTextDrop {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin-top: 23px;
  }
  .fileTextDrop2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin-top: 23px;
  }
  .filesText {
    display: flex;
    align-items: center;
    margin-top: -21px;
  }

  .textDropInvalid {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
  .checkboxTranslate {
    display: flex;
    margin-left: 20px;
    margin-bottom: 31px;
  }
  .labelTranslate {
    font-size: 20px;
    line-height: 24px;
    margin-left: 11px;
  }
  .buttonDiv {
    display: flex;
  }
  .button {
    width: 372px;
    height: 64px;
    margin-left: 0;
    margin-bottom: 120px;
    left: 0;
    margin-top: 5px;
  }
  .buttonDis {
    width: 372px;
    height: 64px;
    margin-left: 0;
    margin-bottom: 120px;
    left: 0;
    margin-top: 5px;
  }
  .buttonText {
    font-size: 22px;
    line-height: 27px;
    top: -6px;
  }
  .fileInputDiv {
    display: block;
  }
  input[type='file'] {
    display: none;
  }
  .customfilButton {
    width: 220px;
    height: 40px;
    background: #3852de;
    border-radius: 8px;
    position: relative;
    margin-bottom: 22px;
    border: none;
    cursor: pointer;
    z-index: -1;
  }
  .customfilText {
    color: rgb(255, 255, 255);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    border: none;
    position: relative;
    top: -3px;
    cursor: pointer;
    z-index: -1;
  }
  .customfileupload {
    padding: 6px 12px;
    cursor: pointer;
    border: none;
    position: relative;
    display: block;
    width: 220px;
    height: 40px;
    margin: 0 auto;
    margin-bottom: 22px;
    margin-left: 10px;
  }
}

/* /////////////////////////////////////////////////////////////////////////////////////////// */

@media screen and (max-width: 1023px) {
  .RPD {
    display: block;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    justify-content: space-around;
    margin: 0 auto;
    width: 688px;
    min-height: calc(100vh - 300px);
  }
  .title {
    margin-top: 30px;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 20px;
  }
  .steps {
    width: 688px;
    margin-bottom: 20px;
  }
  .stepsHeader {
    height: 54px;
  }
  .stepsNumber {
    width: 30px;
    height: 30px;
    margin-right: 12px;
    margin-left: 20px;
  }
  .stepsNumberText {
    font-size: 16px;
    line-height: 19px;
    top: -10px;
  }
  .headerTitle {
    font-size: 16px;
    line-height: 19px;
  }
  .arrow {
    position: absolute;
    right: 20px;
    width: 20px;
    height: 20px;
  }
  .inputDiv {
    display: flex;
    flex-direction: column;
    width: 436px;
    position: relative;
  }
  .chevron {
    top: 46px;
  }
  .inputText {
    width: 336px;
    height: 50px;
    font-size: 14px;
    line-height: 17px;
  }
  .listSearch {
    font-size: 12px;
    margin-left: 20px;
    width: 365px;
  }
  .checkboxText {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
  }
  .checkbox2 {
    width: 20px;
    height: 20px;
  }
  .checkboxTextDiv {
    display: flex;
    width: 100%;
    margin-left: 12px;
  }
  .courseActual {
    width: 69px;
    height: 27px;
  }
  .textActual {
    font-size: 14px;
    line-height: 17px;

    top: -10px;
  }
  .courseNoActual {
    width: 108px;
    height: 27px;
  }
  .textNoActual {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    top: -10px;
  }

  .radiolabel {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
  .checkboxAct {
    width: 16px;
    height: 16px;
  }
  .inputUrl {
    width: 385px;
    height: 50px;
    margin-left: 20px;
    margin-bottom: 21px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
    line-height: 17px;
  }
  .inputUrlRed {
    width: 385px;
    height: 50px;
    margin-left: 20px;
    margin-bottom: 21px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
    line-height: 17px;
  }
  .inputUrlError {
    margin-left: 20px;
    text-align: left;
    position: absolute;
    color: rgb(255, 0, 0);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    top: 52px;
  }
  .dropArea {
    width: 643px;
    height: 270px;
    margin-bottom: 21px;
    margin-left: 20px;
  }
  .dropArea2 {
    width: 643px;
    height: 270px;
    margin-bottom: 21px;
    margin-left: 20px;
  }
  .fileImg {
    width: 36px;
    height: 36px;
    margin-top: 85px;
  }
  .fileTextDrop {
    font-size: 18px;
    line-height: 22px;
    margin-top: 23px;
  }
  .fileTextDrop2 {
    font-size: 18px;
    line-height: 22px;
    margin-top: 23px;
  }
  .filesText {
    display: flex;
    align-items: center;
    margin-top: -21px;
  }

  .textDropInvalid {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
  .checkboxTranslate {
    width: 20px;
    height: 20px;
    display: flex;
    margin-left: 20px;
    margin-bottom: 31px;
  }
  .labelTranslate {
    font-size: 16px;
    line-height: 19px;
    margin-left: 11px;
    position: absolute;
    left: 40px;
  }
  .buttonDiv {
    display: flex;
  }
  .button {
    width: 100%;
    height: 64px;
    margin-left: 0;
    margin-bottom: 100px;
    left: 0;
  }
  .buttonDis {
    width: 100%;
    height: 64px;
    margin-left: 0;
    margin-bottom: 100px;
    left: 0;
  }
  .buttonText {
    font-size: 18px;
    line-height: 22px;
    top: -2px;
  }
  .fileInputDiv {
    display: block;
  }
  input[type='file'] {
    display: none;
  }
  .customfilButton {
    width: 189px;
    height: 40px;
    background: #3852de;
    border-radius: 8px;
    position: relative;
    margin-bottom: 22px;
    border: none;
    cursor: pointer;
    z-index: -1;
  }
  .customfilText {
    color: rgb(255, 255, 255);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    border: none;
    position: relative;
    top: -3px;
    cursor: pointer;
    z-index: -1;
  }
  .customfileupload {
    padding: 6px 12px;
    cursor: pointer;
    border: none;
    position: relative;
    display: block;
    width: 189px;
    height: 40px;
    margin: 0 auto;
    margin-bottom: 22px;
    margin-left: 10px;
  }
  .checkbox {
    height: 22px;
    width: 22px;
  }
}

/* /////////////////////////////////////////////////////////////////////////////////////////// */

@media screen and (max-width: 767px) {
  .RPD {
    width: 290px;
    height: auto;
  }
  .title {
    margin-top: 28px;
    font-size: 24px;
    line-height: 27px;
    margin-bottom: 16px;
  }
  .steps {
    width: 290px;
    margin-bottom: 20px;
  }
  .stepsHeader {
    height: 54px;
  }
  .stepsNumber {
    width: 26px;
    height: 26px;
    margin-right: 8px;
    margin-left: 10px;
  }
  .stepsNumberText {
    font-size: 14px;
    line-height: 17px;
    top: -10px;
  }
  .arrow {
    position: absolute;
    right: 10px;
    width: 20px;
    height: 20px;
  }
  .headerTitle {
    font-size: 14px;
    line-height: 17px;
  }

  .inputDiv {
    display: flex;
    flex-direction: column;
    width: 270px;
    position: relative;
  }
  .chevron {
    top: 46px;
  }
  .inputText {
    width: 215px;
    height: 50px;
    font-size: 14px;
    line-height: 17px;
    margin-left: 10px;
    padding-right: 40px;
    padding-left: 10px;
    margin-bottom: 0px;
  }
  .listSearch {
    font-size: 12px;
    margin-left: 10px;
    width: 205px;
    margin-bottom: 0;
  }
  .checkboxText {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin: 15px;
    text-align: left;
    width: 100%;
    margin-top: 5px;
  }
  .checkbox2 {
    width: 20px;
    height: 20px;
    top: 8px;
    left: -10px;
  }
  .checkboxTextDiv {
    display: flex;
    /* position: absolute;
        left: 20px; */
    /* margin: 15px; */
    width: 100%;
    flex-wrap: wrap;
    margin-left: 2px;
  }
  .courseActual {
    width: 63px;
    height: 25px;
    top: -10px;
    left: 10px;
  }
  .textActual {
    font-size: 12px;
    line-height: 15px;

    top: -7px;
  }
  .courseNoActual {
    width: 108px;
    height: 25px;
    top: 9px;
  }
  .textNoActual {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    top: -7px;
  }
  .radioDiv {
    margin-left: -10px;
  }

  .radiolabel {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
  .checkboxAct {
    width: 16px;
    height: 16px;
  }
  .inputUrl {
    width: 245px;
    height: 50px;
    margin-left: 10px;
    margin-bottom: 21px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    line-height: 17px;
  }
  .inputUrlRed {
    width: 245px;
    height: 50px;
    margin-left: 10px;
    margin-bottom: 21px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    line-height: 17px;
  }
  .inputUrlError {
    margin-left: 20px;
    text-align: left;
    position: absolute;
    color: rgb(255, 0, 0);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 24px;
    top: 52px;
    left: -8px;
  }
  .dropArea {
    width: 263px;
    height: 270px;
    margin-bottom: 21px;
    margin-left: 10px;
  }
  .dropArea2 {
    width: 263px;
    height: 270px;
    margin-bottom: 21px;
    margin-left: 10px;
  }
  .fileImg {
    width: 36px;
    height: 36px;
    margin-top: 85px;
  }
  .fileTextDrop {
    font-size: 18px;
    line-height: 22px;
    margin-top: 23px;
  }
  .fileTextDrop2 {
    font-size: 18px;
    line-height: 22px;
    margin-top: 23px;
  }
  .filesText {
    display: flex;
    align-items: center;
    margin-top: -21px;
  }

  .textDropInvalid {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
  .checkboxTranslate {
    width: 20px;
    height: 20px;
    display: flex;
    margin-left: 10px;
    margin-bottom: 11px;
  }
  .labelTranslate {
    font-size: 14px;
    line-height: 17px;
    margin-left: 7px;
    left: 30px;
  }
  .buttonDiv {
    display: flex;
  }
  .button {
    width: 100%;
    height: 40px;
    margin-left: 0;
    margin-bottom: 100px;
    left: 0;
    margin-top: 0px;
  }
  .buttonDis {
    width: 100%;
    height: 40px;
    margin-left: 0;
    margin-bottom: 100px;
    left: 0;
    margin-top: 0px;
  }
  .buttonText {
    font-size: 14px;
    line-height: 17px;
    top: -5px;
  }
  .linkDiv {
    margin-left: 10px;
  }
  .fileInputDiv {
    display: block;
  }
  input[type='file'] {
    display: none;
  }
  .customfilButton {
    width: 270px;
    height: 40px;
    background: #3852de;
    border-radius: 8px;
    position: relative;
    margin-bottom: 22px;
    border: none;
    cursor: pointer;
    z-index: -1;
    padding: 0;
  }
  .customfilText {
    color: rgb(255, 255, 255);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    border: none;
    position: relative;
    top: -3px;
    cursor: pointer;
    z-index: -1;
  }
  .customfileupload {
    padding: 6px 12px;
    cursor: pointer;
    border: none;
    position: relative;
    display: block;
    width: 270px;
    height: 40px;
    margin: 0 auto;
    margin-bottom: 22px;
    padding: 0;
    margin-top: 8px;
  }
  .checkbox {
    top: -1px;
    height: 20px;
    width: 20px;
  }
}
