.Courses {
  display: flex;
  flex-direction: column;
  width: 1140px;
  margin-bottom: 200px;
  margin-top: 60px;
}
.Preloader {
  position: absolute;
  left: 48%;
  top: 20%;
}
.title {
  margin: 0;
  padding: 0;
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 67px;
  text-align: left;
}
.filterArea {
  display: flex;
  width: 1140px;
  border: 1px solid rgba(35, 35, 35, 0.2);
  border-radius: 8px;
  height: 53px;
  margin-top: 27px;
  box-sizing: border-box;
}
.searchImg {
  margin-right: 8px;
  padding: 10.5px 0 10.5px 20px;
}
.input {
  border: none;
  width: 100%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #818181;
  margin: auto;
  margin-left: 0;
}
.input:hover::placeholder{
  color: #B3B3B3;
}
.filterRolesDiv {
  display: flex;
  width: 190px;
  border-left: 1px solid rgba(35, 35, 35, 0.2);
  align-items: center;
  height: 53px;
  margin-top: 0;
  box-sizing: border-box;
  padding: 14px 12px 14px 20px;
  position: relative;
  cursor: pointer;
}
.optionRow:hover {
  cursor: pointer;
  color: #434343;
}
.optionRoles {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: auto 15px auto 10px;
  text-align: left;
  padding: 2px 0;
}
.optionRolesDiv {
  position: absolute;
  width: 145px;
  height: auto;
  background-color: rgb(255, 255, 255);
  top: 55px;
  left: 10px;
  transition: 0.7s;
  z-index: 3;
  box-shadow: 2px 4px 23px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.optionRolessOver {
  position: absolute;
  width: 500px;
  height: 500px;
  left: -375px;
  top: -50px;
  z-index: -2;
  opacity: 0;
  cursor: default;
}
.filterRoles {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  width: 120px;
  text-align: left;
  cursor: pointer;
  align-items: center;
}
.imgSelect {
  margin-left: 0px;
  cursor: pointer;
}

.titleSimestr {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 49px;
  margin-top: 40px;
  margin-bottom: 23px;
  text-align: left;
}

.tableCourse {
  display: flex;
}
.trCourse {
  display: flex;
  flex-direction: row;
  width: 100%;
  background: #ecefff;
  border-radius: 8px;
  height: 59px;
  align-items: center;
}

.courseTh {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
  margin-left: 10px;
}
.roleTh {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
  margin-left: 545px;
}
.feedbackTh {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
  margin-left: 98px;
}
.divSemestrData {
  display: flex;
  text-align: left;
  min-height: 97px;
  cursor: pointer;
  padding-right: 10px;
  padding-left: 10px;
  box-sizing: border-box;
  position: relative;
  align-items: center;
}

.divSemestrDataFon {
  display: flex;
  text-align: left;
  min-height: 97px;
  cursor: pointer;
  padding-right: 10px;
  padding-left: 10px;
  box-sizing: border-box;
  position: relative;
  align-items: center;
}
.courseName {
  width: 565px;
  margin-right: 50px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin-left: 5px;
  align-items: center;
  height: auto;
  display: flex;
  margin-left: 0;
  padding-left: 0;
  &NoData{
    width: 565px;
    margin-right: 50px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    margin-left: 5px;
    align-items: center;
    height: auto;
    display: flex;
    margin-left: 0;
    padding-left: 0;
    margin-top: 40px;
  }
}

.courseNameActive {
  width: 565px;
  margin-right: 50px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-left: 0px;
  align-items: center;
  height: auto;
  display: flex;
  color: #4B63E1;
  &NoData{
    width: 565px;
    margin-right: 50px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-left: 0px;
    align-items: center;
    height: auto;
    display: flex;
    margin-top: 40px;
    color: #4B63E1;
  }
}
.courseRole {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  width: 95px;
  align-items: center;
  height: auto;
  display: flex;
}
.link{
  display: flex;
  flex-direction: column;
  width: 250px;
  text-decoration: none;
  color: black;
  margin-top: -10px;
  position: relative;
  cursor:auto;
}
.courseID {
  margin-left: 7px;
  margin-top: 3px;
  transition: 0.7;
  margin-top: 0;
}
.linkSummary{
  display: flex;
   align-items: center;
   text-decoration: none;
   margin-top:-10px;
   margin-bottom: -10px;
}
.linkSummary:hover{
  .SummaryFeedback{
      color: #4B63E1;
    }
    .courseID {
      transform: scale(1.08);
      transition: 0.7;
      cursor: pointer;
      path {
        fill: #3852de;
        transition:0.2s;
    }
    }
}

.SummaryFeedback{
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: rgba(35, 35, 35, 0.76);
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@media screen and (max-width: 1919px) {
  .Courses {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 200px;
    margin-top: 50px;
  }

  .title {
    margin: 0;
    padding: 0;
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 49px;
    text-align: left;
  }
  .filterArea {
    display: flex;
    width: 915px;
    border: 1px solid rgba(35, 35, 35, 0.2);
    border-radius: 8px;
    height: 45px;
    margin-top: 30px;
    box-sizing: border-box;
  }
  .searchImg {
    margin-right: 8px;
    padding: 10.5px 0 10.5px 20px;
  }
  .input {
    border: none;
    width: 100%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    line-height: 17px;
    color: #818181;
    margin: auto;
    margin-left: 0;
  }
  .filterRolesDiv {
    display: flex;

    border-left: 1px solid rgba(35, 35, 35, 0.2);
    align-items: center;
    height: 45px;
    margin-top: 0;
    box-sizing: border-box;
    padding: 14px 12px 14px 20px;
    position: relative;
    cursor: pointer;
  }
  .optionRoles {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin: auto 15px auto 10px;
    text-align: left;
    padding: 2px 0;
  }
  .optionRolesDiv {
    position: absolute;
    height: auto;
    background-color: rgb(255, 255, 255);
    top: 45px;
    left: 10px;
    transition: 0.7s;
    z-index: 3;
    box-shadow: 2px 4px 23px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .optionRolessOver {
    position: absolute;
    width: 500px;
    height: 500px;
    left: -375px;
    top: -50px;
    z-index: -2;
    opacity: 0;
    cursor: default;
  }
  .filterRoles {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    text-align: left;
    cursor: pointer;
  }
  .imgSelect {
    margin-left: 12px;
    cursor: pointer;
  }

  .titleSimestr {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 38px;
    margin-top: 40px;
    margin-bottom: 23px;
    text-align: left;
  }

  .tableCourse {
    display: flex;
  }
  .trCourse {
    display: flex;
    flex-direction: row;
    width: 915px;
    background: #ecefff;
    border-radius: 8px;
    height: 43px;
    align-items: center;
  }

  .courseTh {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    margin-left: 10px;
  }
  .roleTh {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    margin-left: 355px;
  }
  .feedbackTh {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    margin-left: 102px;
  }
  .divSemestrData {
    display: flex;
    text-align: left;
    min-height: 97px;
    // border-bottom: 1px solid #F7F7F7;
    cursor: pointer;
  }
  .divSemestrDataFon {
    display: flex;
    text-align: left;
    min-height: 97px;
    cursor: pointer;
  }
  .courseName {
    width: 385px;
    margin-right: 30px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    align-items: center;
    height: auto;
    display: flex;
    &NoData{
      width: 385px;
      margin-right: 30px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      align-items: center;
      height: auto;
      display: flex;
      margin-top: 35px;
    }
  }
  .courseNameActive {
    width: 385px;
    margin-right: 30px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    align-items: center;
    height: auto;
    display: flex;
    &NoData{
      width: 385px;
      margin-right: 30px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      align-items: center;
      height: auto;
      display: flex;
      margin-top: 35px;
    }
  }
  .courseRole {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    width: 95px;
    height: auto;
    display: flex;
  }
}

//////////////////////////////////////////////////////////////////////

@media screen and (max-width: 1439px) {
  .Courses {
    margin-bottom: 140px;
    margin-top: 50px;
  }
  .SummaryFeedback{
    font-size: 16px;
    line-height: 20px;
  }
  .courseID {
    width: 16px;
    height: 16px;
  }
  .link{
    width: 220px;
  }
}

//////////////////////////////////////////////////////////////////////

@media screen and (max-width: 1279px) {
  .Courses {
    margin-bottom: 180px;
    margin-top: 40px;
  }

  .title {
    font-size: 40px;
    line-height: 45px;
  }
  .filterArea {
    height: 45px;
    margin-top: 30px;
  }
  .searchImg {
    margin-right: 8px;
    padding: 10.5px 0 10.5px 20px;
  }
  .input {
    font-size: 16px;
    line-height: 19px;
    line-height: 17px;
  }
  .filterRolesDiv {
    display: flex;

    border-left: 1px solid rgba(35, 35, 35, 0.2);
    align-items: center;
    height: 45px;
    margin-top: 0;
    box-sizing: border-box;
    padding: 14px 12px 14px 20px;
    position: relative;
    cursor: pointer;
  }

  .optionRoles {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin: auto 15px auto 10px;
    text-align: left;
    padding: 2px 0;
  }
  .optionRolesDiv {
    position: absolute;
    height: auto;
    background-color: rgb(255, 255, 255);
    top: 45px;
    left: 10px;
    transition: 0.7s;
    z-index: 3;
    box-shadow: 2px 4px 23px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .optionRolessOver {
    position: absolute;
    width: 500px;
    height: 500px;
    left: -375px;
    top: -50px;
    z-index: -2;
    opacity: 0;
    cursor: default;
  }
  .filterRoles {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    cursor: pointer;
  }
  .imgSelect {
    margin-left: 12px;
    cursor: pointer;
  }

  .titleSimestr {
    font-size: 32px;
    line-height: 36px;
    margin-top: 30px;
    margin-bottom: 22px;
  }

  .tableCourse {
    display: flex;
  }
  .trCourse {
    display: flex;
    flex-direction: row;
    width: 100%;
    background: #ecefff;
    border-radius: 8px;
    height: 43px;
    align-items: center;
  }

  .courseTh {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    margin-left: 10px;
  }
  .roleTh {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    margin-left: 359px;
  }
  .feedbackTh {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    margin-left: 106px;
  }
  .divSemestrData {
    display: flex;
    text-align: left;
    min-height: 97px;
  }
  .divSemestrDataFon {
    display: flex;
    text-align: left;
    min-height: 97px;
  }
  .courseName {
    width: 385px;
    margin-right: 30px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-left: 5px;
    align-items: center;
    height: auto;
    display: flex;
    &NoData{
      width: 385px;
      margin-right: 30px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      margin-left: 5px;
      align-items: center;
      height: auto;
      display: flex;
    }
  }
  .courseNameActive {
    width: 385px;
    margin-right: 30px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-left: 5px;
    align-items: center;
    height: auto;
    display: flex;
    &NoData{
      width: 385px;
      margin-right: 30px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      margin-left: 5px;
      align-items: center;
      height: auto;
      display: flex;
    }
  }
  .courseRole {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    width: 95px;
    align-items: center;
    height: auto;
    display: flex;
  }
  .SummaryFeedback{
    font-size: 14px;
    line-height: 18px;
    margin-left: 5px;
  }
  .courseID {
    width: 14px;
    height: 14px;
  }
  .link{
    width: 200px;
  }
}

//////////////////////////////////////////////////////////////////////

@media screen and (max-width: 1023px) {
  .Courses {
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 30px;
  }

  .title {
    font-size: 32px;
    line-height: 36px;
  }
  .filterArea {
    width: 688px;
    height: 45px;
    margin-top: 20px;
  }
  .searchImg {
    margin-right: 8px;
    padding: 10.5px 0 10.5px 20px;
  }
  .input {
    font-size: 14px;
    line-height: 17px;
  }
  .filterRolesDiv {
    display: flex;

    border-left: 1px solid rgba(35, 35, 35, 0.2);
    align-items: center;
    height: 45px;
    margin-top: 0;
    box-sizing: border-box;
    padding: 14px 12px 14px 20px;
    position: relative;
    cursor: pointer;
  }

  .optionRoles {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin: auto 15px auto 10px;
    text-align: left;
    padding: 2px 0;
  }
  .optionRolesDiv {
    position: absolute;
    height: auto;
    background-color: rgb(255, 255, 255);
    top: 45px;
    left: 10px;
    transition: 0.7s;
    z-index: 3;
    box-shadow: 2px 4px 23px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .optionRolessOver {
    position: absolute;
    width: 500px;
    height: 500px;
    left: -375px;
    top: -50px;
    z-index: -2;
    opacity: 0;
    cursor: default;
  }
  .filterRoles {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    cursor: pointer;
  }
  .imgSelect {
    margin-left: 12px;
    cursor: pointer;
  }

  .titleSimestr {
    font-size: 24px;
    line-height: 27px;
    margin-top: 30px;
    margin-bottom: 9px;
  }

  .tableCourse {
    display: flex;
  }
  .trCourse {
    display: flex;
    flex-direction: row;
    width: 688px;
    background: #ecefff;
    border-radius: 8px;
    height: 43px;
    align-items: center;
    margin-bottom: 18px;
  }

  .courseTh {
    font-size: 16px;
    line-height: 18px;
    margin-left: 10px;
  }
  .roleTh {
    font-size: 16px;
    line-height: 18px;
    margin-left: 314px;
  }
  .feedbackTh {
    font-size: 16px;
    line-height: 18px;
    margin-left: 75px;
  }
  .divSemestrData {
    display: flex;
    text-align: left;
    min-height: 50px;
    width: 688px;
    padding-top: 5px;
  }
  .divSemestrDataFon {
    display: flex;
    text-align: left;
    min-height: 50px;
    width: 688px;
    padding-top: 5px;
  }
  .courseName {
    width: 334px;
    margin-right: 20px;
    font-size: 14px;
    line-height: 17px;
    margin-left: 5px;
    &NoData{
      width: 334px;
      margin-right: 20px;
      font-size: 14px;
      line-height: 17px;
      margin-left: 5px;
      margin-top: 10px;
    }
  }
  .courseNameActive {
    width: 334px;
    margin-right: 20px;
    font-size: 14px;
    line-height: 17px;
    margin-left: 5px;
    &NoData{
      width: 334px;
      margin-right: 20px;
      font-size: 14px;
      line-height: 17px;
      margin-left: 5px;
      margin-top: 10px;
    }
  }
  .courseRole {
    font-size: 14px;
    line-height: 17px;
    width: 120px;
    margin: auto;
    margin-right: 0px;
  }
}

//////////////////////////////////////////////////////////////////////

@media screen and (max-width: 767px) {
  .Courses {
    margin-bottom: 100px;
    margin-top: 28px;
    width: 290px;
  }

  .title {
    font-size: 24px;
    line-height: 27px;
  }
  .filterArea {
    width: 290px;
    height: 45px;
    margin-top: 20px;
    position: relative;
    margin-bottom: 60px;
  }
  .searchImg {
    margin-right: 8px;
    padding: 12.5px 0 12.5px 10px;
    width: 20px;
    height: 20px;
  }
  .input {
    font-size: 12px;
    line-height: 15px;
  }
  .filterRolesDiv {
    display: flex;

    border-left: 0px solid rgba(35, 35, 35, 0.2);
    align-items: center;
    height: 45px;
    margin-top: 0;
    box-sizing: border-box;
    padding: 14px 12px 14px 20px;
    position: absolute;
    cursor: pointer;
    top: 50px;
    left: -20px;
    font-size: 14px;
    line-height: 17px;
  }

  .optionRoles {
    font-size: 14px;
    line-height: 17px;
    margin: auto 15px auto 10px;
    text-align: left;
    padding: 5px 0;
  }
  .optionRolesDiv {
    position: absolute;

    height: auto;
    background-color: rgb(255, 255, 255);
    top: 45px;
    left: 10px;
    transition: 0.7s;
    z-index: 3;
    box-shadow: 2px 4px 23px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .optionRolessOver {
    position: absolute;
    width: 500px;
    height: 500px;
    left: -375px;
    top: -50px;
    z-index: -2;
    opacity: 0;
    cursor: default;
  }
  .filterRoles {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    cursor: pointer;
  }
  .imgSelect {
    margin-left: 12px;
    width: 20px;
    height: 20px;
  }

  .titleSimestr {
    font-size: 24px;
    line-height: 27px;
    margin-top: 30px;
    margin-bottom: 0px;
  }

  .tableCourse {
    display: flex;
  }
  .trCourse {
    display: none;
    flex-direction: row;
    width: 290px;
    background: #ecefff;
    border-radius: 8px;
    height: 43px;
    align-items: center;
  }

  .courseTh {
    font-size: 16px;
    line-height: 18px;
    margin-left: 10px;
  }
  .roleTh {
    font-size: 16px;
    line-height: 18px;
    margin-left: 300px;
  }
  .feedbackTh {
    font-size: 16px;
    line-height: 18px;
    margin-left: 104px;
  }
  .divSemestrData {
    display: block;
    text-align: left;
    min-height: 50px;
    border-bottom: 1px solid #f7f7f7;
    width: 305px;
    position: relative;
    padding-bottom: 0px;
    padding-top: 0;
    padding-left: 0px;
    margin-left: 0px;
  }
  .divSemestrDataFon {
    display: block;
    text-align: left;
    min-height: 50px;
    position: relative;
    padding-bottom: 0px;
    background: rgba(246, 246, 246, 0.69);
    width: 305px;
    padding-left: 0px;
    margin-left: 0px;
    box-sizing: border-box;
    padding-top: 0;
    margin-bottom: 0;
  }

  .courseName {
    width: 100%;
    margin-right: 0px;
    font-size: 14px;
    line-height: 17px;
    margin-left: 0px;
    margin-bottom: 12px;
    &NoData{
      width: 100%;
      margin-right: 0px;
      font-size: 14px;
      line-height: 17px;
      margin-left: 0px;
      margin-bottom: 12px;
    }
  }
  .courseNameActive {
    width: 100%;
    margin-right: 0px;
    font-size: 14px;
    line-height: 17px;
    margin-left: 0px;
    &NoData{
      width: 100%;
      margin-right: 0px;
      font-size: 14px;
      line-height: 17px;
      margin-left: 0px;
    }
  }
  .courseRole {
    margin: 0;
    font-size: 14px;
    line-height: 17px;
    width: 83px;
    font-weight: 500;
    color: #3852de;
    margin-top: 12px;
    margin-bottom: 10px;
  }
  .SummaryFeedback{
    margin-left: 0px;
  }
}
