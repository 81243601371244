.ErrorGlobal {
  margin: auto;
  margin-bottom: 200px;
}
.Preloader {
  position: absolute;
  left: 50%;
  top: 20%;
}
.Feedback {
  margin-left: 0;
  width: 1270px;
  margin-top: 60px;
}
.permissions {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.titleText {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 49px;
  text-align: left;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 40px;
  position: relative;
  z-index: 2;
}
.CourseFeedback {
  display: flex;
  height: auto;
  min-height: calc(100vh - 212px);
  margin: 0 195px;
}

.backSection {
  display: flex;
  align-items: center;
}
.backImg {
  margin: 0;
  margin-right: 4px;
  cursor: pointer;
}
.backText {
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  cursor: pointer;
}
@media screen and (max-width: 1919px) {
  .CourseFeedback {
    max-width: 1919px;
    min-height: 700px;
    margin: 0 105px;
  }
  .Feedback {
    width: 1050px;
    margin-top: 50px;
  }
  .titleText {
    font-size: 34px;
    line-height: 38px;
    margin-top: 16px;
    margin-bottom: 35px;
  }

  .backText {
    font-size: 20px;
    line-height: 24px;
  }
}

@media screen and (max-width: 1439px) {
  .CourseFeedback {
    max-width: 1439px;
    margin: 0 50px;
  }

  .Feedback {
    width: 1080px;
    margin-top: 50px;
  }
  .titleText {
    font-size: 34px;
    line-height: 38px;
    margin-top: 24px;
    margin-bottom: 30px;
  }

  .backText {
    font-size: 20px;
    line-height: 24px;
  }
}

@media screen and (max-width: 1279px) {
  .CourseFeedback {
    display: flex;
    margin: 0 auto;
    justify-content: center;
  }
  .Feedback {
    width: 916px;
    margin-top: 40px;
  }
  .titleText {
    font-size: 34px;
    line-height: 38px;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .backText {
    font-size: 20px;
    line-height: 24px;
  }
}

@media screen and (max-width: 1023px) {
  .CourseFeedback {
    display: flex;
    margin: 0 auto;
    justify-content: center;
  }
  .Feedback {
    width: 688px;
    margin-top: 30px;
  }
  .titleText {
    font-size: 24px;
    line-height: 27px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .backText {
    font-size: 18px;
    line-height: 22px;
  }
}
@media screen and (max-width: 767px) {
  .CourseFeedback {
    display: block;
    margin: 0 auto;
    width: 290px;
    text-align: left;
  }
  .Preloader {
    position: absolute;
    left: 45%;
    top: 20%;
  }
  .Feedback {
    width: 305px;
    margin-top: 28px;
  }
  .titleText {
    font-size: 20px;
    line-height: 22px;
    margin-top: 12px;
    margin-bottom: 0px;
  }

  .backText {
    font-size: 16px;
    line-height: 19px;
  }
  .backSection {
    width: 300px;
  }
}
