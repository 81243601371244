.ProjectSideCard {
  display: flex;
  flex-direction: column;
  width: 520px;
  gap: 16px;
  text-align: left;
  margin-bottom: 10px;


  .paramName {
    display: flex;
    width: 155px;
   min-width: 155px;
    margin-right: 12;
    flex-wrap: wrap;
    font-weight: 500;
    font-size: 16;
    
  }

  .title {
    margin-top: 18px;
  }
}
.params{
  width: 500px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: auto !important;
  gap: 10px;


}
.parametr{
  display: flex;
  width: 333px;
  flex-wrap: wrap;
  gap: 7px;

}
.fileDiv{
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  width: 155px;
  }
  .fileDiv:hover{
    .fileText{
    color: #4B63E1;
    }
    .icon{
      transform: scale(1.05);
    }
  }
  .icon{
    width: 38px;
    height: 38px;
    margin-right: 14px;
  }
  
  .fileText{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    color: black;
  }
  .link{
    text-decoration: none;
  }
  .text{
    color: black;
    text-decoration: none;
    cursor: auto;
  }
  .Description{
    width: 500px;
  }
.title{
  margin-top: 10px;
  font-size: 18px;
}
  .active{
    margin-left: 0px;
    margin-right: 2px;
    background: #0DDC65;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    align-self: center;
}

.archive{
    margin-left: 0px;
    margin-right: 2px;
    background: #FF522D;
    width: 6px;
    height: 6px;
    border-radius: 50%; 
    margin-top: 7px;
}
.sizeText{
  position: relative;
  bottom: 4px;
}
.button{
  display: inline-flex;
  height: 45px;
  padding: 17px 29px 19px 29px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
background: var(--blue-100, #3852DE);
width: 129px;
border: none;
color: var(--white, #FFF);
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
cursor: pointer;
margin-top: 40px;
}
.buttonDis{
  display: inline-flex;
  height: 45px;
  padding: 17px 29px 19px 29px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
background: var(--blue-100, #6f6f74);
width: 129px;
border: none;
color: var(--white, #FFF);
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top: 40px;
}
.button:hover{
  background: #3349c9;
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 767px) {
  .ProjectSideCard {
    width: 290px;
    gap: 16px;
    margin-bottom: 10px;

    .paramName {
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      flex-direction: column;
    }
  
    .title {
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-top: 12px;
      width: 290px;
    }
  }
  .Description{
    width: 290px;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .paraments{
    flex-direction: column !important;
  }
  .params{
  margin-top: 30px;
  width: 280px !important;
    min-width: 280px !important;
    max-width: 280px !important;

  }
  .paramsField{
    width: 280px !important;
    min-width: 280px !important;
    max-width: 280px !important;
    flex-wrap: wrap;
  }
  .paramentsData{
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .parametr{
    display: flex;
    width: 333px;
    flex-wrap: wrap;
    gap: 7px;
  
  }
  .sizeDiv{
    position: relative;
  }
  .size{
    position: absolute;
    top: 20px;
  }
  .sizeText{
    position: relative;
    bottom: 0px;
    font-family: Inter;
    font-size: 14px;
  }
  .fileDiv{
    display: flex;
    align-items: center;
    text-decoration: none;
    width: 135px;
    }
    .fileDiv:hover{
      .fileText{
      color: #4B63E1;
      }
      .icon{
        transform: scale(1.05);
      }
    }
    .icon{
      width: 30px;
      height: 30px;
      margin-right: 14px;
    }
    
    .fileText{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      margin: 0;
      color: black;
    }
    .link{
      font-size: 13px;
    }
    .text{
      font-size: 13px;
    }


    .active{
    display: none;
  }
  
  .archive{
    display: none;
  }
  .iconBody{
    width: 18px;
    height: 18px;
    position: relative;
    top:3px
  }
}