.ProjectCard {
  display: flex;
  position: relative;
  z-index: 4;
cursor: pointer;
  gap: 8px;
  padding: 30px 12px;
  border-bottom: 1px solid rgba(35, 35, 35, 0.2);
}
.ProjectCard:hover{
  background: rgba(56, 81, 222, 0.025);
}

.ProjectCard:last-child {
  border-bottom: none;
}
.mobile{
  display: none;
}
.cell {
  display: flex;
  gap: 8px;
  flex: auto;
  text-align: left;
  font-family: Platform LC Web;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.titleCell {
  flex-direction: column;
  min-width: 150px;
  flex: 3 1 180px;
  overflow: hidden;
  margin-right: 30px;
 
}
.titleTable{
  font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.supervizorCell {
  flex-direction: column;
  flex: 2 1 130px;
  min-width: 120px;
}

.levelCell {
  flex: 1 1 20px;
  min-width: 100px;
  height: max-content;
  flex-wrap: wrap;
  flex-direction: column;
}

.tagsCell {
  flex: 1 1 130px;
  min-width: 100px;
  height: max-content;
  flex-wrap: wrap;
  margin-right: 30px;
}
.Edit{
  margin-top: -5px;
  position: absolute;
  right:10px;
  z-index: 10;
  transition: 0.2s;
  transform: scale(0.9);
  &:hover{
    transform: scale(1);
    transition: 0.2s;
  }
}
.cosupervisor{
  color: var(--dark-gray, #232323);
  font-family: Inter;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}
/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1919px) {
  .titleTable{
  font-size: 16px;
  }
  .cosupervisor{
    font-size: 16px;
  }
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1439px) {
  .levelCell {
    flex: 1 1 80px;
    min-width: 80px;
    flex-direction: column;
  }
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1279px) {
  .cell {
    font-size: 16px;
    }
    .titleTable{
      font-size: 14px;
      }
      .titleTableSupervisor{
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 5px;
      }
      .titleTableCoSupervisor{
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 5px;
        margin-top: 10px;
      }
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1023px) {
  .Projects {
    max-width: 689px;
    margin: 50px auto;
  }

}


/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 767px) {
  .Projects {
    max-width: 290px;
    margin: 50px auto;
  }
  .ProjectCard{
    display: none;
  }
  .mobile{
    display: flex;
    flex-direction: column;
    width: 290px;
    height: auto;
    gap: 14px;
    margin-bottom: 25px;
    padding: 10px 10px 15px 10px;
    box-sizing: border-box;
    border-radius: 6px;
    position: relative;
    cursor: pointer;
  }
  .mobile:nth-of-type(2n+1){
    background: #FAFAFA;
  }
  .blocksLine{
    display: flex;
    gap: 4px;
    max-width: 290px;
    flex-wrap: wrap;
  }
  .block{
    box-sizing: border-box;
    display: flex;
    height: 28px;
    padding: 10px 10px 11px 10px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: var(--blue-100, #3852DE);
    p{
      color: white;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .titleTable{
    text-align: left;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 270px;
    overflow: hidden;
  }
  .titleTableSupervisor{
    text-align: left;
    margin-bottom: -10px;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .titleSupervisor{
    text-align: left;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 270px;
    overflow: hidden;
  }
  .textFields{
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    margin-bottom: -2px;
  }
  .Badges{
    display: flex;
    width: 270px;
    flex-wrap: wrap;
    gap: 4px;
    overflow: hidden;
  }

  .Edit{
    width: 27px;
    height: 27px;
    margin-top: 0;
    }
}
