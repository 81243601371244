.Generators {
  display: flex;
  height: auto;
  min-height: calc(100vh - 52px);
  flex-direction: row;
}
.ErrorGlobal {
  margin: auto;
  margin-bottom: 200px;
}
.permissions {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding-bottom: 100px;
}
.link {
  text-decoration: none;
}
.nav {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  width: 405px;
  margin-bottom: 160px;
  position: sticky;
  top: 20px;
  margin-left: -10px;
}
.navBlock {
  display: block;
}
.main {
  margin-left: 0px;
}
.button {
  margin-top: 0;
  text-align: left;
  margin-left: 195px;
  background-color: #fff;
  border: none;
  cursor: pointer;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 31px;
  margin-bottom: 5px;
  position: relative;
  padding: 0;
  width: 325px;
  padding-left: 18px;
  height: 67px;
  display: flex;
  align-items: center;
  padding: 20px;
}

.buttonActive {
  margin-top: 0;
  text-align: left;
  margin-left: 195px;
  background-color: #fff;
  border: none;
  cursor: pointer;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 31px;
  margin-bottom: 5px;
  position: relative;
  padding: 0;
  width: 325px;
  padding-left: 18px;
  height: 67px;
  border-radius: 6px;
  display: flex;
  align-items: center;
}
.pActive {
  background-color: #f6f6f6;
  height: auto;
  padding: 14px 16px 14px 10px;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  z-index: 15;
}
.img {
  width: 30px;
  height: 32px;
  position: relative;
  background-color: #fff;
  padding: 14px 10px 14px 14px;
}
.imgActive {
  width: 30px;
  height: 32px;
  position: relative;
  background-color: #f6f6f6;
  padding: 14px 0 14px 16px;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  z-index: 15;
}

/* /////////////////////////////////////////////////////////////////////////////////////////// */

@media screen and (max-width: 1919px) {
  .nav {
    margin-top: 45px;
    width: 405px;
  }

  .button {
    margin-left: 105px;
    background-color: #fff;
    font-size: 20px;
    line-height: 24px;
  }

  .buttonActive {
    margin-top: 0;
    text-align: left;
    margin-left: 105px;
    font-size: 20px;
    line-height: 24px;
  }

  .img {
    width: 30px;
    height: 24px;
  }
  .imgActive {
    width: 30px;
    height: 24px;
  }
}

/* /////////////////////////////////////////////////////////////////////////////////////////// */

@media screen and (max-width: 1439px) {
  .nav {
    margin-top: 45px;
    width: 405px;
  }

  .button {
    margin-left: 50px;
    font-size: 20px;
    line-height: 24px;
    width: 280px;
  }

  .buttonActive {
    margin-left: 50px;
    font-size: 20px;
    line-height: 24px;
    width: 280px;
  }

  .img {
    width: 30px;
    height: 24px;
  }
  .imgActive {
    width: 30px;
    height: 24px;
  }
}

/* /////////////////////////////////////////////////////////////////////////////////////////// */

@media screen and (max-width: 1279px) {
  .nav {
    display: none;
  }
  .Generators {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
  }

  .main {
    margin: 0 auto;
  }
}

/* /////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 767px) {
  .main {
    min-height: auto;
  }
}
