.Background {
  display: block;
  margin-top: 60px;
  text-align: left;
  margin-bottom: 170px;
}
.upperBlock {
  display: flex;
  width: 100%;
  gap: 238px;
}
.education {
  width: 447px;
}
.experience {
  width: 447px;
}
.advancedTraining {
  margin-top: 90px;
}
.title {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 49px;
  margin: 0;
  margin-bottom: 40px;
}
.itemBlock {
  display: flex;
  gap: 28px;
}
.imgSection {
  position: relative;
  width: 28px;
}
.circle {
  border: 1.5px solid #232323;
  border-radius: 91px;
  width: 28px;
  height: 28px;
  position: absolute;
  z-index: 2;
  background-color: #fff;
}
.line {
  border: 0.75px solid #818181;
  position: absolute;
  height: 100%;
  left: 14px;
  z-index: 1;
  box-sizing: border-box;
  background-color: #818181;
}

.blockSection {
  width: 391px;
  height: auto;
  background: #f7f7f7;
  border-radius: 6px;
  margin-bottom: 16px;
  box-sizing: border-box;
  padding: 30px;
}
.educationType {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #3852de;
  margin: 0 0 16px;
}
.institutionName {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 8px;
}
.qualification {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #818181;
  margin: 0 0 20px;
}
.educationDate {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
}

.experienceName {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-top: 0;
  margin: 0 0 8px;
}
.experiencePosition {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin: 0 0 20px;
}
.experienceDate {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
}
.advancedBlock {
  width: 1140px;
  height: auto;
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 30px;
  margin-bottom: 10px;
}
.advancedBlock:nth-child(2n) {
  background: rgba(247, 247, 247, 0.95);
}
.advancedData {
  width: 640px;
}
.advancedCourseName {
  margin: 0 0 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #3852de;
}
.advancedInstitutionName {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin: 0 0 7px;
}
.advancedDocumentType {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #818181;
  margin: 0;
}
.advancedTimeDiv {
  display: flex;
}
.advancedHours {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin-right: 30px;
  margin-top: 0;
}
.advancedEndDate {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-top: 0;
}
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1919px) {
  .Background {
    margin-top: 50px;
    margin-bottom: 170px;
  }
  .upperBlock {
    gap: 135px;
  }
  .education {
    width: 390px;
  }
  .experience {
    width: 390px;
  }
  .advancedTraining {
    margin-top: 76px;
  }
  .title {
    font-size: 34px;
    line-height: 38px;
    margin: 0;
    margin-bottom: 30px;
  }
  .itemBlock {
    gap: 30px;
  }
  .imgSection {
    width: 22px;
  }
  .circle {
    width: 22px;
    height: 22px;
  }
  .line {
    left: 12px;
  }

  .blockSection {
    width: 342px;
    margin-bottom: 17px;
    padding: 24px;
  }
  .educationType {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 8px;
  }
  .institutionName {
    font-size: 18px;
    line-height: 22px;
  }
  .educationDate {
    font-size: 16px;
    line-height: 19px;
  }
  .experienceName {
    font-size: 18px;
    line-height: 22px;
  }
  .experiencePosition {
    font-size: 16px;
    line-height: 19px;
  }
  .experienceDate {
    font-size: 16px;
    line-height: 19px;
  }
  .advancedBlock {
    width: 915px;
    padding: 24px;
    margin-bottom: 6px;
  }
  .advancedData {
    width: 640px;
  }
  .advancedCourseName {
    margin: 0 0 9px;
    font-size: 16px;
    line-height: 19px;
  }
  .advancedInstitutionName {
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 5px;
  }
  .advancedDocumentType {
    font-size: 16px;
    line-height: 19px;
  }
  .advancedHours {
    font-size: 16px;
    line-height: 19px;
    margin-right: 30px;
  }
  .advancedEndDate {
    font-size: 18px;
    line-height: 22px;
  }
  .advancedTimeDiv {
    align-items: baseline;
  }
  .qualification {
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 12px;
  }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1439px) {
  .upperBlock {
    gap: 130px;
  }
  .education {
    width: 375px;
  }
  .experience {
    width: 375px;
  }
  .advancedTraining {
    margin-top: 70px;
  }
  .blockSection {
    width: 328px;
  }
  .advancedBlock {
    width: 880px;
  }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1279px) {
  .Background {
    margin-top: 40px;
    margin-bottom: 170px;
  }
  .upperBlock {
    gap: 135px;
  }
  .education {
    width: 390px;
  }
  .experience {
    width: 390px;
  }
  .advancedTraining {
    margin-top: 60px;
  }
  .title {
    font-size: 34px;
    line-height: 38px;
    margin: 0;
    margin-bottom: 30px;
  }
  .itemBlock {
    gap: 30px;
  }
  .imgSection {
    width: 22px;
  }
  .circle {
    width: 22px;
    height: 22px;
  }
  .line {
    left: 12px;
  }

  .blockSection {
    width: 328px;
    margin-bottom: 17px;
    padding: 24px;
  }
  .educationType {
    font-size: 16px;
    line-height: 19px;
  }
  .institutionName {
    font-size: 18px;
    line-height: 22px;
  }
  .educationDate {
    font-size: 16px;
    line-height: 19px;
  }
  .experienceName {
    font-size: 18px;
    line-height: 22px;
  }
  .experiencePosition {
    font-size: 16px;
    line-height: 19px;
  }
  .experienceDate {
    font-size: 16px;
    line-height: 19px;
  }
  .advancedBlock {
    width: 916px;
    padding: 24px;
    margin-bottom: 6px;
  }
  .advancedData {
    width: 640px;
  }
  .advancedCourseName {
    margin: 0 0 9px;
    font-size: 16px;
    line-height: 19px;
  }
  .advancedInstitutionName {
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 5px;
  }
  .advancedDocumentType {
    font-size: 16px;
    line-height: 19px;
  }
  .advancedHours {
    font-size: 16px;
    line-height: 19px;
    margin-right: 30px;
  }
  .advancedEndDate {
    font-size: 18px;
    line-height: 22px;
  }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1023px) {
  .Background {
    margin-top: 30px;
    margin-bottom: 170px;
  }
  .upperBlock {
    gap: 100px;
  }
  .education {
    width: 294px;
  }
  .experience {
    width: 294px;
  }
  .advancedTraining {
    margin-top: 50px;
  }
  .title {
    font-size: 28px;
    line-height: 31px;
    margin: 0;
    margin-bottom: 24px;
  }
  .itemBlock {
    gap: 0px;
  }
  .imgSection {
    display: none;
  }

  .blockSection {
    width: 294px;
    margin-bottom: 12px;
    padding: 18px;
  }
  .educationType {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 7px;
  }
  .institutionName {
    font-size: 14px;
    line-height: 17px;
  }
  .educationDate {
    font-size: 14px;
    line-height: 17px;
  }
  .experienceName {
    font-size: 14px;
    line-height: 17px;
  }
  .experiencePosition {
    font-size: 14px;
    line-height: 17px;
  }
  .experienceDate {
    font-size: 14px;
    line-height: 17px;
  }
  .advancedBlock {
    width: 688px;
    padding: 18px;
    margin-bottom: 4px;
  }
  .advancedData {
    width: 510px;
  }
  .advancedCourseName {
    margin: 0 0 6px;
    font-size: 14px;
    line-height: 17px;
  }
  .advancedInstitutionName {
    font-size: 14px;
    line-height: 17px;
    margin: 0 0 3px;
  }
  .advancedDocumentType {
    font-size: 14px;
    line-height: 17px;
  }
  .advancedHours {
    font-size: 14px;
    line-height: 17px;
    margin-right: 20px;
  }
  .advancedEndDate {
    font-size: 16px;
    line-height: 19px;
  }
  .qualification {
    font-size: 14px;
    line-height: 17px;
    margin: 0 0 12px;
  }
}
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 767px) {
  .Background {
    margin-top: -2px;
    margin-bottom: 100px;
  }
  .upperBlock {
    gap: 0px;
    display: block;
  }
  .education {
    width: 290px;
  }
  .experience {
    width: 290px;
  }
  .advancedTraining {
    margin-top: 50px;
  }
  .title {
    font-size: 24px;
    line-height: 27px;
    margin-bottom: 14px;
    margin-top: 30px;
  }
  .itemBlock {
    gap: 0px;
  }

  .blockSection {
    width: 290px;
    margin-bottom: 8px;
    padding: 18px;
  }
  .educationType {
    font-size: 14px;
    line-height: 17px;
  }
  .institutionName {
    font-size: 14px;
    line-height: 17px;
  }
  .educationDate {
    font-size: 14px;
    line-height: 17px;
  }
  .experienceName {
    font-size: 14px;
    line-height: 17px;
  }
  .experiencePosition {
    font-size: 14px;
    line-height: 17px;
  }
  .experienceDate {
    font-size: 14px;
    line-height: 17px;
  }
  .advancedBlock {
    background: rgba(247, 247, 247, 0.95);
    width: 290px;
    padding: 18px;
    margin-bottom: 8px;
    position: relative;
  }
  .advancedData {
    margin-top: 29px;
  }
  .advancedCourseName {
    margin: 0 0 6px;
    font-size: 14px;
    line-height: 17px;
  }
  .advancedInstitutionName {
    font-size: 14px;
    line-height: 17px;
    margin: 0 0 4px;
  }
  .advancedDocumentType {
    font-size: 14px;
    line-height: 17px;
  }
  .advancedHours {
    font-size: 14px;
    line-height: 17px;
    margin-right: 0px;
    position: absolute;
    right: 18px;
  }
  .advancedEndDate {
    font-size: 16px;
    line-height: 19px;
    position: absolute;
    left: 18px;
  }
}
