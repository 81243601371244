.Instructor{
text-align: left;
width: 1530px;
display: flex;
flex-direction: column;
margin: auto;
margin-top: 156px;
}
.title{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 44px;
    line-height: 53px;
    margin: 0;
}
.mainText{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    margin: 40px 0 30px;
    width: 880px;
    opacity: 0.8;
}
.buttonActive{
    font-weight: 500;
    max-width: 173px;
    height: 66px;
    border-radius: 14px;
    font-size: 20px;
    box-sizing: border-box;
    padding: 20px 28px 22px;
    cursor: auto;
}
.buttonActive:hover{
    background: #3852DE;
}
.button{
    font-weight: 500;
    max-width: 173px;
    height: 66px;
    border-radius: 14px;
    font-size: 20px;
    background-color: #fff;
    border: 1px solid rgba(35, 35, 35, 0.2);
    color: black;
    box-sizing: border-box;
    padding: 20px 28px 22px;
}
.button:hover{
    opacity: 0.8;
    background-color: #fff;
}
.buttonsBlock{
    width: 824px;
    gap: 14px;
    display: flex;
}
.selectedSection{
    background: rgba(236, 239, 255, 0.4);
    border-radius: 20px;
    width: 1530px;
    height: 484px;
    margin-top: 50px;
    position: relative;
    display: flex;
    align-items: center;
}
.content{
    display: flex;
    box-sizing: border-box;
    padding: 0px 60px;
}
.leftSide{
 display: block;
}
.titleContent{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    margin: 0;
    margin-bottom: 26px;
}
.textContent{
    width: 506px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    margin: 0;
    margin-top: 30px;
}
.buttonTry{
    width: 171px;
    height: 71px;
    background: #3852DE;
    border-radius: 20px;
    font-size: 22px;
    margin-right: 30px;
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 29px;
}
.buttons{
    margin-top: 40px;
    display: flex;
    align-items: center;
}
.buttonContact{
    width: 145px;
    height: 27px;
    color: #3852DE;
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 22px;
line-height: 27px;
cursor: pointer;
}
.buttonContact:hover{
    opacity: 0.8;
    width: 155px;
    .arrowShort{
        margin-left: 23px;
    }
}
.arrowShort{
    margin-left: 16px;
}
.screenTwo{
    position: absolute;
    top: 45px;
    right: 141px;
    width: 533.59px;
    height: 384px;
}
.feedbackTitle{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 44px;
    line-height: 53px;
    margin: 160px 0 60px;

}
.blocksSection{
    display: flex;
    gap: 30px;
    // max-width: 1230px;
    flex-wrap: wrap;
}
.wrapper{
    border-radius: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
.block{
border: 1px solid rgba(35, 35, 35, 0.2);
padding: 90px 80px;

width: 750px;
height: 496px;
border: 1px solid rgba(35, 35, 35, 0.2);
border-radius: 20px;
box-sizing: border-box;
padding: 100px 63px;
display: flex;
flex-direction: column;
}
.blockImg{
    width: 750px;
    height: 496px;
    border: none;
    border-radius: 20px;
    box-sizing: border-box;
    background: #3852DE;
}
.feedbackImg{
    width: 750px;
    height: 490px;
    height: 100%;
    border-radius: 20px;
}
.blockPic{
    width: 84px;
    height: 84px;
    border-radius: 12px;
    margin-bottom: 36px;
    }
.blockTitle{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
    margin: 0;
    margin-bottom: 30px;
}
.list{
    display: flex;
    margin-bottom: 18px;
}
.listText{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    margin: 0;
    margin-left: 10px;
    opacity: 0.7;
}
.link{
    text-decoration: none;
}
/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1919px) {
    .Instructor{
        text-align: left;
        width: 1230px;
        display: flex;
        flex-direction: column;
        margin: auto;
        margin-top: 156px;
        }
        .title{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 40px;
            line-height: 48px;
            margin: 0;
        }
        .mainText{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            margin: 40px 0 30px;
            width: 666px;
        }
        .buttonActive{
            max-width: 151px;
            height: 56px;
            border-radius: 14px;
            font-size: 18px;
            box-sizing: border-box;
            padding: 16px 23px 18px;
        }
        .button{
            max-width: 151px;
            height: 56px;
            border-radius: 14px;
            font-size: 18px;
            background-color: #fff;
            border: 1px solid rgba(35, 35, 35, 0.2);
            color: black;
            box-sizing: border-box;
            padding: 16px 23px 18px;
        }
        .button:hover{
            opacity: 0.8;
            background-color: #fff;
        }
        .buttonsBlock{
            width: 732px;
            gap: 14px;
            display: flex;
        }
        .selectedSection{
            background: rgba(236, 239, 255, 0.4);
            border-radius: 20px;
            width: 1230px;
            height: 450px;
            margin-top: 50px;
            position: relative;
        }
        .content{
            display: flex;
            box-sizing: border-box;
            padding: 60px;
        }
        // .leftSide{
        //     display: block;
        // }
        .titleContent{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 39px;
            margin: 0;
            margin-bottom: 40px;
        }
        .textContent{
            width: 506px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 28px;
            margin: 0;
            margin-top: 30px;
        }
        .buttonTry{
            width: 151px;
            height: 65px;
            background: #3852DE;
            border-radius: 20px;
            font-size: 22px;
            margin-right: 30px;
        }
        .buttons{
            margin-top: 40px;
            display: flex;
            align-items: center;
        }
        .buttonContact{
            width: 145px;
            height: 27px;
            color: #3852DE;
            font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 27px;
        }
        .arrowShort{
            margin-left: 16px;
            position: relative;
            top: 1px;
        }
        .screenTwo{
            position: absolute;
            top: 45px;
            right: 67px;
        }
        .feedbackTitle{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 39px;
        }
        .blocksSection{
            display: flex;
            gap: 30px;
            // max-width: 1230px;
            flex-wrap: wrap;
        }
        .block{
            width: 600px;
            height: 496px;
            border: 1px solid rgba(35, 35, 35, 0.2);
            border-radius: 20px;
            box-sizing: border-box;
            padding: 100px 63px;
            display: flex;
            flex-direction: column;
        }
        .blockImg{
            width: 600px;
            height: 496px;
            border-radius: 20px;
            box-sizing: border-box;
        }
        .feedbackImg{
            width: 600px;
            border-radius: 20px;
        }
        .blockPic{
            width: 84px;
            height: 84px;
            border-radius: 12px;
            margin-bottom: 36px;
            }
        .blockTitle{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 34px;
            margin: 0;
            margin-bottom: 18px;
        }
        .list{
            display: flex;
            margin-bottom: 18px;
        }
        .listText{
            font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 27px;
        margin: 0;
        margin-left: 10px;
        }
        .link{
            text-decoration: none;
        }
}

@media screen and (max-width: 1439px) {
    .Instructor{
        width: 1180px;
        margin-top: 120px;
        }
    .title{
        font-size: 32px;
        line-height: 39px;
    }
    .mainText{
        font-size: 18px;
        line-height: 130%;
        margin: 40px 0 30px;
        width: 666px;
    }
    .buttonActive{
        max-width: 151px;
        height: 56px;
        font-size: 18px;
    }
    .button{
        max-width: 151px;
        height: 56px;
        font-size: 18px;

    }
    .buttonsBlock{
        width: 732px;
        gap: 14px;
        display: flex;
    }
    .selectedSection{
        width: 1180px;
        height: 360px;
        margin-top: 48px;
    }
    .content{
        padding: 68px 60px;
    }
    .titleContent{
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 24px;
    }
    .textContent{
        font-size: 18px;
        line-height: 120%;
        margin-top: 30px;
        width: 420px;
    }
    .buttonTry{
        width: 129px;
        height: 58px;
        border-radius: 20px;
        font-size: 20px;
        line-height: 24px;
        margin-right: 30px;
    }
    .buttons{
        margin-top: 40px;
    }
    .buttonContact{
        width: 147px;
        height: 24px;
        font-size: 20px;
        line-height: 24px;
    }
    .arrowShort{
        margin-left: 16px;
        width: 14px;
        height: 16px;
        position: relative;
        top: 1px;
    }
    .screenTwo{
        width: 419.7px;
        height: 302.04px;
        top: 25px;
        right: 85px;
    }
    .feedbackTitle{
        margin: 120px 0 41px;
        font-size: 32px;
        line-height: 39px;
    }
    .blocksSection{
        gap: 20px;
    }
    .block{
        width: 580px;
        height: 416px;
        padding: 85px 60px;
    }
    .blockImg{
        width: 580px;
        height: 416px;
    }
    .feedbackImg{
        width: 580px;
        border-radius: 20px;
    }
    .blockPic{
        width: 72px;
        height: 72px;
        border-radius: 12px;
        margin-bottom: 30px;
        }
    .blockTitle{
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 21px;
    }
    .list{
        margin-bottom: 14px;
    }
    .listText{
        font-size: 18px;
        line-height: 22px;
        margin-left: 10px;
    }
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1279px) {
    .Instructor{
        width: 916px;
        margin-top: 100px;
        }
    .title{
        font-size: 26px;
        line-height: 31px;
    }
    .mainText{
        font-size: 16px;
        line-height: 130%;
        margin: 36px 0 30px;
        width: 666px;
    }
    .buttonActive{
        max-width: 134px;
        height: 49px;
        font-size: 16px;
        padding: 14px 20px 16px;
    }
    .button{
        max-width: 134px;
        height: 49px;
        font-size: 16px;
        padding: 14px 20px 16px;
    }
    .buttonsBlock{
        width: 732px;
        gap: 10px;
        display: flex;
    }
    .selectedSection{
        width: 916px;
        height: 331px;
        margin-top: 24px;
    }
    .content{
        padding: 60px 40px;
    }
    .titleContent{
        font-size: 22px;
        line-height: 27px;
        margin-bottom: 20px;
    }
    .textContent{
        font-size: 16px;
        line-height: 130%;
        margin-top: 20px;
        width: 330px;
    }
    .buttonTry{
        width: 116px;
        height: 54px;
        border-radius: 20px;
        font-size: 18px;
        line-height: 22px;
        margin-right: 14px;
    }
    .buttons{
        margin-top: 38px;
    }
    .buttonContact{
        width: 127px;
        height: 20px;
        font-size: 18px;
        line-height: 22px;
    }
    .buttonContact:hover{
        opacity: 0.8;
        width: 137px;
        .arrowShort{
            margin-left: 23px;
        }
    }
    .arrowShort{
        margin-left: 16px;
        width: 14px;
        height: 16px;
        position: relative;
        top: 1px;
    }
    .screenTwo{
        width: 376.63px;
        height: 271.04px;
        top: 29px;
        right: 57px;
    }
    .feedbackTitle{
        margin: 90px 0 36px;
        font-size: 26px;
        line-height: 31px;
    }
    .blocksSection{
        gap: 20px;
    }
    .block{
        width: 448px;
        height: 348px;
        padding: 65px 40px;
    }
    .blockImg{
        width: 448px;
        height: 348px;
    }
    .feedbackImg{
        width: 448px;
        border-radius: 20px;
    }
    .blockPic{
        width: 64px;
        height: 64px;
        border-radius: 12px;
        margin-bottom: 24x;
        }
    .blockTitle{
        margin-top: -6px;
        font-size: 22px;
        line-height: 27px;
        margin-bottom: 16px;
    }
    .list{
        margin-bottom: 8px;
    }
    .listText{
        font-size: 16px;
        line-height: 19px;
        margin-left: 10px;
    }
    .checkMark{
        width: 24px;
        height: 24px;
    }

}
/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1023px) {
    .Instructor{
        width: 688px;
        margin-top: 80px;
        }
    .title{
        font-size: 22px;
        line-height: 27px
    }
    .mainText{
        font-size: 14px;
        line-height: 130%;
        margin: 30px 0 30px;
        width: 666px;
    }
    .buttonActive{
        max-width: 114px;
        height: 43px;
        font-size: 14px;
        padding: 12px 16px 14px;
    }
    .button{
        max-width: 114px;
        height: 43px;
        font-size: 14px;
        padding: 12px 16px 14px;
    }
    .buttonsBlock{
        width: 688px;
        gap: 12px;
        display: flex;
    }
    .selectedSection{
        width: 688px;
        height: 276px;
        margin-top: 18px;
    }
    .content{
        padding: 56px 30px;
    }
    .titleContent{
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 16px;
    }
    .textContent{
        font-size: 14px;
        line-height: 130%;
        margin-top: 16px;
        width: 300px;
    }
    .buttonTry{
        width: 94px;
        height: 47px;
        border-radius: 16px;
        font-size: 16px;
        line-height: 19px;
        margin-right: 14px;
    }
    .buttons{
        margin-top: 28px;
    }
    .buttonContact{
        width: 117px;
        height: 20px;
        font-size: 18px;
        line-height: 22px;
    }
    .buttonContact:hover{
        opacity: 0.8;
        width: 127px;
        .arrowShort{
            margin-left: 20px;
        }
    }
    
    .arrowShort{
        margin-left: 10px;
        width: 13px;
        height: 14px;
        position: relative;
        top: 1px;
    }
    .screenTwo{
        width: 312px;
        height: 224.53px;
        top: 29px;
        right: 15px;
    }
    .feedbackTitle{
        margin: 80px 0 30px;
        font-size: 22px;
        line-height: 27px;
    }
    .blocksSection{
        gap: 20px;
    }
    .block{
        width: 334px;
        height: 286px;
        padding: 49px 30px;
    }
    .blockImg{
        width: 334px;
        height: 286px;
        border: none
    }
    .feedbackImg{
        width: 334px;
        height: 297px;
        border-radius: 20px;
        border: none;
    }
    .blockPic{
        width: 56px;
        height: 56px;
        border-radius: 12px;
        border-bottom: none;
        }
    .blockTitle{
        margin-top: -10px;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 14px;
    }
    .list{
        margin-bottom: 8px;
    }
    .listText{
        font-size: 14px;
        line-height: 17px;
        margin-left: 8px;
    }
    .checkMark{
        width: 20px;
        height: 20px;
    }
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 767px) {
    .Instructor{
        width: 290px;
        margin-top: 60px;
        }
    .title{
        font-size: 20px;
        line-height: 24px;
    }
    .mainText{
        font-size: 14px;
        line-height: 130%;
        margin: 20px 0 26px;
        width: 290px;
    }
    .buttonActive{
        max-width: 114px;
        height: 43px;
        font-size: 14px;
    }
    .button{
        max-width: 114px;
        height: 43px;
        font-size: 14px;

    }
    .buttonsBlock{
        width: 290px;
        gap: 8px;
        display: flex;
        overflow: scroll;
    }
    .buttonsBlock::-webkit-scrollbar{
        display: none;
    }
    .selectedSection{
        width: 290px;
        margin-top: 20px;
        display: flex;
        align-items:flex-start;
        height: auto;
    }
    .content{
        padding: 30px 20px;
        display: block;
   
    }
    .titleContent{
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 16px;
    }
    .textContent{
        font-size: 14px;
        line-height: 130%;
        margin-top: 16px;
        width: 250px;
    }
    .buttonTry{
        width: 94px;
        height: 47px;
        border-radius: 16px;
        font-size: 16px;
        line-height: 19px;
        margin-right: 14px;
    }
    .buttons{
        margin-top: 28px;
    }
    .buttonContact{
        width: 107px;
        height: 20px;
        font-size: 16px;
        line-height: 19px;
    }
    .buttonContact:hover{
        opacity: 0.8;
        width: 117px;
        .arrowShort{
            margin-left: 20px;
        }
    }
    .arrowShort{
        margin-left: 10px;
        width: 13px;
        height: 14px;
        position: relative;
        top: 1px;
    }
    .screenTwo{
        width: 261px;
        height: 187.83px;
        top: 25px;
        right: 15px;
        position: relative;
        margin-bottom: 15px;
    }

    .feedbackTitle{
        margin: 60px 0 20px;
        font-size: 20px;
        line-height: 24px;
    }
    .blocksSection{
        gap: 0px;
    }
    .block{
        width: 290px;
        height: auto;
        padding: 30px 20px;
        border-radius:  20px 20px 0px 0px;
        border-bottom: none;
    }
    .blockImg{
        width: 290px;
        height: 202px;
        border-radius: 0px 0px 20px 20px;
        margin-bottom: 20px;
    }
    .feedbackImg{
        width: 290px;
        height: 100%;
        border-radius: 0px 0px 20px 20px;
    }
    .blockPic{
        width: 44px;
        height: 44px;
        border-radius: 12px;
        margin-bottom: 20x;
        }
    .blockTitle{
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 12px;
    }
    .list{
        margin-bottom: 6px;
    }
    .listText{
        font-size: 12px;
        line-height: 15px;
        margin-left: 8px;
    }
    .checkMark{
        width: 16px;
        height: 16px;
    }

}