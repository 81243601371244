.searchArea{
    position: relative;
}
.Input{
    width: 444px;
    height: 50px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding-left: 45px;
    padding-right: 4px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding-right: 40px;
    box-sizing: border-box;
    border: 1px solid rgba(255, 255, 255, 0.15);
}
.Input:hover{
  background: rgba(255, 255, 255, 0.25);
  color: #B3B3B3;
}
.activeInput{
    width: 444px;
    height: 50px;
    background-color: #ffffff;
    border-radius: 8px;
    padding-left: 45px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding-right: 40px;
    box-sizing: border-box;
    border: none;
}
.errorInput{
  width: 444px;
  height: 50px;
  background: #FFFFFF;
  border-radius: 8px;
  padding-left: 45px;
  color: #ff0000ca;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding-right: 40px;
  box-sizing: border-box;
  border: none;
}
.Input:focus {
    background: #FFFFFF;
  }
  .searchImg{
    position: absolute;
    cursor: pointer;
    left: 15px;
    top: 16px;
    z-index: 3;
    width: 20px;
    height: 20px;
  }
  .plus{
   position: absolute;
   cursor: pointer;
   right: 15px;
   top: 13px;
   z-index: 3;
  }
  .emailsDiv{
    position: absolute;
    top:43px;
    width: 444px;
    max-height: 440px;
    background-color: #fdfbfb;
    overflow-y: scroll;
    overflow-x:hidden;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 10px 0 16px 12px;
    cursor: pointer;
    z-index: 5;
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.07);
    border-radius: 0px 0px 8px 8px;
  }
  .emailsDiv::-webkit-scrollbar{
    width: 13px;
}
.emailsDiv::-webkit-scrollbar-track{
  background-color: #fdfbfb;
    border-radius: 0px 0px 8px 8px;
}
.emailsDiv::-webkit-scrollbar-thumb{
    background-color: #30303089;
    border: 3px solid #fbfbfb;
    border-radius: 5px;
}

  .emailsFlex{
    display: flex;
    align-items: center;
    text-align: left;
    color: #000;
    text-decoration: none;
    width: 100%;

    &:hover{
      background: rgba(56, 81, 222, 0.025);
      border-radius: 8px;
    }
     img{
        width: 24px;
        height: 24px;
        margin: 9px 10px 9px 12px;
     }
     p{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        margin: 9px 0;
     }
  }

  @media screen and (max-width: 1439px) {
  .Input{
    width: 352px;
    height: 50px;
    font-size: 14px;
    line-height: 17px;
    padding-left: 40px;
  }
  .activeInput{
    width: 352px;
    height: 50px;
    font-size: 14px;
    line-height: 17px;
    padding-left: 40px;
  }
  .errorInput{
    width: 352px;
    height: 50px;
    font-size: 14px;
    line-height: 17px;
    padding-left: 40px;
  }
    .plus{
     right: 15px;
     top: 13px;
    }
    .searchImg{
      left: 15px;
      top: 15px;
      width: 20px;
      height: 20px;
    }
    .emailsDiv{
      top:43px;
      width: 352px;
      max-height: 395px;
      padding: 5px 0 10px 12px;
    }
    .emailsDiv::-webkit-scrollbar{
      width: 11px;
  }
    .emailsFlex{
       img{
          width: 20px;
          height: 20px;
          margin: 9px 10px 9px 12px;
       }
       p{
          font-size: 14px;
          line-height: 17px;
          margin: 9px 0;
       }
    }
  }

  @media screen and (max-width: 1279px) {
    .Input{
      width: 252px;
      height: 40px;
      font-size: 12px;
      line-height: 15px;
      padding-left: 37px;
    }
    .activeInput{
      width: 252px;
      height: 40px;
      font-size: 12px;
      line-height: 15px;
      padding-left: 37px;
    }
    .errorInput{
      width: 252px;
      height: 40px;
      font-size: 12px;
      line-height: 15px;
      padding-left: 37px;
    }
    .searchImg{
      left: 15px;
      top: 13px;
      width: 16px;
      height: 16px;
    }
      .plus{
       right: 10px;
       top: 11px;
       width: 20px;
       height: 20px;
      }
      .emailsDiv{
        top:32px;
        width: 252px;
        max-height: 305px;
        padding: 5px 0 5px 8px;
      }
      .emailsDiv::-webkit-scrollbar{
        width: 10px;
    }
      .emailsFlex{
         img{
            width: 12px;
            height: 12px;
            margin: 9px 5px 9px 12px;
         }
         p{
          font-size: 12px;
          line-height: 15px;
            margin: 2px 0;
         }
      }
    }




    @media screen and (max-width: 767px) {
      .searchArea{
        display: none;
      }


    }