.Preloader {
  position: absolute;
  left: 60%;
  top: 30%;
}
.available {
  display: flex;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  margin-top: 150px;
  margin-bottom: 280px;
  text-align: center;
  justify-content: space-around;
}
.titleSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 67px;
  margin-top: 50px;
  margin-bottom: 40px;
}
.trPrediction {
  background-color: #ecefff;
  border-radius: 8px;
  width: 1140px;
  height: 59px;
  display: flex;
  align-items: center;
  margin-top: 0px;
  position: relative;
}
.courseTh {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
  width: 600px;
  text-align: left;
  padding-left: 10px;
}
.passTh {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
  width: 330px;
  text-align: left;
}
.accuracyTh {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
  width: 200px;
  text-align: center;
  cursor: pointer;
  padding-bottom: 4px;
}
.iconBook {
  position: relative;
  top: 5px;
  margin-right: 6px;
  width: 28px;
  height: 28px;
}
.list {
  height: 140px;
  border-bottom: 1px solid rgba(35, 35, 35, 0.2);
  width: 1140px;
  display: flex;
  align-items: center;
}
.list:last-of-type {
  border-bottom: none;
}
.courseDiv {
  margin-left: 0px;
  text-align: left;
  width: 580px;
  margin-right: 20px;
}
.course {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  margin-left: 10px;
  align-items: center;
  margin-right: 40px;
}
.courseData {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.passDiv {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  width: 310px;
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-right: 50px;
}
.passIcon {
  width: 24px;
  height: 24px;
}
.passFail {
  margin-left: 5px;
}
.accuracy {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  width: auto;
  align-items: center;
  cursor: pointer;
  margin-left: -8px;
}
.tablePrediction {
  margin-bottom: 110px;
}
.onMouse {
  position: relative;
}
.divHint {
  position: absolute;
  width: 246px;
  height: 79px;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
  z-index: 6;
  background-color: #fff;
  right: 0px;
  bottom: -140px;
  text-align: left;
  padding: 20px;
}
.hintTitle {
  color: #3852de;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-top: 0;
}
.hint {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}
.hintMainTitle {
  display: none;
}
.divMobile {
  display: none;
}
@media screen and (max-width: 1919px) {
  .title {
    font-size: 44px;
    line-height: 49px;
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .trPrediction {
    width: 915px;
    height: 43px;
  }
  .courseTh {
    font-size: 20px;
    line-height: 22px;
    width: 415px;
    text-align: left;
    padding-left: 10px;
  }
  .gradeTh {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    width: 80px;
    text-align: left;
  }
  .passTh {
    font-size: 20px;
    line-height: 22px;
    width: 240px;
    text-align: center;
    margin-right: 10px;
  }
  .accuracyTh {
    font-size: 20px;
    line-height: 22px;
    width: 180px;
    text-align: right;
    position: relative;
    padding-bottom: 8px;
  }
  .iconBook {
    top: 5px;
    margin-right: 6px;
    width: 24px;
    height: 24px;
  }
  .list {
    height: 98px;
    width: 915px;
  }
  .courseDiv {
    margin-left: 0px;
    text-align: left;
    width: 480px;
    margin-right: 20px;
  }
  .course {
    font-size: 18px;
    line-height: 22px;
    text-align: left;

    align-items: center;
    margin-right: 40px;
  }
  .courseData {
    font-size: 18px;
    line-height: 22px;
  }

  .passDiv {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    width: 170px;
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-right: 60px;
  }
  .passIcon {
    width: 24px;
    height: 24px;
  }
  .passFail {
    margin-left: 5px;
  }
  .accuracy {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    width: auto;
    align-items: center;
    margin-left: -12px;
  }
  .tablePrediction {
    margin-bottom: 200px;
  }
  .Preloader {
    left: 55%;
  }
}

@media screen and (max-width: 1439px) {
  .trPrediction {
    width: 880px;
  }
  .list {
    width: 880px;
  }
}

@media screen and (max-width: 1279px) {
  .hintMainTitle {
    display: flex;
    text-align: left;
    margin-top: -15px;
    margin-bottom: 15px;
    /* width: 500px;
        height: auto; */
    flex-wrap: wrap;
  }
  .coefficienFlex {
    display: flex;
    /* width: auto; */
  }
  .coefficientitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #3852de;
    margin-right: 7px;
    width: 165px;
    margin-right: 8px;
    margin-bottom: 5px;
    position: relative;
    top: 2px;
  }
  .passIconUp {
    width: 22px;
    height: 22px;
  }
  .flash {
    margin-right: 10px;
    margin-left: 8px;
    /* width: auto; */
  }
  .coefficienHint {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* width: 410px; */
    flex-wrap: wrap;
  }
  .divHint {
    display: none;
  }
  .accuracyTh {
    cursor: auto;
  }
  .accuracy {
    cursor: auto;
  }
  .trPrediction {
    width: 916px;
    margin-top: 20px;
  }
  .list {
    width: 916px;
  }
  .title {
    margin-top: 40px;
  }
  .Preloader {
    top: 20%;
    left: 50%;
  }
}

@media screen and (max-width: 1023px) {
  .tablePrediction {
    margin-bottom: 300px;
    width: 689px;
  }
  .trPrediction {
    width: 689px;
  }
  .list {
    width: 689px;
  }
  .title {
    font-size: 32px;
    line-height: 36px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .courseTh {
    font-size: 16px;
    line-height: 18px;
    width: 375px;
    text-align: left;
    padding-left: 10px;
  }

  .passTh {
    font-size: 16px;
    line-height: 18px;
    width: 150px;
    text-align: center;
    margin-right: 10px;
  }
  .accuracyTh {
    font-size: 16px;
    line-height: 18px;
    width: 130px;
    text-align: right;
    position: relative;
  }
  .iconBook {
    top: 3px;
    margin-right: 6px;
    width: 22px;
    height: 22px;
  }

  .courseDiv {
    margin-left: 0px;
    text-align: left;
    width: 400px;
    margin-right: 20px;
  }
  .course {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    align-items: center;
    margin-right: 40px;
  }
  .courseData {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  .passDiv {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    width: 145px;
    display: flex;
    align-items: center;
    margin-left: 12px;
    margin-right: 0px;
  }
  .passIcon {
    width: 24px;
    height: 24px;
  }
  .passFail {
    margin-left: 5px;
  }
  .accuracy {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    width: auto;
    align-items: center;
    text-align: left;
    margin-left: -3px;
  }
  .coefficientitle {
    top: 1px;
  }
}

@media screen and (max-width: 767px) {
  .tablePrediction {
    margin-bottom: 380px;
    width: 290px;
    margin-left: -15px;
    margin: 0 auto;
    min-height: 730px;
  }
  .hintMainTitle {
    display: block;
    text-align: left;
    position: relative;
    top: 36px;
    margin-bottom: 40px;
  }
  .coefficienFlex {
    display: flex;
    position: relative;
    margin-bottom: 0;
    margin-top: 0;
  }
  .coefficientitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #3852de;
    margin-right: 5px;
    margin-bottom: 0px;
    padding-bottom: 0;
    top: 0px;
  }
  .passIconUp {
    width: 22px;
    height: 22px;
    position: relative;
    top: -2px;
    left: -20px;
  }
  .passIconUp2 {
    width: 22px;
    height: 22px;
    position: relative;
    top: 11px;
    left: 0px;
  }

  .flash {
    position: relative;
    top: -1px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-left: -10px;
  }
  .coefficienHint {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: left;
    margin-top: -8px;
    width: 290px;
    margin-bottom: 0;
    margin-right: 0;
    width: 100px;
  }
  .passIcon {
    width: 22px;
    height: 22px;
  }
  .trPrediction {
    display: none;
  }
  .list {
    width: 290px;
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0px;
    height: auto;
  }

  .divMobile {
    display: block;
    position: relative;
  }
  .divMobileFlex {
    display: flex;
    position: relative;
    right: -13px;
    top: -13px;
    width: auto;
    margin-left: 20px;
  }
  .passing {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-left: 6px;
  }
  .passDivMobile {
    display: flex;
    align-items: center;
    height: 17px;
    margin-top: -16px;
    position: relative;
    right: -13px;
    text-align: right;
  }
  .passDivMobilePass {
    display: flex;
    align-items: center;
    height: 17px;
    margin-top: -16px;
    position: relative;
    right: -20px;
    text-align: right;
  }
  .title {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
    margin-top: 28px;
    margin-bottom: 0px;
  }

  .iconBook {
    top: 3px;
    margin-right: 6px;
    width: 22px;
    height: 22px;
  }

  .courseDiv {
    margin-left: 0px;
    text-align: left;
    width: 200px;
    margin-right: 0px;
  }
  .course {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
    align-items: center;
    margin-right: 40px;
    margin-left: 0;
    margin-top: 0px;
  }
  .courseData {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  .passDiv {
    display: none;
  }
  .passIcon {
    width: 20px;
    height: 20px;
  }
  .passFail {
    margin-left: 5px;
  }
  .accuracy {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    width: auto;
    align-items: center;
    text-align: left;
    display: none;
  }
  .passFail {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
  .available {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    width: 290px;
    margin: 150px auto 440px;
  }
}
