.mainPage {
  padding-top: 120px;
  transition: 0.7s;
  z-index: -2;
  position: relative;
  min-height: calc(100vh - 312px);
}
.title {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 67px;
  width: 566px;
  margin: 20px auto 0;
}
.secondTitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  width: 566px;
  margin: 24px auto 221px;
}

@media screen and (max-width: 767px) {
  .img {
    width: 80px;
    height: 80px;
  }
  .title {
    font-size: 22px;
    line-height: 22px;
    width: auto;
    margin: 10px auto 0;
  }
  .secondTitle {
    font-size: 16px;
    line-height: 17px;
    width: auto;
    margin: 14px auto 221px;
  }
}
