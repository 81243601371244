.Header{
    background-color: #232323;
    height: 118px;
    display: flex;
    align-items: center;
    padding-left: 195px;
    padding-right: 195px;
    justify-content: space-between;
}
.interualSection{
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;
}
.logoDigital {
    width: 212.11px;
    height: 24.45px;
  }
  .link{
    width: 132px;
    height: 48px;
    background: rgba(255, 255, 255, 0.06);
    border-radius: 10px;
    text-decoration: none;
    color: #FFFFFF;
    display: flex;
    cursor: pointer;
  }
  .link:hover{
    opacity: 0.8;
  }
  .linkText{
    align-items: center;
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin: auto;
  }
@media screen and (max-width: 1919px) {
.Header{
    height: 100px;
    padding-left: 105px;
    padding-right: 140px;
} 
.logoDigital {
    width: 199.63px;
    height: 23.01px;
  }
}

@media screen and (max-width: 1439px) {
.Header{
    height: 100px;
    padding-left: 50px;
    padding-right: 50px;
}
.logoDigital {
    width: 149.73px;
    height: 17.26px;
  }
}

@media screen and (max-width: 1279px) {
.Header{
    height: 76px;
    padding-left: 54px;
    padding-right: 54px;
}
.logoDigital {
    width: 149.73px;
    height: 17.26px;
  }
  .link{
    width: 116px;
    height: 45px;
  }
  .linkText{
    font-size: 16px;
    line-height: 19px;
  }
}


@media screen and (max-width: 1023px) {
.Header{
    height: 67px;
    padding-left: 40px;
    padding-right: 40px;
}
.logoDigital {
  width: 152px;
  height: 27px;
  }
  .link{
    width: 101px;
    height: 40px;
  }
  .linkText{
    font-size: 14px;
    line-height: 17px;
}
}


@media screen and (max-width: 767px) {
.Header{
    height: 56px;
    padding-left: 15px;
    padding-right: 15px;
}
.interualSection{
  display: flex;
  align-items: center;
  width: 290px;
  margin: auto;
}
.logoDigital {
  width: 114px;
  height: 20px;
  }
  .link{
    width: 88px;
    height: 34px;
    z-index: 2;
  }
}