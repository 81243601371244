.Projects {
  margin-bottom: 100px;
  width: 100%;
  margin-left: -25px !important;
  min-width: 1140px;
}
  .title {
    padding: 0;
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 67px;
    text-align: left;
    margin: 60px 0 30px 0;
  }
  .navDiv{
    display: flex;
    justify-content: space-between;
    height: auto;
    margin-bottom: -20px;
    margin-right: 175px;
  }
  .leftDiv{
    display: flex;
    align-items: center;
  }
  .buttons{
    display: flex;
    gap: 6px;
  }
  .text{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    margin: 0;
    margin-right: 14px;
  }
  .buttonNavActiv{
    background: #3852DE;
    border-radius: 11px;
    height: 37px;
    box-sizing: border-box;
    display: flex;
    padding: 7.5px 14px;
    border: none;
    p{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      margin: auto;
      color: #FFFFFF;      
    }
  }

  .buttonNav{
    background: #ECEFFF;
    border-radius: 11px;
    height: 37px;
    box-sizing: border-box;
    display: flex;
    padding: 7.5px 14px;
    border: none;
    cursor: pointer;
    p{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      margin: auto;
      color: #3852DE;
    }
  }
  .buttonNav:hover{
    background: #D7DCF8;
    }
  .createButton{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #3852DE;
    cursor: pointer;
  }
  .createButton:hover {
    color: #4B63E1;
  }
////////////////////////////////////////////////////////////
@media screen and (max-width: 1919px) {
  .Projects {
    margin-top: 0px;
    width: 100%;
    min-width: 915px;
  }

  .title {
    font-size: 44px;
    line-height: 49px;
    margin: 50px 0 30px 0;
  }
  .navDiv{
    margin-bottom: -10px;
    margin-right: 80px;
  }
  .buttons{
    gap: 6px;
  }
  .text{
    font-size: 18px;
    line-height: 22px;
    margin-right: 14px;
  }
  .buttonNavActiv{
    height: 30px;
    padding: 6.5px 12px;
    p{
      font-size: 16px;
      line-height: 19px;  
    }
  }

  .buttonNav{
    height: 30px;
    padding: 6.5px 12px;
    p{
      font-size: 16px;
      line-height: 19px; 
    }
  }
  .createButton{
    font-size: 20px;
    line-height: 24px;
  }
}
//////////////////////////////////////////////////////////////////////

@media screen and (max-width: 1439px) {
  .Projects {
    margin-bottom: 140px;
    margin-top: 0px;
    width: 100%;
    min-width: 880px;
  }
  .navDiv{
    margin-bottom: -10px;
    margin-top: -8px;
    margin-right: 30px;
  }
  .buttons{
    gap: 6px;
  }
  .text{
    font-size: 18px;
    line-height: 22px;
    margin-right: 10px;
  }
  .buttonNavActiv{
    height: 30px;
    padding: 6.5px 12px;
    p{
      font-size: 16px;
      line-height: 19px; 
    }
  }

  .buttonNav{
    height: 30px;
    padding: 6.5px 12px;
    p{
      font-size: 16px;
      line-height: 19px;
    }
  }
  .createButton{
    font-size: 20px;
    line-height: 24px;
  }
}
//////////////////////////////////////////////////////////////////////

@media screen and (max-width: 1279px) {
  .Projects {
    margin-bottom: 180px;
    width: 915px;
    margin-left: 0px !important;
  }
  .title {
    font-size: 40px;
    line-height: 45px;
    margin: 40px 0 26px 0;
  }
  .navDiv{
    margin-bottom: -10px;
    margin-right: 0px;
  }
  .buttons{
    gap: 6px;
  }
  .text{
    font-size: 18px;
    line-height: 22px;
    margin-right: 10px;
  }
  .buttonNavActiv{
    height: 30px;
    padding: 5.5px 11.5px;
    p{
      font-size: 16px;
      line-height: 19px; 
    }
  }

  .buttonNav{
    height: 30px;
    padding: 5.5px 11.5px;
    p{
      font-size: 16px;
      line-height: 19px;
    }
  }
  .createButton{
    font-size: 20px;
    line-height: 24px;
  }
}



//////////////////////////////////////////////////////////////////////

@media screen and (max-width: 1023px) {
  .Projects {
    margin: auto;
    margin-top: 30px;
    width: 684px;
    min-width: 684px;
  }
  .title {
    font-size: 32px;
    line-height: 36px;
    margin: 0px 0 20px 0;
  }
  .navDiv{
    margin-bottom: -10px;
  }
  .buttons{
    gap: 6px;
  }
  .text{
    font-size: 16px;
    line-height: 19px;
    margin-right: 8px;
  }
  .buttonNavActiv{
    height: 26px;
    padding: 4.5px 11px;
    p{
      font-size: 14px;
      line-height: 17px;  
    }
  }

  .buttonNav{
    height: 26px;
    padding: 4.5px 11px;
    p{
      font-size: 14px;
      line-height: 17px; 
    }
  }
  .createButton{
    font-size: 18px;
    line-height: 22px;
  }
}

//////////////////////////////////////////////////////////////////////

@media screen and (max-width: 767px) {
  .Projects {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-bottom: 100px;
    margin-top: 28px;
    align-items: center;
    width: 290px !important;
    min-width:  290px !important;
    align-self: center;
    justify-content: center;
    box-sizing: border-box;
  }
.main{
  margin: auto;
  width: 290px !important;
  display: flex;
  align-self: center;
  justify-content: center;
}
  .title {
    font-size: 24px;
    line-height: 27px;
    margin: 0px 0 5px 0px;
    width: 290px;
  }
  .navDiv{
    margin-bottom: 10px;
    position: relative;
    margin-top: 45px;
    width: 290px;
  }
  .buttons{
    gap: 4px;
    margin-top: -30px;
    margin-bottom: 55px;
    position: relative;
    left: -5px;
  }
  .text{
    display: none;
  }
  .buttonNavActiv{
    height: 26px;
    padding: 4.5px 9.5px;
    p{
      font-size: 14px;
      line-height: 17px;     
    }
  }

  .buttonNav{
    height: 26px;
    padding: 4.5px 9.5px;
    p{
      font-size: 14px;
      line-height: 17px;   
    }
  }
  .createButton{
    font-size: 16px;
    line-height: 19px;
    position: absolute;
    left: -11px;
    display: flex;
    width: 290px;
    height: 40px;
    padding: 9px 11px 10px 11px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid var(--blue-100, #3852DE);
  }
  .createButton:hover{
    opacity: 0.8;
  }
}
