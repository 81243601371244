.title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;

  text-align: start;

  margin: 0 0 20px 0;
}
