.InstructorPositions {
  display: flex;
  height: auto;
  min-height: calc(100vh - 212px);
}
.permissions {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}
.text {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 67px;
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
}
