.Badge {
  height: max-content;
  width: max-content;
  border-radius: 8px;

  cursor: pointer;

  .interactive {
    cursor: 'pointer';
  }

  .interactive:hover {
    opacity: 0.8;
  }
}

.default {
  color: rgba(0, 0, 0, 0.8);
  background-color: rgba(0, 0, 0, 0.1);
}

.secondary {
  color: #3852de;
  background-color: #ecefff;
}

.primary {
  color: #ffffff;
  background-color: #3852de;
}

.s {
  padding: 5px 10px;

  font-size: 14px;
  line-height: 14px;
}

.m {
  padding: 6px 12px;

  font-size: 18px;
  line-height: 18px;
}

.l {
  // padding: 8px 16px;

  // font-size: 24px;
  // line-height: 24px;

  padding: 3px 6px;
  margin: 1px;
  font-size: 11px;
  line-height: 14px;
}
.xs{
    padding: 3px 6px;
    margin: 1px;
    font-size: 11px;
    line-height: 14px;
}
