.Slider{
    margin-top: 322px;
    text-align: left;
    margin-bottom: 238px;
}
.text{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 56px;
  margin: 0;
  margin-left: 195px;
}
.colorText{
    color: #3852DE;
}

.sliderSection {
   display: flex;
    height: auto;
    width: 1530px;
    position: relative;
    margin: auto;
    justify-content: space-around;
    gap: 30px;
  }
  
  .img {
    margin: 0 auto;
    width: 170px;
    height: 170px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 0;
  }
  
  .job {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    opacity: 0.6;
    text-align: center;
    margin-top: 9px;
    margin-bottom: 24px;
  }

  .description {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    margin: 0 auto;
    width: 670px;
  }
  .slide {
    position: absolute;
    visibility: hidden;
    transition: opacity ease-in-out 0.4s;
    display: none;
  }
  .activeAnim {
    visibility: visible;
    transition: opacity ease-in-out 0.4s;
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
    border: 1px solid rgba(35, 35, 35, 0.2);
    border-radius: 20px;
    margin: auto;
    margin-top: 60px;
    padding: 40px;
    box-sizing: border-box;
    height: 540px;
    width: 750px;
  }
  
  .btnSlideNext {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ffffff;
    border: none;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    top: -70px;
    right: 0px;
    transform: translateY(60%);
  }
  .btnSlidePrev {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ffffff;
    border: none;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    top: -70px;
    right: 70px;
    transform: translateY(60%);
  }
  .btnSlidePrev:hover {
    opacity: 0.8;
  }
  .btnSlideNext:hover {
    opacity: 0.8;
  }
  .containerDots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    top: 105%;
    transform: translateX(-50%);
    display: flex;
    height: 15px;
    align-items: center;
  }
  
  .dot {
    width: 12px;
    height: 12px;;
    border-radius: 50%;
    margin: 0 5px;
    background: #D2DCF8;
    cursor: pointer;
  }
  .dotActive {
    background: #3852DE;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin: 0 5px;
  
  }
  .arrow{
    transform: rotate(180deg);
  }
  
  
   /* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */
   @media screen and (max-width: 1919px) {
    .Slider{
      margin-top: 316px;
      text-align: left;
      margin-bottom: 218px;
  }
  .text{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 44px;
      line-height: 56px;
      margin: 0;
      margin-left: 105px;
  }
  .colorText{
      color: #3852DE;
  }
  
  .sliderSection {
     display: flex;
      height: auto;
      width: 1230px;
      position: relative;
      margin: auto;
      justify-content: space-around;
      gap: 30px;
    }
    
    .img {
      margin: 0 auto;
      width: 170px;
      height: 170px;
      border-radius: 50%;
      object-fit: cover;
    }
    
    .name {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 34px;
      text-align: center;
      margin-top: 22px;
      margin-bottom: 0;
    }
    
    .job {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      opacity: 0.6;
      text-align: center;
      margin-top: 9px;
      margin-bottom: 24px;
    }
  
    .description {
      display: flex;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 28px;
      text-align: center;
      margin: 0 auto;
      width: 532px;
    }
    .slide {
      position: absolute;
      visibility: hidden;
      transition: opacity ease-in-out 0.4s;
      display: none;
    }
    .activeAnim {
      visibility: visible;
      transition: opacity ease-in-out 0.4s;
      display: flex;
      flex-direction: column;
      text-align: center;
      position: relative;
      border: 1px solid rgba(35, 35, 35, 0.2);
      border-radius: 20px;
      margin: auto;
      margin-top: 60px;
      padding: 34px;
      box-sizing: border-box;
      width: 600px;
      height: 523px;
    }
    
    .btnSlideNext {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #ffffff;
      border: none;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      top: -70px;
      right: 0px;
      transform: translateY(60%);
    }
    .btnSlidePrev {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #ffffff;
      border: none;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      top: -70px;
      right: 70px;
      transform: translateY(60%);
    }
    .btnSlidePrev:hover {
      opacity: 0.8;
    }
    .btnSlideNext:hover {
      opacity: 0.8;
    }
    .containerDots {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      height: 15px;
    }
    
    .dot {
      border-radius: 50%;
      margin: 0 5px;
      cursor: pointer;
    }
    .dotActive {
      border-radius: 50%;
      margin: 0 5px;
      cursor: pointer;
    }
    .arrow{
      transform: rotate(180deg);
    }
}


@media screen and (max-width: 1439px) {
  .Slider{
    margin-top: 230px;
    margin-bottom: 168px;
}
.text{
    font-size: 32px;
    line-height: 40px;
    margin-left: 49px;
}
.sliderSection {
  width: 1180px;
    gap: 19px;
  }
  
  .img {
    width: 150px;
    height: 150px;
  }
  
  .name {
    font-size: 24px;
    line-height: 29px;
    margin-top: 22px;
  }
  
  .job {
    font-size: 16px;
    line-height: 28px;
    margin-top: 4px;
    margin-bottom: 18px;
  }

  .description {
    font-size: 18px;
    line-height: 24px;
    width: 513px;
  }
  .activeAnim {
    margin-top: 40px;
    padding: 34px;
    height: 439px;
    width: 581px;
  }
  
  .btnSlideNext {
    width: 40px;
    height: 40px;
    top: -60px;
    right: 0px;
  }
  .btnSlidePrev {
    width: 40px;
    height: 40px;
    top: -60px;
    right: 55px;
  }
  .arrow2{
    width: 40px;
    height: 40px;
  }
  .arrow{
    width: 40px;
    height: 40px;
  }
  .dot {
    width: 10px;
    height: 10px;;
  }
  .dotActive {
    width: 16px;
    height: 16px;;
  }
    }
    
    /* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */
    @media screen and (max-width: 1279px) {
      .Slider{
        margin-top: 167px;
        margin-bottom: 150px;
    }
    .text{
      font-size: 26px;
      line-height: 40px;
        margin-left: 54px;
    }
    .sliderSection {
      width: 916px;
        gap: 20px;
      }
      .img {
        width: 120px;
        height: 120px;
      }
      
      .name {
        font-size: 22px;
        line-height: 27px;
        margin-top: 22px;
      }
      
      .job {
        font-size: 14px;
        line-height: 20px;
        margin-top: 4px;
        margin-bottom: 14px;
      }
    
      .description {
        font-size: 16px;
        line-height: 20px;
        width: 379px;
      }
      .activeAnim {
        margin-top: 36px;
        padding: 34px;
        height: 395px;
        width: 447px;
      }
      
      .btnSlideNext {
        width: 40px;
        height: 40px;
        top: -60px;
        right: 0px;
      }
      .btnSlidePrev {
        width: 40px;
        height: 40px;
        top: -60px;
        right: 50px;
      }
      .dot {
        width: 10px;
        height: 10px;;
      }
      .dotActive {
        width: 14px;
        height: 14px;;
      }
      
    }
    /* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
    @media screen and (max-width: 1023px) {
      .Slider{
        margin-top: 106px;
        margin-bottom: 142px;
    }
    .text{
      font-size: 22px;
      line-height: 30px;
      margin-left: 40px;
    }
    .sliderSection {
      width: 688px;
      gap: 20px;
      }
      .img {
        width: 90px;
        height: 90px;
      }
      
      .name {
        font-size: 22px;
        line-height: 27px;
        margin-top: 18px;
      }
      
      .job {
        font-size: 14px;
        line-height: 20px;
        margin-top: 4px;
        margin-bottom: 14px;
      }
    
      .description {
        font-size: 16px;
        line-height: 20px;
        width: 285px;
      }
      .activeAnim {
        margin-top: 30px;
        padding: 24px;
        height: 361px;
        width: 333px;
      }
      
      .btnSlideNext {
        display: none;
      }
      .btnSlidePrev {
      display: none;
      }
      .dot {
        width: 8px;
        height: 8px;;
      }
      .dotActive {
        width: 12px;
        height: 12px;;
      }
    }
    
    /* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
    @media screen and (max-width: 767px) {
      .Slider{
        width: 290px;
        margin: 60px auto 112px;
    }
    .text{
      font-size: 20px;
      line-height: 30px;
      margin-left: 0px;
    }
    .sliderSection {
      width: 290px;
      gap: 0px;
      }
      .img {
        width: 74px;
        height: 74px;
      }
      
      .name {
        font-size: 18px;
        line-height: 22px;
        margin-top: 14px;
      }
      
      .job {
        font-size: 12px;
        line-height: 20px;
        margin-top: 3px;
        margin-bottom: 10px;
      }
    
      .description {
        font-size: 14px;
        line-height: 18px;
        width: 250px;
      }
      .activeAnim {
        margin-top: 20px;
        padding: 20px;
        height: 309px;
        width: 290px;
      }
      .dot {
        width: 8px;
        height: 8px;
      }
      .dotActive {
        width: 8px;
        height: 8px;
        cursor:auto;
      }
 
    }