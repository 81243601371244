.SummaryFeedback {
  border-radius: 8px;
  width: 1610px;
  height: auto;
  box-sizing: border-box;
  padding: 40px 0x 0px 0px;
  position: relative;
}
.Preloader {
  position: absolute;
  left: 50%;
  top: 20%;
}
.title {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 38px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 40px;
}
.divChart {
  margin-left: 521px;
  height: 325px;
  display: flex;
}
.Chart {
  position: relative;
  margin-top: -100px;
  margin-left: -40px;
}
.divHint {
  position: absolute;
  left: 0px;
  background: rgba(236, 239, 255, 0.3);
  border: 1px solid #3852de;
  border-radius: 6px;
  width: 278px;
  height: 309px;
  margin-top: 0px;
  box-sizing: border-box;
  padding: 30px;
}
.spanFlex {
  display: flex;
  align-items: center;
}
.titelValue {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  margin-right: 8px;
  margin-bottom: 12px;
  color: #818181;
}
.textValue {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  margin-bottom: 12px;
}
.colorGreen {
  width: 18px;
  height: 18px;
  background: #069542;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 12px;
  margin-top: 15px;
}
.colorGreen2 {
  width: 18px;
  height: 18px;
  background-color: #0ddc65;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 12px;
}
.colorYellow {
  width: 18px;
  height: 18px;
  background-color: #fecd1a;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 12px;
}
.colorOrange {
  width: 18px;
  height: 18px;
  background-color: #ff9900;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 12px;
}
.colorRed {
  width: 18px;
  height: 18px;
  background-color: #ff613f;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 12px;
}
.textColorGreen {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  margin-bottom: 12px;
  margin-top: 15px;
}
.textColor {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  margin-bottom: 12px;
}
.sectionIssue {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  margin-top: 60px;

  margin-bottom: 42px;
  box-sizing: border-box;
  width: 1530px;
  height: auto;
  background: #ffffff;
  border-radius: 10px;
  padding: 0px;
}

.particularIssue {
  width: 463.33px;
  height: 170px;
  margin-right: 0px;
  margin-bottom: 0px;
  align-items: start;
}

.questionName {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  min-height: 40px;
  margin-bottom: 0;
}
.divLabel {
  width: 100%;
  height: auto;
  background: rgba(35, 35, 35, 0.03);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 30px;
  flex-wrap: wrap;
  position: relative;
  z-index: 45;
  margin-bottom: 20px;
}
.divLabel:nth-child(2n) {
  background: rgb(255, 255, 255);
}
.flexDiv {
  display: flex;
  flex-wrap: wrap;
  gap: 15px 40px;
}
.titleLabel {
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: left;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1919px) {
  .SummaryFeedback {
    border-radius: 8px;
    width: 1310px;
    height: auto;
    box-sizing: border-box;
    padding: 30px 40px 0px 0px;
    position: relative;
  }
  .Preloader {
    position: absolute;
    left: 50%;
    top: 20%;
  }
  .title {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 36px;
    text-align: left;
    margin-top: 0;
    margin-bottom: 40px;
  }
  .divChart {
    // margin-left: 421px;
    height: 325px;
    display: flex;
  }
  .Chart {
    position: relative;
  }
  .divHint {
    position: absolute;
    width: 258px;
    height: 299px;
    margin-top: 0px;
    box-sizing: border-box;
    padding: 30px;
    border-radius: 6px;
  }
  .spanFlex {
    display: flex;
    align-items: center;
  }
  .titelValue {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin: 0;
    margin-right: 8px;
    margin-bottom: 12px;
  }
  .textValue {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin: 0;
    margin-bottom: 12px;
  }
  .colorGreen {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 12px;
  }
  .colorGreen2 {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 12px;
  }
  .colorYellow {
    width: 18px;
    height: 18px;
    background-color: #fecd1a;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 12px;
  }
  .colorOrange {
    width: 18px;
    height: 18px;
    background-color: #ff9900;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 12px;
  }
  .colorRed {
    width: 18px;
    height: 18px;
    background-color: #ff613f;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 12px;
  }
  .textColor {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin: 0;
    margin-bottom: 12px;
  }
  .textColorGreen {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin: 0;
    margin-bottom: 0px;
  }

  .sectionIssue {
    margin-top: 40px;
    width: 1230px;
  }

  .particularIssue {
    width: 376.67px;
    height: 160px;
  }

  .questionName {
    font-size: 16px;
    line-height: 19px;
  }

  .divLabel {
    padding: 30px;
  }

  .flexDiv {
    gap: 10px 20px;
  }
  .titleLabel {
    font-size: 20px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1439px) {
  .SummaryFeedback {
    width: 1200px;
  }

  .sectionIssue {
    width: 1180px;
  }

  .particularIssue {
    width: 353.33px;
  }

  .questionName {
    font-size: 16px;
    line-height: 19px;
  }

  .divLabel {
    padding: 30px;
  }

  .flexDiv {
    gap: 10px 30px;
  }
  .titleLabel {
    font-size: 20px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1279px) {
  .SummaryFeedback {
    width: 916px;
    padding: 30px 0px 0px 0px;
  }

  .title {
    font-size: 28px;
    line-height: 31px;
  }
  .divChart {
    height: 325px;
    width: 240px;
  }
  .Chart {
    margin-left: -170px;
    width: 240px;
  }
  .divHint {
    width: 240px;
    height: auto;
    padding: 24px 25px 14px;
    border-radius: 6px;
  }

  .titelValue {
    font-size: 16px;
    line-height: 19px;
    margin-right: 6px;
    margin-bottom: 12px;
  }
  .textValue {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 12px;
  }
  .colorGreen {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 12px;
  }
  .colorGreen2 {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 12px;
  }
  .colorYellow {
    width: 18px;
    height: 18px;
    background-color: #fecd1a;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 12px;
  }
  .colorOrange {
    width: 18px;
    height: 18px;
    background-color: #ff9900;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 12px;
  }
  .colorRed {
    width: 18px;
    height: 18px;
    background-color: #ff613f;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 12px;
  }
  .textColor {
    font-size: 16px;
    line-height: 19px;
    margin: 0;
    margin-bottom: 12px;
  }
  .textColorGreen {
    font-size: 16px;
    line-height: 19px;
    margin: 0;
    margin-bottom: 12px;
    margin-top: 15px;
  }
  .sectionIssue {
    width: 916px;
  }

  .particularIssue {
    width: 269.33px;
  }

  .questionName {
    font-size: 16px;
    line-height: 19px;
  }

  .divLabel {
    padding: 24px;
  }

  .flexDiv {
    gap: 10px 30px;
  }
  .titleLabel {
    font-size: 20px;
    line-height: 24px;
  }
}

@media screen and (max-width: 1023px) {
  .SummaryFeedback {
    width: 700px;
    padding: 30px 0px 0px 0px;
  }

  .title {
    font-size: 20px;
    line-height: 22px;
    margin-left: 0px;
    position: relative;
    z-index: 100;
  }
  .divChart {
    height: 325px;
    width: 180px;
  }
  .Chart {
    margin-top: -102px;
    margin-left: -460px;
    width: 240px;
  }
  .SummaryFeedbackBlock {
    position: relative;
  }
  .divHint {
    width: 688px;
    height: 87px;
    padding: 21px;
    border-radius: 6px;
    top: 390px;
    z-index: 45;
    left: 0;
  }
  .hintLine {
    display: flex;
    align-items: baseline;
    margin-top: -5px;
  }
  .spanFlex {
    margin-right: 8px;
    align-items: flex-end;
  }
  .titelValue {
    font-size: 14px;
    line-height: 17px;
    margin-right: 4px;
    margin-bottom: 12px;
  }
  .textValue {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 12px;
  }
  .colorGreen {
    width: 14px;
    height: 14px;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 12px;
  }
  .colorGreen2 {
    width: 14px;
    height: 14px;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 12px;
  }
  .colorYellow {
    width: 14px;
    height: 14px;
    background-color: #fecd1a;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 12px;
  }
  .colorOrange {
    width: 14px;
    height: 14px;
    background-color: #ff9900;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 12px;
  }
  .colorRed {
    width: 14px;
    height: 14px;
    background-color: #ff613f;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 12px;
  }
  .textColor {
    font-size: 14px;
    line-height: 17px;
    margin: 0;
    margin-bottom: 12px;
  }
  .textColorGreen {
    font-size: 14px;
    line-height: 17px;
    margin: 0;
    margin-bottom: 12px;
    margin-top: 11px;
  }

  .sectionIssue {
    width: 688px;
    margin-top: 120px;
  }

  .particularIssue {
    width: 268.67px;
    height: 170px;
  }

  .questionName {
    font-size: 16px;
    line-height: 19px;
  }
  .divLabel {
    padding: 20px;
  }

  .flexDiv {
    gap: 54px;
  }
  .titleLabel {
    font-size: 18px;
    line-height: 22px;
  }
}
/////////////////////////////////////////////////////////
@media screen and (max-width: 767px) {
  .Preloader {
    position: absolute;
    left: 45%;
    top: 20%;
  }
  .SummaryFeedback {
    width: 320px;
    padding: 20px 0px 0px 0px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 20px;
    margin-left: -7px;
  }
  .SummaryFeedbackBlock {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .title {
    font-size: 20px;
    line-height: 22px;
    margin-left: 0;
    text-align: left;
  }
  .divChart {
    margin: 0 auto;
    height: 435px;
    width: 240px;
  }
  .Chart {
    margin-top: -35px;
    margin-left: -25px;
    width: 240px;
  }
  .divHint {
    display: block;
    top: 280px;
    border-radius: 6px;
    left: 15px;
    width: 290px;
    height: auto;
    padding: 16px;
  }

  .titelValue {
    font-size: 14px;
    line-height: 17px;
    margin-right: 8px;
    margin-bottom: 12px;
    display: none;
  }
  .textValue {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 12px;
    display: none;
  }
  .colorGreen {
    width: 14px;
    height: 14px;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 12px;
    top: -2px;
    position: relative;
  }
  .colorGreen2 {
    width: 14px;
    height: 14px;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 12px;
  }
  .colorYellow {
    width: 14px;
    height: 14px;
    background-color: #fecd1a;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 12px;
  }
  .colorOrange {
    width: 14px;
    height: 14px;
    background-color: #ff9900;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 12px;
  }
  .colorRed {
    width: 14px;
    height: 14px;
    background-color: #ff613f;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 12px;
  }
  .textColor {
    font-size: 14px;
    line-height: 17px;
    margin: 0;
    margin-bottom: 11px;
  }
  .textColorGreen {
    font-size: 14px;
    line-height: 17px;
    margin: 0;
    margin-bottom: 12px;
    margin-top: 12px;
  }

  .sectionIssue {
    width: 320px;
    margin-top: 0px;
    background-color: #fff;
  }

  .particularIssue {
    width: 268.67px;
  }

  .questionName {
    font-size: 14px;
    line-height: 17px;
  }

  .divLabel {
    padding: 16px;
  }

  .flexDiv {
    gap: 10px;
  }
  .titleLabel {
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
  }

  .fontWeight {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }
}
