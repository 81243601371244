.IndividualAnswers {
  width: 1270px;
  height: auto;
  padding: 30px 0;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 10px;
  text-align: left;
}
.title {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 38px;
  margin-top: 20px;
  margin-bottom: 8px;
  margin-left: 0;
}

.titleStudent {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-top: 0;
  margin-bottom: 0;
}
.divStudent {
  margin-bottom: 30px;
  box-sizing: border-box;
  padding: 35px 0 15px 24px;
}
.divStudent:nth-of-type(2n) {
  background: #f7f7f7;
  border-radius: 8px;
}

.slide {
  display: none;
}
.textSection {
  display: block;
  flex-wrap: wrap;
  min-height: 300px;
  height: auto;
  gap: 26px;
}
.line {
  display: flex;
  width: 892px;
  align-items: flex-start;
  margin-top: 27px;
}
.img {
  margin-right: 8px;
}
.numberDivOne {
  background: #3852de;
  border-radius: 50%;
  width: 29px;
  height: 24px;
  size: 24px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  box-sizing: border-box;
}
.numberDivTwo {
  background: #3852de;
  border-radius: 50%;
  width: 25px;
  height: 24px;
  size: 24px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  box-sizing: border-box;
}
.numberDiv {
  background: #3852de;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  size: 24px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  box-sizing: border-box;
}
.numberText {
  margin: 0;
  color: #ffffff;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
}
.textTitle {
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}
.name {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  width: 692px;
  margin-left: 35px;
}
/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1919px) {
  .IndividualAnswers {
    width: 1050px;
    padding: 0px;
  }
  .title {
    font-size: 32px;
    line-height: 36px;
    margin-top: 50px;
    margin-bottom: 8px;
    margin-left: 0px;
  }

  .titleStudent {
    font-size: 20px;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .Pagination {
    margin: 50px auto 13px;
    width: 345px;
  }
  .textSection {
    min-height: 300px;
    gap: 26px;
  }
  .line {
    width: 741px;
    margin-top: 25px;
  }
  .numberDivOne {
    width: 34px;
    height: 24px;
    size: 24px;
    margin-right: 10px;
  }
  .numberDivTwo {
    width: 28px;
    height: 24px;
    size: 24px;
    margin-right: 10px;
  }
  .numberDiv {
    width: 26px;
    height: 24px;
    size: 24px;
    margin-right: 10px;
  }
  .numberText {
    margin: 0;
    color: #ffffff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
  }
  .textTitle {
    font-size: 18px;
    line-height: 22px;
  }
  .name {
    font-size: 16px;
    line-height: 19px;
    width: 541px;
  }
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1439px) {
  .IndividualAnswers {
    width: 1180px;
  }
  .title {
    font-size: 28px;
    line-height: 31px;
    margin-top: 50px;
    margin-bottom: 8px;
  }

  .titleStudent {
    font-size: 20px;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .Pagination {
    margin: 50px auto 13px;
    width: 345px;
  }
  .textSection {
    min-height: 300px;
    gap: 26px;
  }
  .line {
    width: 816px;
    margin-top: 25px;
  }
  .numberDivOne {
    width: 34px;
    height: 24px;
    size: 24px;
    margin-right: 10px;
  }
  .numberDiv {
    width: 24px;
    height: 24px;
    size: 24px;
    margin-right: 10px;
  }
  .numberText {
    margin: 0;
    color: #ffffff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
  }
  .textTitle {
    font-size: 18px;
    line-height: 22px;
  }
  .name {
    font-size: 16px;
    line-height: 19px;
    width: 516px;
  }
}
/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1279px) {
  .IndividualAnswers {
    width: 916px;
  }
  .Pagination {
    margin: 40px auto 13px;
    width: 342px;
  }
  .textSection {
    min-height: 300px;
    gap: 26px;
  }
  .line {
    width: 684px;
    margin-top: 25px;
  }
  .numberDivOne {
    width: 42px;
    height: 24px;
    size: 24px;
    margin-right: 10px;
  }
  .numberDivTwo {
    width: 38px;
    height: 24px;
    size: 24px;
    margin-right: 10px;
  }
  .numberDiv {
    width: 26px;
    height: 24px;
    size: 24px;
    margin-right: 10px;
  }
  .numberText {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
  }
  .textTitle {
    font-size: 16px;
    line-height: 19px;
  }
  .name {
    font-size: 16px;
    line-height: 19px;
    width: 384px;
  }
}
/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1023px) {
  .IndividualAnswers {
    width: 656px;
  }
  .title {
    font-size: 20px;
    line-height: 22px;
    margin-top: 50px;
    margin-bottom: 8px;
  }

  .titleStudent {
    font-size: 20px;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .Pagination {
    margin: 30px auto 13px;
    width: 342px;
  }
  .textSection {
    min-height: 300px;
    gap: 26px;
  }
  .line {
    width: 577px;
    margin-top: 25px;
    display: block;
  }
  .numberDivOne {
    width: 24px;
    height: 24px;
    size: 24px;
    margin-right: 10px;
  }
  .numberDivTwo {
    width: 24px;
    height: 24px;
    size: 24px;
    margin-right: 10px;
  }
  .numberDiv {
    width: 24px;
    height: 24px;
    size: 24px;
    margin-right: 10px;
  }
  .numberText {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
  }
  .textTitle {
    font-size: 14px;
    line-height: 17px;
    margin-top: 4px;
  }
  .name {
    font-size: 14px;
    line-height: 17px;
    width: 277px;
    text-align: left;
    margin-left: 0;
  }
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 767px) {
  .IndividualAnswers {
    width: 320px;
    padding-bottom: 65px;
    border-top: none;
  }
  .title {
    font-size: 18px;
    line-height: 20px;
    margin-top: 40px;
    margin-bottom: 8px;
  }

  .titleStudent {
    font-size: 16px;
    line-height: 19px;
    margin-top: 0;
    margin-bottom: 20px;
  }
  .textSection {
    min-height: 200px;
    gap: 0px;
  }
  .Pagination {
    margin: 20px auto 13px;
    width: 342px;
    position: absolute;
  }
  .name {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .line {
    margin-top: 10px;
    width: 280px;
  }
  .divStudent {
    padding-left: 0;
  }
}
