.github {
  display: block;
  width: 1140px;
  margin-bottom: 180px;
}
.titleSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 67px;
  margin-top: 50px;
  margin-bottom: 40px;
}
.inputArea {
  display: flex;
  width: 100%;
}
.borderFilterDiv {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}
.borderFilter {
  width: 100%;
  height: 53px;
  border: 1px solid rgba(35, 35, 35, 0.2);
  border-radius: 8px;
  margin-right: 14px;
  display: flex;
  margin: auto;
  margin-left: 0;
}
.img {
  margin: 14.5px 8px 13.5px 23px;
  width: 24px;
  height: 24px;
}
.input {
  border: none;
  width: 100%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  line-height: 17px;
  color: #818181;
  margin: auto;
  margin-left: 0;
}
.input:hover::placeholder{
  color: #B3B3B3;
}
.filterLanguages {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  border: none;
  margin: auto 0px;
  text-align: left;
  width: 150px;
  cursor: pointer;
}
.filterLanguagesDiv {
  border-left: 1px solid rgba(35, 35, 35, 0.2);
  border-right: 1px solid rgba(35, 35, 35, 0.2);
  height: 100%;
  width: 150px;
  display: flex;
  position: relative;
  padding-left: 19px;
  padding-right: 10px;
}
.filterYears {
  border: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: auto 0px;
  text-align: left;
  position: relative;
  width: 90px;
}
.optionlanguagesDiv {
  position: absolute;
  width: 186px;
  height: auto;
  background-color: rgb(255, 255, 255);
  top: 55px;
  left: -5px;
  transition: 0.7s;
  z-index: 3;
  box-shadow: 2px 4px 23px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.optionRow {
  display: flex;
  height: 32px;
  padding-left: 10px;
  align-items: center;
}
.optionLanguages {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: auto 15px auto 10px;
  text-align: left;
}
.optionLanguagesNone {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: auto 15px;
  text-align: left;
  margin-left: 50px;
}
.optionLanguagesAll {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: auto 15px auto 10px;
  text-align: center;
  margin-left: 35px;
}
.optionyearDiv {
  position: absolute;
  width: 110px;
  height: auto;
  background-color: rgb(255, 255, 255);
  top: 55px;
  left: 5px;
  transition: 0.7s;
  z-index: 3;
  box-shadow: 2px 4px 23px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.filterYearsDiv {
  height: 100%;
  width: 90px;
  display: flex;
  position: relative;
  cursor: pointer;
  margin-right: 0;
  padding-left: 19px;
  padding-right: 10px;
}
.imgSelect {
  width: 24px;
  height: 24px;
  margin: auto 0;
}
.optionLanguages {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: auto 15px auto 10px;
  text-align: left;
}
.optionLanguagessOver {
  position: absolute;
  width: 500px;
  height: 500px;
  left: -155px;
  top: -50px;
  z-index: -2;
  opacity: 0;
}
.optionYearsOver {
  position: absolute;
  width: 500px;
  height: 500px;
  left: -255px;
  top: -50px;
  z-index: -2;
  opacity: 0;
}
.optionYears {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: auto 5px;
  text-align: left;
}
.optionRow:hover {
  cursor: pointer;
  color: #434343;
}
.imgBut {
  background-color: #3852de;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: rgb(255, 255, 255);
  border-radius: 8px;
  border: none;
  width: 150px;
  height: 53px;
  cursor: pointer;
  transition: 0.5s;
  padding-bottom: 2px;
}
.imgBut:hover {
  box-sizing: border-box;
  color: #3852de;
  background-color: #fff;
  border: 1px solid #3852de;
  transition: 0.5s;
}
.trGithub {
  background-color: #ecefff;
  border-radius: 8px;
  width: 1140px;
  height: 59px;
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.repositoryTh {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
  width: 500px;
  text-align: left;
  padding-left: 10px;
  padding-right: 16px;
}
.languageslTh {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
  width: 334px;
  text-align: left;
}
.dateTh {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
  width: 200px;
  text-align: center;
}
.list {
  height: 140px;
  border-bottom: 1px solid rgba(35, 35, 35, 0.2);
  width: 1140px;
  display: flex;
}
.list:last-of-type {
  border-bottom: none;
}
.repository {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-decoration: none;
  text-align: left;
  width: 320px;
  margin-right: 200px;
  color: #3852de;
  margin-top: 58px;
  margin-left: 10px;
  height: 20px;
}
.repository:hover {
  text-decoration: underline;
}
.language {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-top: 7px;
  margin-bottom: 23px;
  width: 250px;
  text-align: left;
  margin-right: 10px;
  margin-top: 58px;
}
.languageNone {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-top: 7px;
  margin-bottom: 23px;
  width: 250px;
  text-align: left;
  margin-right: 18px;
  margin-left: 50px;
  margin-top: 58px;
}
.updated {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  padding-left: 60px;
  width: 100px;
  margin-top: 58px;
}
.worning {
  display: flex;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-top: 150px;
}
.iconLang {
  width: 55px;
  height: 55px;
  margin-bottom: 12px;
  margin-right: 3px;
  margin-top: 42px;
}
.iconMiniLang {
  width: 25px;
  height: 25px;
}

@media screen and (max-width: 1919px) {
  .github {
    display: block;
    width: 915px;
    margin-bottom: 180px;
  }
  .titleSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .title {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 49px;
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .inputArea {
    display: flex;
    width: 100%;
  }
  .borderFilter {
    width: 100%;
    height: 44px;
    border: 1px solid rgba(35, 35, 35, 0.2);
    border-radius: 8px;
    margin-right: 6px;
    display: flex;
    margin: auto;
    margin-left: 0;
  }
  .img {
    margin: 10.5px 8px 13.5px 23px;
    width: 24px;
    height: 24px;
  }
  .input {
    border: none;
    width: 100%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #818181;
    margin: auto;
    margin-left: 0;
  }

  .filterLanguages {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    border: none;
    margin: auto 0px;
    text-align: left;
    width: 150px;
    cursor: pointer;
  }
  .filterLanguagesDiv {
    border-left: 1px solid rgba(35, 35, 35, 0.2);
    border-right: 1px solid rgba(35, 35, 35, 0.2);
    height: 100%;
    width: 150px;
    display: flex;
    position: relative;
  }
  .filterYears {
    border: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin: auto 0px;
    text-align: left;
    position: relative;
    width: 90px;
  }
  .optionlanguagesDiv {
    position: absolute;
    width: 186px;
    height: auto;
    background-color: rgb(255, 255, 255);
    top: 50px;
    left: -5px;
    transition: 0.7s;
    z-index: 3;
    box-shadow: 2px 4px 23px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .optionRow {
    display: flex;
    height: 32px;
    padding-left: 10px;
    align-items: center;
  }
  .optionLanguages {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin: auto 15px auto 10px;
    text-align: left;
  }
  .optionLanguagesNone {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin: auto 15px;
    text-align: left;
    margin-left: 50px;
  }
  .optionLanguagesAll {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin: auto 15px auto 10px;
    text-align: center;
    margin-left: 35px;
  }
  .optionyearDiv {
    position: absolute;
    width: 110px;
    height: auto;
    background-color: rgb(255, 255, 255);
    top: 50px;
    left: 5px;
    transition: 0.7s;
    z-index: 3;
    box-shadow: 2px 4px 23px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .filterYearsDiv {
    height: 100%;
    width: 90px;
    display: flex;
    position: relative;
    cursor: pointer;
    margin-right: 0;
  }
  .imgSelect {
    width: 24px;
    height: 24px;
    margin: auto 0;
  }
  .optionLanguages {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin: auto 15px auto 10px;
    text-align: left;
  }
  .optionLanguagessOver {
    position: absolute;
    width: 500px;
    height: 500px;
    left: -155px;
    top: -50px;
    z-index: -2;
    opacity: 0;
  }
  .optionYearsOver {
    position: absolute;
    width: 500px;
    height: 500px;
    left: -355px;
    top: -50px;
    z-index: -2;
    opacity: 0;
  }
  .optionYears {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin: auto 5px;
    text-align: left;
  }
  .imgBut {
    background-color: #3852de;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: rgb(255, 255, 255);
    border-radius: 8px;
    border: none;
    width: 111px;
    height: 45px;
    cursor: pointer;
    transition: 0.5s;
  }
  .imgBut:hover {
    box-sizing: border-box;
    color: #3852de;
    background-color: #fff;
    border: 1px solid #3852de;
    transition: 0.5s;
  }
  .trGithub {
    background-color: #ecefff;
    border-radius: 8px;
    width: 915px;
    height: 43px;
    display: flex;
    align-items: center;
    margin-top: 30px;
  }
  .repositoryTh {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    width: 355px;
    text-align: left;
    padding-left: 10px;
    margin-right: 60px;
  }
  .languageslTh {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    width: 330px;
    text-align: left;
  }
  .dateTh {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    width: 100px;
    text-align: center;
  }
  .list {
    align-items: center;
    height: 98px;
    border-bottom: 1px solid rgba(35, 35, 35, 0.2);
    width: 915px;
    display: flex;
  }
  .list:last-of-type {
    border-bottom: none;
  }
  .repository {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-decoration: none;
    text-align: left;
    width: 270px;
    margin-right: 160px;
    color: #3852de;
    margin-top: 0px;
  }
  .language {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin-top: 0px;
    margin-bottom: 23px;
    width: 230px;
    text-align: left;
    margin-right: 10px;
    padding-top: 20px;
  }
  .languageNone {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 23px;
    width: 230px;
    text-align: left;
    margin-right: 10px;
    margin-left: 36px;
    margin-top: 0px;
    padding-top: 20px;
  }
  .updated {
    margin-top: 0px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    padding-left: 60px;
    width: 100px;
    margin-top: 0px;
    align-items: center;
    padding-top: 20px;
  }
  .worning {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-top: 150px;
  }
  .iconLang {
    width: 32px;
    height: 32px;
    margin-bottom: 12px;
    margin-right: 3px;
    margin-top: 12px;
  }
  .iconMiniLang {
    width: 25px;
    height: 25px;
  }
}
/* //////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1439px) {
  .github {
    width: 880px;
  }
  .trGithub {
    width: 880px;
  }
  .repositoryTh {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    width: 335px;
    text-align: left;
    padding-left: 10px;
    margin-right: 60px;
  }
  .languageslTh {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    width: 335px;
    text-align: left;
  }
  .dateTh {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    width: 100px;
    text-align: center;
  }
  .list {
    width: 880px;
  }
  .list:last-of-type {
    border-bottom: none;
  }
  .repository {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-decoration: none;
    text-align: left;
    width: 270px;
    margin-right: 140px;
    color: #3852de;
    margin-top: 0px;
  }
  .language {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-top: 0px;
    margin-bottom: 23px;
    width: 240px;
    text-align: left;
    margin-right: 10px;
    padding-top: 20px;
  }
  .languageNone {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 23px;
    width: 250px;
    text-align: left;
    margin-right: 10px;
    margin-left: 25px;
    margin-top: 10px;
    padding-top: 20px;
  }
  .updated {
    margin-top: 0px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    padding-left: 60px;
    width: 100px;
    margin-top: 0px;
    align-items: center;
    padding-top: 20px;
  }
  .trGithub {
    margin-top: 20px;
  }
}
@media screen and (max-width: 1279px) {
  .title {
    margin-top: 40px;
  }
}
@media screen and (max-width: 1023px) {
  .github {
    width: 689px;
  }
  .title {
    font-size: 32px;
    line-height: 36px;
    margin-top: 30px;
  }
  .borderFilter {
    width: 100%;
    height: 38px;
  }
  .img {
    margin: 8.5px 8px 9.5px 13px;
    width: 20px;
    height: 20px;
  }
  .input {
    width: 100%;
  }
  .imgBut {
    width: 101px;
    height: 40px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
  .trGithub {
    width: 689px;
  }
  .repositoryTh {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    width: 300px;
    text-align: left;
    padding-left: 10px;
    margin-right: 10px;
  }
  .languageslTh {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    width: 210px;
    text-align: left;
  }
  .dateTh {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    width: 100px;
    text-align: center;
  }
  .list {
    width: 689px;
  }
  .repository {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-decoration: none;
    text-align: left;
    width: 275px;
    margin-right: 50px;
    color: #3852de;
    margin-top: 0px;
    margin-left: 15px;
  }
  .language {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-top: 0px;
    margin-bottom: 23px;
    width: 190px;
    text-align: left;
    margin-right: 0px;
    padding-top: 20px;
  }
  .languageNone {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 23px;
    width: 190px;
    text-align: left;
    margin-right: 0px;
    margin-left: 35px;
    margin-top: 0px;
    padding-top: 20px;
  }
  .updated {
    margin-top: 0px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    padding-left: 0px;
    width: 100px;
    margin-top: 0px;
    align-items: center;
    padding-top: 20px;
    text-align: left;
  }
  .optionyearDiv {
    top: 40px;
  }
  .optionlanguagesDiv {
    top: 40px;
  }
}

@media screen and (max-width: 767px) {
  .github {
    width: 290px;
    margin-left: -15px;
    margin: 0 auto;
    min-height: 730px;
  }
  .title {
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
    margin-top: 28px;
    margin-bottom: 16px;
  }
  .inputArea {
    display: block;
    width: 100%;
    position: relative;
  }
  .borderFilter {
    width: 100%;
    height: 44px;
    border: 1px solid rgba(35, 35, 35, 0.2);
    margin-right: 0px;
    display: block;
    margin: auto;
    margin-left: 0;
  }
  .img {
    margin: 13.5px 8px 15.5px 13px;
    width: 20px;
    height: 20px;
  }
  .imgSelect {
    width: 20px;
    height: 20px;
  }
  .input {
    border: none;
    width: 100%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    margin: auto;
    margin-left: 0;
    margin-bottom: 17px;
  }
  .imgBut {
    background-color: #3852de;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgb(255, 255, 255);
    border-radius: 8px;
    border: none;
    width: 100%;
    height: 45px;
    cursor: pointer;
    transition: 0.5s;
    position: absolute;
    top: 100px;
    left: 0;
  }
  .filterLanguagesDiv {
    border-left: none;
    border-right: none;
    height: 100%;
    display: flex;
    position: relative;
    cursor: pointer;
    width: auto;
    left: -18px;
    position: relative;
  }
  .filterLanguages {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    border: none;
    margin: auto 0px;
    text-align: left;
    width: auto;
    margin-right: 5px;
  }
  .trGithub {
    display: none;
  }
  .list {
    width: 280px;
    display: flex;
    margin-left: 10px;
    justify-content: space-between;
    height: 100px;
    position: relative;
    margin-left: 0px;
  }
  .list:last-of-type {
    margin-bottom: 100px;
  }
  .searchSection {
    margin-bottom: 48px;
  }
  .filterYearsDiv {
    position: absolute;
    width: auto;
    height: auto;
    background-color: rgb(255, 255, 255);
    top: 53px;
    left: 150px;
    transition: 0.7s;
    z-index: 3;
    box-shadow: none;
    border-radius: 6px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 0;
  }
  .filterYears {
    width: auto;
    margin-right: 5px;
  }
  .language {
    display: none;
  }
  .languageNone {
    display: none;
  }
  .repository {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    width: 180px;
    margin-right: 50px;
    margin-top: 0px;
    margin-left: 15px;
    position: absolute;
    top: 65px;
    left: -10px;
  }
  .updated {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    padding-left: 0px;
    width: 100px;
    margin-top: 0px;
    align-items: center;
    padding-top: 0px;
    text-align: right;
    position: absolute;
    right: 0;
  }
  .iconLang {
    width: 32px;
    height: 32px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-top: -20px;
    margin-left: 5px;
  }
  .optionLanguagessOver {
    position: absolute;
    width: 280px;
    height: 600px;
    left: 0px;
    top: -130px;
    z-index: -2;
    opacity: 0;
  }
  .optionYearsOver {
    position: absolute;
    width: 280px;
    height: 600px;
    left: -155px;
    top: -130px;
    z-index: -2;
    opacity: 0;
  }
  .worning {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-top: 150px;
    margin-bottom: 270px;
  }
}
