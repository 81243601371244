.information {
  margin-left: -10px;
  margin-bottom: 180px;
}

.generalInformation {
  display: flex;
}
.img {
  max-width: 360px;
  height: auto;
  border-radius: 8%;
  margin-top: 60px;
}
.imgPreloader {
  width: 250px;
  height: 360px;
  border-radius: 50%;
  opacity: 1;
  margin-top: 40px;
  margin-right: 40px;
  position: relative;
}
.rightSection {
  display: flex;
  flex-direction: column;
}
.titleSection {
  display: flex;
  margin-left: 30px;
  text-align: left;
  margin-bottom: 0;
  position: relative;
}
.title {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 67px;
  margin-top: 85px;
  margin-bottom: 0;
  max-width: 830px;
}
.icon {
  position: relative;
  top: 100px;
  right: -11px;
  width: 44px;
  height: 44px;
}

.title2 {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
  text-align: left;
  margin-left: 30px;
  margin-top: 4px;
  color: #818181;
  margin-bottom: 10px;
  opacity: 0.6;
}
.gpaSection {
  background-color: #fecd1a;
  display: flex;
  width: 129px;
  border-radius: 15px;
  margin-left: 30px;
  height: 36px;
  position: relative;
  margin-bottom: 12px;
}
.gpaTitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  position: relative;
  bottom: 4px;
  left: 10px;
}
.gpaValue {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  position: relative;
  bottom: 11px;
  left: 15px;
}
.contactSection {
  display: flex;
  margin-left: 30px;
  position: relative;
  margin-top: 11px;
}
.grayContact {
  display: flex;
  width: 100px;
}
.contactValueA:hover {
  color: #3852de;
}
.iconContact {
  margin-right: 10px;
  width: 24px;
  height: 24px;
  margin-bottom: 0;
  padding-top: 2px;
}
.grayTitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #818181;
  margin-bottom: 0;
  margin-top: 0;
}
.contactValue {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-left: 30px;
  text-decoration: none;
  margin-bottom: 0px;
  color: #000;
  margin-top: 0;
}
.contactValueA {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-left: 30px;
  text-decoration: none;
  margin-bottom: 0px;
  color: #000;
  position: relative;
  margin-top: 0;
}
/* ////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1919px) {
  .information {
    margin-bottom: 160px;
  }
  .generalInformation {
    display: flex;
  }
  .img {
    max-width: 210px;
    height: auto;
    border-radius: 8%;
    margin-top: 50px;
  }
  .imgPreloader {
    width: 210px;
    height: 210px;
  }
  .title {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 49px;
    margin-top: 61px;
    margin-bottom: 0;
  }
  .icon {
    position: relative;
    top: 73px;
    right: -11px;
    width: 30px;
    height: 30px;
  }

  .title2 {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    text-align: left;
    margin-left: 30px;
    margin-top: 10px;
    color: #818181;
    margin-bottom: 20px;
    opacity: 0.6;
  }
  .gpaSection {
    width: 129px;
    border-radius: 15px;
    margin-left: 30px;
    height: 36px;
  }
  .gpaTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    position: relative;
    bottom: 4px;
    left: 10px;
  }
  .gpaValue {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    position: relative;
    bottom: 11px;
    left: 15px;
  }
  .contactSection {
    display: flex;
    margin-left: 30px;
    position: relative;
    margin-top: 11px;
  }
  .grayContact {
    display: flex;
    width: 100px;
  }
  .iconContact {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    margin-bottom: 0;
  }
  .grayTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #818181;
    margin-bottom: 0;
    margin-top: 0;
  }
  .contactValue {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin-left: 20px;
    text-decoration: none;
    margin-bottom: 0px;
    color: #000;
    margin-top: 0;
  }
  .contactValueA {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin-left: 20px;
    text-decoration: none;
    margin-bottom: 0px;
    color: #000;
    position: relative;
    margin-top: 0;
  }
}
/* ///////////////////////////////////////////////////////// */
@media screen and (max-width: 1439px) {
  .information {
    margin-bottom: 140px;
  }
  .img {
    margin-top: 50px;
  }

  .title {
    margin-top: 50px;
  }
  .icon {
    display: none;
  }
}
/* ///////////////////////////////////////////////////////////// */
@media screen and (max-width: 1279px) {
  .information {
    margin-left: 20px;
    margin-bottom: 120px;
  }
  .img {
    max-width: 292px;
    height: auto;
    border-radius: 8%;
    margin-top: 30px;
  }
  .imgPreloader {
    width: 292px;
    height: 292px;
  }
  .titleSection {
    margin-left: 20px;
    margin-bottom: 0;
    width: 584px;
  }
  .title {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 49px;
    margin-top: 30px;
    margin-bottom: 0;
  }

  .title2 {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    text-align: left;
    margin-left: 20px;
    margin-top: 15px;
    color: #818181;
    margin-bottom: 20px;
    opacity: 0.6;
  }
  .gpaSection {
    width: 129px;
    border-radius: 15px;
    margin-left: 20px;
    height: 36px;
    margin-bottom: 0;
  }
  .gpaTitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    position: relative;
    bottom: 4px;
    left: 10px;
  }
  .gpaValue {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    position: relative;
    bottom: 11px;
    left: 15px;
  }
  .contactSection {
    display: flex;
    margin-left: 20px;
    position: relative;
    margin-top: 11px;
  }
  .grayContact {
    display: flex;
    width: 100px;
  }
  .iconContact {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    margin-bottom: 0;
  }
  .grayTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #818181;
    margin-bottom: 0;
    margin-top: 0;
  }
  .contactValue {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin-left: 20px;
    text-decoration: none;
    margin-bottom: 0px;
    color: #000;
    margin-top: 0;
  }
  .contactValueA {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin-left: 20px;
    text-decoration: none;
    margin-bottom: 0px;
    color: #000;
    position: relative;
    margin-top: 0;
  }
}
/* /////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1023px) {
  .information {
    margin-left: 20px;
    margin-bottom: 100px;
  }
  .img {
    max-width: 216px;
    height: auto;
    border-radius: 8%;
    margin-top: 30px;
  }
  .imgPreloader {
    width: 216px;
    height: 216px;
  }
  .titleSection {
    width: auto;
  }
  .title {
    font-weight: 400;
    font-size: 34px;
    line-height: 38px;
    margin-top: 41px;
  }
  .title2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;

    margin-left: 20px;
    color: #818181;
    opacity: 0.6;
  }
  .gpaSection {
    width: 129px;
    border-radius: 15px;
    margin-left: 20px;
    height: 36px;
  }
  .gpaTitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    position: relative;
    bottom: 4px;
    left: 10px;
  }
  .gpaValue {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    position: relative;
    bottom: 11px;
    left: 15px;
  }
  .contactSection {
    display: flex;
    margin-left: 20px;
    margin-top: 11px;
  }
  .iconContact {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    margin-bottom: 0;
  }
  .grayTitle {
    font-size: 16px;
    line-height: 19px;
  }
  .contactValue {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-left: 20px;
  }
  .contactValueA {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-left: 20px;
  }
}
/* //////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 767px) {
  .information {
    max-width: 290px;
    display: flex;
    margin: 0 auto;
    margin-bottom: -50px;
  }
  .generalInformation {
    display: block;
    margin-right: 15px;
  }
  .img {
    max-width: 130px;
    height: auto;
    border-radius: 8%;
    margin-top: 20px;
  }
  .imgPreloader {
    width: 130px;
    height: 130px;
    margin-left: 50px;
    margin-top: 50px;
  }
  .titleSection {
    text-align: center;
    margin: 0 auto;
  }
  .title {
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
    margin-top: 12px;
  }
  .title2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    margin-top: 4px;
    color: #818181;
    margin-bottom: 12px;
    margin-top: 12px;
    opacity: 0.6;
    text-align: center;
    padding-right: 10px;
  }
  .gpaSection {
    width: 129px;
    border-radius: 15px;
    margin: 0 auto;
    height: 30px;
    padding-bottom: 4px;
  }
  .gpaTitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    position: relative;
    bottom: 4px;
    left: 10px;
  }
  .gpaValue {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    position: relative;
    bottom: 11px;
    left: 15px;
  }
  .contactSection {
    display: flex;
    margin-left: 00px;
    margin-top: 11px;
  }
  .iconContact {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    margin-bottom: 0;
    top: -3px;
    position: relative;
  }
  .grayTitle {
    display: none;
  }
  .contactValue {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-left: 5px;
  }

  .grayContact {
    display: flex;
    width: auto;
  }
  .contactValueA {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-left: 5px;
  }
  .contactSection {
    width: 290px;
  }
  .Preloader {
    display: none;
  }
}
