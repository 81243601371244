.IndividualAnswers {
  margin: 0;
  text-align: left;
  margin-bottom: 160px;
}

.title {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 38px;
  margin-top: 50px;
  margin-bottom: 8px;
  margin-left: 0;
}

.titleStudent {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-top: 0;
  margin-bottom: 0;
}
.colorBlocksSection {
  display: flex;
  width: 1270px;
  flex-wrap: wrap;
}

.divStudent {
  margin-bottom: 30px;
  box-sizing: border-box;
  padding: 35px 0 45px 24px;
}
.divStudent:nth-of-type(2n) {
  background: #f7f7f7;
  border-radius: 8px;
}

.divColor {
  display: block;
  border-right: 1px solid rgba(35, 35, 35, 0.2);
  margin-right: 21px;
  padding-right: 21px;
  margin-top: 30px;
}
.divColor:last-child {
  display: block;
  margin-right: 21px;
  padding-right: 21px;
  border-right: 1px solid rgba(255, 255, 255, 0);
}
.blocksFlexColor {
  display: flex;
  justify-content: center;
}

.blockColorGreen {
  width: 26px;
  height: 26px;
  background: #069542;
  border-radius: 2px;
  margin-right: 8px;
  position: relative;
  cursor: pointer;
}
.blockColorGreen2 {
  width: 26px;
  height: 26px;
  background-color: #0ddc65;
  border-radius: 2px;
  margin-right: 8px;
  position: relative;
  cursor: pointer;
}
.blockColorYellow {
  width: 26px;
  height: 26px;
  background-color: #fecd1a;
  border-radius: 2px;
  margin-right: 8px;
  position: relative;
  cursor: pointer;
}
.blockColorOrange {
  width: 26px;
  height: 26px;
  background-color: #ff9900;
  border-radius: 2px;
  margin-right: 8px;
  position: relative;
  cursor: pointer;
}
.blockColorRed {
  width: 26px;
  height: 26px;
  background-color: #ff613f;
  border-radius: 2px;
  margin-right: 8px;
  position: relative;
  cursor: pointer;
}
.blocksHint {
  position: absolute;
  width: 256px;
  height: auto;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
  left: 0px;
  top: 40px;
  z-index: 3;
  background: #ffffff;
  box-sizing: border-box;
  padding: 20px;
}
.blocksHintHidden {
  display: none;
}
.textHint {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: 0;
}
.textLabel {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}

.imgDiv {
  display: flex;
  align-items: center;
  margin-top: 29px;
}
.img {
  margin-right: 10px;
}
.titleAnswers {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
}

.textAnswers {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
  margin-top: 10px;
}

@media screen and (max-width: 1919px) {
  .IndividualAnswers {
    margin: 0;
    text-align: left;
    margin-bottom: 160px;
  }

  .title {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 36px;
    margin-top: 50px;
    margin-bottom: 8px;
    margin-left: 0;
  }

  .titleStudent {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .colorBlocksSection {
    display: flex;
    width: 1050px;
    flex-wrap: wrap;
  }

  .divStudent {
    margin-bottom: 30px;
    box-sizing: border-box;
    padding: 25px 0 45px 24px;
  }
  .divStudent:nth-of-type(2n) {
    background: #f7f7f7;
    border-radius: 8px;
  }

  .divColor {
    display: block;
    border-right: 1px solid rgba(35, 35, 35, 0.2);
    margin-right: 21px;
    padding-right: 21px;
    margin-top: 30px;
  }
  .divColor:last-child {
    display: block;
    margin-right: 21px;
    padding-right: 21px;
    border-right: 1px solid rgba(255, 255, 255, 0);
  }
  .blocksFlexColor {
    display: flex;
    justify-content: center;
  }

  .blockColorGreen {
    width: 22px;
    height: 22px;
    border-radius: 2px;
    margin-right: 8px;
    position: relative;
    cursor: pointer;
  }
  .blockColorGreen2 {
    width: 22px;
    height: 22px;
    border-radius: 2px;
    margin-right: 8px;
    position: relative;
    cursor: pointer;
  }
  .blockColorYellow {
    width: 22px;
    height: 22px;
    background-color: #fecd1a;
    border-radius: 2px;
    margin-right: 8px;
    position: relative;
    cursor: pointer;
  }
  .blockColorOrange {
    width: 22px;
    height: 22px;
    background-color: #ff9900;
    border-radius: 2px;
    margin-right: 8px;
    position: relative;
    cursor: pointer;
  }
  .blockColorRed {
    width: 22px;
    height: 22px;
    background-color: #ff613f;
    border-radius: 2px;
    margin-right: 8px;
    position: relative;
    cursor: pointer;
  }
  .blocksHint {
    position: absolute;
    width: 256px;
    height: auto;
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.07);
    border-radius: 6px;
    left: 0px;
    top: 40px;
    z-index: 3;
    background: #ffffff;
    box-sizing: border-box;
    padding: 20px;
  }
  .blocksHintHidden {
    display: none;
  }
  .textHint {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin: 0;
  }
  .textLabel {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }

  .imgDiv {
    display: flex;
    align-items: center;
    margin-top: 29px;
  }
  .img {
    margin-right: 10px;
  }
  .titleAnswers {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
  }

  .textAnswers {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
    margin-top: 10px;
  }
}
@media screen and (max-width: 1279px) {
  .IndividualAnswers {
    margin: 0;
    text-align: left;
    margin-bottom: 160px;
  }

  .title {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 31px;
    margin-top: 50px;
    margin-bottom: 8px;
    margin-left: 0;
  }

  .titleStudent {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .colorBlocksSection {
    display: flex;
    width: 916px;
    flex-wrap: wrap;
  }

  .divStudent {
    margin-bottom: 30px;
    box-sizing: border-box;
    padding: 24px 0 45px 24px;
  }
  .divStudent:nth-of-type(2n) {
    background: #f7f7f7;
    border-radius: 8px;
  }

  .divColor {
    display: block;
    border-right: 1px solid rgba(35, 35, 35, 0.2);
    margin-right: 21px;
    padding-right: 21px;
    margin-top: 30px;
  }
  .divColor:last-child {
    display: block;
    margin-right: 21px;
    padding-right: 21px;
    border-right: 1px solid rgba(255, 255, 255, 0);
  }
  .blocksFlexColor {
    display: flex;
    justify-content: center;
  }

  .blockColorGreen {
    width: 22px;
    height: 22px;
    border-radius: 2px;
    margin-right: 8px;
    position: relative;
    cursor: pointer;
  }
  .blockColorGreen2 {
    width: 22px;
    height: 22px;
    border-radius: 2px;
    margin-right: 8px;
    position: relative;
    cursor: pointer;
  }
  .blockColorYellow {
    width: 22px;
    height: 22px;
    background-color: #fecd1a;
    border-radius: 2px;
    margin-right: 8px;
    position: relative;
    cursor: pointer;
  }
  .blockColorOrange {
    width: 22px;
    height: 22px;
    background-color: #ff9900;
    border-radius: 2px;
    margin-right: 8px;
    position: relative;
    cursor: pointer;
  }
  .blockColorRed {
    width: 22px;
    height: 22px;
    background-color: #ff613f;
    border-radius: 2px;
    margin-right: 8px;
    position: relative;
    cursor: pointer;
  }
  .blocksHint {
    position: absolute;
    width: 256px;
    height: auto;
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.07);
    border-radius: 6px;
    left: 0px;
    top: 40px;
    z-index: 3;
    background: #ffffff;
    box-sizing: border-box;
    padding: 20px;
  }
  .blocksHintHidden {
    display: none;
  }
  .textHint {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin: 0;
  }
  .textLabel {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }

  .imgDiv {
    display: flex;
    align-items: center;
    margin-top: 29px;
  }
  .img {
    margin-right: 10px;
  }
  .titleAnswers {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
  }

  .textAnswers {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
    margin-top: 10px;
  }
}

@media screen and (max-width: 1023px) {
  .IndividualAnswers {
    margin: 0;
    text-align: left;
    margin-bottom: 160px;
  }

  .title {
    font-size: 20px;
    line-height: 22px;
    margin-top: 50px;
    margin-bottom: 8px;
    margin-left: 0;
  }

  .titleStudent {
    font-size: 20px;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .colorBlocksSection {
    display: flex;
    width: 688px;
    flex-wrap: wrap;
  }

  .divStudent {
    margin-bottom: 30px;
    box-sizing: border-box;
    padding: 20px 0 45px 24px;
  }
  .divStudent:nth-of-type(2n) {
    background: #f7f7f7;
    border-radius: 8px;
  }

  .divColor {
    display: block;
    border-right: 1px solid rgba(35, 35, 35, 0.2);
    margin-right: 21px;
    padding-right: 21px;
    margin-top: 30px;
  }
  .divColor:last-child {
    display: block;
    margin-right: 21px;
    padding-right: 21px;
    border-right: 1px solid rgba(255, 255, 255, 0);
  }
  .blocksFlexColor {
    display: flex;
    justify-content: center;
  }

  .blockColorGreen {
    width: 18px;
    height: 18px;
    border-radius: 2px;
    margin-right: 8px;
    position: relative;
    cursor: pointer;
  }
  .blockColorGreen2 {
    width: 18px;
    height: 18px;
    border-radius: 2px;
    margin-right: 8px;
    position: relative;
    cursor: pointer;
  }
  .blockColorYellow {
    width: 18px;
    height: 18px;
    border-radius: 2px;
    margin-right: 8px;
    position: relative;
    cursor: pointer;
  }
  .blockColorOrange {
    width: 18px;
    height: 18px;
    border-radius: 2px;
    margin-right: 8px;
    position: relative;
    cursor: pointer;
  }
  .blockColorRed {
    width: 18px;
    height: 18px;
    border-radius: 2px;
    margin-right: 8px;
    position: relative;
    cursor: pointer;
  }
  .blocksHint {
    position: absolute;
    width: 256px;
    height: auto;
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.07);
    border-radius: 6px;
    left: 0px;
    top: 40px;
    z-index: 3;
    background: #ffffff;
    box-sizing: border-box;
    padding: 20px;
  }
  .blocksHintHidden {
    display: none;
  }
  .textHint {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin: 0;
  }
  .textLabel {
    font-size: 14px;
    line-height: 17px;
  }

  .imgDiv {
    display: flex;
    align-items: center;
    margin-top: 29px;
  }
  .img {
    margin-right: 10px;
  }
  .titleAnswers {
    font-size: 14px;
    line-height: 17px;
    margin: 0;
  }

  .textAnswers {
    font-size: 14px;
    line-height: 17px;
    margin: 0;
    margin-top: 10px;
  }
}
/////////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 767px) {
  .IndividualAnswers {
    width: 320px;
    margin-left: -15px;
  }

  .title {
    font-size: 18px;
    line-height: 20px;
    margin-top: 40px;
    margin-bottom: 8px;
    margin-left: 0;
    padding-left: 15px;
  }

  .titleStudent {
    font-size: 16px;
    line-height: 19px;
    margin-top: 0;
    margin-bottom: 20px;
  }
  .colorBlocksSection {
    width: 290px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: -10px;
  }

  .divStudent {
    margin-bottom: 30px;
    box-sizing: border-box;
    padding: 20px 15px 5px;
    height: auto;
  }
  .divStudent:nth-of-type(2n) {
    background: #f7f7f7;
    border-radius: 8px;
  }

  .divColor {
    display: block;
    border-right: none;
    margin-right: 5px;
    padding-right: 10px;
    margin-top: 0px;
    height: auto;
  }
  .divColor:last-child {
    display: block;
    border-right: none;
    margin-right: 5px;
    padding-right: 10px;
    margin-top: 0px;
  }
  .blocksFlexColor {
    display: flex;
    justify-content: center;
  }

  .blockColorGreen {
    width: 18px;
    height: 18px;
    border-radius: 2px;
    margin-right: 8px;
    position: relative;
    cursor: pointer;
  }
  .blockColorGreen2 {
    width: 18px;
    height: 18px;
    border-radius: 2px;
    margin-right: 8px;
    position: relative;
    cursor: pointer;
  }
  .blockColorYellow {
    width: 18px;
    height: 18px;
    border-radius: 2px;
    margin-right: 8px;
    position: relative;
    cursor: pointer;
  }
  .blockColorOrange {
    width: 18px;
    height: 18px;
    border-radius: 2px;
    margin-right: 8px;
    position: relative;
    cursor: pointer;
  }
  .blockColorRed {
    width: 18px;
    height: 18px;
    border-radius: 2px;
    margin-right: 8px;
    position: relative;
    cursor: pointer;
  }
  .blocksHint {
    position: absolute;
    width: 256px;
    height: auto;
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.07);
    border-radius: 6px;
    left: 0px;
    top: 40px;
    z-index: 3;
    background: #ffffff;
    box-sizing: border-box;
    padding: 20px;
  }
  .blocksHintHidden {
    display: none;
  }
  .textHint {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin: 0;
  }
  .textLabel {
    font-size: 14px;
    line-height: 17px;
    margin-top: 6px;
  }

  .imgDiv {
    display: block;
    align-items: center;
    margin-top: 20px;
  }
  .img {
    margin-right: 10px;
    width: 24px;
    height: 24px;
  }
  .titleAnswers {
    font-size: 14px;
    line-height: 17px;
    margin: 0;
  }

  .textAnswers {
    font-size: 14px;
    line-height: 17px;
    margin: 0;
    margin-top: 10px;
  }
}
