.footer {
  height: 260px;
  position: relative;
  background-color: #1d1d1d;
  color: rgb(255, 255, 255);
  display: flex;
  margin: 0 195px;
  justify-content: space-between;
}
.footerColor {
  background-color: #1d1d1d;
}
.sectionA {
  margin-left: 0px;
  margin-top: 60px;
  text-align: left;
}
.title {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 49px;
  text-align: left;
  margin-top: 0px;
}
.Copyright {
  width: 256px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  margin-top: 73px;
}
.sectionB {
  height: 150px;
  margin-left: 180px;
  margin-top: 47px;
  display: flex;
  flex-direction: column;
}
.link {
  text-align: left;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-top: 26px;
  text-decoration: none;
  color: rgb(255, 255, 255);
  transition: 0.5s;
  width: 100%;
}
.link:hover{
  opacity: 0.6;
}
.sectionC {
  height: 150px;
  margin-left: 124px;
  text-align: left;
  margin-top: 60px;
}
.logo {
  margin-top: 0px;
  width: 29px;
  height: 29px;
  margin-right: 6px;
  background-image: url('../../images/Frame 119.svg');
  background-size: cover;
  transition: 0.5s;
}
.logo:hover{
  opacity: 0.7;
  cursor: pointer;
}
.contactValueA{
  cursor:default;
}
.textEmail {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #fecd1a;
  margin-top: 7px;
  margin-bottom: 0;
  display: none;
}
.text {
  width: 248px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-top: 8px;
  margin-bottom: 0;
}
.sectionD {
  height: 212px;
  margin-left: 101px;
  position: relative;
}
.buttonFooter {
  background-color: #fff631;
  border: none;
  width: 118px;
  height: 40px;
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  margin-top: 65px;
  margin-left: 80px;
  margin-bottom: 0;
}

.text2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-top: 0px;
  margin-left: 75px;
  cursor: pointer;
}
.text3 {
  display: none;
}
.text2:hover{
  opacity: 0.6;
}
.text3:hover{
  opacity: 0.6;
}
.logout {
  display: inline-block;
  background-color: #fecd1a;
  color: rgb(0, 0, 0);
  text-decoration: none;
  border: none;
  border-radius: 8px;
  width: 99px;
  height: 34px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  margin-top: 60px;
  margin-right: 0;
  margin-bottom: 89px;
  transition: 0.8s;
  position: relative;
  right: -50px;
  padding-bottom: 5px;
  transition: 0.5s;
}
.logout:hover {
  transition: 0.5s;
  background-color: #ffdc5b;
}
.logoutText {
  margin: 7px auto;
}

@media screen and (max-width: 1919px) {
  .footer {
    height: 212px;
    max-width: 1919px;
    position: relative;
    background-color: #1d1d1d;
    color: rgb(255, 255, 255);
    display: flex;
    margin: 0 105px;
    justify-content: space-between;
  }
  .footerColor {
    background-color: #1d1d1d;
  }
  .sectionA {
    margin-left: 0px;
    margin-top: 60px;
    text-align: left;
  }
  .title {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 38px;
    text-align: left;
    margin-top: 0px;
    margin-bottom: 24px;
  }
  .Copyright {
    width: 254px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    margin-top: 24px;
  }
  .sectionB {
    height: 150px;
    margin-left: 180px;
    margin-top: 47px;
    display: flex;
    flex-direction: column;
  }
  .link {
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-top: 20px;
    text-decoration: none;
    color: rgb(255, 255, 255);
    position: relative;
  }

  .sectionC {
    height: 150px;
    margin-left: 124px;
    text-align: left;
    margin-top: 60px;
  }
  .logo {
    margin-top: 0px;
    width: 29px;
    height: 29px;
    margin-right: 6px;
  }
  .textEmail {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #fecd1a;
    margin-top: 7px;
    margin-bottom: 0;
  }
  .text {
    width: 214px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 120%;
    margin-top: 8px;
    margin-bottom: 0;
  }
  .sectionD {
    height: 212px;
    margin-left: 101px;
    position: relative;
  }
  .buttonFooter {
    background-color: #fff631;
    border: none;
    width: 118px;
    height: 40px;
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    margin-top: 65px;
    margin-left: 80px;
    margin-bottom: 0;
  }

  .text2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-top: -2px;
    margin-left: 84px;
  }
  .text3 {
    display: none;
  }
  .logout {
    display: inline-block;
    background-color: #fecd1a;
    color: rgb(0, 0, 0);
    text-decoration: none;
    border: none;
    border-radius: 8px;
    width: 99px;
    height: 34px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-top: 60px;
    margin-right: 0;
    margin-bottom: 48px;
    transition: 0.8s;
    position: relative;
    right: -50px;
    padding-bottom: 0;
  }
  .logoutText {
    margin: 7px auto;
  }
}

@media screen and (max-width: 1439px) {
  .footer {
    height: 212px;
    max-width: 1439px;
    position: relative;
    background-color: #1d1d1d;
    color: rgb(255, 255, 255);
    display: flex;
    margin: 0 55px;
    justify-content: space-between;
  }
  .footerColor {
    background-color: #1d1d1d;
  }
  .sectionA {
    margin-left: 0px;
    margin-top: 60px;
    text-align: left;
  }
  .title {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 38px;
    text-align: left;
    margin-top: 0px;
  }
  .Copyright {
    width: 254px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    margin-top: 44px;
  }
  .sectionB {
    height: 150px;
    margin-left: 180px;
    margin-top: 47px;
    display: flex;
    flex-direction: column;
  }
  .link {
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-top: 20px;
    text-decoration: none;
    color: rgb(255, 255, 255);
  }

  .sectionC {
    height: 150px;
    margin-left: 124px;
    text-align: left;
    margin-top: 60px;
    position: relative;
  }
  .logo {
    margin-top: 0px;
    width: 29px;
    height: 29px;
    margin-right: 6px;
    position: absolute;
    top: 70px;
  }
  .textEmail {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #fecd1a;
    margin-top: 7px;
    margin-bottom: 0;
  }
  .text {
    width: 214px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 120%;
    margin-top: 0px;
    margin-bottom: 0;
  }
  .sectionD {
    height: 212px;
    margin-left: 101px;
    position: relative;
  }
  .buttonFooter {
    background-color: #fff631;
    border: none;
    width: 118px;
    height: 40px;
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    margin-top: 65px;
    margin-left: 80px;
    margin-bottom: 0;
  }

  .text2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-top: 0px;
    width: 197px;
    text-align: right;
    margin-left: 0;
  }
  .text3 {
    display: none;
  }
  .logout {
    display: inline-block;
    background-color: #fecd1a;
    color: rgb(0, 0, 0);
    text-decoration: none;
    border: none;
    border-radius: 8px;
    width: 99px;
    height: 34px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-top: 60px;
    margin-right: 0;
    margin-bottom: 65px;
    transition: 0.8s;
    position: relative;
    right: -50px;
  }
  .logoutText {
    margin: 7px auto;
  }
}
@media screen and (max-width: 1279px) {
  .footer {
    height: 202px;
    max-width: 1279px;
    position: relative;
    background-color: #1d1d1d;
    color: rgb(255, 255, 255);
    display: flex;
    width: 880px;
    margin: 0 auto;
    justify-content: space-between;
  }
  .footerColor {
    background-color: #1d1d1d;
  }
  .sectionA {
    margin-left: 0px;
    margin-top: 54px;
    text-align: left;
  }
  .title {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
    text-align: left;
    margin-top: 0px;
  }
  .Copyright {
    width: 254px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    margin-top: 49px;
  }
  .sectionB {
    display: none;
  }
  .link {
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-top: 20px;
    text-decoration: none;
    color: rgb(255, 255, 255);
  }

  .sectionC {
    height: 140px;
    margin-left: 124px;
    text-align: left;
    margin-top: 54px;
    position: relative;
  }
  .logo {
    margin-top: 0px;
    width: 29px;
    height: 29px;
    margin-right: 6px;
    position: absolute;
    top: 70px;
  }
  .textEmail {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #fecd1a;
    margin-top: 7px;
    margin-bottom: 0;
  }
  .text {
    width: 214px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 120%;
    margin-top: 0px;
    margin-bottom: 0;
  }
  .sectionD {
    height: 202px;
    margin-left: 101px;
    position: relative;
  }
  .buttonFooter {
    background-color: #fff631;
    border: none;
    width: 118px;
    height: 40px;
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    margin-top: 65px;
    margin-left: 80px;
    margin-bottom: 0;
  }

  .text2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-top: -3px;
    width: 197px;
    text-align: right;
  }
  .text3 {
    display: none;
  }
  .logout {
    display: inline-block;
    background-color: #fecd1a;
    color: rgb(0, 0, 0);
    text-decoration: none;
    border: none;
    border-radius: 8px;
    width: 99px;
    height: 34px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-top: 60px;
    margin-right: 0;
    margin-bottom: 56px;
    transition: 0.8s;
    position: relative;
    right: -50px;
  }
  .logoutText {
    margin: 7px auto;
  }
}

@media screen and (max-width: 1023px) {
  .footer {
    height: 189px;
    position: relative;
    background-color: #1d1d1d;
    color: rgb(255, 255, 255);
    display: flex;
    max-width: 689px;
    justify-content: space-between;
  }
  .footerColor {
    background-color: #1d1d1d;
  }
  .sectionA {
    margin-left: 0px;
    margin-top: 40px;
    text-align: left;
  }
  .title {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
    text-align: left;
    margin-top: 0px;
  }
  .Copyright {
    width: 128px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: left;
    margin-top: 46px;
    margin-right: 38px;
  }
  .sectionB {
    display: none;
  }

  .sectionC {
    height: 150px;
    margin-left: 55px;
    text-align: left;
    margin-top: 40px;
    position: relative;
  }
  .logo {
    margin-top: 0px;
    width: 25px;
    height: 25px;
    margin-right: 6px;
    position: absolute;
    top: 60px;
  }
  .textEmail {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #fecd1a;
    margin-top: 4px;
    margin-bottom: 0;
  }
  .text {
    width: 214px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    margin-top: 0px;
    margin-bottom: 0;
  }
  .sectionD {
    height: 189px;
    margin-left: 47px;
    position: relative;
  }
  .buttonFooter {
    background-color: #fff631;
    border: none;
    width: 118px;
    height: 40px;
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    margin-top: 65px;
    margin-left: 80px;
    margin-bottom: 0;
  }

  .text2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    margin-top: -5px;
    width: 197px;
    text-align: right;
  }
  .text3 {
    display: none;
  }
  .logout {
    display: inline-block;
    background-color: #fecd1a;
    color: rgb(0, 0, 0);
    text-decoration: none;
    border: none;
    border-radius: 8px;
    width: 77px;
    height: 34px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    margin-top: 40px;
    margin-right: 0;
    margin-bottom: 75px;
    transition: 0.8s;
    position: relative;
    right: -50px;
  }
  .logoutText {
    margin: 7px auto;
  }
}

@media screen and (max-width: 767px) {
  .footer {
    height: 369px;
    position: relative;
    background-color: #1d1d1d;
    color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    max-width: 290px;
    margin: 0 auto;
    justify-content: flex-start;
  }
  .footerColor {
    background-color: #1d1d1d;
  }
  .sectionA {
    margin-left: 0px;
    margin-top: 40px;
    text-align: center;
  }
  .title {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0;
  }
  .Copyright {
    width: 220px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    display: flex;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    margin-top: 46px;
    margin: 0 auto;
    position: absolute;
    top: 310px;
    left: 50%;
    margin-left: -110px;
  }
  .sectionB {
    display: none;
  }

  .sectionC {
    height: auto;
    margin-left: 0px;
    text-align: center;
    margin-top: 26px;
    position: relative;
    margin-bottom: 0;
  }
  .logo {
    margin-top: 0px;
    width: 30px;
    height: 30px;
    margin-right: 0px;
    position: relative;
    top: -2px;
    left: 45%;
    margin-bottom: 6px;
  }
  .textEmail {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #fecd1a;
    margin-top: 4px;
    margin-bottom: 0;
  }
  .text {
    width: 214px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-top: 8px;
    margin-bottom: 0;
    text-align: center;
    margin: 0 auto;
  }
  .sectionD {
    height: auto;
    margin-left: 0px;
    position: relative;
    margin-top: 0;
  }

  .text2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    width: 197px;
    text-align: center;
    margin: 0 auto;
  }
  .text3 {
    display: none;
  }
  .logout {
    display: inline-block;
    background-color: #fecd1a;
    color: rgb(0, 0, 0);
    text-decoration: none;
    border: none;
    border-radius: 8px;
    width: 207px;
    height: 34px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    margin-top: 18px;
    margin-right: 0;
    margin-bottom: 50px;
    transition: 0.8s;
    position: relative;
    right: 0px;
  }
  .logoutText {
    margin: 7px auto;
  }
}
