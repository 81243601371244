.background {
  background-color: #232323;
}
.header {
  height: 118px;
  background-color: #232323;
  display: flex;
  margin: 0 195px;
  position: relative;
  justify-content: space-between;
}
.title {
  color: white;
  margin: 35px 40% 31px 0px;
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 38px;
  width: 280px;
  text-align: left;
  padding-top: 3px;
}
.title:hover {
  font-weight: 500;
}
.linkTitle {
  text-decoration: none;
  color: white;
}
.logoDigital {
  width: 274.5px;
  height: 31.64px;
}
.nav {
  margin-top: 44px;
  margin-right: -150px;
}
.link {
  text-decoration: none;
  color: white;
  margin-right: 46px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  transition: 0.3s;
  box-sizing: border-box;
  width: 90px;
  position: relative;
}
.link:hover{
  opacity: 0.6;
  transition: 0.3s;
}
.logo {
  width: 54px;
  height: 54px;
  margin: 30px 0px 30px 0px;
  z-index: 5;
  display: flex;
  cursor: pointer;
  background-image: url('../../images/logo.svg');
  background-size: cover;
  background-repeat: no-repeat;
  border: none;
  position: relative;
  z-index: 20;
  transition: 0.5s;
}
.logo:hover{
  opacity: 0.7;
}
.overlayMiniBarHidden {
  display: none;
  transition: 0.7s;
}

.miniBar {
  background-color: rgb(255, 255, 255);
  width: 124px;
  height: auto;
  position: absolute;
  right: 10%;
  top: 100px;
  box-shadow: 1px 3px 14px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  transition: 0.9s;
  z-index: 3;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.miniBarHidden {
  display: none;
  transition: 0.9s;
}
.miniBarDIv {
  position: relative;
}
.login {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none;
  color: #000;
  text-align: center;
  margin: 0;
  padding: 10px 5px;
}
.login:hover{
  background: rgba(56, 82, 222, 0.05);
  border-radius: 8px;
}
.edit {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  margin-left: 20px;
  color: grey;
}
.miniBarOverlay {
  background-color: rgba(0, 0, 0, 0);
  height: 500px;
  width: 900px;
  position: absolute;
  top: 0px;
  right: -110px;
  z-index: 2;
}
.logoBurger {
  display: none;
}
.logoBurger:hover{
  opacity: 0.7;
}
.miniBarBurger {
  display: none;
}
.overlayMiniBarBurger {
  display: none;
}
.HeaderNavStudent {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: auto;
  margin-top: 38px;
}
.HeaderNavGenerator {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: auto;
  margin-top: 38px;
}
.HeaderNavInstructor {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: auto;
  margin-top: 38px;
}
.search{
  position: absolute;
  top: calc(50% - (50px / 2));
  bottom: calc(50% - (50px / 2));
  left: 375px;
}

@media screen and (max-width: 1919px) {
  .header {
    max-width: 1919px;
    margin: 0 105px;
    height: 100px;
  }
  .title {
    margin: 31px 40% 31px 0px;
    font-size: 34px;
    width: 240px;
    padding-top: 0px;
  }

  .link {
    font-size: 18px;
    line-height: 22px;
  }
  .logo {
    width: 40px;
    height: 40px;
    margin: 30px 0px 30px 0px;
  }
  .nav {
    margin-top: 39px;
    margin-right: -110px;
  }
  .miniBar {
    width: 124px;
    height: auto;
    position: absolute;
    right: 10%;
    top: 84px;
    box-shadow: 1px 3px 14px rgba(0, 0, 0, 0.06);
    transition: 0.9s;
    z-index: 3;
    /* display: block; */
  }
  .miniBarOverlay {
    background-color: rgba(0, 0, 0, 0);
    height: 500px;
    width: 900px;
    position: absolute;
    top: 0px;
    right: -70px;
    z-index: 2;
  }
  .logoDigital {
    width: 212.11px;
    height: 24.45px;
  }
  .search{
    left: 290px;
  }
}
@media screen and (max-width: 1439px) {
  .header {
    max-width: 1439px;
    margin: 0 50px;
    height: 100px;
  }
  .title {
    margin: 31px 30% 31px 0px;
    font-size: 32px;
    width: 240px;
  }

  .link {
    font-size: 18px;
    line-height: 22px;
  }
  .logo {
    width: 40px;
    height: 40px;
    margin: 30px 0px 30px 0px;
  }
  .nav {
    margin-top: 39px;
    margin-right: -160px;
  }
  .miniBar {
    background-color: rgb(255, 255, 255);
    width: 124px;
    position: absolute;
    right: 10%;
    top: 84px;
    box-shadow: 1px 3px 14px rgba(0, 0, 0, 0.06);
    transition: 0.9s;
    z-index: 3;

  }
  .miniBarOverlay {
    background-color: rgba(0, 0, 0, 0);
    height: 500px;
    width: 700px;
    position: absolute;
    top: 0px;
    right: -30px;
    z-index: 2;
  }
  .logoDigital {
    width: 199.63px;
    height: 23.01px;
  }
  .miniBarBurger {
    padding-top: 40px;
  }
}
@media screen and (max-width: 1279px) {
  .header {
    display: flex;
    width: 916px;
    margin: 0 auto;
    height: 76px;
  }
  .title {
    margin: 25px 20% 24px 0px;
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
    width: 240px;
  }

  .link {
    font-size: 16px;
    line-height: 19px;
  }
  .logo {
    display: none;
  }
  .logoBurger {
    z-index: 5;
    display: flex;
    width: 32px;
    height: 32px;
    margin: 22px 0px 22px 0px;
    cursor: pointer;
  }
  .nav {
    margin-top: 30px;
    margin-right: -100px;
  }
  .miniBar {
    display: none;
  }
  .miniBarBurger {
    background-color: rgb(255, 255, 255);
    width: 100%;
    height: auto;
    position: absolute;
    top: 0px;
    transition: 0.9s;
    z-index: 23;
    display: flex;
    flex-direction: column;
    transition: 0.7s;
    transform: translateY(0px);
  }
  .overlayMiniBarBurger {
    display: flex;
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 5;
    transition: 0.7s;
  }

  .miniBarHidden {
    background-color: rgb(255, 255, 255);
    width: 100%;
    height: auto;
    top: 0px;
    box-shadow: 1px 3px 14px;
    transition: 0.7s;
    z-index: 23;
    display: block;
    position: absolute;
    transform: translateY(-800px);
  }
  .navBurger {
    display: none;
  }
  .linkBurger {
    display: block;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-decoration: none;
    color: black;
    margin-top: 14px;
    width: 200px;
    text-align: center;
    margin: 12px auto 20px;
  }
  .plusBlack {
    position: absolute;
    right: 54px;
    top: 24px;
    width: 34px;
    height: 34px;
    cursor: pointer;
  }
  .miniBarOverlay {
    background-color: rgba(0, 0, 0, 0);
    height: 500px;
    width: 700px;
    position: absolute;
    top: 0px;
    right: -30px;
    z-index: 2;
  }
  .editBurgerDiv {
    width: 194px;
    height: 50px;
    background: #3852de;
    border-radius: 8px;
    text-align: center;
    margin: auto;
    position: relative;
    opacity: 0.4;
    margin-top: 20px;
  }
  .editBurger {
    color: white;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    position: relative;
    top: -5px;
  }
  .logOutBurgerDiv {
    align-self: center;
    display: flex;
    width: 194px;
    height: 50px;
    border: 1px solid #3852de;
    border-radius: 8px;
    align-items: center;
    margin: 30px auto 25px;
  }
  .logOutBurgerDiv:last-of-type{
    margin: 0px auto 40px;
  }
  .logOutBurger {
    color: #3852de;
    text-decoration: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    margin: auto;

  }
  .logoDigital {
    width: 149.73px;
    height: 17.26px;
  }
  .pActive {
    margin-top: 0px;
    margin-bottom: 22px;
  }
  .button {
    display: block;
    background-color: #fff;
    border: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    cursor: pointer;
    height: auto;
    width: auto;
    text-decoration: none;
    margin: auto;
  }
  .search{
    left: 200px;
    top: calc(50% - (40px / 2));
    bottom: calc(50% - (40px / 2));

  }
}

@media screen and (max-width: 1023px) {
  .header {
    max-width: 689px;
    margin: 0 auto;
    height: 67px;
  }
  .title {
    margin: 20px 20% 20px 0px;
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
    width: 240px;
  }

  .link {
    font-size: 16px;
    line-height: 19px;
  }
  .logo {
    display: none;
  }
  .logoBurger {
    z-index: 5;
    display: flex;
    width: 32px;
    height: 32px;
    margin: 18px 0px 18px 0px;
    cursor: pointer;
  }
  .nav {
    display: none;
  }

  .miniBarOverlay {
    background-color: rgba(0, 0, 0, 0);
    height: 500px;
    width: 700px;
    position: absolute;
    top: 0px;
    right: -30px;
    z-index: 2;
  }

  .logoDigital {
    width: 149.73px;
    height: 17.26px;
  }
  .HeaderNavStudent {
    margin-top: 24px;
  }
  .HeaderNavGenerator {
    margin-top: 24px;
  }
  .HeaderNavInstructor {
    margin-top: 24px;
  }
  .navBurger {
    display: block;
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) {
  .navBurger {
    display: block;
    margin-top: 72px;
  }
  .miniBarBurger {
    padding-top: 0px;
  }
  .header {
    max-width: 290px;
    margin: 0 auto;
    height: 56px;
  }
  .title {
    margin: 15px 20% 15px 0px;
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
    width: 240px;
  }

  .link {
    font-size: 16px;
    line-height: 19px;
  }
  .logo {
    display: none;
  }
  .logoBurger {
    z-index: 5;
    display: flex;
    width: 32px;
    height: 32px;
    margin: 12px 0px 12px 0px;
    cursor: pointer;
  }
  .nav {
    display: none;
  }

  .miniBarOverlay {
    background-color: rgba(0, 0, 0, 0);
    height: 500px;
    width: 300px;
    position: absolute;
    top: 0px;
    right: -10px;
    z-index: 2;
  }
  .HeaderNavStudent {
    display: none;
  }
  .linkBurger {
    margin-top: 5px;
    margin-bottom: 12px;
  }
  .logoDigital {
    width: 149.73px;
    height: 17.26px;
  }
  .plusBlack {
    width: 24px;
    height: 24px;
  }
  .editBurgerDiv {
    margin-top: 18px;
  }
  .logOutBurgerDiv {
    width: 290px;
    height: 40px;
  }
  .editBurgerDiv {
    width: 290px;
    height: 40px;
  }
  .logOutBurgerP {
    font-size: 14px;
    line-height: 17px;
  }
  .editBurger {
    font-size: 14px;
    line-height: 17px;
  }
}
