.CreateProjectSideCard {
  display: flex;
  flex-direction: column;

  gap: 16px;

  text-align: left;

  width: 100%;

  .paramName {
    display: flex;
    width: 200px;
    margin-bottom: 0;
  }

  .detailsButton {
    text-decoration: underline;
    cursor: pointer;
    width: 100px;
  }

  .selectTag {
    margin-right: 4px;
  }
}
.areaContacts{
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(35, 35, 35, 0.2);
  border-radius: 8px;
  box-sizing: border-box;
  padding: 15px;
  width: 100%;
  gap: 6px;
  margin-bottom: 20px;
}
.areaContactsError{
    display: flex;
  flex-direction: column;
  border: 1px solid rgb(233, 6, 6);
  border-radius: 8px;
  box-sizing: border-box;
  padding: 15px;
  width: 100%;
  gap: 6px;
  margin-bottom: 20px;
}
input:focus{
  .areaContacts{
    border: 1px solid #818181;
  }
}
.inputContacts{
  border: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  width: 100%;
  margin: 0;
}
.divInput{
  display: flex;
  align-items: center;
}

.textInput{
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  width: 110px;
}
.errorSize{
  position: absolute;
  top: 14px;
  left: 0;
  color: #dd2c2c;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  width: 250px;
}
.attachDiv{
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 118px;
  height: 20px;
  margin-top: 10px;
  margin-bottom: -90px;
  position: absolute;
  top: -5px;
  left: 0;
}
.attachDiv:hover {
  color: #4B63E1;
  .textAttach{
    color: #4B63E1;
  }
  .icon{
    transform: scale(1.05);
  }
}

.textAttach{
  color: #3852DE;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-left: 8px;
}
.ainputFile{
visibility: hidden;
width: 118px;
position: absolute;
top: -30px;
}
.customFileLabel{
  padding: 6px 12px;
  border: none;
  position: relative;
  display: block;
  width: 118px;
  height: 0px;
  margin-bottom: 0px;
 
}
.fileDiv{
margin-top: -25px;
margin-left: 5px;
}
.textFilename{
  color: rgba(35, 35, 35, 0.538);
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-left: 22px;
  margin-top: 28px;
  margin-bottom: 0;
  width: 400px;
}
.spanDeleteFile{
  display: flex;
  align-items: center;
margin-top: 5px;
 left: 28px;
 width: 100px;
 cursor: pointer;
 margin-bottom: -30px;
 z-index: 50;
}
.cross{
  margin-right: 10px;
  color: rgba(35, 35, 35, 0.538);
  z-index: 50;
}
.textDeleteFile{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  color: rgba(35, 35, 35, 0.722);
}
.moreContacts{
  position: absolute;
  right: 35px;
}

.input{
width: 490px;
height: auto;
padding: 15px;
box-sizing: border-box;
border-radius: 8px;
border: 1px solid rgba(35, 35, 35, 0.20);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.inputError{
  width: 490px;
  height: auto;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid rgb(233, 6, 6);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.input::placeholder{
  color: #818181ca;
}
.input:hover::placeholder{
  color: #818181;
}
.input:focus {
  background: #FFFFFF;
  border: 1px solid #818181;
}

.textarea{
  width: 490px;
  min-height: 173px;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid rgba(35, 35, 35, 0.20);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  resize: none;
  overflow: hidden;
}
.textareaError{
  width: 490px;
  min-height: 173px;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid rgb(233, 6, 6) !important;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  resize: none;
  overflow: hidden;
}

.textarea:focus {
  outline: none !important;
  border: 1px solid #818181;
}
.textareaError:focus {
  outline: none !important;
  border: 1px solid #818181;
}
.textarea::placeholder{
  color: #818181ca;
}
.textarea:hover::placeholder{
  outline: none !important;
  color: #818181;
}

.autocompleteFields:hover{
border: none !important;
}
.Dialog{
  text-align: center;
  padding: 30px !important;
  min-width: 900px !important;
}
.button{
  border-radius: 8px;
background: var(--blue-100, #3852DE);
display: inline-flex;
height: 45px;
padding: 17px 29px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
max-width: 113px;
width: auto;
margin-top: 30px;
box-sizing: border-box;
p{
  color: var(--white, #FFF);
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
}
.button:hover{
  cursor: pointer;
  background: #3349c9;
}
.buttonSecondary{
  display: inline-flex;
  height: 45px;
  padding: 17px 29px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
border: 1px solid var(--blue-100, #3852DE);
width: auto;
max-width: 121px;
margin-top: 30px;
p{
  color: var(--blue-100, #3852DE);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
}
.supervisorDiv{
display: flex;
}
.supervisorTextActive{
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--blue-100, #3852DE);
  width: 155px;
}
.supervisorText{
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: rgba(0, 0, 0, 0.60);
  width: 155px;
}
.switch {
  position: relative;
  top: 14px;
  left: 4px;
  display: inline-block;
  width: 36px;
  height: 22px;
  margin-right: 12px;
  margin-left: -30px;

}
.switch input {
  display: none;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(255, 255, 255);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: #3852de 2px solid;
}
.slider:before {
  position: absolute;
  content: '';
  height: 15px;
  width: 15px;
  left: 3px;
  top: 2px;
  bottom: 4px;
  background-color: #3852de;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ffffff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ffffff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}



.inputDiv{
  display: flex;
  height: 45px;
  padding: 15px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  position: relative;
  box-sizing: border-box;
  border: 1px solid var(--black-20, rgba(35, 35, 35, 0.20));
  margin-top: 17px;
  border-radius: 8px;
  margin-bottom: 0px;
}
.inputDivError{
  display: flex;
  height: 45px;
  padding: 15px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  position: relative;
  box-sizing: border-box;
  border: 1px solid rgb(233, 6, 6);
  margin-top: 17px;
  border-radius: 8px;
  margin-bottom: 0px;
}
.inputSupervisor{
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  width: 100%;
}
.inputSupervisor::placeholder{
  color: #818181ca;
}
.inputSupervisor:hover::placeholder{
  color: #818181;
}

.dropdown{
 position: relative;
 left: 0;
 top: 5px;
box-sizing: border-box;
 width: 100%;
 max-height: 300px;
 border-radius: 8px;
 border: 1px solid var(--black-20, rgba(35, 35, 35, 0.20));
 overflow-y: auto;
 overflow-x: hidden;
 margin-bottom: 20px;
}
.dropdownText{
  font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 0;
padding: 4px 10px;
}
.dropdownText:hover{
  background-color: #8c95f315;
  cursor: pointer;
}
.Chevron{
  position: relative;
  top:1px;
  width: 25px;
  height: 25px;
}
.Chevron:hover{
  border-radius: 50%;
  cursor: pointer;
  background-color: #f3f1f1;
}
.ChevronRevers{
  position: relative;
  top:1px;
  width: 25px;
  height: 25px;
  transform: rotate(180deg);
}
.ChevronRevers:hover{
  border-radius: 50%;
  cursor: pointer;
  background-color: #f3f1f1;
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 767px) {
  .input{
    width: auto;
    padding: 10px;
    font-size: 12px;
    box-sizing: border-box;
    }
    .inputError{
      width: auto;
      padding: 10px;
      font-size: 12px;
      box-sizing: border-box;
    }
.textCheckbox{
  font-size: 14px !important;
}
.textarea{
  width: auto;
  font-size: 12px;
}
.textareaError{
  width: auto;
  font-size: 12px;
}
.moreContacts{
  font-size: 14px !important;
}
.areaContacts{
  padding: 10px 5px; 
}
.textInput{
  font-size: 13px;
  margin-left: 10px;
}
.inputContacts{
  font-size: 13px;
}
.detailsButton{
  font-size: 14px;
  margin-top: 0px;
}
.autocompleteFields{
  font-size: 14px !important;
}

.button{
height: 36px;
padding: 14px 23px;
max-width: 80px;
margin-top: 30px;
p{
font-size: 16px;
}
}
.buttonSecondary{
  height: 36px;
  padding: 14px 23px;
  max-width: 80px;
  margin-top: 30px;
  p{
  font-size: 16px;
  }
  }
  .supervisorTextActive{
    font-size: 14px;
    width: 165px;
  }
  .supervisorText{
    font-size: 14px;
    width: 165px;
  }


  .switch {
    top: 14px;
    left: 4px;
    width: 26px;
    height: 16px;
    margin-right: 12px;
    margin-left: -30px;
  
  }
  .slider {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
 
  }
  .slider:before {
    height: 10px;
    width: 10px;
    left: 1px;
  }
  
  input:checked + .slider {
    background-color: #ffffff;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #ffffff;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(6px);
    -ms-transform: translateX(6px);
    transform: translateX(6px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
}