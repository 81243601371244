.Feedback {
  width: 1530px;
  height: auto;
  box-sizing: border-box;
  position: relative;
}
.firsLine {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 40px;
}
.title {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 38px;
  padding-right: 361px;
  margin: 0;
}
.totalAnswers {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
}
.count {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
}
.blocksSections {
  display: flex;
  flex-wrap: wrap;
  gap: 30px 30px;
  margin-top: 30px;
  margin-bottom: 70px;
}
.line {
  margin-top: 0;
  margin-bottom: 18px;
  display: flex;
}
.img {
  margin-right: 10px;
}
.name {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
}
.answers {
  width: 1530px;
  height: auto;
  background: #ffffff;
  border-radius: 10px;
  padding: 0px;
  box-sizing: border-box;
}
.sectionIssue {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: start;
  margin-top: 20px;
  gap: 40px;
  margin-bottom: 42px;
  box-sizing: border-box;
}

.particularIssue {
  width: 483.33px;
  height: auto;
  margin-right: 0px;
  margin-bottom: 0px;
  align-items: start;
}

.questionName {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  min-height: 40px;
  margin: 0;
}
.answersHidden {
  display: none;
}

.PreloaderDiv {
  height: 300px;
  width: 300px;
  position: relative;
  margin: auto;
}
.Preloader {
  position: absolute;
  left: 48%;
  top: 30%;
}
/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1919px) {
  .Feedback {
    width: 1230px;
  }
  .title {
    font-size: 28px;
    line-height: 31px;
    padding-right: 233px;
  }
  .totalAnswers {
    font-size: 16px;
    line-height: 19px;
  }
  .count {
    font-size: 16px;
    line-height: 19px;
  }
  .blocksSections {
    gap: 20px 20px;
    margin-top: 24px;
    margin-bottom: 60px;
  }
  .line {
    margin-bottom: 16px;
  }
  .img {
    margin-right: 10px;
  }
  .name {
    font-size: 24px;
    line-height: 29px;
  }

  .answers {
    width: 1230px;
  }
  .sectionIssue {
    margin-top: 0px;
    gap: 20px;
    margin-bottom: 42px;
  }

  .particularIssue {
    width: 396.67px;
  }

  .questionName {
    font-size: 18px;
    line-height: 22px;
  }
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1439px) {
  .Feedback {
    width: 1180px;
  }
  .title {
    font-size: 28px;
    line-height: 31px;
    padding-right: 183px;
  }
  .totalAnswers {
    font-size: 16px;
    line-height: 19px;
  }
  .count {
    font-size: 16px;
    line-height: 19px;
  }
  .blocksSections {
    gap: 20px 20px;
    margin-top: 24px;
    margin-bottom: 60px;
  }
  .line {
    margin-bottom: 16px;
  }
  .img {
    margin-right: 10px;
  }
  .name {
    font-size: 24px;
    line-height: 29px;
  }

  .answers {
    width: 1180px;
  }
  .sectionIssue {
    margin-top: 0px;
    gap: 20px;
    margin-bottom: 42px;
  }

  .particularIssue {
    width: 280px;
  }

  .questionName {
    font-size: 18px;
    line-height: 22px;
  }
}
/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1279px) {
  .Feedback {
    width: 916px;
    margin: auto;
  }
  .title {
    font-size: 28px;
    line-height: 31px;
    padding-right: 106px;
  }
  .totalAnswers {
    font-size: 14px;
    line-height: 17px;
  }
  .count {
    font-size: 14px;
    line-height: 17px;
  }
  .blocksSections {
    gap: 20px 20px;
    margin-top: 24px;
    margin-bottom: 40px;
  }
  .line {
    margin-bottom: 16px;
  }
  .img {
    margin-right: 10px;
  }
  .name {
    font-size: 20px;
    line-height: 24px;
  }

  .answers {
    width: 916px;
  }
  .sectionIssue {
    margin-top: 0px;
    gap: 30px;
    margin-bottom: 42px;
  }

  .particularIssue {
    width: 285.33px;
  }

  .questionName {
    font-size: 16px;
    line-height: 19px;
  }
}
/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1023px) {
  .Feedback {
    width: 688px;
  }
  .title {
    font-size: 20px;
    line-height: 22px;
    padding-right: 106px;
    position: absolute;
    top: -30px;
  }
  .firsLine {
    margin-top: 50px;
  }
  .totalAnswers {
    font-size: 14px;
    line-height: 17px;
  }
  .count {
    font-size: 14px;
    line-height: 17px;
  }
  .blocksSections {
    gap: 20px 20px;
    margin-top: 24px;
    margin-bottom: 50px;
  }
  .line {
    margin-bottom: 16px;
  }
  .img {
    margin-right: 10px;
  }
  .name {
    font-size: 20px;
    line-height: 24px;
  }

  .answers {
    width: 688px;
  }
  .sectionIssue {
    margin-top: 0px;
    gap: 30px;
    margin-bottom: 42px;
  }

  .particularIssue {
    width: 329px;
  }

  .questionName {
    font-size: 16px;
    line-height: 19px;
  }
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 767px) {
  .Feedback {
    width: 320px;
  }
  .title {
    font-size: 18px;
    line-height: 20px;
    padding-right: 67px;
    position: absolute;
    top: -30px;
    margin-left: 15px;
  }
  .firsLine {
    margin-top: 4px;
  }
  .totalAnswers {
    font-size: 14px;
    line-height: 17px;
    position: absolute;
    right: 15px;
    top: -25px;
  }
  .blocksSections {
    gap: 0px 0px;
    margin-top: 20px;
    margin-bottom: 50px;
  }
  .line {
    margin-bottom: 16px;
    display: none;
  }
  .img {
    margin-right: 10px;
  }
  .name {
    font-size: 20px;
    line-height: 24px;
  }

  .answers {
    width: 320px;
    margin-top: -30px;
    background-color: #fff;
  }
  .sectionIssue {
    margin-top: 0px;
    gap: 20px;
    margin-bottom: 20px;
  }

  .particularIssue {
    width: 248.67px;
  }

  .questionName {
    font-size: 14px;
    line-height: 17px;
    margin-top: 5px;
    margin-bottom: 5px;
    min-height: 0px;
  }
  .selectionDiv {
    width: 290px;
    height: 40px;
    background: #ffffff;
    border: 1px solid rgba(35, 35, 35, 0.2);
    border-radius: 6px;
    margin: auto;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    justify-content: space-between;
  }
  .selectionText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    margin: auto 0;
  }
  .selectionTextStltct {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    margin: auto 0;
    padding: 3px 0;
  }
  .selectionTextStltct:hover {
    background-color: rgba(224, 223, 223, 0.132);
  }

  .selection {
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 2px 4px 23px rgba(0, 0, 0, 0.08);
    height: auto;
    left: 14px;
    padding: 15px;
    top: 50px;
    transition: 0.7s;
    z-index: 3;
    width: 290px;
    margin: auto;
    margin-top: 5px;
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
  }
}
