.FeedbackAnswers {
  display: block;
  margin-bottom: 10px;
  padding-top: 0px;
  align-items: flex-start;
  margin-left: 0px;
  height: auto;
  margin-top: 10px;
}

.flexM {
  display: flex;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 2px;
  height: auto;
}
.greenM {
  background: #069542;
  height: 14px;
  border-radius: 6px;
}
.green2M {
  background-color: #0ddc65;
  height: 14px;
  border-radius: 6px;
}
.yellowM {
  background-color: #fecd1a;
  height: 14px;
  border-radius: 6px;
}
.orangeM {
  background-color: #ff9900;
  height: 14px;
  border-radius: 6px;
}
.redM {
  background-color: #ff613f;
  height: 14px;
  border-radius: 6px;
}
.textM {
  margin-top: 0;
  margin-bottom: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-left: 6px;
}
.textNoAnswers {
  margin-top: 0;
  margin-bottom: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  opacity: 0.5;
  text-align: left;
  margin-left: 6px;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1919px) {
  .FeedbackAnswers {
    margin-bottom: 10px;
    padding-top: 0px;
  }
  .greenM {
    height: 12px;
  }
  .green2M {
    height: 12px;
  }
  .yellowM {
    height: 12px;
  }
  .orangeM {
    height: 12px;
  }
  .redM {
    height: 12px;
  }
  .textM {
    font-size: 14px;
    line-height: 17px;
    margin-left: 6px;
  }
  .textNoAnswers {
    font-size: 14px;
  }
}

@media screen and (max-width: 1023px) {
  .FeedbackAnswers {
    margin-bottom: 10px;
    padding-top: 0px;
  }
  .greenM {
    height: 10px;
  }
  .green2M {
    height: 10px;
  }
  .yellowM {
    height: 10px;
  }
  .orangeM {
    height: 10px;
  }
  .redM {
    height: 10px;
  }
  .textM {
    font-size: 14px;
    line-height: 17px;
    margin-left: 6px;
  }
  .textNoAnswers {
    font-size: 14px;
  }
}
