.TextInput {
  padding: 15px 20px;

  border: 1px solid rgba(35, 35, 35, 0.2);
  border-radius: 8px;
}

.withIcon {
  padding-left: 32px;
}
