.BlockTA {
  width: 490px;
  height: 210px;
  background: rgba(236, 239, 255, 0.56);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 24px;
  cursor: pointer;
}
.BlockTA:hover{
  background: #EBEEFF;
}
.BlockTAActive {
  width: 490px;
  height: 210px;
  background: rgba(236, 239, 255, 0.56);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 23px;
  cursor: pointer;
  border: 1px solid #3852de;
}
.line {
  margin-top: 0;
  margin-bottom: 18px;
  display: flex;
}
.img {
  margin-right: 10px;
}
.name {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  text-align: left;
}
.nameActive {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  color: #3852de;
  text-align: left;
}
.answers {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
}
.text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
  text-align: left;
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1919px) {
  .BlockTA {
    width: 396.67px;
    height: 182px;
    padding: 20px;
  }
  .BlockTAActive {
    width: 396.67px;
    height: 182px;
    padding: 19px;
  }
  .line {
    margin-bottom: 16px;
  }
  .img {
    margin-right: 6px;
    width: 20px;
    height: 20px;
  }
  .name {
    font-size: 18px;
    line-height: 22px;
  }
  .nameActive {
    font-size: 18px;
    line-height: 22px;
  }
  .answers {
    font-size: 16px;
    line-height: 19px;
  }
  .text {
    font-size: 16px;
    line-height: 19px;
  }
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1439px) {
  .BlockTA {
    width: 380px;
    height: 182px;
  }
  .BlockTAActive {
    width: 380px;
    height: 182px;
  }
  .line {
    margin-bottom: 16px;
  }
  .img {
    margin-right: 6px;
  }
  .name {
    font-size: 18px;
    line-height: 22px;
  }
  .answers {
    font-size: 16px;
    line-height: 19px;
  }
  .text {
    font-size: 16px;
    line-height: 19px;
  }
}
/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1279px) {
  .BlockTA {
    width: 292px;
    height: 167px;
    padding: 16px;
  }
  .BlockTAActive {
    width: 292px;
    height: 167px;
    padding: 15px;
  }
  .line {
    margin-bottom: 13px;
  }
  .img {
    margin-right: 4px;
  }
  .name {
    font-size: 16px;
    line-height: 19px;
  }
  .nameActive {
    font-size: 16px;
    line-height: 19px;
  }
  .answers {
    font-size: 14px;
    line-height: 17px;
  }
  .text {
    font-size: 14px;
    line-height: 17px;
  }
}
/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1023px) {
  .BlockTA {
    width: 334px;
    height: 162px;
  }
  .BlockTAActive {
    width: 334px;
    height: 162px;
  }
  .line {
    margin-bottom: 13px;
  }
  .img {
    margin-right: 4px;
    width: 19px;
    height: 20px;
  }
  .name {
    font-size: 16px;
    line-height: 19px;
  }
  .nameActive {
    font-size: 16px;
    line-height: 19px;
  }
  .answers {
    font-size: 14px;
    line-height: 17px;
  }
  .text {
    font-size: 14px;
    line-height: 17px;
  }
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 767px) {
  .BlockTA {
    width: 320px;
    height: auto;
  }
  .BlockTAActive {
    width: 320px;
    height: auto;
    border: none;
  }
  .line {
    margin-bottom: 16px;
  }
  .img {
    margin-right: 4px;
  }
  .name {
    font-size: 16px;
    line-height: 19px;
  }
  .nameActive {
    font-size: 16px;
    line-height: 19px;
    color: #000;
  }
  .answers {
    font-size: 14px;
    line-height: 17px;
  }
  .text {
    display: none;
  }
  .grades {
    margin-top: -20px;
  }
}
