.LegalInformation {
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 90;
  top: 0px;
  display: flex;
  transition: 0.7s;
  visibility: visible;
}
.LegalInformationHidden {
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 15;
  top: 0px;
  transition: 0.1s;
  visibility: hidden;
}

.popup {
  position: fixed;
  width: 682px;
  height: 371px;
  background: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.1s;
  transform: translate(-50%, -50%) scale(1);
  text-align: center;
}
.popupHidden {
  position: fixed;
  width: 682px;
  height: 371px;
  background: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.1s;
  transform: translate(-50%, -50%) scale(0);
}
.img {
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
}
.title {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 45px;
  margin-top: 60px;
  margin-bottom: 0;
 
}
.pdf {
  width: 32px;
  height: 32px;
  margin-right: 12px;
}
.text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
}
.linkTop {
  text-decoration: none;
  color: black;
  width: 555px;
  height: 84px;
  background: #f2f4ff;
  margin: 40px auto 0;
  display: flex;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 8px;
}
.linkButtom {
  width: 555px;
  height: 72px;
  background: #f2f4ff;
  margin: 8px auto 0;
  display: flex;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;
  color: black;
  border-radius: 8px;
}

@media screen and (max-width: 1279px) {
  .popup {
    width: 630px;
    height: 329px;
  }
  .popupHidden {
    width: 630px;
    height: 329px;
  }
  .img {
    right: 20px;
    top: 20px;
  }
  .title {
    font-size: 34px;
    line-height: 38px;
    margin-top: 50px;
    margin-bottom: 0;
  }
  .pdf {
    width: 32px;
    height: 32px;
    margin-right: 12px;
  }
  .text {
    font-size: 16px;
    line-height: 19px;
  }
  .linkTop {
    width: 491px;
    height: 78px;
    margin: 30px auto 0;
    padding: 20px;
  }
  .linkButtom {
    width: 491px;
    height: 72px;
    margin: 8px auto 0;
    padding: 20px;
  }
}
@media screen and (max-width: 1023px) {
  .popup {
    width: 509px;
    height: 280px;
  }
  .popupHidden {
    width: 509px;
    height: 280px;
  }
  .img {
    right: 20px;
    top: 20px;
    width: 26px;
    height: 26px;
  }
  .title {
    font-size: 28px;
    line-height: 31px;
    margin-top: 44px;
    margin-bottom: 0;
  }
  .pdf {
    width: 26px;
    height: 26px;
    margin-right: 12px;
  }
  .text {
    font-size: 14px;
    line-height: 17px;
  }
  .linkTop {
    width: 371px;
    height: 64px;
    margin: 24px auto 0;
    padding: 15px;
  }
  .linkButtom {
    width: 371px;
    height: 63px;
    margin: 4px auto 0;
    padding: 15px;
  }
}
@media screen and (max-width: 767px) {
  .popup {
    width: 290px;
    height: 253px;
  }
  .popupHidden {
    width: 290px;
    height: 253px;
  }
  .img {
    right: 16px;
    top: 16px;
    width: 20px;
    height: 20px;
  }
  .title {
    font-size: 20px;
    line-height: 22px;
    margin-top: 30px;
    margin-bottom: 0;
  }
  .pdf {
    width: 26px;
    height: 26px;
    margin-right: 12px;
  }
  .text {
    font-size: 14px;
    line-height: 17px;
  }
  .linkTop {
    width: 263px;
    height: 83px;
    margin: 16px auto 0;
    padding: 15px;
  }
  .linkButtom {
    width: 263px;
    height: 66px;
    margin: 6px auto 0;
    padding: 15px;
  }
}
