.Projects {
  display: flex;
  flex-direction: column;
 height: auto;
  margin: 50px auto;

  font-family: 'Inter';
}

.titleSection {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .title {
    margin: 0;
    font-family: Platform LC Web;
    font-size: 44px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .description {
    margin: 0;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    text-align: start;

    margin-top: 30px;
  }
}

.filterSection {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.projectsSection {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 40px;
}
.searchArea{
  width: 322px;
  height: 48px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
}
.serachInput{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  position: relative;
  border: 1px solid rgba(35, 35, 35, 0.2);
  border-radius: 8px;
  padding: 17px 23px 17px 47px;
  width: 322px;
  height: 48px;
  box-sizing: border-box;
  color: rgb(35, 35, 35);
  &::placeholder{
    color: rgba(35, 35, 35, 0.3);
}}
.serachActive{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  position: relative;
  border: 1px solid rgba(35, 35, 35, 0.752);
  border-radius: 8px;
  padding: 17px 23px 17px 47px;
  width: 322px;
  height: 48px;
  box-sizing: border-box;
  color: rgb(35, 35, 35);
}
.ErrorInput{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  position: relative;
  border: 1px solid rgba(217, 48, 48, 0.752);
  border-radius: 8px;
  padding: 17px 23px 17px 47px;
  width: 322px;
  height: 48px;
  box-sizing: border-box;
  color: rgba(217, 48, 48, 0.752)
}

.searchIcon{
  width: 22px;
height: 22px;
position: absolute;
top: 14px;
left: 18px;
path{
  stroke: rgba(35, 35, 35, 0.159);
}
}
.searchIconActive{
    width: 22px;
  height: 22px;
  position: absolute;
  top: 14px;
  left: 18px;
  path{
    stroke: rgba(35, 35, 35, 0.752);
  }
}
.ErrorSearchIcon{
    width: 22px;
  height: 22px;
  position: absolute;
  top: 14px;
  left: 18px;
  path{
    stroke: rgba(217, 48, 48, 0.752);
  }
}
.projectsSection{
  width: 100%;
}
.Preloader {
  position: absolute;
  left: 50%;
  top: 30%;
}
.filterButton{
  font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.filterButton:hover{
  color: #3349c9;;
}

.serachInput:focus{
  border: 1px solid rgba(35, 35, 35, 0.752);
  
}

.searchArea:hover{
  .serachInput::placeholder{
    color: #818181;
  }
}
 
  .serachInput:hover{
  color: #818181;
  }
  .searchArea:hover .searchIcon {
    path{
      stroke: #818181;
    }}
.rightSection{
  display: flex;
}
.createButton{
  display: flex;
  align-items: center;
  align-items: center;
  margin: auto;
  height: 31px;
  margin-right: 40px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
    .notessvg{
    rect {
      fill: #3852DE;
      transition:0.2s;
  }
  path {
      fill: white;
      transition:0.2s;
  }
  }}
}

.notessvg{
  margin-left: 10px;
  transition:0.2s;
  width: 24px;
height: 24px;
}
.createText{
  color: var(--blue-100, #3852DE);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  padding: 0;
}
.filterIconMobDiv{
  display: none;
}
/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1919px) {
  .projectsSection{
    width: 100%;
  }

  .filterSection {
    width: 100%;
    margin-top: 30px;
    position: relative;
  }
  .title {
    font-size: 40px !important;
  }
  .filterButton{
  font-size: 18px;
  }
  .searchArea{
    width: 322px;
    height: 45px;
    padding: 15px 20px;
    
  }
  .searchArea{
    width: 322px;
    height: 45px;
    position: relative;
    right: -20px;
  }
  .serachInput{
    width: 322px;
    height: 45px;
  }
  .serachActive{
    width: 322px;
    height: 45px;
  }
  .ErrorInput{
    width: 322px;
    height: 45px;
  }
  .serachInput{
    font-size: 14px;
    line-height: 17px;
  }
  .searchIcon{
    width: 22px;
  height: 22px;
  left: 35px;
  top:12px;
  }
  .searchIconActive{
    width: 22px;
  height: 22px;
  left: 35px;
  top:12px;
  }
.ErrorSearchIcon{
  width: 22px;
  height: 22px;
  left: 35px;
  top:12px;
}

.createButton{
  height: 31px;
  margin-right: 5px;
}
.createText{
  font-size: 18px;
}
.notessvg{
width: 24px;
height: 24px;
}
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1439px) {
  .filterSection {
    margin-top: 30px;
  }
  .searchArea{
    width: 322px;
    height: 45px;
    padding: 13px 18px;
  }
  .title {
    font-size: 34px !important;
  }
  .filterButton{
  font-size: 18px;
  }
  .searchArea{
    width: 322px;
    height: 45px;
    padding: 15px 20px;
  }
  .serachInput{
    font-size: 14px;
    line-height: 17px;
  }
  .searchIcon{
    width: 22px;
  height: 22px;
  }
  .searchIconActive{
    width: 22px;
  height: 22px;
  }
  .ErrorSearchIcon{
    width: 22px;
  height: 22px;
}
.createButton{
  margin-right: 0px;
}
}


/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1279px) {
  .filterButton{
    font-size: 16px;
    }
    .Projects {
      margin: 40px auto;
    }
    .createButton{
      height: 31px;
      margin-right: -5px;
    }
    .createText{
      font-size: 16px;
    }
    .notessvg{
    width: 20px;
    height: 20px;
    }
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1023px) {
  .filterSection {
    margin-top: 30px;
  }
  .searchArea{
    width: 322px;
    height: 45px;
    padding: 13px 18px;
  }
  .title {
    font-size: 28px !important;
  }
  .filterButton{
    font-size: 16px;
  }
  .searchArea{
    width: 279px;
    height: 40px;
    padding: 15px 15px;
    right: -10px;
  }
  .serachInput{
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .searchIcon{
    width: 16px;
    height: 16px;
  }
  .searchIconActive{
    width: 16px;
    height: 16px;
  }
  .ErrorSearchIcon{
    width: 16px;
    height: 16px;
}
  .Projects {
    margin: 34px auto;
  }
}



/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 767px) {
  .filterSection {
    margin-top: 30px;
  }
  .searchArea{
    width: 320px;
    height: 45px;
    padding: 13px 18px;
  }
  .title {
    font-size: 20px !important;
  }
  .filterButton{
    font-size: 16px;
  }


  .searchArea{
    width: 322px;
    height: 45px;
    padding: 15px 20px;
  }
  .serachInput{
    width: 184px !important;
    height: 45px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 130px;
    margin-left: -20px;
    padding-left: 33px;
  }
  .serachActive{
    width: 184px !important;
    height: 40px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 130px;
    margin-left: -20px;
    padding-left: 33px;
  }
  .ErrorInput{
    width: 184px !important;
    height: 40px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 130px;
    margin-left: -20px;
    padding-left: 33px;
  }
  .serachInput{
    width: 184px;
    height: 40px;
  }
  .searchIcon{
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 130px;
    left: 8px;
  }
  .searchIconActive{
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 130px;
    left: -49px;
  }
.ErrorSearchIcon{
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 130px;
  left: -49px;
}
  .searchArea{
    width: 184px;
    height: 40px;
    padding: 15px 15px;
    right: -5px;

  }
  .searchIcon{
    width: 16px;
    height: 16px;
  }
  .Projects {
    margin: 24px auto;
  }
  .createButton{
    width: 40px;
    height: 40px;
    padding: 10px;
    border-radius: 4px;
    background: #F0F3FF;
    box-sizing: border-box;
    margin-right: 20px;
  }
  .createText{
  display: none;
  }
  .notessvg{
    margin-left: 0px;
    transition:0.2s;
    width: 20px !important;
  height: 20px !important;
  rect {
    fill: #3852DE;
    transition:0.2s;
}
path {
    fill: white;
    transition:0.2s;
}
  }
  .filterButton{
    display: none;
  }
  .filterIconMobDiv{
    display: flex;
    width: 40px;
    height: 40px;
    padding: 10px;
    border-radius: 4px;
    background: #F0F3FF;
    box-sizing: border-box;
    margin-right: 6px;
    cursor: pointer;
    &:hover{
      opacity: 0.8;
    }
  }

}








