.Header {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 31px;

  text-align: left;
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 767px) {
  .Header {
    font-family: Platform LC Web;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}