.block {
  width: 360px;
  height: auto;
  display: inline-flex;
  margin: auto;
  margin-left: 50px;
  align-items: flex-end;
  padding-top: 0px;
}
.blockTA {
  width: 100%;
  height: auto;
  display: inline-flex;
  margin-left: 0;
  margin: auto;
  align-items: flex-start;
  margin-top: 12px;
}
.blockArr {
  width: 305px;
  height: auto;
  display: inline-flex;
  margin-left: 76px;
  margin: auto;
  margin-left: 50px;
  align-items: center;
  padding-top: 32px;
  box-sizing: border-box;
}
.blockMobile {
  display: none;
}
.text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  color: #000000;
  margin: auto;
  padding: 0px;
  width: 100%;
}
.textTA {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  color: #000000;
  margin: auto;
  padding: 0px;
  width: 100%;
}
.green {
  background: #069542;
  height: 10px;
  border-radius: 6px;
  margin-top: 6px;
  min-width: 5px;
}
.green2 {
  background-color: #0ddc65;
  height: 10px;
  border-radius: 6px;
  margin-top: 6px;
  min-width: 5px;
}

.yellow {
  background-color: #fecd1a;
  height: 10px;
  border-radius: 6px;
  margin-top: 6px;
  min-width: 5px;
}
.orange {
  background-color: #ff9900;
  height: 10px;
  border-radius: 6px;
  margin-top: 6px;
  min-width: 5px;
}
.red {
  background-color: #ff613f;

  height: 10px;
  border-radius: 6px;
  margin-top: 6px;
  min-width: 5px;
}
.noDataDiv {
  display: flex;
  align-items: center;
  margin-top: 0px;
  position: absolute;
  height: 100%;
  // top:50%;
  top: 0;
}
.noDataText {
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin-left: 10px;
  align-items: center;
}

@media screen and (max-width: 1919px) {
  .block {
    width: 349px;
    display: flex;
    margin: auto;
    margin-left: 40px;
  }

  .green {
    background: #069542;
    height: 8px;
    border-radius: 6px;
    margin-top: 6px;
  }
  .green2 {
    background-color: #0ddc65;
    height: 8px;
    border-radius: 6px;
    margin-top: 6px;
  }

  .yellow {
    background-color: #fecd1a;
    height: 8px;
    border-radius: 6px;
    margin-top: 6px;
  }
  .orange {
    background-color: #ff9900;
    height: 8px;
    border-radius: 6px;
    margin-top: 6px;
  }
  .red {
    background-color: #ff613f;

    height: 8px;
    border-radius: 6px;
    margin-top: 6px;
  }
  .noDataText {
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin-left: 10px;
  }
}

//////////////////////////////////////////////////////////////////////

@media screen and (max-width: 1439px) {
  .block {
    display: flex;
    margin-left: 76px;
    margin: auto;
    margin-left: 50px;
    width: 330px;
  }

  .text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    text-align: center;
    color: #000000;
    margin: auto;
    padding: 0px;
    width: 100%;
  }

  .green {
    border-radius: 6px;
    margin-top: 6px;
  }
  .green2 {
    border-radius: 6px;
    margin-top: 6px;
  }

  .yellow {
    background-color: #fecd1a;
    border-radius: 6px;
    margin-top: 6px;
  }
  .orange {
    background-color: #ff9900;
    border-radius: 6px;
    margin-top: 6px;
  }
  .red {
    background-color: #ff613f;
    border-radius: 6px;
    margin-top: 6px;
  }

  .noDataText {
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-left: 10px;
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////

@media screen and (max-width: 1023px) {
  .block {
    display: none;
    width: 200px;
  }
  .blockArr {
    display: none;
  }
  .blockMobile {
    display: block;
    width: 200px;
    margin-bottom: 10px;
    padding-top: 0px;
    align-items: flex-start;
    margin-left: 0px;
  }

  .flexM {
    display: flex;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 0px;
    height: auto;
  }
  .greenM {
    background: #069542;
    height: 10px;
    border-radius: 6px;
  }
  .green2M {
    background-color: #0ddc65;
    height: 10px;
    border-radius: 6px;
  }
  .yellowM {
    background-color: #fecd1a;
    height: 10px;
    border-radius: 6px;
  }
  .orangeM {
    background-color: #ff9900;
    height: 10px;
    border-radius: 6px;
  }
  .redM {
    background-color: #ff613f;
    height: 10px;
    border-radius: 6px;
  }
  .textM {
    margin-top: 0;
    margin-bottom: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin-left: 6px;
  }
  .noDataText {
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-left: 10px;
  }
  .noDataImg {
    width: 22px;
    height: 22px;
  }
}

@media screen and (max-width: 767px) {
  .noDataDiv {
    display: none;
  }
  .block {
    display: none;
  }
}
