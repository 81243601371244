.title {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 49px;
  text-align: left;
  margin-top: 80px;
  margin-bottom: 0;
}
.mainBlocks {
  display: flex;
}
.block {
  width: 360px;
  min-height: auto;
  background-color: #f7f7f7;
  margin-right: 30px;
  margin-top: 34px;
  overflow: hidden;
  border-radius: 8px;
  padding-bottom: 20px;
}
.blockTitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  opacity: 0.6;
  text-align: left;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 0;
}
.valueFirst {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  margin-left: 20px;
  margin-top: 7px;
  margin-bottom: 0;
}
.blockTitle2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  opacity: 0.6;
  text-align: left;
  margin-top: 7px;
  margin-bottom: 0;
}
.value {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  margin-top: 7px;
  margin-bottom: 0;
}
.valueFlags {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  margin-left: 7px;
  margin-top: 7px;
  margin-bottom: 0;
}
.bigBlock {
  text-align: left;
  width: 300px;
  height: auto;
  background-color: #f7f7f7;
  margin-right: 30px;
  margin-top: 25px;
  margin-bottom: 60px;
  position: relative;
  border-radius: 8px;
  padding: 40px 30px;
}
.icon {
  margin-top: 24px;
  width: 40px;
  height: 38px;
}
.iconUp {
  width: 40px;
  height: 38px;
}
.iconSupervisor {
  position: absolute;
  top: 169px;
  left: 30px;
}
.blockTitle2Supervisor {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  opacity: 0.6;
  text-align: left;
  position: absolute;
  top: 195px;
  left: 30px;
}
.valueSupervisor {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: left;
  margin-left: 30px;
  position: absolute;
  top: 229px;
  left: 10px;
}
.iconFlags {
  width: 24px;
  height: 18px;
  border: 0.5px solid #818181;
  border-radius: 2px;
  margin-top: 7px;
  margin-left: 20px;
}
.divlags {
  display: flex;
}

@media screen and (max-width: 1919px) {
  .title {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 38px;
    text-align: left;
    margin-top: 60px;
    margin-bottom: 0;
  }
  .mainBlocks {
    display: flex;
  }
  .valueFirst {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    margin-left: 20px;
    margin-top: 7px;
    margin-bottom: 0;
  }
  .block {
    width: 285px;
    min-height: auto;
    background-color: #f7f7f7;
    margin-right: 30px;
    margin-top: 30px;
    overflow: hidden;
    border-radius: 8px;
  }
  .blockTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    opacity: 0.6;
    text-align: left;
    margin-bottom: 0;
  }
  .blockTitle2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    opacity: 0.6;
    text-align: left;
    margin-top: 7px;
    margin-bottom: 0;
  }
  .value {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    margin-top: 7px;
    margin-bottom: 0;
  }

  .bigBlock {
    text-align: left;
    width: 237px;
    height: auto;
    background-color: #f7f7f7;
    margin-right: 30px;
    margin-top: 25px;
    margin-bottom: 50px;
    position: relative;
    border-radius: 8px;
    padding: 30px 24px;
  }
  .icon {
    margin-top: 24px;
    width: 40px;
    height: 38px;
  }
  .iconUp {
    width: 40px;
    height: 38px;
  }
  .iconSupervisor {
    position: absolute;
    top: 158px;
    left: 30px;
  }
  .blockTitle2Supervisor {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    opacity: 0.6;
    text-align: left;
    position: absolute;
    top: 190px;
    left: 30px;
  }
  .valueSupervisor {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    margin-left: 30px;
    position: absolute;
    top: 216px;
    left: 10px;
  }
  .iconFlags {
    width: 24px;
    height: 18px;
    border: 0.5px solid #818181;
    border-radius: 2px;
    margin-top: 7px;
    margin-left: 20px;
  }
  .divlags {
    display: flex;
  }
  .valueFlags {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    margin-left: 7px;
    margin-top: 7px;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1439px) {
  .block {
    width: 281px;
    background-color: #f7f7f7;
    margin-right: 20px;
    height: auto;
  }
  .bigBlock {
    text-align: left;
    width: 232px;
    background-color: #f7f7f7;
    margin-right: 20px;
    margin-top: 25px;
    position: relative;
    border-radius: 8px;
  }
}

@media screen and (max-width: 1279px) {
  .block {
    width: 293px;
    height: auto;
  }
  .bigBlock {
    width: 244px;
    height: 218px;
  }
}

@media screen and (max-width: 1023px) {
  .title {
    font-size: 24px;
    line-height: 27px;
    text-align: left;
    margin-top: 50.5px;
  }
  .valueFirst {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    margin-left: 15px;
    margin-top: 7px;
    margin-bottom: 0;
  }

  .blockTitle {
    font-size: 16px;
    line-height: 19px;
    opacity: 0.6;
    text-align: left;
    margin-left: 15px;
    margin-top: 20px;
    margin-bottom: 0;
  }
  .blockTitle2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    opacity: 0.6;
    text-align: left;
    margin-top: 7px;
    margin-bottom: 0;
  }
  .value {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    margin-top: 7px;
    margin-bottom: 0;
  }
  .valueFlags {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    text-align: left;
    margin-left: 7px;
    margin-top: 7px;
    margin-bottom: 0;
  }
  .icon {
    margin-top: 24px;
    width: 30px;
    height: 30px;
  }
  .iconUp {
    width: 30px;
    height: 30px;
  }
  .iconSupervisor {
    position: absolute;
    top: 148px;
    left: 20px;
    width: 30px;
    height: 30px;
  }
  .blockTitle2Supervisor {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    opacity: 0.6;
    text-align: left;
    position: absolute;
    top: 175px;
    left: 20px;
  }
  .valueSupervisor {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    margin-left: 30px;
    position: absolute;
    top: 196px;
    left: -5px;
  }
  .iconFlags {
    width: 24px;
    height: 18px;
    border: 0.5px solid #818181;
    border-radius: 2px;
    margin-top: 7px;
    margin-left: 15px;
  }
  .divlags {
    display: flex;
  }

  .block {
    width: 217px;
    margin-right: 20px;
  }
  .block:last-of-type {
    width: 217px;
    margin-right: 0px;
  }
  .bigBlock {
    width: 176px;
    margin-top: 20px;
    padding: 20px;
    margin-bottom: 40px;
    height: auto;
  }
  .bigBlock:last-of-type {
    width: 176px;
    margin-top: 20px;
    padding: 20px;
    margin-bottom: 40px;
    margin-right: 0;
    height: auto;
  }
}

@media screen and (max-width: 767px) {
  .title {
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    margin-top: 41.5px;
    margin-bottom: 16px;
  }
  .valueFirst {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    margin-left: 15px;
    margin-top: 7px;
    margin-bottom: 0;
  }

  .blockTitle {
    font-size: 16px;
    line-height: 19px;
    opacity: 0.6;
    text-align: left;
    margin-left: 15px;
    margin-top: 20px;
    margin-bottom: 0;
  }
  .blockTitle2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    opacity: 0.6;
    text-align: left;
    margin-left: 6px;
    margin-top: 0px;
    margin-bottom: 0;
  }
  .value {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    margin-left: 0px;
    margin-top: 7px;
    margin-bottom: 11.5px;
  }
  .valueFlags {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    text-align: left;
    margin-left: 7px;
    margin-top: 7px;
    margin-bottom: 0;
  }
  .icon {
    margin-left: 0px;
    margin-top: 0px;
    width: 20px;
    height: 20px;
  }
  .iconUp {
    margin-left: 0px;
    margin-top: 0px;
    width: 20px;
    height: 20px;
  }
  .iconSupervisor {
    position: absolute;
    top: 78px;
    left: 20px;
    width: 20px;
    height: 20px;
  }
  .blockTitle2Supervisor {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    opacity: 0.6;
    text-align: left;
    position: absolute;
    top: 63px;
    left: 55px;
  }
  .valueSupervisor {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    margin-left: 30px;
    position: absolute;
    top: 90px;
    left: -5px;
  }
  .iconFlags {
    width: 24px;
    height: 18px;
    border: 0.5px solid #818181;
    border-radius: 2px;
    margin-top: 7px;
    margin-left: 15px;
  }
  .divlags {
    display: flex;
  }
  .divInfo {
    display: flex;
    align-items: center;
  }

  .mainBlocks {
    display: block;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
  }
  .block {
    width: 100%;
    margin-top: 10px;
    min-height: auto;
    height: auto;
  }
  .block:last-of-type {
    width: 100%;
    margin-top: 10px;
    min-height: auto;
    height: auto;
  }
  .bigBlock {
    height: 137px;
    height: auto;
    width: 100%;
    padding: 0;
    margin-top: 0px;
    margin-bottom: 10px;
    padding: 17px 16px 3px;
    box-sizing: border-box;
  }
  .bigBlock:last-of-type {
    margin-top: 0;
    width: 290px;
    padding: 17px 16px 3px;
  }
  .blocks {
    margin-right: 15px;
    margin-bottom: 160px;
  }
}
