.popup{
display: flex;
width: 450px;
height: 138px;
background: #0DDC65;
border-radius: 12px;
box-sizing: border-box;
padding: 40px;
position: fixed;
right: 60px;
bottom: 60px;
visibility:visible;
cursor: pointer;
animation: popup 1.7s;
z-index: 99;
}

.popupHidden{
visibility: hidden;
}

.text{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #FFFFFF;
  text-align: left;
  margin: 0;
  padding: 0;
}

@keyframes popup {
  0%{
    transform: scale(0.5);
  }
  50%{
    transform: scale(1.01);
  }
  60%{
    transform: scale(1.02);
  }
  70%{
    transform: scale(1);
  }
  80%{
    transform: scale(1.01);
  }
  90%{
    transform: scale(0.9);
  }
  100%{
    transform: scale(1);
  }
}

@media screen and (max-width: 1279px) {
  .popup{
    width: 370px;
    height: 108px;
    padding: 30px;
    right: 60px;
    bottom: 60px;
    }

    .text{
      font-size: 20px;
      line-height: 25px;
    }
}
  @media screen and (max-width: 767px) {
    .popup{
      width: 250px;
      height: 67px;
      padding: 15px;
      right: 60px;
      bottom: 60px;
      }
  
      .text{
        font-size: 14px;
        line-height: 18px;
      }
  
  }