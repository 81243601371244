.mainSection{
    text-align: left;
    margin: 0;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
}
.firstInformation{
    margin-top: 127px;
    margin-left: 195px;
    margin-bottom: 238px;
}
.title{
    width: 688px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 54px;
    line-height: 130.02%;
    margin: 0;
    margin-bottom: 36px;
}
.titleColor{
    color: #3852DE;
}
.buttonsSection{
    display: flex;
    max-width: 500px;
}
.buttonSignIn{
    background: #3852DE;
    border-radius: 20px;
    width: 230px;
    height: 79px;
    box-sizing: border-box;
    border: none;
    display: flex;
    align-items: center;
    text-decoration: none;
}
.buttonSignIn:hover{
    cursor: pointer;
    background: #3349c9;
}
.buttonText{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 31px;
    color: white;
    margin: 0;
    padding: 0;
    text-align: left;
    margin-left: 30px;
}
.arrowArea{
    background: #FFFFFF;
    border-radius: 20px;
    width: 51px;
    height: 49px; 
    display: flex;
    align-items: center;
    margin-left: 51px;
}
.arrow{
    width: 20px;
    height: 22px;
    margin: auto;
}
.divWithLogo{
    border: 1px solid #CCCCCC;
    border-radius: 10px;
    width: 230px;
    height: 79px;
    display: flex;
    margin-left: 27px;
}
.logoInno{
    margin: auto;
}
.checkMarkSection{
    display: flex;
    margin-top: 51px;
    gap: 26px;
}
.checkMarkItem{
    width: 289px;
    height: 48px;
    display: flex;
    text-align: center;
}
.checkMark{
    width: 28px;
    height: 28px;
    margin-right: 16px;
}
.checkMarkText{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    margin: 0;
    opacity: 0.8;
}
.rightPicture{
    position: absolute;
    right: 0;
    top: 170px;
    background: #3852DE;
    border-radius: 20px 0px 0px 20px;
    width: 815px;
    height: 628px;

}
.screenOne{
    width: 761.48px;
    height: 548px;
    position: absolute;
    top: 0px;
    right: 194px;
}
.areasTitle{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 44px;
    line-height: 56px;
    text-align: center;
    margin: 0;
    margin-top: 0px;
}
.navDiv{
    margin: auto;
    margin-top: 0;
}
.navSection{
    display: flex;
    margin-bottom: 50px;
    gap: 30px;
    margin: auto;
}
.navBlock{
    box-sizing: border-box;
    padding: 40px;
    border: 1px solid rgba(35, 35, 35, 0.2);
    border-radius: 20px;
    width: 490px;
    height: 310px;
    text-align: center;
    margin-top: 10px;

}
.imgBlock{
    width: 84px;
    height: 84px;
    margin-bottom: 28px;
}
.navTitle{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 120%;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
}
.navText{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    opacity: 0.7;
    width: 410px;
    margin: 0;
    padding: 0;
}
.linkSection{
    text-decoration: none;
    color: black;
    cursor: pointer;
    margin-top: 50px;
    transition: 0.2s;
}
.linkSection:hover{
    .navTitle{
        color: #3852DE;
        transition: 0.2s;
    }
}
/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1919px) {
    .mainSection{
        text-align: left;
        margin: 0;
        padding: 0;
        position: relative;
        display: flex;
        flex-direction: column;
    }
    .firstInformation{
        margin-top: 100px;
        margin-left: 105px;
        margin-bottom: 214px;
    }
    .title{
        width: 588px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 46px;
        line-height: 56px;
        margin: 0;
        margin-bottom: 35px;
    }
    .titleColor{
        color: #3852DE;
    }
    .buttonsSection{
        display: flex;
        max-width: 400px;
    }
    .buttonSignIn{
        background: #3852DE;
        border-radius: 20px;
        width: 215px;
        height: 68px;
        box-sizing: border-box;
        border: none;
        display: flex;
        align-items: center;
        text-decoration: none;
    }
    .buttonSignIn:hover{
        cursor: pointer;
        opacity: 0.9;
    }
    .buttonText{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 31px;
        color: white;
        margin: 0;
        padding: 0;
        text-align: left;
        margin-left: 14px;
    }
    .arrowArea{
        background: #FFFFFF;
        border-radius: 20px;
        width: 51px;
        height: 49px; 
        display: flex;
        align-items: center;
        margin-left: 36px;
    }
    .arrow{
        width: 20px;
        height: 22px;
        margin: auto;
    }
    .divWithLogo{
        border: 1px solid #CCCCCC;
        border-radius: 10px;
        width: 191px;
        height: 68px;
        display: flex;
        margin-left: 27px;
    }
    .logoInno{
        margin: auto;
    }
    .checkMarkSection{
        display: flex;
        margin-top: 51px;
        gap: 31px;
    }
    .checkMarkItem{
        width: 220px;
        height: 38px;
        display: flex;
        text-align: center;
    }
    .checkMark{
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }
    .checkMarkText{
        font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    margin: 0;
    }
    .rightPicture{
        position: absolute;
        right: 0;
        top: 146px;
        background: #3852DE;
        border-radius: 20px 0px 0px 20px;
        width: 600px;
        height: 534px;
    
    }
    .screenOne{
        width: 632px;
        height: 454.82px;
        position: absolute;
        top: 34px;
        right: 88px;
    }
    .areasTitle{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 56px;
        text-align: center;
        margin: 0;
        margin-top: 0px;
    }
    .navDiv{
        margin: auto;
        margin-top: 0;
    }
    .navSection{
        display: flex;
        margin-bottom: 50px;
        gap: 30px;
        margin: auto;
    }
    .navBlock{
        box-sizing: border-box;
        padding: 30px 24px;
        border: 1px solid rgba(35, 35, 35, 0.2);
        border-radius: 20px;
        width: 390px;
        height: 272px;
        text-align: center;
        margin-top: 50px;
    
    }
    .imgBlock{
        width: 84px;
        height: 84px;
        margin-bottom: 28px;
    }
    .navTitle{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 120%;
        margin: 0;
        padding: 0;
        margin-bottom: 14px;
    }
    .navText{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        opacity: 0.7;
        margin: 0;
        padding: 0;
        width: 342px;
    }
    .linkSection{
        text-decoration: none;
        color: black;
        margin-top: 0px;
    }  
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1439px) {
    .firstInformation{
        margin-top: 40px;
        margin-left: 50px;
        margin-bottom: 186px;
    }
    .title{
        width: 500px;
        font-weight: 600;
        font-size: 40px;
        line-height: 125.02%;
        margin-bottom: 34px;
    }
    .buttonSignIn{
        width: 202px;
        height: 68px;
    }
    .buttonText{
        font-size: 24px;
        line-height: 29px;
        margin-left: 14px;
    }
    .arrowArea{
        width: 51px;
        height: 49px; 
        margin-left: 36px;
    }
    .arrow{
        width: 20px;
        height: 22px;
    }
    .divWithLogo{
        width: 191px;
        height: 68px;
    }
    .checkMarkSection{
        margin-top: 56px;
        gap: 26px;
    }
    .checkMarkItem{
        width: 220px;
        height: 38px;
    }
    .checkMark{
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }
    .checkMarkText{
    font-size: 16px;
    line-height: 19px;
    }
    .rightPicture{
        top: 146px;
        width: 530px;
        height: 477px;
    
    }
    .screenOne{
        width: 537.25px;
        height: 386px;
        top: 0px;
        right: 88px;
    }
    .areasTitle{
        font-size: 32px;
        line-height: 120%;
    }
    .navSection{
        display: flex;
        margin-bottom: 40px;
        gap: 15px;
    }
    .navBlock{
        padding: 30px 24px;
        width: 380px;
        height: 253px;
        margin-top: 40px;
    
    }
    .imgBlock{
        width: 78px;
        height: 78px;
        margin-bottom: 20px;
    }
    .navTitle{
        font-size: 24px;
        line-height: 120%;
        margin-bottom: 12px;
    }
    .navText{
        font-size: 18px;
        line-height: 125%;
        width: 342px;
        margin-right: 0;
    } 
}


/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1279px) {
    .firstInformation{
        margin-top: 8px;
        margin-left: 54px;
        margin-bottom: 150px;
    }
    .title{
        width: 390px;
        font-size: 32px;
        line-height: 125.02%;
        margin-bottom: 24px;
    }
    .buttonSignIn{
        width: 164px;
        height: 57px;
        border-radius: 16px;
    }
    .buttonText{
        font-size: 20px;
        line-height: 24px;
        margin-left: 17px;
    }
    .arrowArea{
        width: 43px;
        height: 41px;
        margin-left: 26px;
        border-radius: 16px;
    }
    .arrow{
        width: 16px;
        height: 18px;
    }
    .divWithLogo{
        width: 161px;
        height: 57px;
        margin-left: 18px;
    }
    .logoInno{
        width: 132px;
        height: 30px;
    }
    .checkMarkSection{
        margin-top: 40px;
        gap: 24px;
    }
    .checkMarkItem{
        width: 187px;
        height: 34px;
    }
    .checkMarkItem:last-child{
        width: 117px;
    }
    .checkMark{
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }
    .checkMarkText{
        font-size: 14px;
        line-height: 17px;
    }
 
    .rightPicture{
        top: 106px;
        width: 424px;
        height: 353px;
    
    }
    .screenOne{
        width: 404px;
        height: 290.27px;
        top: -15px;
        right: 88px;
    }
    .areasTitle{
        font-size: 26px;
        line-height: 120%;
    }
    .navSection{
        margin-bottom: 30px;
        gap: 19px;
    }
    .navBlock{
        padding: 24px 20px;
        width: 292px;
        height: 224px;
        margin-top: 0px;
    
    }
    .imgBlock{
        width: 64px;
        height: 64px;
        margin-bottom: 20px;
    }
    .navTitle{
        font-size: 20px;
        line-height: 120%;
        margin-bottom: 8px;
    }
    .navText{
        font-size: 16px;
        line-height: 125%;
        width: 234px;
    } 
    .linkSection{
        margin-top: 36px;
    }
}


/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1023px) {
    .firstInformation{
        margin-top: 8px;
        margin-left: 40px;
        margin-bottom: 100px;
    }
    .title{
        width: 334px;
        font-size: 26px;
        line-height: 120.02%;
        margin-bottom: 22px;
    }
    .buttonSignIn{
        width: 164px;
        height: 53px;
        border-radius: 12px;
    }
    .buttonText{
        font-size: 18px;
        line-height: 22px;
        margin-left: 20px;
    }
    .arrowArea{
        width: 39px;
        height: 38px;
        margin-left: 39px;
        border-radius: 20px;
        border-radius: 12px;
    }
    .arrow{
        width: 16px;
        height: 18px;
    }
    .divWithLogo{
        width: 142px;
        height: 53px;
        margin-left: 14px;
    }
    .logoInno{
        width: 100.55px;
        height: 22.85px;
    }
    .checkMarkSection{
        margin-top: 40px;
        gap: 8px;
        display: block;
        margin-top: 28px;
    }
    .checkMarkItem{
        width: auto;
        height: 26px;
    }
    .checkMarkItem:last-child{
        width: auto;
    }
    .checkMark{
        width: 20px;
        height: 20px;
        margin-right: 6px;
    }
    .checkMarkText{
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
    }
    .rightPicture{
        top: 92px;
        width: 331px;
        height: 321px;
    
    }
    .screenOne{
        width: 343px;
        height: 246.44px;
        top: -10px;
        right: 40px;
    }
    .areasTitle{
        font-size: 22px;
        line-height: 120%;
    }
    .navSection{
        margin-bottom: 30px;
        gap: 16px;
    }
    .navBlock{
        padding: 20px;
        width: 216px;
        height: 189px;
        margin-top: 0px;
    
    }
    .imgBlock{
        width: 56px;
        height: 56px;
        margin-bottom: 14px;
    }
    .navTitle{
        font-size: 16px;
        line-height: 120%;
        margin-bottom: 6px;
    }
    .navText{
        font-size: 14px;
        line-height: 125%;
        width: 176px;
    } 
    .linkSection{
        margin-top: 30px;
    }
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 767px) {

    .firstInformation{
        display: flex;
        flex-direction: column;
        width: 290px;
        margin: 0 auto 100px;
        position: relative;
    }
    .title{
        width: 290px;
        font-size: 22px;
        line-height: 120.02%;
        margin-bottom: 18px;
    }
    .buttonSignIn{
        width: 125px;
        height: 48px;
        border-radius: 10px;
        z-index: 2;
    }
    .buttonText{
        font-size: 16px;
        line-height: 19px;
        margin-left: 19px;
    }
    .arrowArea{
        width: 37px;
        height: 36px;
        margin-left: 12px;
        border-radius: 10x;
    }
    .arrow{
        width: 14px;
        height: 16px;
    }
    .divWithLogo{
        width: 120px;
        height: 48px;
        margin-left: 10px;
    }
    .logoInno{
        width: 78.88px;
        height: 17.93px;
    }
    .checkMarkSection{
        margin-top: 40px;
        gap: 12px;
        width: 137px;
    }
    .checkMarkItem{
        width: auto;
        height: auto;
        margin-top: 8px;
    }
    .checkMark{
        width: 16px;
        height: 16px;
        margin-right: 6px;
    }
    .checkMarkText{
        font-size: 12px;
        line-height: 15px;
    }
    .right{
    position: absolute;
    left: calc(50% - (720px / 2));
    right: calc(50% - (720px / 2));
    width: 290px;
    height: 500px;
    display: flex;
    margin: 0 auto;
    }
    .rightPicture{
        top: 310px;
        width: 141px;
        height: 135px;
        right: -14px;
   
    }
    .screenOne{
        width: 150px;
        height: 107.77px;
        top: -25px;
        right: 15px;
    }
    .areasTitle{
        font-size: 20px;
        line-height: 120%;
        width: 200px;
        margin:  40px auto 20px;
    }
    .navSection{
        margin-bottom: 30px;
        gap: 16px;
        display: block;
    }
    .navBlock{
        padding: 20px;
        width: 290px;
        height: auto;
        margin-top: 10px;
        text-align: center;
    
    }
    .imgBlock{
        width: 56px;
        height: 56px;
        margin-bottom: 14px;
    }
    .navTitle{
        font-size: 16px;
        line-height: 120%;
        margin-bottom: 6px;
    }
    .navText{
        font-size: 14px;
        line-height: 125%;
        width: 250px;
    } 
    .linkSection{
        margin-top: 0px;
    }
}