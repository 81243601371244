.Syllabus {
  display: block;
  height: auto;
  min-height: calc(100vh - 212px);
  margin-left: 195px;
}
.preloader {
  top: 200px;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  position: absolute;
  visibility: visible;
  opacity: 1;
  transition: visibility 0.5s, opacity 0.5s linear;
  z-index: 1;
}
.title {
  margin-top: 60px;
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 67px;
  margin-bottom: 40px;
  text-align: left;
}
.hint {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  margin-left: 0;
  margin-bottom: 40px;
  text-align: left;
}
.steps {
  display: block;
  width: 1140px;
  margin-bottom: 20px;
}
.stepsHeader {
  display: flex;
  height: 71px;
  background-color: #f7f7f7;
  border-radius: 10px 10px 0px 0px;
  align-items: center;
  position: relative;
  width: 100%;
}
.stepsNumber {
  background: #3852de;
  border-radius: 50px;
  width: 41px;
  height: 41px;
  position: relative;
  margin-right: 12px;
  margin-left: 20px;
}
.stepsNumberText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  position: relative;
  top: -17px;
  color: white;
}
.headerTitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
}
.arrow {
  position: absolute;
  right: 20px;
}
.stepsBody {
  border-bottom: 1px #d6d6d6 solid;
  border-left: 1px #d6d6d6 solid;
  border-right: 1px #d6d6d6 solid;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  flex-direction: column;
  height: auto;
}
.toggleDiv {
  display: flex;
  margin-left: 20px;
  align-items: center;
  margin-top: 30px;
}
.img {
  width: 40px;
  height: 40px;
  margin-right: 16px;
}
.toggleText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  margin-right: 20px;
}

.toggleButtonDegree1Active {
  width: 144px;
  height: 60px;
  border: 2px solid #3852de;
  border-radius: 6px 0px 0px 6px;
  background-color: white;
  position: relative;
  margin-left: 52px;
}
.toggleButtonDegree2Active {
  width: 144px;
  height: 60px;
  border: 2px solid #3852de;
  border-radius: 0px 6px 6px 0px;
  background-color: white;
  position: relative;
}
.toggleButtonDegree1 {
  width: 144px;
  height: 60px;
  border: 1px solid rgba(35, 35, 35, 0.2);
  border-radius: 6px 0px 0px 6px;
  background-color: white;
  cursor: pointer;
  position: relative;
  margin-left: 52px;
}
.toggleButtonDegree2 {
  width: 144px;
  height: 60px;
  border: 1px solid rgba(35, 35, 35, 0.2);
  border-radius: 0px 6px 6px 0px;
  background-color: white;
  cursor: pointer;
  position: relative;
}
.toggleButtonText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  position: relative;
  top: -3px;
}
.toggleButtonTextActive {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #3852de;
  position: relative;
  top: -4px;
}
.toggleDivType {
  display: flex;
  margin-left: 20px;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 30px;
}
.toggleButtonCourse1Active {
  border: 2px solid #3852de;
  border-radius: 6px 0px 0px 6px;
  width: auto;
  height: 60px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 0 30px;
}
.toggleButtonCourse1 {
  border: 1px solid rgba(35, 35, 35, 0.2);
  border-radius: 6px 0px 0px 6px;
  width: auto;
  height: 60px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 0 31px;
  cursor: pointer;
}
.toggleButtonCourse2Active {
  border: 2px solid #3852de;
  width: auto;
  height: 60px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 0 30px;
}
.toggleButtonCourse2 {
  border: 1px solid rgba(35, 35, 35, 0.2);
  width: auto;
  height: 60px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 0 31px;
  cursor: pointer;
}
.toggleButtonCourse3Active {
  border: 2px solid #3852de;
  border-radius: 0px 6px 6px 0px;
  width: auto;
  height: 60px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 0 30px;
}
.toggleButtonCourse3 {
  border: 1px solid rgba(35, 35, 35, 0.2);
  border-radius: 0px 6px 6px 0px;
  width: auto;
  height: 60px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 0 31px;
  cursor: pointer;
}

.linkDiv {
  display: flex;
  margin-left: 20px;
  margin-top: 10px;
}
.linksImg {
  margin-right: 8px;
}
.linkText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-decoration-line: underline;
  color: rgba(35, 35, 35, 0.71);
}
.dropArea {
  display: block;
  width: 1095px;
  height: 270px;
  border: 2px dashed #818181;
  border-radius: 8px;
  margin-bottom: 21px;
  margin-left: 20px;
  margin-bottom: 30px;
  box-sizing: border-box;

  position: relative;
}
.dropArea2 {
  display: block;
  width: 1095px;
  height: 270px;
  border: 2px dashed #3852de;
  border-radius: 8px;
  margin-bottom: 21px;
  margin-left: 20px;
  margin-bottom: 30px;
  box-sizing: border-box;

  position: relative;
}
.dropFild {
  z-index: 18;
  position: relative;
}
.fileImg {
  width: 46px;
  height: 46px;
  margin-top: 85px;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -10;
}
.fileTextDrop {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin: auto;
  margin-top: 23px;
  width: 364px;
  z-index: -10;
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
}
.fileTextDrop2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin-top: 23px;
  color: #3852de;
  z-index: -10;
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
}
.filesText {
  display: flex;
  align-items: center;
  margin-top: -21px;
}

.textDropInvalid {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}
.alarmHintDiv {
  display: flex;
  margin-top: 40px;
  width: 1140px;
}
.alarmHintImg {
  width: 40px;
  height: 40px;
  margin-right: 14px;
}
.alarmHintText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  margin-top: 0;
}
.buttonDiv {
  display: flex;
  margin-top: 30px;
  width: 1140px;
  justify-content: space-between;
  margin-bottom: 180px;
}
.buttonUpload {
  width: 565px;
  height: 64px;
  background: #3852de;
  border-radius: 8px;
  border: 1px solid #3852de;
  position: relative;
  cursor: pointer;
}
.buttonUploadDis {
  width: 565px;
  height: 64px;
  background: #3852de;
  border-radius: 8px;
  border: 1px solid #3852de;
  position: relative;
  opacity: 0.3;
}
.textUpload {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: #ffffff;
  position: relative;
  top: -6px;
}

.buttonTrialUpload {
  width: 565px;
  height: 64px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #3852de;
  position: relative;
  cursor: pointer;
}
.buttonTrialUploadDis {
  width: 565px;
  height: 64px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #3852de;
  position: relative;
  opacity: 0.3;
}
.textTrialUpload {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: #3852de;
  position: relative;
  top: -6px;
}

/* .dropDocx{
width: 700px;
white-space: nowrap;
text-overflow: ellipsis;
margin: auto;
overflow:hidden;

} */
/* .dropDocxNo{
    width: 700px;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: auto;
    overflow:hidden;

} */
.fileImgDrop {
  width: 46px;
  height: 46px;
  margin-top: 56px;
  margin-bottom: 0;
}
.fileTextFormat {
  margin-top: 23px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 0;
  text-overflow: ellipsis;
}
.fileTextError {
  color: #ff613f;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: 8px;
  margin-bottom: 0;
  width: 201px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.spanDeleteFile {
  margin-top: 31px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  align-items: center;
  cursor: pointer;
  position: relative;
  top: -6px;
}
.cross {
  margin-top: 21px;
  position: relative;
  top: 0px;
  margin-right: 7px;
  width: 11.32px;
  height: 11.32px;
}

.sectionCourseName {
  border-top: 1px solid rgba(35, 35, 35, 0.2);
  margin: 0 20px;
  box-sizing: border-box;
  text-align: left;
}
.textCourseName {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  margin: 0;
  padding: 0;
  margin-top: 30px;
}
.divInput {
  display: flex;
  margin-top: 22px;
  margin-bottom: 0px;
  position: relative;
}
.divHint {
  box-sizing: border-box;
  padding: 24px 20px;
  background: #f7f7f7;
  border: 1px solid rgba(35, 35, 35, 0.2);
  border-radius: 6px 0px 0px 6px;
  height: 70px;
  width: 110px;
}
.input {
  box-sizing: border-box;
  width: 633px;
  height: 70px;
  border-color: rgba(35, 35, 35, 0.2);
  border-radius: 0px 6px 6px 0px;
  border: 1px rgba(35, 35, 35, 0.2) solid;
  border-left: none;
  opacity: 0.5;
  padding: 24px;
}
.textHint {
  padding: 0;
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

.listSearchHidden {
  visibility: hidden;
  margin-top: 30px;
}
.listSearch {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  padding: 18px 25px;
  margin-top: 10px;

  max-width: 580px;
  margin-bottom: 10px;
  max-height: 300px;
  overflow-y: auto;
  text-overflow: ellipsis;
  max-height: 253px;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.07);
  margin-left: 130px;
}
.itemsText {
  width: auto;
}
.itemsText:hover {
  cursor: pointer;
}

.flexDiv {
  display: flex;
  align-items: center;
}
.hintImgText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin-left: 8px;
  text-align: left;
  margin-top: 0;
}
.hintImg {
  margin-top: -15px;
  margin-left: 20px;
}
.chevron {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 25px;
  right: 380px;
  cursor: pointer;
}
/* /////////////////////////////////////////////////////////////////////////////////////////// */

@media screen and (max-width: 1919px) {
  .Syllabus {
    margin-left: 35px;
  }
  .title {
    margin-top: 50px;
    font-size: 44px;
    line-height: 49px;
    margin-bottom: 30px;
  }
  .hint {
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 30px;
    width: 915px;
  }
  .steps {
    width: 915px;
    margin-bottom: 20px;
  }
  .stepsHeader {
    height: 71px;
  }
  .stepsNumber {
    width: 41px;
    height: 41px;
    position: relative;
    margin-right: 12px;
    margin-left: 20px;
  }
  .stepsNumberText {
    font-size: 20px;
    line-height: 24px;
    top: -12px;
  }
  .headerTitle {
    font-size: 20px;
    line-height: 24px;
  }
  .arrow {
    position: absolute;
    right: 20px;
  }

  .toggleDiv {
    display: flex;
    margin-left: 20px;
    align-items: center;
    margin-top: 30px;
  }
  .img {
    width: 32px;
    height: 33px;
    margin-right: 10px;
  }
  .toggleText {
    font-size: 18px;
    line-height: 22px;
    margin-right: 16px;
  }
  .toggleButtonDegree1Active {
    width: 124px;
    height: 50px;
    margin-left: 45px;
  }
  .toggleButtonDegree2Active {
    width: 108px;
    height: 50px;
  }
  .toggleButtonDegree1 {
    width: 124px;
    height: 50px;
    margin-left: 45px;
  }
  .toggleButtonDegree2 {
    width: 108px;
    height: 50px;
  }
  .toggleButtonText {
    font-size: 18px;
    line-height: 22px;
    top: -6px;
  }
  .toggleButtonTextActive {
    font-size: 18px;
    line-height: 22px;
    top: -7px;
  }
  .toggleDivType {
    margin-left: 20px;
    margin-top: 0px;
    margin-bottom: 24px;
  }
  .toggleButtonCourse1Active {
    width: auto;
    height: 50px;
  }
  .toggleButtonCourse1 {
    width: auto;
    height: 50px;
  }
  .toggleButtonCourse2Active {
    width: auto;
    height: 50px;
  }
  .toggleButtonCourse2 {
    width: auto;
    height: 50px;
  }
  .toggleButtonCourse3Active {
    width: auto;
    height: 50px;
  }
  .toggleButtonCourse3 {
    width: auto;
    height: 50px;
  }

  .linkDiv {
    display: flex;
    margin-left: 20px;
    margin-top: 0px;
  }
  .linksImg {
    margin-right: 8px;
  }
  .linkText {
    font-size: 20px;
    line-height: 24px;
  }
  .dropArea {
    width: 870px;
    height: 238px;
    margin-bottom: 21px;
    margin-left: 20px;
  }
  .dropArea2 {
    width: 870px;
    height: 238px;
    margin-bottom: 21px;
    margin-left: 20px;
  }
  .fileImg {
    width: 46px;
    height: 46px;
    margin-top: 70px;
  }
  .fileTextDrop {
    font-size: 24px;
    line-height: 29px;
    margin-top: 23px;
  }

  .fileTextDrop2 {
    color: #3852de;
    font-size: 24px;
    line-height: 29px;
    margin-top: 23px;
  }
  .filesText {
    margin-top: -21px;
  }

  .textDropInvalid {
    font-size: 14px;
    line-height: 22px;
  }
  .alarmHintDiv {
    margin-top: 30px;
    width: 915px;
  }
  .alarmHintImg {
    width: 40px;
    height: 40px;
    margin-right: 14px;
  }
  .alarmHintText {
    font-size: 18px;
    line-height: 22px;
  }
  .buttonDiv {
    margin-top: 30px;
    width: 915px;
    margin-bottom: 180px;
  }
  .buttonUpload {
    width: 452px;
    height: 64px;
  }
  .buttonUploadDis {
    width: 452px;
    height: 64px;
  }
  .textUpload {
    font-size: 22px;
    line-height: 27px;
    top: -6px;
  }

  .buttonTrialUpload {
    width: 452px;
    height: 64px;
  }
  .buttonTrialUploadDis {
    width: 452px;
    height: 64px;
  }
  .textTrialUpload {
    font-size: 22px;
    line-height: 27px;
    top: -6px;
  }
  .fileImgDrop {
    margin-top: 40px;
    width: 46px;
    height: 46px;
  }
  .chevron {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 25px;
    right: 150px;
    cursor: pointer;
  }
}

/* /////////////////////////////////////////////////////////////////////////////////////////// */

@media screen and (max-width: 1439px) {
  .Syllabus {
    margin-left: -40px;
  }
  .title {
    margin-top: 50px;
    font-size: 44px;
    line-height: 49px;
    margin-bottom: 30px;
  }
  .hint {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 30px;
    width: 880px;
  }
  .steps {
    width: 880px;
    margin-bottom: 20px;
  }
  .stepsHeader {
    height: 71px;
  }
  .stepsNumber {
    width: 36px;
    height: 36px;
    margin-right: 12px;
    margin-left: 20px;
  }
  .stepsNumberText {
    font-size: 18px;
    line-height: 22px;
    top: -12px;
  }
  .headerTitle {
    font-size: 18px;
    line-height: 22px;
  }
  .arrow {
    position: absolute;
    right: 20px;
    width: 24px;
    height: 24px;
  }

  .toggleDiv {
    display: flex;
    margin-left: 20px;
    align-items: center;
    margin-top: 30px;
  }
  .toggleDivType {
    margin-left: 20px;
    margin-top: 0px;
    margin-bottom: 24px;
  }
  .img {
    width: 32px;
    height: 33px;
    margin-right: 10px;
  }
  .toggleText {
    font-size: 18px;
    line-height: 22px;
    margin-right: 16px;
  }
  .toggleButtonDegree1Active {
    width: 124px;
    height: 50px;
  }
  .toggleButtonDegree2Active {
    width: 108px;
    height: 50px;
  }
  .toggleButtonDegree1 {
    width: 124px;
    height: 50px;
  }
  .toggleButtonDegree2 {
    width: 108px;
    height: 50px;
  }
  .toggleButtonText {
    font-size: 18px;
    line-height: 22px;
    top: -6px;
  }
  .toggleButtonTextActive {
    font-size: 18px;
    line-height: 22px;
    top: -7px;
  }

  .toggleButtonCourse1Active {
    width: auto;
    height: 50px;
  }
  .toggleButtonCourse1 {
    width: auto;
    height: 50px;
  }
  .toggleButtonCourse2Active {
    width: auto;
    height: 50px;
  }
  .toggleButtonCourse2 {
    width: auto;
    height: 50px;
  }
  .toggleButtonCourse3Active {
    width: auto;
    height: 50px;
  }
  .toggleButtonCourse3 {
    width: auto;
    height: 50px;
  }

  .linkDiv {
    display: flex;
    margin-left: 20px;
    margin-top: 0px;
  }
  .linksImg {
    margin-right: 8px;
  }
  .linkText {
    font-size: 20px;
    line-height: 24px;
  }
  .dropArea {
    width: 834px;
    height: 170px;
    margin-bottom: 21px;
    margin-left: 20px;
  }
  .dropArea2 {
    width: 834px;
    height: 170px;
    margin-bottom: 21px;
    margin-left: 20px;
  }
  .fileImg {
    width: 34px;
    height: 34px;
    margin-top: 49px;
  }
  .fileTextDrop {
    font-size: 20px;
    line-height: 24px;
    margin-top: 14px;
    width: 300px;
    top: 90px;
  }
  .fileTextDrop2 {
    color: #3852de;
    font-size: 20px;
    line-height: 24px;
    margin-top: 14px;
    top: 90px;
  }
  .filesText {
    margin-top: -21px;
  }

  .textDropInvalid {
    font-size: 14px;
    line-height: 22px;
  }
  .alarmHintDiv {
    margin-top: 30px;
    width: 880px;
  }
  .alarmHintImg {
    width: 40px;
    height: 40px;
    margin-right: 14px;
  }
  .alarmHintText {
    font-size: 18px;
    line-height: 22px;
  }
  .buttonDiv {
    margin-top: 30px;
    width: 880px;
    margin-bottom: 180px;
  }
  .buttonUpload {
    width: 435px;
    height: 64px;
  }
  .buttonUploadDis {
    width: 435px;
    height: 64px;
  }
  .textUpload {
    font-size: 22px;
    line-height: 27px;
    top: -6px;
  }

  .buttonTrialUpload {
    width: 435px;
    height: 64px;
  }
  .buttonTrialUploadDis {
    width: 435px;
    height: 64px;
  }
  .textTrialUpload {
    font-size: 22px;
    line-height: 27px;
    top: -6px;
  }
  .fileTextFormat {
    margin-top: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0;
    text-overflow: ellipsis;
  }
  .fileImgDrop {
    width: 30px;
    height: 40px;
    margin-top: 15px;
    margin-bottom: 0;
  }
}

/* /////////////////////////////////////////////////////////////////////////////////////////// */

@media screen and (max-width: 1279px) {
  .Syllabus {
    display: block;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    justify-content: space-around;
    margin: 0 auto;
    width: 916px;
  }
  .title {
    margin-top: 40px;
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 26px;
  }
  .hint {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 26px;
    width: 916px;
  }
  .steps {
    width: 916px;
    margin-bottom: 20px;
  }
  .stepsHeader {
    height: 71px;
  }
  .stepsNumber {
    width: 36px;
    height: 36px;
    margin-right: 12px;
    margin-left: 20px;
  }
  .stepsNumberText {
    font-size: 18px;
    line-height: 22px;
    top: -12px;
  }
  .headerTitle {
    font-size: 18px;
    line-height: 22px;
  }
  .arrow {
    position: absolute;
    right: 20px;
    width: 24px;
    height: 24px;
  }

  .toggleDiv {
    display: flex;
    margin-left: 20px;
    align-items: center;
    margin-top: 30px;
  }
  .toggleDivType {
    margin-left: 20px;
    margin-top: 5px;
    margin-bottom: 24px;
  }
  .img {
    width: 26px;
    height: 27px;
    margin-right: 10px;
  }
  .toggleText {
    font-size: 16px;
    line-height: 19px;
    margin-right: 12px;
  }
  .toggleButtonDegree1Active {
    width: 115px;
    height: 48px;
    margin-left: 39px;
  }
  .toggleButtonDegree2Active {
    width: 102px;
    height: 48px;
  }
  .toggleButtonDegree1 {
    width: 115px;
    height: 48px;
    margin-left: 39px;
  }
  .toggleButtonDegree2 {
    width: 102px;
    height: 48px;
  }
  .toggleButtonText {
    font-size: 16px;
    line-height: 19px;
    top: -4px;
  }
  .toggleButtonTextActive {
    font-size: 16px;
    line-height: 19px;
    top: -5px;
  }

  .toggleButtonCourse1Active {
    padding: 0 20px;
    width: auto;
    height: 48px;
  }
  .toggleButtonCourse1 {
    padding: 0 21px;
    width: auto;
    height: 48px;
  }
  .toggleButtonCourse2Active {
    width: auto;
    height: 48px;
    padding: 0 20px;
  }
  .toggleButtonCourse2 {
    width: auto;
    height: 48px;
    padding: 0 21px;
  }
  .toggleButtonCourse3Active {
    width: auto;
    height: 48px;
    padding: 0 20px;
  }
  .toggleButtonCourse3 {
    width: auto;
    height: 48px;
    padding: 0 21px;
  }

  .linkDiv {
    display: flex;
    margin-left: 20px;
    margin-top: 0px;
  }
  .linksImg {
    margin-right: 8px;
    width: 20px;
    height: 20px;
    margin-top: 15px;
  }
  .linkText {
    font-size: 16px;
    line-height: 19px;
  }

  .fileImg {
    width: 34px;
    height: 34px;
    margin-top: 9px;
  }
  .fileInputDiv {
    display: block;
  }
  input[type='file'] {
    display: none;
  }
  .customfilButton {
    width: 220px;
    height: 40px;
    background: #3852de;
    border-radius: 8px;
    position: relative;
    margin-bottom: 22px;
    border: none;
    cursor: pointer;
    z-index: -1;
  }
  .customfilText {
    color: rgb(255, 255, 255);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    border: none;
    position: relative;
    top: -3px;
    cursor: pointer;
    z-index: -1;
  }
  .customfileupload {
    padding: 6px 12px;
    cursor: pointer;
    border: none;
    position: relative;
    display: block;
    width: 220px;
    height: 40px;
    margin: 0 auto;
    margin-bottom: 22px;
    margin-left: 10px;
  }

  .alarmHintDiv {
    margin-top: 30px;
    width: 916px;
  }
  .alarmHintImg {
    width: 40px;
    height: 40px;
    margin-right: 14px;
  }
  .alarmHintText {
    font-size: 18px;
    line-height: 22px;
  }
  .buttonDiv {
    margin-top: 30px;
    width: 916px;
    margin-bottom: 0px;
  }
  .buttonUpload {
    width: 453px;
    height: 56px;
  }

  .buttonUploadDis {
    width: 453px;
    height: 56px;
  }
  .textUpload {
    font-size: 18px;
    line-height: 22px;
    top: -4px;
  }

  .buttonTrialUpload {
    width: 453px;
    height: 56px;
    margin-bottom: 120px;
  }
  .buttonTrialUploadDis {
    width: 453px;
    height: 56px;
    margin-bottom: 120px;
  }
  .textTrialUpload {
    font-size: 18px;
    line-height: 22px;
    top: -4px;
  }
}

/* /////////////////////////////////////////////////////////////////////////////////////////// */

@media screen and (max-width: 1023px) {
  .Syllabus {
    display: block;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    justify-content: space-around;
    margin: 0 auto;
    width: 688px;
  }
  .title {
    margin-top: 30px;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 20px;
  }
  .hint {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 20px;
    width: 688px;
  }
  .steps {
    width: 688px;
    margin-bottom: 20px;
  }
  .stepsHeader {
    height: 54px;
  }
  .stepsNumber {
    width: 30px;
    height: 30px;
    margin-right: 12px;
    margin-left: 20px;
  }
  .stepsNumberText {
    font-size: 16px;
    line-height: 19px;
    top: -10px;
  }
  .headerTitle {
    font-size: 16px;
    line-height: 19px;
  }
  .arrow {
    position: absolute;
    right: 20px;
    width: 20px;
    height: 20px;
  }

  .toggleDiv {
    display: flex;
    margin-left: 20px;
    align-items: center;
    margin-top: 30px;
  }
  .toggleDivType {
    margin-left: 20px;
    margin-top: -3px;
    margin-bottom: 24px;
  }
  .img {
    width: 26px;
    height: 27px;
    margin-right: 10px;
  }
  .toggleText {
    font-size: 16px;
    line-height: 19px;
    margin-right: 12px;
  }
  .toggleButtonDegree1Active {
    width: 99px;
    height: 44px;
  }
  .toggleButtonDegree2Active {
    width: 87px;
    height: 44px;
  }
  .toggleButtonDegree1 {
    width: 99px;
    height: 44px;
  }
  .toggleButtonDegree2 {
    width: 87px;
    height: 44px;
  }
  .toggleButtonText {
    font-size: 14px;
    line-height: 17px;
    top: -3px;
  }
  .toggleButtonTextActive {
    font-size: 14px;
    line-height: 17px;
    top: -4px;
  }

  .toggleButtonCourse1Active {
    width: auto;
    height: 44px;
  }
  .toggleButtonCourse1 {
    width: auto;
    height: 44px;
  }
  .toggleButtonCourse2Active {
    width: auto;
    height: 44px;
  }
  .toggleButtonCourse2 {
    width: auto;
    height: 44px;
  }
  .toggleButtonCourse3Active {
    width: auto;
    height: 44px;
  }
  .toggleButtonCourse3 {
    width: auto;
    height: 44px;
  }

  .linkDiv {
    display: flex;
    margin-left: 20px;
    margin-top: 0px;
  }
  .linksImg {
    margin-right: 8px;
    width: 20px;
    height: 20px;
    margin-top: 15px;
  }
  .linkText {
    font-size: 16px;
    line-height: 19px;
  }

  .fileImg {
    width: 34px;
    height: 34px;
    margin-top: 9px;
  }
  .fileInputDiv {
    display: block;
  }
  input[type='file'] {
    display: none;
  }
  .customfilButton {
    width: 189px;
    height: 40px;
    background: #3852de;
    border-radius: 8px;
    position: relative;
    margin-bottom: 22px;
    border: none;
    cursor: pointer;
    z-index: -1;
  }
  .customfilText {
    color: rgb(255, 255, 255);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    border: none;
    position: relative;
    top: -3px;
    cursor: pointer;
    z-index: -1;
  }
  .customfileupload {
    padding: 6px 12px;
    cursor: pointer;
    border: none;
    position: relative;
    display: block;
    width: 189px;
    height: 40px;
    margin: 0 auto;
    margin-bottom: 22px;
    margin-left: 10px;
  }

  .alarmHintDiv {
    margin-top: 30px;
    width: 688px;
    position: relative;
  }
  .alarmHintImg {
    width: 40px;
    height: 40px;
    margin-right: 14px;
    position: relative;
    top: -3px;
  }
  .alarmHintText {
    font-size: 14px;
    line-height: 17px;
  }
  .buttonDiv {
    margin-top: 30px;
    width: 688px;
    margin-bottom: 180px;
  }
  .buttonUpload {
    width: 340px;
    height: 50px;
  }
  .buttonUploadDis {
    width: 340px;
    height: 50px;
  }
  .textUpload {
    font-size: 18px;
    line-height: 22px;
    top: -6px;
  }

  .buttonTrialUpload {
    width: 340px;
    height: 50px;
  }
  .buttonTrialUploadDis {
    width: 340px;
    height: 50px;
  }

  .textTrialUpload {
    font-size: 18px;
    line-height: 22px;
    top: -6px;
  }
  .listSearch {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: left;
    padding: 8px 25px;
    margin-top: 10px;

    max-width: 495px;
    margin-bottom: 10px;
    max-height: 300px;
    overflow-y: auto;
    text-overflow: ellipsis;
    max-height: 253px;
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.07);
    margin-left: 120px;
  }
  .chevron {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 25px;
    right: 20px;
    cursor: pointer;
  }
  .sectionCourseName {
    border-top: 1px solid rgba(35, 35, 35, 0.2);
    margin: 0 20px;
    box-sizing: border-box;
    text-align: left;
  }
  .textCourseName {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    margin: 0;
    padding: 0;
    margin-top: 30px;
  }
  .divInput {
    display: flex;
    margin-top: 22px;
    margin-bottom: 0px;
    position: relative;
  }
  .divHint {
    box-sizing: border-box;
    padding: 24px 20px;
    background: #f7f7f7;
    border: 1px solid rgba(35, 35, 35, 0.2);
    border-radius: 6px 0px 0px 6px;
    height: 70px;
    width: 110px;
  }
  .input {
    box-sizing: border-box;
    width: 633px;
    height: 70px;
    border-color: rgba(35, 35, 35, 0.2);
    border-radius: 0px 6px 6px 0px;
    border: 1px rgba(35, 35, 35, 0.2) solid;
    border-left: none;
    opacity: 0.5;
    padding: 24px;
  }
  .textHint {
    padding: 0;
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
  }

  .hintImgText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin-left: 8px;
    text-align: left;
    margin-top: 0;
  }
  .hintImg {
    margin-top: -15px;
    margin-left: 20px;
  }
}

/* /////////////////////////////////////////////////////////////////////////////////////////// */

@media screen and (max-width: 767px) {
  .Syllabus {
    width: 290px;
    height: auto;
  }
  .title {
    margin-top: 28px;
    font-size: 24px;
    line-height: 27px;
    margin-bottom: 16px;
  }
  .hint {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 16px;
    width: 290px;
  }
  .steps {
    width: 290px;
    margin-bottom: 20px;
  }
  .stepsHeader {
    height: 54px;
  }
  .stepsNumber {
    width: 26px;
    height: 26px;
    margin-right: 8px;
    margin-left: 10px;
  }
  .stepsNumberText {
    font-size: 14px;
    line-height: 17px;
    top: -10px;
  }
  .headerTitle {
    font-size: 14px;
    line-height: 17px;
  }
  .arrow {
    position: absolute;
    right: 10px;
    width: 20px;
    height: 20px;
  }

  .toggleDiv {
    display: block;
    margin-left: 10px;
    align-items: center;
    margin-top: 10px;
  }
  .toggleDivType {
    display: block;
    margin-left: 10px;
    margin-top: -3px;
    margin-bottom: 14px;
  }
  .toggleFlex {
    display: flex;
  }
  .img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  .toggleText {
    font-size: 16px;
    line-height: 19px;
    margin-right: 12px;
    margin: 0;
  }

  .linkDiv {
    display: flex;
    margin-left: 10px;
    margin-top: 0px;
  }
  .linksImg {
    margin-right: 8px;
    width: 16px;
    height: 16px;
    margin-top: 13px;
  }
  .linkText {
    font-size: 12px;
    line-height: 15px;
  }

  .fileImg {
    width: 34px;
    height: 34px;
    margin-top: 0px;
  }
  .fileInputDiv {
    display: block;
  }
  input[type='file'] {
    display: none;
  }
  .customfilButton {
    width: 270px;
    height: 40px;
    background: #3852de;
    border-radius: 8px;
    position: relative;
    margin-bottom: 22px;
    border: none;
    cursor: pointer;
    z-index: -1;
    padding: 0;
  }
  .customfilText {
    color: rgb(255, 255, 255);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    border: none;
    position: relative;
    top: -3px;
    cursor: pointer;
    z-index: -1;
  }
  .customfileupload {
    padding: 6px 12px;
    cursor: pointer;
    border: none;
    position: relative;
    display: block;
    width: 270px;
    height: 40px;
    margin: 0 auto;
    margin-bottom: 22px;
    padding: 0;
    margin-top: 8px;
  }

  .alarmHintDiv {
    margin-top: -5px;
    width: 290px;
    position: relative;
    display: block;
  }
  .alarmHintImg {
    width: 30px;
    height: 30px;
    margin-right: 14px;
    position: relative;
    top: 0px;
    left: -120px;
    margin-left: 0;
    margin-bottom: 5px;
  }
  .alarmHintText {
    font-size: 14px;
    line-height: 17px;
  }
  .buttonDiv {
    margin-top: 4px;
    width: 290px;
    margin-bottom: 0px;
    display: block;
  }
  .buttonUpload {
    width: 290px;
    height: 40px;
  }
  .buttonUploadDis {
    width: 290px;
    height: 40px;
  }
  .textUpload {
    font-size: 14px;
    line-height: 17px;
    top: -6px;
  }

  .buttonTrialUpload {
    width: 290px;
    height: 40px;
    margin-top: 6px;
    margin-bottom: 100px;
  }
  .buttonTrialUploadDis {
    width: 290px;
    height: 40px;
    margin-top: 6px;
    margin-bottom: 100px;
  }
  .textTrialUpload {
    font-size: 14px;
    line-height: 17px;
    top: -6px;
  }
  .radioDiv {
    margin-top: 5px;
    margin-bottom: 10px;
    margin-left: -10px;
  }

  .radiolabel {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
  .checkboxAct {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
  .radioinputDiv {
    display: flex;
    flex-direction: row;
    margin-left: 11px;
    margin-bottom: 0px;
    align-items: center;
    height: 24px;
  }

  .listSearch {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-align: left;
    padding: 4px 5px;
    margin-top: 5px;

    max-width: 255px;
    margin-bottom: 5px;
    max-height: 300px;
    overflow-y: auto;
    text-overflow: ellipsis;
    max-height: 253px;
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.07);
    margin-left: 10px;
  }
  .chevron {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 20px;
    right: 10px;
    cursor: pointer;
  }
  .sectionCourseName {
    border-top: 1px solid rgba(35, 35, 35, 0.2);
    margin: 0 10px;
    box-sizing: border-box;
    text-align: left;
  }
  .textCourseName {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    margin: 0;
    padding: 0;
    margin-top: 15px;
  }
  .divInput {
    display: flex;
    margin-top: 12px;
    margin-bottom: 0px;
    position: relative;
  }
  .divHint {
    box-sizing: border-box;
    padding: 24px 20px;
    background: #f7f7f7;
    border: 1px solid rgba(35, 35, 35, 0.2);
    border-radius: 6px 0px 0px 6px;
    height: 40px;
    width: 50px;
  }
  .input {
    box-sizing: border-box;
    width: 633px;
    height: 50px;
    border-color: rgba(35, 35, 35, 0.2);
    border-radius: 0px 6px 6px 0px;
    border: 1px rgba(35, 35, 35, 0.2) solid;
    border-left: none;
    opacity: 0.5;
    padding: 8px;
    font-size: 11px;
    line-height: 2px;
  }
  .textHint {
    padding: 0;
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 2px;
    margin-left: -10px;
    padding-left: 0;
    text-align: left;
  }

  .hintImgText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    margin-left: 8px;
    text-align: left;
    margin-top: 0;
  }
  .hintImg {
    margin-top: -12px;
    margin-left: 20px;
    width: 13px;
    height: 13px;
  }
}
