.mainPage {
  padding-top: 0px;
  transition: 0.7s;
  position: absolute;
  min-height: calc(100vh - 312px);
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.img {
  margin-left: auto;
  margin-right: auto;
  margin-top: 147px;
  margin-bottom: 0;
}
.title {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 67px;
  margin-top: 31px;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
}

.secondTitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  margin-top: 24px;
  margin-bottom: 0;
  width: 600px;
  margin-right: auto;
  margin-left: auto;
}

.button {
  margin-top: 40px;
  width: 256px;
  height: 73px;
  background: #3852de;
  border-radius: 8px;
  position: relative;
  margin-bottom: 200px;
  cursor: pointer;
  border: none;
  box-sizing: border-box;
  color: #ffffff;
  border: #3852de 1px solid;
}

.buttonText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  position: relative;
  top: -4px;
  transition: 0.2s;
  box-sizing: border-box;
}

.button:hover{
  background-color: #2B46D4;
}

@media screen and (max-width: 1925px) {
  .img {
    margin-top: 120px;
    margin-bottom: 0;
  }
  .button {
    margin-top: 40px;
    width: 206px;
    height: 63px;
  }

  .buttonText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    position: relative;
    top: -4px;
    transition: 0.2s;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1023px) {
  .img {
    width: 160px;
  }

  .title {
    font-size: 20px;
    line-height: 24px;
    margin-top: 10px;
    margin-bottom: 0;
  }

  .secondTitle {
    font-size: 12px;
    line-height: 14px;
    margin-top: 14px;

    width: auto;
  }
  .button {
    margin-top: 20px;
    width: 146px;
    height: 43px;
  }

  .buttonText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    position: relative;
    top: -3px;
    transition: 0.2s;
    box-sizing: border-box;
  }
}
