.Student{
    text-align: left;
    width: 1530px;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 156px;
    }
    .title{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 44px;
        line-height: 53px;
        margin: 0;
    }
    .mainText{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 30px;
        margin: 40px 0 30px;
        width: 880px;
        opacity: 0.8;
    }
    .buttonActive{
        max-width: 173px;
        height: 66px;
        border-radius: 14px;
        font-weight: 500;
        font-size: 20px;
        box-sizing: border-box;
        padding: 20px 28px 22px;
        cursor: auto;
    }
    .buttonActive:hover{
        background: #3852DE;
    }
    .button{
        max-width: 173px;
        height: 66px;
        border-radius: 14px;
        font-weight: 500;
        font-size: 20px;
        background-color: #fff;
        border: 1px solid rgba(35, 35, 35, 0.2);
        color: black;
        box-sizing: border-box;
        padding: 20px 28px 22px;
    }
    .button:hover{
        opacity: 0.8;
        background-color: #fff;
    }
    .buttonsBlock{
        width: 824px;
        gap: 14px;
        display: flex;
    }
    .selectedSection{
        background: rgba(236, 239, 255, 0.4);
        border-radius: 20px;
        width: 1530px;
        height: 484px;
        margin-top: 50px;
        position: relative;
        display: flex;
        align-items: center;
    }
    .content{
        display: flex;
        box-sizing: border-box;
        padding: 0 60px;
    }
    .leftSide{
        display: block;
    }
    .titleContent{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 44px;
        margin: 0;
        margin-bottom: 26px;
    }
    .textContent{
        width: 506px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 30px;
        margin: 0;
        margin-top: 30px;
    }
    .buttonTry{
        width: 171px;
        height: 71px;
        background: #3852DE;
        border-radius: 20px;
        font-size: 22px;
        margin-right: 30px;
        font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    }
    .buttons{
        margin-top: 40px;
        display: flex;
        align-items: center;
    }
    .buttonContact{
        width: 145px;
        height: 27px;
        color: #3852DE;
        font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    cursor: pointer;
    }
    .buttonContact:hover{
        opacity: 0.8;
        width: 155px;
        .arrowShort{
            margin-left: 23px;
        }
    }
    .arrowShort{
        margin-left: 16px;
    }
    .screenTwo{
        position: absolute;
        top: 45px;
        right: 141px;
        width: 533.59px;
        height: 384px;
    }
   
    .link{
        text-decoration: none;
    }


    /* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1919px) {
    .Student{
        text-align: left;
        width: 1230px;
        display: flex;
        flex-direction: column;
        margin: auto;
        margin-top: 156px;
        }
        .title{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 40px;
            line-height: 48px;
            margin: 0;
        }
        .mainText{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            margin: 40px 0 30px;
            width: 666px;
        }
        .buttonActive{
            max-width: 151px;
            height: 56px;
            border-radius: 14px;
            font-size: 18px;
            padding: 16px 23px 18px;
        }
        .button{
            max-width: 151px;
            height: 56px;
            border-radius: 14px;
            font-size: 18px;
            background-color: #fff;
            border: 1px solid rgba(35, 35, 35, 0.2);
            color: black;
            padding: 16px 23px 18px;
        }
        .button:hover{
            opacity: 0.8;
            background-color: #fff;
        }
        .buttonsBlock{
            width: 732px;
            gap: 14px;
            display: flex;
        }
        .selectedSection{
            background: rgba(236, 239, 255, 0.4);
            border-radius: 20px;
            width: 1230px;
            height: 450px;
            margin-top: 50px;
            position: relative;
        }
        .content{
            display: flex;
            box-sizing: border-box;
            padding: 60px;
        }
        .leftSide{
            display: block;
        }
        .titleContent{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 39px;
            margin: 0;
            margin-bottom: 40px;
        }
        .textContent{
            width: 506px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 28px;
            margin: 0;
            margin-top: 30px;
        }
        .buttonTry{
            width: 151px;
            height: 65px;
            background: #3852DE;
            border-radius: 20px;
            font-size: 22px;
            margin-right: 30px;
        }
        .buttons{
            margin-top: 40px;
            display: flex;
            align-items: center;
        }
        .buttonContact{
            width: 145px;
            height: 27px;
            color: #3852DE;
            font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 27px;
        }
        .arrowShort{
            margin-left: 16px;
        }
        .screenTwo{
            position: absolute;
            top: 45px;
            right: 67px;
        }
        .link{
            text-decoration: none;
        }

}


@media screen and (max-width: 1439px) {
.Student{
    width: 1180px;
    margin-top: 120px;
    }
.title{
    font-size: 32px;
    line-height: 39px;
}
.mainText{
    font-size: 18px;
    line-height: 130%;
    margin: 40px 0 30px;
    width: 666px;
}
.buttonActive{
    max-width: 151px;
    height: 56px;
    font-size: 18px;
}
.button{
    max-width: 151px;
    height: 56px;
    font-size: 18px;

}
.buttonsBlock{
    width: 732px;
    gap: 14px;
    display: flex;
}
.selectedSection{
    width: 1180px;
    height: 360px;
    margin-top: 48px;
}
.content{
    padding: 68px 60px;
}
.titleContent{
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 24px;
}
.textContent{
    font-size: 18px;
    line-height: 120%;
    margin-top: 30px;
    width: 420px;
}
.buttonTry{
    width: 129px;
    height: 58px;
    border-radius: 20px;
    font-size: 20px;
    line-height: 24px;
    margin-right: 30px;
}
.buttons{
    margin-top: 40px;
}
.buttonContact{
    width: 147px;
    height: 24px;
    font-size: 20px;
    line-height: 24px;
}
.arrowShort{
    margin-left: 16px;
    width: 14px;
    height: 16px;
    position: relative;
    top: 1px;
}
.screenTwo{
    width: 419.7px;
    height: 302.04px;
    top: 25px;
    right: 85px;
}
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1279px) {
.Student{
    width: 916px;
    margin-top: 100px;
    }
.title{
    font-size: 26px;
    line-height: 31px;
}
.mainText{
    font-size: 16px;
    line-height: 130%;
    margin: 36px 0 30px;
    width: 666px;
}
.buttonActive{
    max-width: 134px;
    height: 49px;
    font-size: 16px;
    padding: 14px 20px 16px;
}
.button{
    max-width: 134px;
    height: 49px;
    font-size: 16px;
    padding: 14px 20px 16px;
}
.buttonsBlock{
    width: 732px;
    gap: 10px;
    display: flex;
}
.selectedSection{
    width: 916px;
    height: 331px;
    margin-top: 24px;
}
.content{
    padding: 60px 40px;
}
.titleContent{
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 20px;
}
.textContent{
    font-size: 16px;
    line-height: 130%;
    margin-top: 20px;
    width: 330px;
}
.buttonTry{
    width: 116px;
    height: 54px;
    border-radius: 20px;
    font-size: 18px;
    line-height: 22px;
    margin-right: 14px;
}
.buttons{
    margin-top: 38px;
}
.buttonContact{
    width: 127px;
    height: 20px;
    font-size: 18px;
    line-height: 22px;
}
.buttonContact:hover{
    opacity: 0.8;
    width: 137px;
    .arrowShort{
        margin-left: 23px;
    }
}
.arrowShort{
    margin-left: 16px;
    width: 14px;
    height: 16px;
    position: relative;
    top: 1px;
}
.screenTwo{
    width: 376.63px;
    height: 271.04px;
    top: 29px;
    right: 57px;
}
}
/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 1023px) {
.Student{
    width: 688px;
    margin-top: 80px;
    }
.title{
    font-size: 22px;
    line-height: 27px
}
.mainText{
    font-size: 14px;
    line-height: 130%;
    margin: 30px 0 30px;
    width: 666px;
}
.buttonActive{
    max-width: 114px;
    height: 43px;
    font-size: 14px;
    padding: 12px 16px 14px;
}
.button{
    max-width: 114px;
    height: 43px;
    font-size: 14px;
    padding: 12px 16px 14px;
}
.buttonsBlock{
    width: 688px;
    gap: 12px;
    display: flex;
}
.selectedSection{
    width: 688px;
    height: 276px;
    margin-top: 18px;
}
.content{
    padding: 56px 30px;
}
.titleContent{
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 16px;
}
.textContent{
    font-size: 14px;
    line-height: 130%;
    margin-top: 16px;
    width: 300px;
}
.buttonTry{
    width: 94px;
    height: 47px;
    border-radius: 16px;
    font-size: 16px;
    line-height: 19px;
    margin-right: 14px;
}
.buttons{
    margin-top: 28px;
}
.buttonContact{
    width: 117px;
    height: 20px;
    font-size: 18px;
    line-height: 22px;
}
.buttonContact:hover{
    opacity: 0.8;
    width: 127px;
    .arrowShort{
        margin-left: 20px;
    }
}
.arrowShort{
    margin-left: 10px;
    width: 13px;
    height: 14px;
    position: relative;
    top: 1px;
}
.screenTwo{
    width: 312px;
    height: 224.53px;
    top: 29px;
    right: 15px;
}
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 767px) {
.Student{
    width: 290px;
    margin-top: 60px;
    }
.title{
    font-size: 20px;
    line-height: 24px;
}
.mainText{
    font-size: 14px;
    line-height: 130%;
    margin: 20px 0 26px;
    width: 290px;
}
.buttonActive{
    max-width: 114px;
    height: 43px;
    font-size: 14px;
}
.button{
    max-width: 114px;
    height: 43px;
    font-size: 14px;

}
.buttonsBlock{
    width: 290px;
    gap: 8px;
    display: flex;
    overflow: scroll;
}
.buttonsBlock::-webkit-scrollbar{
    display: none;
}
.selectedSection{
    width: 290px;
    margin-top: 20px;
    display: flex;
    align-items:flex-start;
    height: auto;
}
.content{
    padding: 30px 20px;
    display: block;

}
.titleContent{
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 16px;
}
.textContent{
    font-size: 14px;
    line-height: 130%;
    margin-top: 16px;
    width: 250px;
}
.buttonTry{
    width: 94px;
    height: 47px;
    border-radius: 16px;
    font-size: 16px;
    line-height: 19px;
    margin-right: 14px;
}
.buttons{
    margin-top: 28px;
}
.buttonContact{
    width: 107px;
    height: 20px;
    font-size: 16px;
    line-height: 19px;
}
.buttonContact:hover{
    opacity: 0.8;
    width: 117px;
    .arrowShort{
        margin-left: 20px;
    }
}
.arrowShort{
    margin-left: 10px;
    width: 13px;
    height: 14px;
    position: relative;
    top: 1px;
}
.screenTwo{
    width: 261px;
    height: 187.83px;
    top: 25px;
    right: 15px;
    position: relative;
    margin-bottom: 15px;
}
}