.Alert {
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 300;
    top: 0px;
    left: 0;
    display: flex;
    transition: 0.7s;
    visibility: visible;
  }
  .AlertHidden {
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 200;
    top: 0px;
    transition: 0.1s;
    visibility: hidden;
  }
  
  .popup {
    position: fixed;
    width: 750px;
    height: 415px;
    background: #ffffff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.1s;
    transform: translate(-50%, -50%) scale(1);
    padding: 58px 104px;
    box-sizing: border-box;
  }
  .popupHidden {
    position: fixed;
    width: 750px;
    height: 415px;
    background: #ffffff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.1s;
    transform: translate(-50%, -50%) scale(0);
    padding: 58px 104px;
    box-sizing: border-box;
  }
  .img {
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
  }
  .img:hover{
    opacity: 0.7;
  }
  .title {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 45px;
    margin: 0;
    margin-top: 50px;
  }
  .mainSection {
    display: flex;
    text-align: left;
    justify-content: center;
  }
  .label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }
  .input {
    align-items: center;
    padding: 15px 50px 15px 20px;
    box-sizing: border-box;
    width: 542px;
    height: 50px;
    border: 1px solid rgba(35, 35, 35, 0.2);
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
  .textarea {
    border: 1px solid rgba(35, 35, 35, 0.2);
    border-radius: 8px;
    align-items: flex-start;
    padding: 15px 50px 15px 20px;
    box-sizing: border-box;
    width: 542px;
    min-height: 127px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    resize: none;
  }
  .button {
    background: #3852de;
    border-radius: 8px;
    width: 113.5px;
    height: 54px;
    align-items: center;
    padding: 16px 29px;
    box-sizing: border-box;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
    margin: 50px 12px 0;
    border: none;
    cursor: pointer;
  }
  .button:hover{
    background-color: #2B46D4;
  }
  .button:last-of-type {
    background: #ffffff;
    color: #3852de;
    border: 1px solid #3852de;
    border-radius: 8px;
  }
  @media screen and (max-width: 1279px) {
    .popup {
      width: 599px;
      height: 315px;
      padding: 52px 63px;
    }
    .popupHidden {
      width: 599px;
      height: 315px;
      padding: 52px 63px;
    }
    .img {
      right: 20px;
      top: 20px;
    }
    .title {
      font-size: 26px;
      line-height: 28px;
      margin-bottom: 0;
    }
  
    .label {
      font-size: 16px;
      line-height: 19px;
    }
    .input {
      padding: 14px 50px 14px 20px;
      width: 473px;
      height: 45px;
      font-size: 14px;
      line-height: 17px;
    }
    .textarea {
      width: 473px;
      min-height: 108px;
      padding: 15px 50px 15px 20px;
      font-size: 14px;
      line-height: 17px;
    }
    .button {
      width: 90px;
      height: 52px;
      padding: 15px 29px;
      font-size: 18px;
      line-height: 22px;
      color: #ffffff;
      margin: 30px 10px 0;
    }
  }
  @media screen and (max-width: 1023px) {
    .popup {
      width: 573px;
      height: 300px;
      padding: 52px 63px;
    }
    .popupHidden {
      width: 573px;
      height: 300px;
      padding: 52px 63px;
    }
    .img {
      right: 20px;
      top: 20px;
      width: 26px;
      height: 26px;
    }
    .title {
      font-size: 24px;
      line-height: 27px;
    }
  
    .label {
      font-size: 14px;
      line-height: 17px;
    }
    .input {
      padding: 14px 50px 14px 20px;
      width: 432px;
      height: 45px;
      font-size: 14px;
      line-height: 17px;
    }
    .textarea {
      width: 432px;
      min-height: 108px;
      padding: 15px 50px 15px 20px;
      font-size: 14px;
      line-height: 17px;
    }
    .button {
      width: 90px;
      height: 47px;
      padding: 14px 29px;
      font-size: 16px;
      line-height: 19px;
      margin: 18px 10px 0;
    }
  }
  @media screen and (max-width: 767px) {
    .popup {
      width: 290px;
      height: 260px;
      padding: 28px 15px;
    }
    .popupHidden {
      width: 290px;
      height: 361px;
      padding: 28px 15px;
    }
    .img {
      right: 10px;
      top: 10px;
      width: 26px;
      height: 26px;
    }
    .title {
      font-size: 20px;
      line-height: 22px;
      margin-top: 30px;
    }
  
    .label {
      font-size: 14px;
      line-height: 17px;
    }
    .input {
      padding: 12.5px 20px 12.5px 20px;
      width: 260px;
      height: 40px;
      font-size: 12px;
      line-height: 15px;
    }
    .textarea {
      width: 260px;
      min-height: 108px;
      padding: 12.5px 20px 12.5px 20px;
      font-size: 12px;
      line-height: 15px;
    }
    .button {
      width: 260px;
      height: 39px;
      padding: 11px 29px;
      font-size: 14px;
      line-height: 17px;
      margin: 14px auto 0;
    }
    .mainSection {
      flex-direction: column;
    }
  }
  