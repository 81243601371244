.backSection {
  display: flex;
  align-items: center;
  width: 80px;
  position: relative;
  z-index: 4;
}
.backSection:hover{
  .backText{
    color: #4B63E1;
  }
}
.backImg {
  margin: 0;
  margin-right: 4px;
  cursor: pointer;
}
.backText {
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  cursor: pointer;
}
@media screen and (max-width: 1919px) {
  .backText {
    font-size: 20px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1439px) {
  .backText {
    font-size: 20px;
    line-height: 24px;
  }
  .backSection {
    width: 75px;
  }
}
@media screen and (max-width: 1279px) {
  .backText {
    font-size: 20px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1023px) {
  .backText {
    font-size: 18px;
    line-height: 22px;
  }
}
@media screen and (max-width: 767px) {
  .backSection {
    width: 67px;
  }
  .backText {
    font-size: 16px;
    line-height: 19px;
  }
}
