.grades {
  display: block;
  width: 1140px;
  margin-bottom: 180px;
}
.titleSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 67px;
  margin-top: 50px;
  margin-bottom: 40px;
}
.gpaSection {
  background-color: #fecd1a;
  display: flex;
  width: 164px;
  border-radius: 15px;
  margin-left: 30px;
  height: 42px;
  position: relative;
  margin-top: 20px;
}
.gpaTitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  position: relative;
  bottom: 8px;
  left: 14px;
}
.gpaValue {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  position: relative;
  bottom: 14px;
  left: 19px;
}
.inputArea {
  display: flex;
  width: 100%;
}
.borderFilter {
  width: 100%;
  height: 53px;
  border: 1px solid rgba(35, 35, 35, 0.2);
  border-radius: 8px;
  margin-right: 14px;
  display: flex;
  margin: auto;
  margin-left: 0;
}
.borderFilterDiv {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}
.img {
  margin: 14.5px 8px 13.5px 23px;
  width: 24px;
  height: 24px;
}
.imgSelect {
  width: 24px;
  height: 24px;
}
.input {
  border: none;
  width: 100%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  line-height: 17px;
  margin: auto;
  margin-left: 0;
  color: black;
}
input:focus {
  outline: none;
}
.input:hover::placeholder{
  color: #B3B3B3;
}
.filterSemesters {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  border: none;
  margin: auto 0px;
  text-align: left;
  width: 137px;
}
.filterSemestersDiv {
  border-left: 1px solid rgba(35, 35, 35, 0.2);
  border-right: 1px solid rgba(35, 35, 35, 0.2);
  height: 100%;
  display: flex;
  position: relative;
  cursor: pointer;
  padding-left: 19px;
  padding-right: 10px;
}
.filterGrades {
  border: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  position: relative;
  margin: auto 0px;
  text-align: left;
  width: 100px;
}

.imgSelect {
  width: 24px;
  height: 24px;
  margin: auto 0;
}
.imgBut {
  background-color: #3852de;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: rgb(255, 255, 255);
  border-radius: 8px;
  border: none;
  width: 150px;
  height: 53px;
  cursor: pointer;
  transition: 0.5s;
  padding-bottom: 2px;
}
.imgBut:hover {
  box-sizing: border-box;
  color: #3852de;
  background-color: #fff;
  border: 1px solid #3852de;
  transition: 0.5s;
}
.titleSemester {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 49px;
  text-align: left;
  margin-top: 40px;
  margin-bottom: 0;
}
.optionSemestersDiv {
  position: absolute;
  width: 186px;
  height: auto;
  background-color: rgb(255, 255, 255);
  top: 55px;
  left: 2px;
  transition: 0.7s;
  z-index: 3;
  box-shadow: 2px 4px 23px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.optionGradesDiv {
  position: absolute;
  width: 121px;
  height: auto;
  background-color: rgb(255, 255, 255);
  top: 55px;
  left: -17px;
  transition: 0.7s;
  z-index: 3;
  box-shadow: 2px 4px 23px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.optionGradesOver {
  position: absolute;
  width: 500px;
  height: 500px;
  left: -320px;
  top: -50px;
  z-index: -2;
  opacity: 0;
}
.filterGradesDiv {
  height: 100%;
  width: 100px;
  display: flex;
  position: relative;
  cursor: pointer;
  margin-left: 19px;
  margin-right: 10px;
  align-items: center;
}
.optionRow {
  display: flex;
  height: 27px;
  padding-left: 5px;
  align-items: center;
}
.optionRow:hover {
  cursor: pointer;
  color: #434343;
}
.optionRowG {
  display: flex;
  height: 27px;
  padding-left: 20px;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.optionRowG:hover {
  cursor: pointer;
  color: #434343;
}
.optionSemesters {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: auto 15px;
  text-align: left;
}
.optionSemestersOver {
  position: absolute;
  width: 500px;
  height: 500px;
  left: -155px;
  top: -50px;
  z-index: -2;
  opacity: 0;
}
.trSemester {
  background-color: #ecefff;
  border-radius: 8px;
  width: 1140px;
  height: 59px;
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.courseTh {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
  width: 780px;
  text-align: left;
  padding-left: 10px;
}
.sourceTh {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
  width: 250px;
  text-align: left;
}
.gradeTh {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
  width: 100px;
  text-align: center;
}
.list {
  height: auto;
  border-bottom: 1px solid rgba(35, 35, 35, 0.2);
  width: 1140px;
  display: flex;
}
.list:last-of-type {
  border-bottom: none;
}
.course {
  width: 730px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  margin-left: 10px;
  align-items: center;
  margin-right: 50px;
  margin-top: 48px;
  margin-bottom: 48px;
}
.source {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  width: 260px;
  text-align: left;
  margin-top: 48px;
  margin-bottom: 48px;
}
.grade {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 24px;
  width: 50px;
  margin-top: 48px;
  margin-bottom: 48px;
}
.gradeA {
  margin: 0;
  color: #0ddc65;
}
.gradeC {
  margin: 0;
  color: #fecd1a;
}
.gradeD {
  margin: 0;
  color: #ff613f;
}
.gradeAny {
  margin: 0;
  padding-top: 2px;
  font-size: 18px;
  line-height: 18px;
}

.accuracy {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 24px;
  width: 50px;
  margin-top: 10px;
}
.worning {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-top: 150px;
  margin-bottom: 450px;
}
.titleSemesterDegre {
  width: auto;
  padding: 4px;
  padding-left: 14px;
  padding-right: 14px;
  height: 36px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  padding-bottom: 7px;
  color: #3852de;
  background: rgba(56, 82, 222, 0.11);
  border-radius: 42px;
  position: relative;
  top: -6px;
  left: 20px;
}

.searchSection {
  position: relative;
}
.buttonToggle {
  border: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 0;
  top: 115px;
  /* display: none; */
}

.switch {
  position: relative;
  top: 0px;
  left: 4px;
  display: inline-block;
  width: 38px;
  height: 24px;
  margin-right: 15px;
  margin-left: 10px;
}
.switch input {
  display: none;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(255, 255, 255);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: #3852de 2px solid;
}
.slider:before {
  position: absolute;
  content: '';
  height: 15px;
  width: 15px;
  left: 3px;
  top: 2px;
  bottom: 4px;
  background-color: #3852de;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ffffff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ffffff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.hint {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 10px;
  background-color: rgb(255, 255, 255);
  color: #000000;
  width: 100px;
  height: 20px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 22px;
  opacity: 0.5;
}
.gradeMin{
  transform: scale(0.7);
}
/* ///////////////////////////////////////////////////////////////////////////////////////////////// */

@media screen and (max-width: 1919px) {
  .grades {
    display: block;
    width: 915px;
    margin-bottom: 180px;
  }
  .titleSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .title {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 49px;
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .gpaSection {
    background-color: #fecd1a;
    display: flex;
    width: 129px;
    border-radius: 15px;
    margin-left: 30px;
    height: 36px;
    position: relative;
  }
  .gpaTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    position: relative;
    bottom: 4px;
    left: 10px;
  }
  .gpaValue {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    position: relative;
    bottom: 11px;
    left: 15px;
  }
  .inputArea {
    display: flex;
    width: 100%;
  }
  .borderFilter {
    width: 100%;
    height: 44px;
    border: 1px solid rgba(35, 35, 35, 0.2);
    border-radius: 8px;
    margin-right: 6px;
    display: flex;
    margin: auto;
    margin-left: 0;
  }
  .img {
    margin: 10.5px 8px 13.5px 23px;
  }
  .imgSelect {
    width: 24px;
    height: 24px;
  }
  .input {
    border: none;
    width: 100%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin: auto;
    margin-left: 0;
  }
  .filterSemesters {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    border: none;
    margin: auto 0px;
    text-align: left;
    width: 120px;
  }
  .filterSemestersDiv {
    border-left: 1px solid rgba(35, 35, 35, 0.2);
    border-right: 1px solid rgba(35, 35, 35, 0.2);
    height: 100%;
    display: flex;
    position: relative;
    cursor: pointer;
  }
  .filterGrades {
    border: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    position: relative;
    margin: auto 0px;
    text-align: left;
    width: 100px;
  }

  .imgSelect {
    width: 24px;
    height: 24px;
    margin: auto 0;
  }
  .imgBut {
    background-color: #3852de;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: rgb(255, 255, 255);
    border-radius: 8px;
    border: none;
    width: 111px;
    height: 45px;
    cursor: pointer;
    transition: 0.5s;
  }
  .imgBut:hover {
    box-sizing: border-box;
    color: #3852de;
    background-color: #fff;
    border: 1px solid #3852de;
    transition: 0.5s;
  }
  .titleSemester {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 38px;
    text-align: left;
    margin-top: 40px;
    margin-bottom: 0;
  }
  .optionSemestersDiv {
    position: absolute;
    width: 167px;
    height: auto;
    background-color: rgb(255, 255, 255);
    top: 50px;
    left: 2px;
    transition: 0.7s;
    z-index: 3;
    box-shadow: 2px 4px 23px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .optionGradesDiv {
    position: absolute;
    width: 122px;
    height: auto;
    background-color: rgb(255, 255, 255);
    top: 50px;
    left: -17px;
    transition: 0.7s;
    z-index: 3;
    box-shadow: 2px 4px 23px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .optionGradesOver {
    position: absolute;
    width: 500px;
    height: 500px;
    left: -365px;
    top: -50px;
    z-index: -2;
    opacity: 0;
  }
  .filterGradesDiv {
    height: 100%;
    width: 100px;
    display: flex;
    position: relative;
    cursor: pointer;
    align-items: center;
  }
  .optionRow {
    display: flex;
    height: 27px;
    padding-left: 5px;
    align-items: center;
  }
  .optionRowG {
    display: flex;
    height: 27px;
    padding-left: 20px;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
  .optionSemesters {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin: auto 15px;
    text-align: left;
  }
  .optionSemestersOver {
    position: absolute;
    width: 500px;
    height: 500px;
    left: -155px;
    top: -50px;
    z-index: -2;
    opacity: 0;
  }
  .trSemester {
    background-color: #ecefff;
    border-radius: 8px;
    width: 915px;
    height: 43px;
    display: flex;
    align-items: center;
    margin-top: 24px;
  }
  .courseTh {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    width: 600px;
    text-align: left;
    padding-left: 10px;
  }
  .sourceTh {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    width: 190px;
    text-align: left;
  }
  .gradeTh {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    width: 100px;
    text-align: center;
  }
  .list {
    border-bottom: 1px solid rgba(35, 35, 35, 0.2);
    width: 915px;
    display: flex;
  }
  .list:last-of-type {
    border-bottom: none;
  }
  .course {
    width: 550px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    margin-left: 10px;
    align-items: center;
    margin-right: 50px;
    margin-top: 37px;
    margin-bottom: 37px;
  }
  .source {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    width: 200px;
    text-align: left;
    margin-top: 37px;
    margin-bottom: 37px;
  }
  .grade {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 24px;
    width: 50px;
    margin-top: 37px;
    margin-bottom: 37px;
  }

  .accuracy {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 24px;
    width: 50px;
    margin-top: 10px;
  }
  .worning {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-top: 150px;
    margin-bottom: 450px;
  }

  .titleSemesterDegre {
    width: auto;
    padding: 4px;
    height: 34px;
    padding-left: 14px;
    padding-right: 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #3852de;
    background: rgba(56, 82, 222, 0.11);
    border-radius: 42px;
    position: relative;
    top: -4px;
    left: 20px;
    padding-bottom: 4px;
  }
  .buttonToggle {
    font-size: 16px;
    line-height: 19px;
    top: 95px;
  }
  .switch {
    top: -2px;
    left: 4px;
    width: 38px;
    height: 23px;
    margin-right: 15px;
    margin-left: 10px;
  }
}
/* ///////////////////////////////////////////////////////////// */
@media screen and (max-width: 1439px) {
  .grades {
    width: 880px;
  }
  .trSemester {
    width: 880px;
  }
  .course {
    width: 550px;
    margin-right: 38px;
  }
  .grade {
    width: 44px;
  }
  .list {
    width: 880px;
  }
}

@media screen and (max-width: 1279px) {
  .grades {
    width: 916px;
  }
  .grade {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    top: -4px;
    position: relative;
  }
  .course {
    width: 564px;
    font-size: 16px;
    line-height: 19px;
  }
  .source {
    font-size: 16px;
    line-height: 19px;
  }
  .trSemester {
    width: 916px;
  }
  .title {
    font-weight: 400;
    font-size: 40px;
    line-height: 45px;
    margin-top: 40px;
  }
  .titleSemester {
    font-weight: 400;
    font-size: 32px;
    line-height: 36px;
  }
  .titleSemesterDegre {
    height: 34px;
    padding-left: 14px;
    padding-right: 14px;
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 4px;
    top: -5px;
    left: 20px;
  }
  .list {
    width: 916px;
    align-items: flex-start;
  }
}

@media screen and (max-width: 1023px) {
  .grades {
    width: 689px;
  }
  .trSemester {
    width: 689px;
  }
  .gpaValue {
    font-size: 16px;
    line-height: 19px;
    top: -7px;
  }
  .gpaTitle {
    top: -5px;
    margin-left: 4px;
  }
  .course {
    width: 420px;
    margin-right: 18px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-top: 35px;
    margin-bottom: 35px;
  }
  .source {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    width: 175px;
    text-align: left;
    margin-top: 35px;
    margin-bottom: 35px;
  }
  .grade {
    width: 44px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    top: 0;
    margin-top: 35px;
    margin-bottom: 35px;
  }
  .list {
    width: 689px;
  }
  .borderFilter {
    width: 100%;
    height: 38px;
  }
  .imgBut {
    width: 101px;
    height: 40px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
  .img {
    margin: 8.5px 8px 9.5px 13px;
    width: 20px;
    height: 20px;
  }
  .input {
    /* width: 250px; */
    width: 100%;
  }
  .title {
    font-weight: 400;
    font-size: 32px;
    line-height: 36px;
    margin-top: 30px;
  }
  .titleSemester {
    font-weight: 400;
    font-size: 32px;
    line-height: 36px;
  }
  .titleSemesterDegre {
    height: 26px;
    font-weight: 500;
    font-size: 14px;
    padding-left: 14px;
    padding-right: 14px;
    line-height: 17px;
    padding-bottom: 4px;
    top: -4px;
    left: 20px;
  }

  .courseTh {
    width: 530px;
    font-size: 16px;
    line-height: 18px;
  }
  .sourceTh {
    font-size: 16px;
    line-height: 18px;
  }
  .gradeTh {
    font-size: 16px;
    line-height: 18px;
  }
  .buttonToggle {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    top: 95px;
  }
  .switch {
    top: 0px;
    left: 4px;
    width: 32px;
    height: 19px;
    margin-right: 15px;
    margin-left: 10px;
  }
  .slider:before {
    height: 12px;
    width: 12px;
    left: 1px;
    top: 1px;
    bottom: 4px;
  }

  .optionSemestersDiv {
    top: 40px;
  }
  .optionGradesDiv {
    top: 40px;
  }
}

/* ////////////////////////////////////////////////////////////// */

@media screen and (max-width: 767px) {
  .grades {
    width: 290px;
    margin-left: -15px;
    min-height: 730px;
    display: flex;
    margin: 0 auto 100px;
  }
  .title {
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
    margin-top: 28px;
    margin-bottom: 16px;
  }
  .gpaSection {
    width: 51px;
    height: 30px;
    margin-top: 12px;
  }
  .gpaTitle {
    display: none;
  }
  .gpaValue {
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
    bottom: 8px;
    left: 10px;
  }
  .inputArea {
    display: block;
    width: 100%;
    position: relative;
  }
  .borderFilter {
    width: 100%;
    height: 44px;
    border: 1px solid rgba(35, 35, 35, 0.2);
    margin-right: 0px;
    display: block;
    margin: auto;
    margin-left: 0;
  }

  .img {
    margin: 13.5px 8px 15.5px 13px;
    width: 20px;
    height: 20px;
  }
  .imgSelect {
    width: 20px;
    height: 20px;
  }
  .input {
    border: none;
    width: 100%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    margin: auto;
    margin-left: 0;
    margin-bottom: 17px;
  }
  .filterSemesters {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    border: none;
    margin: auto 0px;
    text-align: left;
    width: 120px;
    left: 0;
    position: relative;

    width: auto;
    margin-right: 5px;
  }
  .filterSemestersDiv {
    border-left: none;
    border-right: none;
    height: 100%;
    display: flex;
    position: relative;
    cursor: pointer;
    width: auto;
    left: -18px;
  }
  .filterGradesDiv {
    height: 100%;
    width: auto;
    display: flex;
    position: absolute;
    cursor: pointer;
    align-items: center;
    top: 50px;
    left: 150px;
  }
  .filterGrades {
    border: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    width: auto;
    margin-right: 5px;
  }

  .imgBut {
    background-color: #3852de;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgb(255, 255, 255);
    border-radius: 8px;
    border: none;
    width: 100%;
    height: 45px;
    cursor: pointer;
    transition: 0.5s;
    position: absolute;
    top: 100px;
    left: 0;
  }
  .titleSemester {
    font-family: 'Platform LC Web';
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    text-align: left;
    margin-top: 28px;
    margin-bottom: 0;
  }
  .masterSemester {
    margin-top: 28px;
  }
  .bachelorSemester {
    margin-top: 28px;
  }
  .postgraduateSemester {
    margin-top: 28px;
  }
  .optionSemestersDiv {
    position: absolute;
    width: 160px;
    height: auto;
    background-color: rgb(255, 255, 255);
    top: 40px;
    left: 2px;
    transition: 0.7s;
    z-index: 3;
    box-shadow: 2px 4px 23px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .optionGradesDiv {
    position: absolute;
    width: 107px;
    height: auto;
    background-color: rgb(255, 255, 255);
    top: 40px;
    left: -5px;
    transition: 0.7s;
    z-index: 3;
    box-shadow: 2px 4px 23px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .optionGradesOver {
    position: absolute;
    width: 260px;
    height: 600px;
    left: -115px;
    top: -150px;
    z-index: -2;
    opacity: 0;
  }

  .optionRow {
    display: flex;
    height: 27px;
    padding-left: 5px;
    align-items: center;
  }
  .optionRowG {
    display: flex;
    height: 27px;
    padding-left: 20px;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
  .optionSemesters {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin: auto 15px;
    text-align: left;
  }
  .optionSemestersOver {
    position: absolute;
    width: 280px;
    height: 600px;
    left: 0px;
    top: -150px;
    z-index: -2;
    opacity: 0;
  }
  .trSemester {
    display: none;
  }
  .list {
    height: auto;
    border-bottom: 1px solid rgba(35, 35, 35, 0.2);
    width: 280px;
    display: block;
    position: relative;
    margin-left: 0px;
  }
  .course {
    width: 212px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-right: 50px;
    margin-top: 12px;
    margin-left: 0;
    margin-bottom: 0;
  }
  .source {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    width: 200px;
    text-align: left;
    margin-top: 12px;
    margin-bottom: 0;
    color: #3852de;
  }
  .grade {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    width: 50px;
    margin-top: auto;
    position: absolute;
    right: 0;
    top: -14px;
    text-align: right;
    margin-top: 12px;
  }

  .accuracy {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 24px;
    width: 50px;
    margin-top: 10px;
  }
  .worning {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-top: 150px;
    margin-bottom: 150px;
  }

  .titleSemesterDegre {
    width: auto;
    padding: 4px;
    height: 26px;
    padding-left: 14px;
    padding-right: 14px;
    font-size: 14px;
    line-height: 17px;
    color: #3852de;
    background: rgba(56, 82, 222, 0.11);
    border-radius: 42px;
    position: relative;
    top: -1px;
    left: 10px;
    padding-bottom: 4px;
  }
  .searchSection {
    margin-bottom: 95px;
  }
  .buttonToggle {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    top: 110px;
    left: 0;
  }
  .switch {
    top: 3px;
    left: 4px;
    width: 20px;
    height: 13px;
    margin-right: 11px;
    margin-left: 4px;
  }
  .slider:before {
    height: 8px;
    width: 8px;
    left: 0px;
    top: 1px;
    bottom: 4px;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(8px);
    -ms-transform: translateX(8px);
    transform: translateX(8px);
  }
  .gradeAny {
    margin: 0;
    padding-top: 4px;
    font-size: 12px;
    line-height: 12px;
  }
}
