.main {
  text-align: left;
}
.title {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 38px;
  margin: 0;
}
.sausagSection {
  margin-top: 40px;
  display: flex;
}
.sausagPoint {
  background: #ecefff;
  border-radius: 99px;
  width: 44px;
  height: 44px;
  display: flex;
}
.sausagPointActiv {
  background: #3852de;
  border-radius: 99px;
  width: 44px;
  height: 44px;
  display: flex;
}
.sausagPointText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #3852de;
  margin: auto;
}
.sausagPointTextActiv {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  margin: auto;
}
.dataSection {
  display: flex;
  margin-top: 30px;
  gap: 30px;
  width: 940px;
  overflow: hidden;
}
.degree {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin: 0;
  margin-bottom: 8px;
  width: 360px;
}
.degreeActive {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin: 0;
  margin-bottom: 8px;
  width: 160px;
  color: #3852de;
}
.track {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #3852de;
  margin: 0;
  margin-bottom: 20px;
  width: 360px;
}
.year {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  opacity: 0.6;
  margin-bottom: 8px;
  width: 360px;
}
.date {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  margin-bottom: 0px;
  width: 360px;
}
.lineMin {
  display: none;
}
@media screen and (max-width: 1919px) {
  .title {
    font-size: 28px;
    line-height: 31px;
  }
  .sausagSection {
    margin-top: 30px;
  }
  .sausagPoint {
    width: 40px;
    height: 40px;
  }
  .sausagPointActiv {
    width: 40px;
    height: 40px;
  }
  .sausagPointText {
    font-size: 18px;
    line-height: 22px;
  }
  .sausagPointTextActiv {
    font-size: 18px;
    line-height: 22px;
  }
  .line {
    width: 275px;
  }
  .dataSection {
    margin-top: 24px;
    gap: 30px;
    max-width: 900px;
  }
  .degree {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 6px;
    width: 285px;
  }
  .degreeActive {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 6px;
    width: 160px;
  }
  .track {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 18px;
    width: 285px;
  }
  .year {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 6px;
    width: 285px;
  }
  .date {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 0px;
    width: 285px;
  }
}
@media screen and (max-width: 1023px) {
  .title {
    font-size: 24px;
    line-height: 27px;
  }
  .sausagSection {
    margin-top: 22px;
  }
  .sausagPoint {
    width: 36px;
    height: 36px;
  }
  .sausagPointActiv {
    width: 36px;
    height: 36px;
  }
  .sausagPointText {
    font-size: 16px;
    line-height: 19px;
  }
  .sausagPointTextActiv {
    font-size: 16px;
    line-height: 19px;
  }
  .line {
    width: 202px;
  }
  .dataSection {
    margin-top: 16px;
    gap: 20px;
    max-width: 600px;
  }
  .degree {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 6px;
    width: 216px;
  }
  .degreeActive {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 6px;
    width: 160px;
  }
  .track {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 18px;
    width: 216px;
  }
  .year {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 6px;
    width: 216px;
  }
  .date {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 0px;
    width: 216px;
  }
}

@media screen and (max-width: 767px) {
  .main {
    width: 290px;
  }
  .lineMin {
    display: flex;
    width: 1px;
    height: 59px;
    margin-left: 14px;
  }
  .line {
    display: none;
  }
  .display {
    display: flex;
  }
  .title {
    font-size: 20px;
    line-height: 22px;
    width: 290px;
  }
  .sausagSection {
    margin-top: 18px;
    flex-direction: column;
    width: 50px;
  }
  .sausagPoint {
    width: 30px;
    height: 30px;
  }
  .sausagPointActiv {
    width: 30px;
    height: 30px;
  }
  .sausagPointText {
    font-size: 14px;
    line-height: 17px;
  }
  .sausagPointTextActiv {
    font-size: 14px;
    line-height: 17px;
  }
  .dataSection {
    display: flex;
    margin-top: 20px;
    gap: 20px;
    width: 200px;
    overflow: hidden;
    flex-direction: column;
  }
  .firstLine {
    display: flex;
    margin-bottom: 8px;
  }
  .degree {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0px;
    width: auto;
  }
  .degreeActive {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0px;
    width: auto;
  }
  .track {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0px;
    width: 200px;
    margin-left: 10px;
  }
  .year {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 6px;
    width: 200px;
  }
  .date {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0px;
    width: 200px;
  }
}
