.ProjectFullPage {
  display: flex;
  flex-direction: column;

  gap: 16px;

  text-align: left;
  margin-bottom: 80px;

  .paramName {
    display: flex;
    width: auto;
    max-width: 188px;
    min-width: 188px;

    font-weight: 500;
    font-size: 16;
  }
.flex{
  display: flex;
  position: relative;
}
  .title {
    margin-top: 18px;
  }

  .table {
    box-shadow: none;
    border: none !important;
  }

  .tableHeader {
    background-color: #ecefff;
  }

  .centerCell {
    text-align: left;
  }
}
.fileDiv{
display: flex;
align-items: center;
cursor: pointer;
text-decoration: none;
}
.fileDiv:hover{
  .fileText{
  color: #4B63E1;
  }
  .icon{
    transform: scale(1.05);
  }
}
.icon{
  width: 38px;
  height: 38px;
  margin-right: 14px;
}

.fileText{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  color: black;
}
.display{
  display: flex;
}
.active{
    margin-left: 0px;
    margin-right: 9px;
    background: #0DDC65;
    width: 6px !important;
    height: 6px !important;
    border-radius: 50%;
    align-self: center;
    margin-top: 2px;
 
}

.archive{
    margin-left: 0px;
    margin-right: 9px;
    background: #FF522D;
    width: 6px;
    height: 6px;
    border-radius: 50%; 
    margin-top: 10px;
}
.status{
  margin-left: 4px;
}
.stackArea{
  max-width: 800px;
}
.link{
  text-decoration: none;
}
.text{
  color: black;
  text-decoration: none;
  cursor: auto;
}
.titleDescription{
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.textDescription{
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.infinity{
  opacity: 0.8;
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@media screen and (max-width: 1919px) {
  .ProjectFullPage {
    display: flex;
    flex-direction: column;
  
    gap: 16px;
  
    text-align: left;
    margin-bottom: 80px;
  
    .paramName {
      display: flex;
      width: auto;
      width: 188px;
  
      font-weight: 500;
      font-size: 16;
    }
  .flex{
    display: flex;
    position: relative;
  }
    .title {
      margin-top: 18px;
    }
  
    .table {
      box-shadow: none;
      border: none !important;
    }
  
    .tableHeader {
      background-color: #ecefff;
    }
  
    .centerCell {
      text-align: left;
    }
  }
  .fileDiv{
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  }
  .fileDiv:hover{
    .fileText{
    color: #4B63E1;
    }
    .icon{
      transform: scale(1.05);
    }
  }
  .icon{
    width: 38px;
    height: 38px;
    margin-right: 14px;
  }
  
  .fileText{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    margin: 0;
    color: black;
  }
  .display{
    display: flex;
  }
  .active{
      margin-left: 0px;
      margin-right: 9px;
      background: #0DDC65;
      width: 6px !important;
      height: 6px !important;
      border-radius: 50%;
      align-self: center;
      margin-top: 2px;
   
  }
  
  .archive{
      margin-left: 0px;
      margin-right: 9px;
      background: #FF522D;
      width: 6px;
      height: 6px;
      border-radius: 50%; 
      margin-top: 10px;
  }
  .status{
    margin-left: 4px;
  }
  .stackArea{
    max-width: 800px;
  }
  .link{
    text-decoration: none;
  }
  .text{
    color: black;
    text-decoration: none;
    cursor: auto;
  }
  .titleDescription{
    font-size: 20px;
  }
  .textDescription{
    font-size: 18px;
    margin-top: -1px !important;
  }
}


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1439px) {

}


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1279px) {

}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1023px) {
  .titleDescription{
    font-size: 18px !important;;
  }
  .textDescription{
    font-size: 16px !important;;
  }
  .tableHeader{
    display: block !important;
    background-color: white !important;
    width: 180px !important;
    margin-top: -15px !important;
  }
  .TableHead{
    position: relative;
    left: -15px !important;
  }
  .tableeBody{
    display: block !important;
    background-color: white !important;
    width: 190px !important;
    margin-top: -14px !important;
    border: none !important;
  }
  .tableeBodyBotton{
    display: block !important;
    background-color: white !important;
    width: 190px !important;
    margin-top: -6px !important;
    border: none !important;
  }
  .textBodyButton{
    display: block !important;
    background-color: white !important;
    width: 190px !important;
    margin-top: -10px !important;
    border: none !important;
  }
  .tableeBodySize{
    display: block !important;
    background-color: white !important;
    width: 190px !important;
    margin-top: -16px !important;
    border: none !important;
  }
  .textHeader{
    font-family: Inter;
    font-size: 16px !important;;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .textBody{
    font-family: Inter;
    font-size: 16px !important;;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .tableBody{
    display: block !important;
    position: absolute;
    top: 0;
    left: 70px;
    border: none !important;
  }
  .status{
    font-family: Inter;
    font-size: 16px !important;;
    font-style: normal;
    font-weight: 400;
    margin-left: 0px;
  }
  .table{
    position: relative;
    overflow: hidden;
  }
  .param {
    font-family: Inter;
    font-size: 14px !important;;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    -webkit-box-flex-wrap: wrap !important;
  }
  .paramTitle{
    font-family: Inter;
    font-size: 16px !important;;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .text{
    font-size: 16px;
  }
  .link{
    font-size: 16px;
  }
  .title{
    font-size: 18px;
  }
  .fileText{
    font-size: 18px;
  }
  .paramName{
    max-width: 140px !important;
    min-width: 140px !important;
  }
  .tableStatys{
    display: block !important;
    background-color: white !important;
    width: 190px !important;
    margin-top: -13px !important;
    border: none !important;
  }
  .tableeThesis{
    display: block !important;
    background-color: white !important;
    width: 190px !important;
    margin-top: -13px !important;
    border: none !important;
  }
  .texSupervisor{
    display: block !important;
    background-color: white !important;
    width: 190px !important;
    margin-top: -11px !important;
    border: none !important;
  }
  .tableDeadline{
    display: block !important;
    background-color: white !important;
    width: 190px !important;
    margin-top: -11px !important;
    border: none !important;
  }
  .tableLevel{
    display: block !important;
    background-color: white !important;
    width: 190px !important;
    margin-top: -12px !important;
    border: none !important;
  }
  .tableSize{
    display: block !important;
    background-color: white !important;
    width: 190px !important;
    margin-top: -14px !important;
    border: none !important;
  }
}



/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 767px) {
  .titleDescription{
    font-size: 16px !important;;
  }
  .tableBody{
    position: relative;

  }
  .textDescription{
    font-size: 14px !important;;
  }
  .tableStatys{
    width: 250px !important;
    margin-top: -13px !important;
    position: absolute;
    left: -153px;
    top: -329px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .tableeThesis{
    width: 250px !important;
    margin-top: -13px !important;
    position: absolute;
    left: -153px;
    top: -267px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .texSupervisor{
    width: 320px !important;
    margin-top: -13px !important;
    position: absolute;
    left: -155px;
    top: -205px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .tableDeadline{
    width: 280px !important;
    margin-top: -13px !important;
    position: absolute;
    left: -155px;
    top: -141px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .tableLevel{
    width: 280px !important;
    margin-top: -13px !important;
    position: absolute;
    left: -154px;
    top: -80px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .tableSize{
    width: 280px !important;
    margin-top: -13px !important;
    position: absolute;
    left: -155px;
    top: -16px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .table{
    position: relative;
    overflow: hidden;
  }
  .param {
    font-family: Inter;
    font-size: 14px !important;;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    -webkit-box-flex-wrap: wrap !important;
  }
  .paramTitle{
    font-family: Inter;
    font-size: 16px !important;;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .text{
    font-size: 14px !important;;
  }
  .link{
    font-size: 14px !important;;
  }
  .title{
    font-size: 18px !important;;
  }
  .fileText{
    font-size: 16px !important;;
  }
  .tableHeader{
    display: block !important;
    background-color: white !important;
    width: 180px !important;
    margin-top: -15px !important;
    margin-bottom: 20px;
  }
  .paramsFlexColumn{
    display: flex;
    flex-direction: column !important;
  }
  .status{
    font-size: 14px !important;
  }

  .active{
    margin-top: 0px;
}

.archive{
    margin-top: 8px;
}
.textBody{
  font-size: 14px !important;
}
.IconSize{
width: 18px;
height: 18px;
}
}