.Buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 11px;
  margin-bottom: 15px;
  margin-left: -10px;
}
.block {
  width: 1140px;
  height: 226px;
  background: rgba(236, 239, 255, 0.74);
  border-radius: 6px;
  box-sizing: border-box;
  padding: 22px 24px 35px;
  text-align: left;
  position: relative;
}
.img2 {
  display: none;
}
.title {
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}
.flexDiv {
  display: flex;
}
.li {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
  margin-top: 10px;
  margin-left: -20px;
}

.ul {
  margin: 0;
  margin-top: 15px;
  margin-bottom: 5px;
  margin-right: 37px;
}
.button {
  margin-top: 18px;
  background: #3852de;
  border-radius: 8px;
  width: 138px;
  height: 48px;
  display: flex;
  justify-content: center;
  border: none;
  cursor: pointer;
}
.button:hover{
  background-color: #2B46D4;
}
.textButton {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;

  margin: auto;
}
.link {
  text-decoration: none;
}
.img {
  position: absolute;
  top: 0px;
  right: 22px;
}

//////////////////////////////////////////////////////////////////////

@media screen and (max-width: 1919px) {
  .Buttons {
    display: flex;
    justify-content: space-between;
    margin-left: -15px;
    overflow: hidden;
  }
  .block {
    width: 915px;
    height: auto;
    background: rgba(236, 239, 255, 0.74);
    border-radius: 6px;
    box-sizing: border-box;
    padding: 30px 24px 35px;
    text-align: left;
    position: relative;
    overflow: hidden;
  }

  .title {
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
  }

  .li {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
    margin-top: 10px;
    margin-left: -20px;
  }

  .ul {
    margin: 0;
    margin-top: 15px;
    margin-bottom: 5px;
    margin-right: 37px;
  }
  .button {
    margin-top: 18px;
    background: #3852de;
    border-radius: 8px;
    width: 138px;
    height: 48px;
    display: flex;
    justify-content: center;
    border: none;
    cursor: pointer;
  }
  .textButton {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;

    margin: auto;
  }
  .link {
    text-decoration: none;
  }
  .img {
    width: 463px;
    height: 218px;
    float: right;
    margin-right: -77px;
    overflow: hidden;
    clip-path: polygon(88% 0%, 88% 100%, -2% 100%, 1% 0%);
  }
  .img2 {
    position: absolute;
    top: 70px;
    right: 54px;
    width: 92px;
    height: 85px;
  }
}

//////////////////////////////////////////////////////////////////////

@media screen and (max-width: 1439px) {
  .block {
    width: 915px;
    padding: 30px 24px 35px;
  }
  .title {
    font-size: 18px;
    line-height: 22px;
  }
  .img {
    width: 442px;
    height: 212px;
    margin-right: -60px;
    clip-path: polygon(88% 0%, 100% 100%, -2% 100%, 1% 0%);
  }
}
@media screen and (max-width: 1279px) {
  .block {
    width: 925px;
  }
  .img2 {
    display: none;
  }
  .img4 {
    display: flex;
    position: absolute;
    top: -40px;
    right: 0px;
    width: 145px;
    overflow: hidden;
    height: 300px;
  }
  .img3 {
    display: flex;
    position: absolute;
    top: -40px;
    right: 0px;
    width: 178px;
    height: 113px;
    overflow: hidden;
    height: 300px;
  }

  .img3 img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: 300px;
    object-fit: cover;
  }
  .img4 img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: 300px;
    object-fit: cover;
  }
  .button {
    margin-top: 18px;
    background: #3852de;
    border-radius: 8px;
    width: 129px;
    height: 42px;
    display: flex;
    justify-content: center;
    border: none;
    cursor: pointer;
  }
  .textButton {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;

    margin: auto;
  }
}

@media screen and (max-width: 1023px) {
  .block {
    padding: 22px;
    width: 688px;
  }
  .Buttons {
    width: 668px;
    margin-left: -15px;
  }
  .img {
    height: 210px;
    margin-right: -65px;
    overflow: hidden;
    clip-path: polygon(91% 0%, 92% 96%, -2% 96%, 1% 0%);
  }

  .title {
    font-size: 16px;
    line-height: 19px;
    margin-left: -5px;
  }

  .li {
    font-size: 14px;
    line-height: 17px;
    margin-top: 2px;
    margin-left: -30px;
  }
  .flexDiv {
    display: block;
    margin-top: 15px;
  }

  .ul {
    margin-top: 0;
  }
}

@media screen and (max-width: 767px) {
  .block {
    width: 290px;
    padding: 16px;
    display: block;
    margin-bottom: 10px;
  }
  .Buttons {
    width: 290px;
    margin-left: 0px;
    display: block;
  }
  .img2 {
    display: flex;
    position: absolute;
    top: 5px;
    right: 2px;
    width: 125px;
    height: 163px;
  }
  .img {
    display: none;
    position: absolute;
    top: 24px;
    right: 2px;
    width: 125px;
    overflow: hidden;
    height: 163px;
    object-fit: cover;
  }
  .img img {
    display: none;
  }
  .img2 {
    display: none;
  }
  .title {
    font-size: 14px;
    line-height: 17px;
    margin-left: -5px;
    width: 164px;
    margin-bottom: -10px;
  }

  .li {
    font-size: 14px;
    line-height: 17px;
    margin-top: 5px;
    margin-left: -30px;
    width: 220px;
  }
  .flexDiv {
    margin-top: 25px;
  }
  .button {
    margin-top: 18px;
    width: 103px;
    height: 36px;
  }
  .textButton {
    font-size: 14px;
    line-height: 17px;
  }
}
