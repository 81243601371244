.dropdownContainer {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;

  position: relative;
  height: max-content;
  text-align: left;
}

.dropdownInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  padding: 15px 20px;
  outline: none;
cursor: pointer;
  border: 1px solid #ccc;
  max-width: 340px;
  border-radius: 5px;

  margin-bottom: 8px;
}

.dropdownMenu {
  position: absolute;
  top: 45px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: auto;
  max-height: 250px;
  max-width: 380px;
  background-color: #fff;
  z-index: 99;
  overflow-x:hidden;
  padding: 0;
  box-sizing: border-box;
}

.dropdownItem {
  padding: 12px 20px;
  cursor: pointer;
}

.dropdownItem:hover {
  background-color: #ecefff;
}

.dropdownTags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.dropdownTagItem {
  display: flex;
  align-items: center;
}

.dropdownTagClose {
  display: flex;
  align-items: center;
}
.dropdownSelectedValue{
  margin: 3.5px 0;
}


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 767px) {

  .dropdownContainer {
    font-size: 14px !important;
  }
  .dropdownSelectedValue{
    font-size: 14px !important;
  }
  .dropdownInput {
    font-size: 14px !important;
  }
  .dropdownTagItem {
    font-size: 13px !important;
  }
  }

