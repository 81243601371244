.studentStubs {
  margin-top: 90px;
  position: absolute;
  min-height: calc(100vh - 312px);
  left: 50%;
  transform: translateX(-50%);
}
.title {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 49px;
  width: 529px;
  margin: 18px auto 0;
}
.secondTitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  margin-top: 20px;
  margin-bottom: 0;
}
.studentStubsPrediction {
  margin-top: 90px;
  margin-left: 230px;
}

@media screen and (max-width: 1919px) {
  .studentStubsPrediction {
    margin-top: 90px;
    margin-left: 120px;
  }
}

@media screen and (max-width: 1279px) {
  .studentStubsPrediction {
    margin-top: 90px;
    margin-left: 0px;
  }
}

@media screen and (max-width: 767px) {
  .img {
    width: 60px;
    height: 60px;
  }
  .title {
    font-size: 20px;
    line-height: 22px;
    width: auto;
    margin: 8px auto 0;
  }
  .secondTitle {
    font-size: 12px;
    line-height: 16px;
    margin-top: 15px;
    margin-bottom: 0;
  }
  .studentStubsPrediction {
    margin-bottom: 500px;
  }
}
