.title{
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 36px;
    margin: 0;
    max-width: 1100px;
    overflow: hidden;
}
.divFlex{
    display: flex;
    align-items: center;
    margin-top: 26px;
}
.lineDiv{
    justify-content: space-between;
    display: flex;
}
.svg{
    width: 24px;
    height: 24px; 
    margin-right: 10px;
}
.text{
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
}
.active{
    margin-left: 20px;
    margin-right: 9px;
    background: #0DDC65;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    align-self: center;
}

.archive{
    margin-left: 20px;
    margin-right: 9px;
    background: #FF522D;
    width: 6px;
    height: 6px;
    border-radius: 50%; 
}
.divLevel{
    display: flex;
    gap: 10px;
    align-items: end;
}
.level{
background: #3852DE;
border-radius: 8px;
height: 37px;
width: auto;
display: flex;
box-sizing: border-box;
padding: 6px 16px 7px;

p{
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 24px;
margin: auto;
color: #FFFFFF;

}
}
.titleParagraph{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;  
    margin: 0;
}
.textParagraph{
margin: 0;
margin-top: 12px;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;
max-width: 1100px;
overflow: hidden;
}
.divFields{
    display: flex;
    margin-top: 14px;
    gap: 8px;
    flex-wrap: wrap;
}
.element{
    width: auto;
    height: 33px;
    border: 1px solid #3852DE;
    border-radius: 22px;
    display: flex;
    box-sizing: border-box;
    padding: 5px 15px 6px;

    p{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #3852DE;
        margin: auto;
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@media screen and (max-width: 1919px) {
    .title{
        font-size: 24px;
        line-height: 27px;
    }
    .divFlex{
        margin-top: 22px;
    }
    .svg{
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }
    .text{
        font-size: 16px;
        line-height: 19px;
    }
    .active{
        margin-left: 20px;
        margin-right: 7px;
        width: 5px;
        height: 5px;
    }
    
    .archive{
        margin-left: 20px;
        margin-right: 7px;
        width: 5px;
        height: 5px;
    }
    .divLevel{
        gap: 8px;
    }
    .level{
        height: 27px;
    padding: 4px 12.5px 4px;
    
    p{
        font-size: 16px;
        line-height: 19px;
    
    }
    }
    .titleParagraph{
        font-size: 18px;
        line-height: 22px;
    }
    .textParagraph{
    margin-top: 10px;
    font-size: 16px;
    line-height: 19px;
    }
    .divFields{
        margin-top: 10px;
        gap: 8px;
    }
    .element{
        height: 29px;
        padding: 5px 15px 5px;
    
        p{
            font-size: 16px;
            line-height: 19px;
        }
    }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1023px) {
    .title{
        font-size: 20px;
        line-height: 22px;

    }
    .divFlex{
        margin-top: 19px;
    }
    .svg{
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }
    .text{
        font-size: 14px;
        line-height: 17px;
    }
    .active{
        margin-left: 20px;
        margin-right: 7px;
        width: 5px;
        height: 5px;
    }
    
    .archive{
        margin-left: 20px;
        margin-right: 7px;
        width: 5px;
        height: 5px;
    }
    .divLevel{
        gap: 4px;
    }
    .level{
        height: 27px;
    padding: 4px 10px 4px;
    
    p{
        font-size: 16px;
        line-height: 19px;
    }
    }
    .titleParagraph{
        font-size: 16px;
        line-height: 19px;
    }
    .textParagraph{
    margin-top: 10px;
    font-size: 14px;
    line-height: 17px;
    }
    .divFields{
        margin-top: 10px;
        gap: 6px;
    }
    .element{
        height: 28px;
        padding: 5px 11px 6px;
    
        p{
            font-size: 14px;
            line-height: 17px;

        }
    }
}



/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 767px) {

    .title{
        font-size: 18px;
        line-height: 20px;
        margin-top: 47px;
    }
    .divFlex{
        margin-top: 16px;
    }
    .svg{
        width: 18px;
        height: 18px;
        margin-right: 6px;
    }
    .mainInformation{
    position: relative;
    }
    .divLevel{
        gap: 4px;
        position: absolute;
        top: -41px;
    }
    .level{
        height: 25px;
    padding: 4px 10px 4px;
    
    p{
        font-size: 14px;
        line-height: 17px;
    }
    }
    .titleParagraph{
        font-size: 14px;
        line-height: 17px;
    }
    .textParagraph{
    margin-top: 10px;
    font-size: 14px;
    line-height: 17px;
    }
    .divFields{
        margin-top: 10px;
        gap: 6px;
    }
    .element{
        height: 28px;
        padding: 5px 11px 6px;
    
        p{
            font-size: 14px;
            line-height: 17px;
        }
    }
}