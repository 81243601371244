.mainPage {
  padding-top: 0px;
  transition: 0.7s;
  position: relative;
  min-height: calc(100vh - 312px);
  display: flex;
  flex-direction: column;
}

.img {
  width: 101px;
  height: 101px;
  margin: 117px auto 0;
}
.title {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 67px;
  margin-top: 31px;
  margin-bottom: 0;
  width: 875px;
  margin-right: auto;
  margin-left: auto;
}

.secondTitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  margin-top: 24px;
  margin-bottom: 0;
  width: 600px;
  margin-right: auto;
  margin-left: auto;
}

.button {
  margin-top: 40px;
  width: 256px;
  height: 73px;
  background: #3852de;
  border-radius: 8px;
  position: relative;
  margin-bottom: 200px;
  cursor: pointer;
}

.buttonText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
  position: relative;
  top: -4px;
}
.contactValue {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  color: #3852de;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 200px;
}
.button:hover{
  background-color: #2B46D4;
}
@media screen and (max-width: 1925px) {
  .img {
    margin-top: 120px;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 1024px) {
  .title {
    font-size: 35px;
    line-height: 42px;
    margin-top: 20px;
    margin-bottom: 0;
    width: auto;
  }
}
@media screen and (max-width: 767px) {
  .img {
    width: 220px;
  }

  .secondTitle {
    font-size: 14px;
    line-height: 17px;
    margin-top: 24px;

    width: auto;
  }
}
