.Preloader {
  position: absolute;
  left: 50%;
  top: 20%;
}
.information {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 200px;

  &Flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  &Image {
    flex-direction: column;
    width: 360px;
  }
  &Contacts {
    margin-left: 30px;
    flex-direction: column;
    text-align: left;
  }
}

.img {
  max-width: 360px;
  height: auto;
  border-radius: 8%;
  margin-top: 60px;
}
.status {
  display: flex;
  align-items: center;
  justify-content: center;

  &Green {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #0ddc65;
    margin-right: 6px;
  }
  &Text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }
}
.titleName {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 67px;
  margin-top: 80px;
  margin-bottom: 0;
  max-width: 830px;

  &Ru {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
    color: #818181;
    opacity: 0.6;
    margin-top: 4px;
    margin-bottom: 0;
  }
}
.contacts {
  margin-top: 30px;
  overflow-wrap: break-word;
  max-width: 748px;

  &Line {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 14px;
  }
}
.key {
  width: 160px;
  display: flex;
}
.icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.descript {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}
.value {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  padding: 0;
}
.valueLink {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: black;
}
.valueLink:hover {
  color: #3852de;
}
.title {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 49px;
  margin-top: 80px;
  text-align: left;
  margin-bottom: 0;
}
.bigBlock {
  width: 555px;
  height: auto;
  background: #f7f7f7;
  border-radius: 8px;
  padding: 30px;
  box-sizing: border-box;
  text-align: left;
  flex-wrap: wrap;
  margin-top: 34px;
  margin-right: 0px;
}
.informationFlexBlock {
  display: block;
}
.informationFlexStubs {
  display: flex;
  opacity: 0.5;
}
.informationFlex {
  display: flex;
  gap: 30px;
}
.text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-top: 4px;
  margin-bottom: 14px;
}
.listOfInterest {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.listOfInterestStubs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  opacity: 0.5;
  background-color: rgb(246, 8, 8);
}
.interstDiv {
  background: #3852de;
  border-radius: 22px;
  box-sizing: border-box;
  padding: 5px 14px;
  width: auto;
  height: auto;
  margin-right: 6px;
  margin-bottom: 6px;
  &Grin {
    background: #0ddc65;
    border-radius: 22px;
    box-sizing: border-box;
    padding: 5px 14px;
    width: auto;
    height: auto;
    margin-right: 6px;
    margin-bottom: 6px;
  }
  &Yellow {
    background: #fecd1a;
    border-radius: 22px;
    box-sizing: border-box;
    padding: 5px 14px;
    width: auto;
    height: auto;
    margin-right: 6px;
    margin-bottom: 6px;
  }
}
.interst {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: white;
  margin: 0;
  padding: 0;
  padding-bottom: 1px;
}

.minBlock {
  box-sizing: border-box;
  text-align: left;
  flex-wrap: wrap;
  width: 360px;
  height: auto;
  padding: 30px;
  margin: 0;
  background: #f7f7f7;
  border-radius: 6px;
  word-wrap: break-word;
}
.minBlockLinkStubs {
  display: flex;
  gap: 30px;
  margin-top: 30px;
  opacity: 0.5;
}
.nameResearch {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  margin-top: 10px;
  margin-bottom: 6px;
}
.link {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin-top: 0;
}

.titlePosition {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-top: 0;
  margin-bottom: 20px;
}

.info {
  display: flex;
  flex-direction: row;
  margin-top: 0px;
  align-items: center;
  position: relative;
}
.textLab {
  margin-top: 0;
  margin-bottom: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}
.info2 {
  display: flex;
  flex-direction: row;
  margin-top: 17px;
  align-items: center;
}

.textPosition {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin-top: 11px;
  margin-bottom: 0;
}
.iconGroup {
  margin-right: 7px;
  width: 28px;
  height: 26px;
  margin-top: 10px;
}
.iconGroup2 {
  margin-right: 7px;
  width: 24px;
  height: 22px;
  margin-top: 10px;
}
.flex {
  display: flex;
  align-items: flex-start;
}
.minBlockLink {
  text-decoration: none;
  color: black;
  margin-right: 0px;
  margin-top: 30px;
  padding: 0;
}
.minBlockLink:hover {
  text-decoration: underline;
  color: #3852de;
}
.editDiv {
  display: flex;
  align-items: center;
  margin-top: 18px;
}
.editImg {
  margin-right: 10px;
}
.editText {
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #3852de;
  text-decoration-line: underline;
  cursor: pointer;
}
////////////////////////////////////////////////////////////

@media screen and (max-width: 1919px) {
  .information {
    margin-bottom: 160px;

    &Image {
      width: 210px;
    }
    &Contacts {
      margin-left: 30px;
    }
  }

  .img {
    max-width: 210px;
    height: auto;
    border-radius: 8%;
    margin-top: 53px;;
  }
  .status {
    &Green {
      width: 6px;
      height: 6px;
      margin-right: 6px;
    }
    &Text {
      font-size: 16px;
      line-height: 19px;
    }
  }
  .titleName {
    font-size: 44px;
    line-height: 49px;
    margin-top: 53px;
    max-width: 630px;

    &Ru {
      font-size: 20px;
      line-height: 22px;
      margin-top: 4px;
    }
  }
  .contacts {
    margin-top: 20px;
    max-width: 675px;
    &Line {
      margin-bottom: 11px;
    }
  }
  .key {
    width: 140px;
  }
  .icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    padding-top: 2px;
  }
  .descript {
    font-size: 18px;
    line-height: 22px;
  }
  .value {
    font-size: 18px;
    line-height: 22px;
  }
  .valueLink {
    font-size: 18px;
    line-height: 22px;
  }
  .title {
    font-size: 34px;
    line-height: 38px;
    margin-top: 60px;
  }
  .bigBlock {
    width: 442px;
    padding: 30px;
    margin-top: 30px;
  }
  .text {
    font-size: 20px;
    line-height: 24px;
    margin-top: 12px;
    margin-bottom: 14px;
  }

  .interstDiv {
    padding: 5px 14px;
    margin-right: 6px;
    margin-bottom: 6px;
    &Grin {
      padding: 5px 14px;
      margin-right: 6px;
      margin-bottom: 6px;
    }
    &Yellow {
      padding: 5px 14px;
      margin-right: 6px;
      margin-bottom: 6px;
    }
  }
  .interst {
    font-size: 16px;
    line-height: 19px;
  }

  .minBlock {
    width: 285px;
    height: auto;
    padding: 30px;
    margin: 0;
  }
  .researchIcon {
    width: 34px;
    height: 34px;
  }
  .nameResearch {
    font-size: 20px;
    line-height: 24px;
    margin-top: 10px;
    margin-bottom: 6px;
  }
  .link {
    font-size: 20px;
    line-height: 24px;
  }

  .titlePosition {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .textLab {
    font-size: 18px;
    line-height: 22px;
    margin-top: -8px;
  }
  .info2 {
    margin-top: 17px;
  }

  .textPosition {
    font-size: 20px;
    line-height: 24px;
    margin-top: 11px;
  }
  .iconGroup {
    margin-right: 7px;
    width: 24px;
    height: 24px;
    align-items: start;
    display: flex;
    position: relative;
    top: 0;
  }
}

//////////////////////////////////////////////////////////////////////

@media screen and (max-width: 1439px) {
  .information {
    margin-bottom: 140px;

    &Image {
      width: 210px;
    }
    &Contacts {
      margin-left: 30px;
    }
  }

  .img {
    margin-top: 53px;
  }
  .status {
    &Green {
      width: 6px;
      height: 6px;
      margin-right: 6px;
    }
    &Text {
      font-size: 16px;
      line-height: 19px;
    }
  }
  .titleName {
    font-size: 44px;
    line-height: 49px;
    margin-top: 53px;
    max-width: 510px;
    &Ru {
      font-size: 20px;
      line-height: 22px;
      margin-top: 4px;
    }
  }
  .contacts {
    margin-top: 20px;
    max-width: 670px;
    &Line {
      margin-bottom: 11px;
    }
  }
  .key {
    width: 140px;
  }
  .icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  .descript {
    font-size: 18px;
    line-height: 22px;
  }
  .value {
    font-size: 18px;
    line-height: 22px;
  }
  .valueLink {
    font-size: 18px;
    line-height: 22px;
  }
  .title {
    font-size: 34px;
    line-height: 38px;
    margin-top: 63px;
  }
  .bigBlock {
    width: 430px;
    padding: 30px;
    margin-top: 30px;
  }
  .text {
    font-size: 20px;
    line-height: 24px;
    margin-top: 12px;
    margin-bottom: 14px;
  }

  .interstDiv {
    padding: 5px 14px;
    margin-right: 6px;
    margin-bottom: 6px;
    &Grin {
      padding: 5px 14px;
      margin-right: 6px;
      margin-bottom: 6px;
    }
    &Yellow {
      padding: 5px 14px;
      margin-right: 6px;
      margin-bottom: 6px;
    }
  }
  .interst {
    font-size: 16px;
    line-height: 19px;
  }

  .minBlock {
    width: 280px;
    padding: 30px;
  }
  .minBlockLink {
    margin-top: 28px;
  }
  .researchIcon {
    width: 34px;
    height: 34px;
  }
  .nameResearch {
    font-size: 18px;
    line-height: 22px;
    margin-top: 10px;
    margin-bottom: 6px;
  }
  .link {
    font-size: 18px;
    line-height: 22px;
  }

  .titlePosition {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .textLab {
    font-size: 18px;
    line-height: 22px;
  }
  .info2 {
    margin-top: 17px;
  }

  .textPosition {
    font-size: 20px;
    line-height: 24px;
    margin-top: 11px;
  }
  .iconGroup {
    margin-right: 7px;
    width: 24px;
    height: 24px;
  }
  .minBlockLinkStubs {
    display: flex;
    gap: 20px;
    margin-top: 30px;
  }
  .informationFlex {
    gap: 20px;
  }
}
//////////////////////////////////////////////////////////////////////////////////////////////

@media screen and (max-width: 1279px) {
  .information {
    margin-bottom: 120px;
    margin-left: 30px;

    &Image {
      width: 292px;
    }
    &Contacts {
      margin-left: 40px;
    }
  }

  .img {
    max-width: 292px;
    height: auto;
    border-radius: 8%;
    margin-top: 30px;
  }
  .status {
    &Green {
      width: 6px;
      height: 6px;
      margin-right: 6px;
    }
    &Text {
      font-size: 18px;
      line-height: 22px;
    }
  }
  .titleName {
    font-size: 44px;
    line-height: 49px;
    margin-top: 40px;
    max-width: 480px;
    &Ru {
      font-size: 20px;
      line-height: 22px;
      margin-top: 8px;
    }
  }
  .contacts {
    margin-top: 32px;
    max-width: 643px;
    &Line {
      margin-bottom: 11px;
    }
  }
  .key {
    width: 145px;
  }
  .icon {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
  .descript {
    font-size: 18px;
    line-height: 22px;
  }
  .value {
    font-size: 18px;
    line-height: 22px;
  }
  .valueLink {
    font-size: 18px;
    line-height: 22px;
  }
  .title {
    font-size: 32px;
    line-height: 36px;
    margin-top: 51px;
  }
  .bigBlock {
    width: 448px;
    padding: 30px;
    margin-top: 20px;
  }
  .text {
    font-size: 20px;
    line-height: 24px;
    margin-top: 12px;
    margin-bottom: 14px;
  }
  .language_iconLang {
    width: 48px;
    height: 48px;
  }
  .interstDiv {
    padding: 5px 14px;
    margin-right: 6px;
    margin-bottom: 6px;
    &Grin {
      padding: 5px 14px;
      margin-right: 6px;
      margin-bottom: 6px;
    }
    &Yellow {
      padding: 5px 14px;
      margin-right: 6px;
      margin-bottom: 6px;
    }
  }
  .interst {
    font-size: 16px;
    line-height: 19px;
  }

  .minBlock {
    width: 292px;
    padding: 30px;
  }
  .minBlockLink {
    margin-top: 26px;
  }
  .researchIcon {
    width: 34px;
    height: 34px;
  }
  .nameResearch {
    font-size: 18px;
    line-height: 22px;
    margin-top: 10px;
    margin-bottom: 6px;
  }
  .link {
    font-size: 18px;
    line-height: 22px;
  }

  .titlePosition {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .textLab {
    font-size: 18px;
    line-height: 22px;
  }
  .info2 {
    margin-top: 13px;
  }

  .textPosition {
    font-size: 18px;
    line-height: 22px;
    margin-top: 5px;
  }
  .iconGroup {
    margin-right: 7px;
    width: 28px;
    height: 26px;
    margin-top: 4px;
  }
  .iconGroup2 {
    margin-right: 7px;
    width: 24px;
    height: 22px;
    margin-top: 4px;
  }
}

///////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1023px) {
  .information {
    margin: 0 auto;
    margin-bottom: 100px;
    margin-left: 40px;
    &Image {
      width: 216px;
    }
    &Contacts {
      margin-left: 20px;
    }
  }

  .img {
    max-width: 216px;
    height: auto;
    border-radius: 8%;
    margin-top: 20px;
  }
  .status {
    &Green {
      width: 5px;
      height: 5px;
      margin-right: 6px;
    }
    &Text {
      font-size: 16px;
      line-height: 19px;
    }
  }
  .titleName {
    font-size: 34px;
    line-height: 38px;
    margin-top: 33px;
    max-width: 410px;
    &Ru {
      font-size: 16px;
      line-height: 18px;
      margin-top: 6px;
    }
  }
  .contacts {
    margin-top: 16px;
    max-width: 475px;
    &Line {
      margin-bottom: 8px;
    }
  }
  .key {
    width: 115px;
  }
  .icon {
    width: 15px;
    height: 15px;
    margin-right: 9px;
  }
  .descript {
    font-size: 14px;
    line-height: 17px;
  }
  .value {
    font-size: 14px;
    line-height: 17px;
  }
  .valueLink {
    font-size: 14px;
    line-height: 17px;
  }
  .title {
    font-size: 24px;
    line-height: 27px;
    margin-top: 49px;
  }
  .bigBlock {
    width: 334px;
    padding: 24px;
    margin-top: 20px;
  }
  .interest_icon {
    width: 24px;
    height: 24px;
    margin-top: -15px;
  }
  .language_iconLang {
    width: 24px;
    height: 24px;
    margin-top: -15px;
  }
  .informationFlexBlock {
    display: flex;
    align-items: center;
  }
  .text {
    font-size: 16px;
    line-height: 19px;
    margin-top: 0px;
    margin-bottom: 14px;
    margin-left: 11px;
  }

  .interstDiv {
    padding: 3px 10px;
    margin-right: 6px;
    margin-bottom: 6px;
    &Grin {
      padding: 3px 10px;
      margin-right: 6px;
      margin-bottom: 6px;
    }
    &Yellow {
      padding: 3px 10px;
      margin-right: 6px;
      margin-bottom: 6px;
    }
  }
  .interst {
    font-size: 14px;
    line-height: 17px;
  }

  .minBlock {
    width: 216px;
    padding: 20px 24px;
  }
  .minBlockLink {
    margin-top: 20px;
  }
  .researchIcon {
    width: 24px;
    height: 24px;
  }
  .nameResearch {
    font-size: 14px;
    line-height: 17px;
    margin-top: 7px;
    margin-bottom: 10px;
  }
  .link {
    font-size: 14px;
    line-height: 17px;
  }

  .titlePosition {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 16px;
  }

  .textLab {
    font-size: 16px;
    line-height: 19px;
  }
  .info2 {
    margin-top: 13px;
  }

  .textPosition {
    font-size: 14px;
    line-height: 17px;
    margin-top: 5px;
  }
  .iconGroup {
    margin-right: 7px;
    width: 24px;
    height: 24px;
  }
  .minBlockLinkStubs {
    display: flex;
    gap: 18px;
    margin-top: 30px;
  }
}

///////////////////////////////////////////////////////////////////////////////

@media screen and (max-width: 767px) {
  .information {
    margin: 0 auto;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    width: 290px;
    &Image {
      margin: 0 auto;
      width: 130px;
      position: relative;
    }
    &Flex {
      display: flex;
      flex-direction: column;
    }
    &Contacts {
      margin-left: 0px;
    }
  }

  .img {
    max-width: 130px;
    height: auto;
    border-radius: 8%;
    margin-top: 20px;
  }
  .status {
    position: absolute;
    top: 210px;
    margin: 0 auto;
    width: 160px;
    align-items: center;
    text-align: center;
    &Green {
      width: 5px;
      height: 5px;
      margin-right: 6px;
      margin-top: 2px;
      margin-left: -30px;
    }
    &Text {
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 21px;
    }
  }
  .titleName {
    text-align: center;
    font-size: 24px;
    line-height: 27px;
    margin-top: 12px;

    &Ru {
      text-align: center;
      font-size: 16px;
      line-height: 18px;
      margin-top: 2px;
    }
  }
  .contacts {
    margin-top: 47px;

    &Line {
      margin-bottom: 8px;
    }
  }
  .key {
    width: auto;
  }
  .icon {
    width: 16px;
    height: 16px;
    margin-right: 9px;
  }
  .descript {
    display: none;
  }
  .value {
    font-size: 14px;
    line-height: 17px;
  }
  .valueLink {
    font-size: 14px;
    line-height: 17px;
  }
  .title {
    font-size: 20px;
    line-height: 22px;
    margin-top: 30px;
    margin-bottom: 6px;
  }
  .bigBlock {
    width: 289px;
    padding: 17px 15px;
    margin-top: 8px;
    margin-right: 0px;
  }
  .interest_icon {
    width: 22px;
    height: 22px;
    position: relative;
    top: -2px;
  }
  .language_iconLang {
    width: 22px;
    height: 22px;
  }
  .informationFlexBlock {
    display: flex;
    align-items: center;
  }
  .text {
    font-size: 14px;
    line-height: 17px;
    margin-top: 0px;
    margin-bottom: 14px;
    margin-left: 11px;
    position: relative;
    top: 6px;
  }

  .interstDiv {
    padding: 3px 10px;
    margin-right: 6px;
    margin-bottom: 6px;
    &Grin {
      padding: 3px 10px;
      margin-right: 6px;
      margin-bottom: 6px;
    }
    &Yellow {
      padding: 3px 10px;
      margin-right: 6px;
      margin-bottom: 6px;
    }
  }
  .interst {
    font-size: 14px;
    line-height: 17px;
  }

  .minBlock {
    width: 290px;
    padding: 15px;
    margin-right: 0px;
    margin-top: 6px;
    position: relative;
    height: 52px;
  }
  .researchIcon {
    width: 22px;
    height: 22px;
  }
  .nameResearch {
    font-size: 14px;
    line-height: 17px;
    position: relative;
    top: -32px;
    left: 30px;
  }
  .minBlockLink {
    margin-right: 0px;
    margin-top: 0px;
  }
  .titlePosition {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 8.5px;
  }

  .textLab {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
  .info2 {
    margin-top: 8.5px;
  }
  .info {
    position: relative;
  }
  .textPosition {
    font-size: 14px;
    line-height: 17px;
    margin-top: 5px;
    margin-left: 5px;
  }
  .iconGroup {
    margin-right: 7px;
    width: 18px;
    height: 18px;
    margin-top: 5px;
  }
  .iconGroup2 {
    margin-right: 7px;
    width: 18px;
    height: 18px;
    margin-top: 5px;
  }
  .minBlockLinkStubs {
    display: block;
    gap: 18px;
    margin-top: 10px;
  }
  .informationFlexStubs {
    display: block;
  }
  .informationFlex {
    gap: 0px;
  }
}
