.Notes {
  display: flex;
  flex-direction: column;
  width: 1140px;
  margin-bottom: 200px;
  margin-top: 60px;
}
.Preloader {
  position: absolute;
  left: 48%;
  top: 20%;
}
.title {
  margin: 0;
  padding: 0;
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 67px;
  text-align: left;
  margin-bottom: 30px;
}
.handlerBar {
  display: flex;
  justify-content: space-between;
}
.sortDiv {
  display: flex;
  align-items: center;
}
.showText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  margin: 0;
  margin-right: 14px;
}
.sortItem {
  background: #ecefff;
  border-radius: 11px;
  box-sizing: border-box;
  padding: 7.5px 14px;
  height: 37px;
  margin-right: 6px;
}
.sortItem:hover{
background: #D7DCF8;
}
.sortItemActive {
  background: #3852de;
  border-radius: 11px;
  box-sizing: border-box;
  padding: 7.5px 14px;
  height: 37px;
  margin-right: 6px;
}
.sortText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #3852de;
  margin: 0;
  cursor: pointer;
}
.sortTextActive {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin: 0;
}
.addNote {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #3852de;
  margin-right: -25px;
  cursor: pointer;
}
.addNote:hover {
  color: #4B63E1;
}
.notesSection {
  width: 1168px;
  height: auto;
  position: relative;
}
.divNate {
  width: 100%;
  display: block;
  min-height: 118px;
  cursor: pointer;
  position: relative;
  z-index: 2;
  padding: 1px 10px 15px 15px;
  left: -10px;
}
.divNate:hover {
  background: rgba(247, 247, 247, 0.74);
  border-radius: 8px;
}
.firstLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 11px;
}

.firstLineMain {
  display: flex;
  align-items: center;
  width: 976px;
}

.noteName {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 36px;
  margin: 0;
  margin-right: 24px;
  text-align: left;
  max-width: 600px;
  overflow: hidden;
}
.noteNameArch {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 36px;
  margin: 0;
  margin-right: 24px;
  text-align: left;
  max-width: 600px;
  overflow: hidden;
  text-decoration: line-through;
}
.editedDiv {
  display: flex;
}
.editedTitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #818181;
  margin: 0;
  margin-right: 5px;
}
.editedName {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #3852de;
  margin: 0;
}
.firstLineIcons {
  display: flex;
  gap: 12px;
}
.icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  &:hover{
    path {
      fill: #3852de;
      transition:0.2s;
  }
  }
}

.description {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  margin: 0;
  text-align: left;
  width: 976px;
  overflow: hidden;
  margin-top: 16px;
}
.descriptionArch {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  margin: 0;
  text-align: left;
  width: 976px;
  overflow: hidden;
  margin-top: 16px;
  text-decoration: line-through;
}
.date {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
  color: #818181;
  text-align: left;
  margin-top: 8px;
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@media screen and (max-width: 1919px) {
  .Notes {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 200px;
    margin-top: 50px;
  }
  .title {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 49px;
    text-align: left;
    margin-bottom: 30px;
  }
  .showText {
    font-size: 18px;
    line-height: 22px;
    margin-right: 10px;
  }
  .sortItem {
    padding: 6.5px 12px;
    height: 30px;
    margin-right: 4px;
  }
  .sortItemActive {
    padding: 6.5px 12px;
    height: 30px;
    margin-right: 4px;
  }
  .sortText {
    font-size: 16px;
    line-height: 19px;
  }
  .sortTextActive {
    font-size: 16px;
    line-height: 19px;
  }
  .addNote {
    font-size: 20px;
    line-height: 24px;
    margin-right: 0px;
  }
  .notesSection {
    width: 885px;
  }
  .divNate {
    min-height: 98px;
    padding: 1px 10px 11px 15px;
    left: -12px;
  }
  .firstLine {
    margin-top: 8px;
  }
  .firstLineMain {
    display: flex;
    align-items: center;
    width: 976px;
  }
  .noteName {
    font-size: 24px;
    line-height: 27px;
    margin-right: 23px;
    max-width: 400px;
  }
  .noteNameArch {
    font-size: 24px;
    line-height: 27px;
    margin-right: 23px;
    max-width: 400px;
    text-decoration: line-through;
  }
  .editedTitle {
    font-size: 16px;
    line-height: 19px;
  }
  .editedName {
    font-size: 16px;
    line-height: 19px;
  }
  .firstLineIcons {
    gap: 10px;
  }
  .icon {
    width: 24px;
    height: 24px;
  }
  .description {
    font-size: 20px;
    line-height: 24px;
    width: 810px;
    margin-top: 12px;
  }
  .descriptionArch {
    font-size: 20px;
    line-height: 24px;
    width: 810px;
    margin-top: 12px;
    text-decoration: line-through;
  }
  .date {
    font-size: 18px;
    line-height: 22px;
    margin-top: 8px;
  }
}

//////////////////////////////////////////////////////////////////////

@media screen and (max-width: 1279px) {
  .Notes {
    margin-bottom: 180px;
    margin-top: 40px;
  }

  .title {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 49px;
    text-align: left;
    margin-bottom: 30px;
  }
  .showText {
    font-size: 18px;
    line-height: 22px;
    margin-right: 10px;
  }
  .sortItem {
    padding: 5.5px 11.5px;
    height: 30px;
    margin-right: 4px;
  }
  .sortItemActive {
    padding: 5.5px 11.5px;
    height: 30px;
    margin-right: 4px;
  }
  .sortText {
    font-size: 16px;
    line-height: 19px;
  }
  .sortTextActive {
    font-size: 16px;
    line-height: 19px;
  }
  .addNote {
    font-size: 20px;
    line-height: 24px;
    margin-right: 0px;
  }
  .notesSection {
    width: 915px;
  }
  .divNate {
    min-height: 98px;
  }
  .firstLine {
    margin-top: 20px;
  }
  .firstLineMain {
    display: flex;
    align-items: center;
    width: 976px;
  }
  .noteName {
    font-size: 24px;
    line-height: 27px;
    margin-right: 23px;
    max-width: 400px;
  }
  .noteNameArch {
    font-size: 24px;
    line-height: 27px;
    margin-right: 23px;
    max-width: 400px;
    text-decoration: line-through;
  }
  .editedTitle {
    font-size: 16px;
    line-height: 19px;
  }
  .editedName {
    font-size: 16px;
    line-height: 19px;
  }
  .firstLineIcons {
    gap: 10px;
  }
  .icon {
    width: 24px;
    height: 24px;
  }
  .description {
    font-size: 20px;
    line-height: 24px;
    width: 810px;
    margin-top: 12px;
  }
  .descriptionArch {
    font-size: 20px;
    line-height: 24px;
    width: 810px;
    margin-top: 12px;
    text-decoration: line-through;
  }
  .date {
    font-size: 18px;
    line-height: 22px;
    margin-top: 8px;
  }
}
//////////////////////////////////////////////////////////////////////

@media screen and (max-width: 1439px) {
  .Notes {
    margin-bottom: 140px;
    margin-top: 50px;
  }
  .title {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 26px;
  }
  .showText {
    font-size: 18px;
    line-height: 22px;
    margin-right: 10px;
  }
  .sortItem {
    padding: 6.5px 12px;
    height: 30px;
    margin-right: 4px;
  }
  .sortItemActive {
    padding: 6.5px 12px;
    height: 30px;
    margin-right: 4px;
  }
  .sortText {
    font-size: 16px;
    line-height: 19px;
  }
  .sortTextActive {
    font-size: 16px;
    line-height: 19px;
  }
  .addNote {
    font-size: 20px;
    line-height: 24px;
    margin-right: 0px;
  }
  .notesSection {
    width: 865px;
  }
  .divNate {
    min-height: 98px;
  }
  .firstLine {
    margin-top: 8px;
  }
  .firstLineMain {
    display: flex;
    align-items: center;
    width: 976px;
  }
  .noteName {
    font-size: 24px;
    line-height: 27px;
    margin-right: 23px;
    max-width: 400px;
  }
  .noteNameArch {
    font-size: 24px;
    line-height: 27px;
    margin-right: 23px;
    max-width: 400px;
    text-decoration: line-through;
  }
  .editedTitle {
    font-size: 16px;
    line-height: 19px;
  }
  .editedName {
    font-size: 16px;
    line-height: 19px;
  }
  .firstLineIcons {
    gap: 10px;
  }
  .icon {
    width: 24px;
    height: 24px;
  }
  .description {
    font-size: 18px;
    line-height: 22px;
    width: 810px;
    margin-top: 10px;
  }
  .descriptionArch {
    font-size: 18px;
    line-height: 22px;
    width: 810px;
    margin-top: 10px;
    text-decoration: line-through;
  }
  .date {
    font-size: 18px;
    line-height: 22px;
    margin-top: 8px;
  }
  .handlerBar{
    margin-top: -10px;
  }
}

//////////////////////////////////////////////////////////////////////

@media screen and (max-width: 1023px) {
  .Notes {
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 30px;
    width: 684px;
  }
.handlerBar{
  margin-top: -10px;
}
  .title {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 23px;
  }
  .showText {
    font-size: 16px;
    line-height: 19px;
    margin-right: 8px;
  }
  .sortItem {
    padding: 4.5px 11px;
    height: 26px;
    margin-right: 2px;
  }
  .sortItemActive {
    padding: 4.5px 11px;
    height: 26px;
    margin-right: 2px;
  }
  .sortText {
    font-size: 14px;
    line-height: 17px;
  }
  .sortTextActive {
    font-size: 14px;
    line-height: 17px;
  }
  .addNote {
    font-size: 18px;
    line-height: 22px;
    margin-right: 0px;
  }
  .notesSection {
    width: 684px;
  }
  .divNate {
    min-height: 78px;
  }
  .firstLine {
    margin-top: 11px;
  }
  .firstLineMain {
    display: flex;
    align-items: center;
    width: 676px;
  }
  .noteName {
    font-size: 20px;
    line-height: 22px;
    margin-right: 23px;
    max-width: 250px;
  }
  .noteNameArch {
    font-size: 20px;
    line-height: 22px;
    margin-right: 23px;
    max-width: 250px;
    text-decoration: line-through;
  }
  .editedTitle {
    font-size: 14px;
    line-height: 17px;
  }
  .editedName {
    font-size: 14px;
    line-height: 17px;
  }
  .firstLineIcons {
    gap: 10px;
  }
  .icon {
    width: 24px;
    height: 24px;
  }
  .description {
    font-size: 14px;
    line-height: 17px;
    width: 684px;
    margin-top: 6px;
  }
  .descriptionArch {
    font-size: 14px;
    line-height: 17px;
    width: 684px;
    margin-top: 6px;
    text-decoration: line-through;
  }
  .date {
    font-size: 14px;
    line-height: 17px;
    margin-top: 6px;
  }
}

//////////////////////////////////////////////////////////////////////

@media screen and (max-width: 767px) {
  .Notes {
    margin-bottom: 350px;
    margin-top: 28px;
    width: 290px;
    min-height: 320px;
  }

  .title {
    font-size: 24px;
    line-height: 27px;
    margin-bottom: 14px;
  }
  .showText {
    display: none;
  }
  .sortItem {
    padding: 4.5px 11px;
    height: 26px;
    margin-right: 2px;
  }
  .sortItemActive {
    padding: 4.5px 11px;
    height: 26px;
    margin-right: 2px;
  }
  .sortText {
    font-size: 14px;
    line-height: 17px;
  }
  .sortTextActive {
    font-size: 14px;
    line-height: 17px;
  }
  .handlerBar {
    position: relative;
    margin-bottom: 20px;
  }
  .addNote {
    font-size: 16px;
    line-height: 19px;
    position: absolute;
    right: 0;
    top: -50px;
  }
  .notesSection {
    width: 290px;
  }
  .divNate {
    display: block;
    background: #f7f7f7;
    border-radius: 5px;
    width: 290px;
    margin-top: 0;
    margin-bottom: 10px;
    box-sizing: border-box;
    padding: 10px 10px;
    left: 0px;
    z-index: 0;
  }
  .firstLine {
    margin-top: 0px;
    display: block;
    position: relative;
  }
  .firstLineMain {
    display: block;
    align-items: center;
    width: 290px;
  }
  .noteName {
    font-size: 20px;
    line-height: 22px;
    margin-right: 23px;
    max-width: 200px;
    margin-bottom: 8px;
  }
  .noteNameArch {
    font-size: 20px;
    line-height: 22px;
    margin-right: 23px;
    max-width: 200px;
    text-decoration: line-through;
    margin-bottom: 8px;
  }
  .editedTitle {
    display: none;
  }
  .editedName {
    margin-right: 8px;
    font-size: 14px;
    line-height: 17px;
    max-width: 200px;
  }
  .firstLineIcons {
    gap: 10px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .icon {
    width: 20px;
    height: 20px;
  }
  .description {
    font-size: 14px;
    line-height: 17px;
    width: 270px;
    margin-top: 6px;
  }
  .descriptionArch {
    font-size: 14px;
    line-height: 17px;
    width: 270px;
    margin-top: 6px;
    text-decoration: line-through;
  }
  .date {
    font-size: 14px;
    line-height: 17px;
    margin-top: 6px;
  }
}
