.Project {
  min-width: 1140px;
  margin-top: 60px;
  margin-left: -45px !important;
}
  .title {
    padding: 0;
    font-family: Platform LC Web;
    font-size: 44px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    margin: 0;
    margin-top: 30px;
  }

  .controls {
    align-items: center;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
  }

  .data {
    height: auto;
  }

.createButton{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #3852DE;
  cursor: pointer;
  top: 40px;
  align-items: start;
}
.createButton:hover {
  color: #4B63E1;
}

@media screen and (max-width: 1919px) {
  .Project {
    margin-top: 50px;
    width: 100%;
    min-width: 915px;
    max-width: 1030px !important;
    
  }

    .title {
      padding: 0;
      font-family: 'Platform LC Web';
      font-style: normal;
      font-weight: 400;
      font-size: 34px;
      line-height: 38px;
      text-align: left;
      margin: 0;
      margin-top: 30px;
    }
  
    .controls {
      align-items: center;
      margin-bottom: 20px;
    }
  
    .data {
      height: auto;
    }
 
  .createButton{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    color: #3852DE;
    cursor: pointer;
    top: 30px;
  }
  .createButton:hover {
    color: #4B63E1;
  }
}

//////////////////////////////////////////////////////////////////////

@media screen and (max-width: 1279px) {
  .Project {
    margin-bottom: 180px;
    margin-top: 40px;
    width: 915px;
  }
}

//////////////////////////////////////////////////////////////////////

@media screen and (max-width: 1439px) {
  .Project {
    margin-bottom: 140px;
    margin-top: 50px;
    width: 915px;
  }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1279px) {
  .Project {
    margin: auto;
    margin-top: 30px;
    width: 935px !important;
    min-width: 935px !important;
    max-width: 935px !important;
    padding: 0 !important;
   padding-left: 30px !important;
  }
}

//////////////////////////////////////////////////////////////////////

@media screen and (max-width: 1023px) {
  .Project {
    margin: auto;
    margin-top: 30px;
    width: 700px !important;
    min-width: 700px !important;
    max-width: 700px !important;
  }
  .title {
    font-size: 28px;
    margin-top: 20px;
  }
  .createButton{
    top: 20px;
  }
}

//////////////////////////////////////////////////////////////////////

@media screen and (max-width: 767px) {
  .Project {
    display: flex !important;;
    flex-direction: column !important;
    margin-bottom: 100px;
    margin-top: 28px;
    width: 290px !important;
    min-width: 290px !important;
    max-width: 290px !important;
    margin: auto  !important;;
    padding: 0 !important;
    position: relative;
    margin-top: 30px !important;
    .controls {
      align-items: flex-start;
      margin-bottom: 80px;

 
    }
    .controls {
      align-items: left;
      display: flex !important;
      flex-direction: column !important;

    }
    .title {
    margin-left: 0;
    }
    .data {
      width: 100%;
    }
  }
  .position{
    position: relative !important;
  }
  .createButton{
    font-size: 16px;
    line-height: 19px;
    position: absolute !important;
    left: -10px !important;
    top: 15px !important;
    display: flex;
    width: 290px;
    height: 40px;
    padding: 9px 11px 10px 11px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid var(--blue-100, #3852DE);
    margin-left: 0 !important;
    margin-top: 0 !important;
  }
  .createButton:hover{
    opacity: 0.8;
  }
}
