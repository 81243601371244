.instructorStubs {
  margin-top: 50px;
  margin-bottom: 50px;
}
.title {
  font-family: 'Platform LC Web';
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 49px;
  width: 529px;
  margin: 18px auto 0;
}
.secondTitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  margin-top: 20px;
  margin-bottom: 0;
}
.studentStubsPrediction {
  margin-top: 50px;
  margin-left: 230px;
}

@media screen and (max-width: 1919px) {
  .studentStubsPrediction {
    margin-top: 40px;
    margin-left: 140px;
  }
  .title {
    font-size: 34px;
    line-height: 38px;
  }
  .secondTitle {
    font-size: 20px;
    line-height: 24px;
  }
  .studentStubs {
    margin-top: 40px;
  }
}
@media screen and (max-width: 1439px) {
  .studentStubs {
    margin-top: 32px;
  }
  .studentStubsPrediction {
    margin-top: 32px;
  }
}
@media screen and (max-width: 1279px) {
  .studentStubsPrediction {
    margin-left: 0px;
  }
  .title {
    font-size: 32px;
    line-height: 36px;
  }
  .secondTitle {
    font-size: 18px;
    line-height: 22px;
  }
}
@media screen and (max-width: 1023px) {
  .title {
    font-size: 24px;
    line-height: 27px;
  }
  .secondTitle {
    font-size: 16px;
    line-height: 19px;
  }
  .studentStubs {
    margin-top: 32px;
  }
  .studentStubsPrediction {
    margin-top: 32px;
  }
}

@media screen and (max-width: 767px) {
  .img {
    width: 60px;
    height: 60px;
    margin-top: 0px;
  }
  .title {
    font-size: 18px;
    line-height: 20px;
    width: auto;
    margin: 8px auto 0;
  }
  .secondTitle {
    font-size: 14px;
    line-height: 17px;
    margin-top: 15px;
    margin-bottom: 0;
  }
  .studentStubsPrediction {
    margin-bottom: 500px;
  }
  .studentStubs {
    margin-top: 2px;
  }
  .studentStubsPrediction {
    margin-top: 2px;
  }
}
