.hints {
  display: flex;
  align-items: center;
}
.hintColorGreen {
  background: #0ddc65;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.hintColorGreen2 {
  background: #069542;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.hintColorYellow {
  background-color: #fecd1a;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.hintColorGreenOrange {
  background-color: #ff9900;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.hintColorGreenRed {
  background-color: #ff613f;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.hintText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  margin-right: 20px;
}
.hintText:last-of-type {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin-right: 0px;
}
@media screen and (max-width: 1919px) {
  .hintColorGreen {
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  .hintColorGreen2 {
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  .hintColorYellow {
    background-color: #fecd1a;
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  .hintColorGreenOrange {
    background-color: #ff9900;
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  .hintColorGreenRed {
    background-color: #ff613f;
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }

  .hintText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-right: 12px;
  }
  .hintText:last-of-type {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-right: 12px;
  }
}

@media screen and (max-width: 1439px) {
  .hintColorGreen {
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  .hintColorGreen2 {
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  .hintColorYellow {
    background-color: #fecd1a;
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  .hintColorGreenOrange {
    background-color: #ff9900;
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  .hintColorGreenRed {
    background-color: #ff613f;
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }

  .hintText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-right: 12px;
  }
  .hintText:last-of-type {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-right: 12px;
  }
}

@media screen and (max-width: 1023px) {
  .hintColorGreen {
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  .hintColorGreen2 {
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  .hintColorYellow {
    background-color: #fecd1a;
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  .hintColorGreenOrange {
    background-color: #ff9900;
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  .hintColorGreenRed {
    background-color: #ff613f;
    border-radius: 4px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }

  .hintText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-right: 12px;
  }
  .hintText:last-of-type {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-right: 12px;
  }
}

@media screen and (max-width: 767px) {
  .hints {
    display: none;
  }
}
