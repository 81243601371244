.ProjectsFilter {
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 30px;

  .title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    text-align: start;

    margin: 0 0 20px 0;
  }
}
.ProjectsButton{
  margin-left: 2px;
}

.button{
  border-radius: 8px;
background: var(--blue-100, #3852DE);
display: inline-flex;
height: 45px;
padding: 17px 29px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
max-width: 113px;
width: auto;
margin-top: 20px;
p{
  color: var(--white, #FFF);
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
}
.button:hover{
  cursor: pointer;
  background: #3349c9;
}


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 767px) {


.button{
  height: 36px;
  padding: 14px 23px;
  max-width: 80px;
  margin-top: 30px;
  p{
  font-size: 16px;
  }
  }


}