.main{
display: flex;
flex-direction: column;
margin: 0 auto;
height: 960px;
}
.imgSearch {
    width: 120px;
    height: 83px;
    margin: 90px auto 0;
  }
  .formName {
    font-family: 'Platform LC Web';
    font-style: normal;
    font-weight: 400;
    font-size: 52px;
    line-height: 58px;;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 40px;

  }
  .text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;

  }
.searchArea{
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 40px auto;
}
.Input{
  width: 488px;
  height: 68px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding-left: 50px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    padding-right: 45px;
    box-sizing: border-box;
    border: 1px solid rgba(35, 35, 35, 0.3);
    color: #818181;
    &::placeholder{
      color: rgba(35, 35, 35, 0.3);
    }
}
.activeInput{
  width: 488px;
  height: 68px;
    background-color: #ffffff;
    border-radius: 8px;
    padding-left: 50px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    padding-right: 45px;
    box-sizing: border-box;
    border: 1px solid #818181;
}
.errorInput{
  width: 488px;
  height: 68px;
  background: #FFFFFF;
  border-radius: 8px;
  padding-left: 50px;
  color: #ff0000ca;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  padding-right: 45px;
  box-sizing: border-box;
  border: 1px solid #ff0000;
}
  .searchImg{
    position: absolute;
    left: 23px;
    top: 25px;
    z-index: 3;
    width: 20px;
    height: 20px;
    path{
      stroke: rgba(35, 35, 35, 0.159);
    }
  }
  .errorSearchImg{
    position: absolute;
    left: 23px;
    top: 25px;
    z-index: 3;
    width: 20px;
    height: 20px;
    path{
      stroke: #ff0000;
    }
  }
  .activeSearchImg{
    position: absolute;
    left: 23px;
    top: 25px;
    z-index: 3;
    width: 20px;
    height: 20px;
    path{
      stroke: rgb(0, 0, 0);
    }
  }
  .Input:hover::placeholder{
    color: #818181;
  }
  .Input:hover{
  color: #818181;
  }
  .searchArea:hover .searchImg {
    path{
      stroke: #818181;
    }}

  .Input:focus {
    background: #FFFFFF;
    border: 1px solid #818181;
    .searchImg {
      path{
        stroke: rgb(0, 0, 0);
      }}
  }
  .plus{
   position: absolute;
   cursor: pointer;
   right: 23px;
   top: 22px;
   z-index: 3;
  }
  .emailsDiv{
    width: 488px;
    max-height: 280px;
    background-color: #fdfbfb;
    overflow-y: scroll;
    overflow-x:hidden;
    text-overflow: ellipsis;
    z-index: 2;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 10px 0 16px 12px;
    cursor: pointer;
    z-index: 3;
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    margin-top: 10px;
  }
  .emailsDiv::-webkit-scrollbar{
    width: 13px;
}
.emailsDiv::-webkit-scrollbar-track{
  background-color: #fdfbfb;
    border-radius: 0px 0px 8px 8px;
}
.emailsDiv::-webkit-scrollbar-thumb{
    background-color: #30303089;
    border: 3px solid #fbfbfb;
    border-radius: 5px;
}

  .emailsFlex{
    display: flex;
    align-items: center;
    text-align: left;
    color: #000;
    text-decoration: none;
    width: 100%;
  

    &:hover{
      background: rgba(56, 81, 222, 0.025);
      border-radius: 8px;
    }
     img{
      font-size: 20px;
      line-height: 23px;
        margin: 9.5px 12px 9.5px 12px;
     }
     p{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
       margin: 9.5px 0;
      
     }
  }
  .Preloader {
    position: absolute;
    left: 48%;
    top: 20%;
  }
  @media screen and (max-width: 1919px) {
  .main{
    height: 750px;
    }
  .imgSearch {
    width: 102px;
    height: 71px;
      margin: 78px auto 0;
    }
    .formName {
      font-size: 44px;
      line-height: 49px;
      margin-bottom: 16px;
      margin-top: 30px;

    }
    .text {
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 0;
    }
  .searchArea{
      position: relative;
      display: flex;
      flex-direction: column;
      margin: 32px auto;
  }
  .Input{
    width: 371px;
    height: 58px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      padding-left: 40px;
      font-size: 18px;
      line-height: 22px;
      padding-right: 40px;
  }
  .activeInput{
    width: 371px;
    height: 58px;
      background-color: #ffffff;
      border-radius: 8px;
      padding-left: 40px;
      font-size: 18px;
      line-height: 22px;
      padding-right: 40px;

  }
  .errorInput{
    width: 371px;
    height: 58px;
    background: #FFFFFF;
    border-radius: 8px;
    padding-left: 40px;
    font-size: 18px;
    line-height: 22px;
    padding-right: 40px;
  }
  .Input:focus {
      background: #FFFFFF;
    }
    .searchImg{
      position: absolute;
      cursor: pointer;
      left: 15px;
      top: 19px;
      z-index: 3;
      width: 20px;
      height: 20px;
    }
    .errorSearchImg{
      position: absolute;
      cursor: pointer;
      left: 15px;
      top: 19px;
      z-index: 3;
      width: 20px;
      height: 20px;
    }
    .activeSearchImg{
      position: absolute;
      cursor: pointer;
      left: 15px;
      top: 19px;
      z-index: 3;
      width: 20px;
      height: 20px;
    }
    .plus{
      position: absolute;
      cursor: pointer;
      right: 15px;
      top: 17px;
      z-index: 3;
    }
    .emailsDiv{
      width: 371px;
      max-height: 235px;
      padding: 14px 0 16px 12px;
      box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.07);
      border-radius: 10px;
    }
    .emailsFlex{
      display: flex;
      align-items: center;
      text-align: left;
      color: #000;
      text-decoration: none;
      width: 100%;
  
      &:hover{
        background: rgba(56, 81, 222, 0.025);
        border-radius: 8px;
      }
        img{
          width: 18px;
          height: 23px;
          margin: 9.5px 12px 9.5px 12px;
        }
        p{
          font-size: 18px;
          line-height: 22px;
          margin: 9.5px 0;
        }
    }
  }
  @media screen and (max-width: 1439px) {
    .main{
      height: 700px;
      }
    .imgSearch {
      width: 96px;
      height: 67px;
      margin: 60px auto 0;
      }
      .formName {
        font-size: 40px;
        line-height: 45px;
        margin-top: 30px;
        margin-bottom: 18px;
      }
      .text {
        font-size: 14px;
        line-height: 17px;
      }
    .Input{
      width: 371px;
      height: 58px;
        border-radius: 8px;
        padding-left: 40px;
        font-size: 18px;
        line-height: 22px;
        padding-right: 40px;
    }
    .activeInput{
      width: 371px;
      height: 58px;
        border-radius: 8px;
        padding-left: 40px;
        font-size: 18px;
        line-height: 22px;
        padding-right: 40px;
  
    }
    .errorInput{
      width: 371px;
      height: 58px;
      border-radius: 8px;
      padding-left: 40px;
      font-size: 18px;
      line-height: 22px;
      padding-right: 40px;
    }
    .searchArea{
      margin: 30px auto;
  }
      .searchImg{
        left: 15px;
        top: 18px;
        z-index: 3;
        width: 20px;
        height: 20px;
      }
      .errorSearchImg{
        left: 15px;
        top: 18px;
        z-index: 3;
        width: 20px;
        height: 20px;
      }
      .activeSearchImg{
        left: 15px;
        top: 18px;
        z-index: 3;
        width: 20px;
        height: 20px;
      }
      .plus{
        right: 15px;
        top: 18px;
      }
      .emailsDiv{
        width: 371px;
        max-height: 219px;
        padding: 10px 0 16px 12px;
        box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.07);
        border-radius: 10px;
      }
    
      .emailsFlex{
    
        &:hover{
          background: rgba(56, 81, 222, 0.025);
          border-radius: 8px;
        }
        img{
          width: 18px;
          height: 23px;
          margin: 9.5px 12px 9.5px 12px;
        }
        p{
          font-size: 16px;
          line-height: 19px;
          margin: 9.5px 0;
        }
      }
  }

  @media screen and (max-width: 1279px) {
    .main{
      height: 600px;
      }
    .imgSearch {
      width: 84px;
      height: 58px;
        margin: 54px auto 0;
      }
      .formName {
        font-size: 34px;
        line-height: 38px;
        margin-bottom: 16px;
        margin-top: 30px;
  
      }
      .text {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 0;
      }
    .searchArea{
        margin: 30px auto;
    }
    .Input{
      width: 371px;
      height: 54px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        padding-left: 40px;
        font-size: 16px;
        line-height: 19px;
        padding-right: 40px;
    }
    .activeInput{
      width: 371px;
      height: 54px;
        background-color: #ffffff;
        border-radius: 8px;
        padding-left: 40px;
        font-size: 16px;
        line-height: 19px;
        padding-right: 40px;
  
    }
    .errorInput{
      width: 371px;
      height: 54px;
      background: #FFFFFF;
      border-radius: 8px;
      padding-left: 40px;
      font-size: 16px;
      line-height: 19px;
      padding-right: 40px;
    }
      .searchImg{
        left: 15px;
        top: 18px;
        width: 20px;
        height: 20px;
      }
      .errorSearchImg{
        left: 15px;
        top: 18px;
        width: 20px;
        height: 20px;
      }
      .activeSearchImg{
        left: 15px;
        top: 18px;
        width: 20px;
        height: 20px;
      }
      .plus{
        right: 15px;
        top: 18px;
      }
      .emailsDiv{
        width: 371px;
        max-height: 217px;
        padding: 10px 0 16px 12px;
        box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.07);
        border-radius: 10px;
      }
    
      .emailsFlex{
 
        &:hover{
          background: rgba(56, 81, 222, 0.025);
          border-radius: 8px;
        }
          img{
            width: 16px;
            height: 21px;
            margin: 10px 10px 9px 12px;
          }
          p{
            font-size: 16px;
            line-height: 19px;
            margin: 10px 0;
          }
      }
  }

    @media screen and (max-width: 1023px) {
      .main{
        height: 600px;
        }
      .imgSearch {
        width: 66px;
        height: 47px;
          margin: 46px auto 0;
        }
        .formName {
          font-size: 28px;
          line-height: 31px;
          margin-bottom: 14px;
          margin-top: 24px;
    
        }
        .text {
          font-size: 14px;
          line-height: 17px;
          margin-bottom: 0;
        }
      .searchArea{
          margin: 30px auto;
      }
      .Input{
        width: 336px;
        height: 50px;
          border-radius: 8px;
          padding-left: 40px;
          font-size: 16px;
          line-height: 19px;
          padding-right: 40px;
      }
      .activeInput{
        width: 336px;
        height: 50px;
          border-radius: 8px;
          padding-left: 40px;
          font-size: 16px;
          line-height: 19px;
          padding-right: 40px;
    
      }
      .errorInput{
        width: 336px;
        height: 50px;
        border-radius: 8px;
        padding-left: 40px;
        font-size: 16px;
        line-height: 19px;
        padding-right: 40px;
      }
        .searchImg{
          left: 15px;
          top: 15px;
          width: 20px;
          height: 20px;
        }
        .errorSearchImg{
          left: 15px;
          top: 15px;
          width: 20px;
          height: 20px;
        }
        .activeSearchImg{
          left: 15px;
          top: 15px;
          width: 20px;
          height: 20px;
        }
        .plus{
          right: 15px;
          top: 14px;
        }
        .emailsDiv{
          width: 336px;
          max-height: 181px;
          padding: 10px 0 16px 12px;
          box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.07);
          border-radius: 10px;
        }
      
        .emailsFlex{
   
          &:hover{
            background: rgba(56, 81, 222, 0.025);
            border-radius: 8px;
          }
            img{
              width: 13px;
              height: 16px;
              margin: 9px 10px 9px 12px;
            }
            p{
              font-size: 14px;
              line-height: 17px;
              margin: 9px 0;
            }
        }
    }


      @media screen and (max-width: 767px) {

        .main{
          height: 500px;
          }
        .imgSearch {
          width: 65px;
          height: 45px;
            margin: 36px auto 0;
          }
          .formName {
            font-size: 22px;
            line-height: 25px;
            margin-bottom: 14px;
            margin-top: 20px;
      
          }
          .text {
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 0;
          }
        .searchArea{
            margin: 24px auto;
        }
        .Input{
          width: 290px;
          height: 50px;
            border-radius: 8px;
            padding-left: 40px;
            font-size: 14px;
            line-height: 17px;
            padding-right: 40px;
        }
        .activeInput{
          width: 290px;
          height: 50px;
            border-radius: 8px;
            padding-left: 40px;
            font-size: 14px;
            line-height: 17px;
            padding-right: 40px;
      
        }
        .errorInput{
          width: 290px;
          height: 50px;
          border-radius: 8px;
          padding-left: 40px;
          font-size: 14px;
          line-height: 17px;
          padding-right: 40px;
        }
          .searchImg{
            left: 15px;
            top: 15px;
            width: 20px;
            height: 20px;
          }
          .errorSearchImg{
            left: 15px;
            top: 15px;
            width: 20px;
            height: 20px;
          }
          .activeSearchImg{
            left: 15px;
            top: 15px;
            width: 20px;
            height: 20px;
          }
          .plus{
            right: 15px;
            top: 14px;
          }
          .emailsDiv{
            width: 290px;
            max-height: 177px;
            padding: 10px 0 16px 6px;
            box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.07);
            border-radius: 10px;
          }
        
          .emailsFlex{
     
            &:hover{
              background: rgba(56, 81, 222, 0.025);
              border-radius: 8px;
            }
              img{
                width: 13px;
                height: 16px;
                margin: 9px 10px 8px 12px;
              }
              p{
                font-size: 14px;
                line-height: 17px;
                margin: 8px 0;
              }
          }
      }

    