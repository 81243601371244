.mainSection {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  gap: 16px;
  position: relative;
  height: auto;
}
.TextTitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  margin: 0;
  margin-top: 13px;
}
.TextTitleLanguages {
  margin: 0;
  margin-bottom: 14px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin-top: 0px;
  margin-bottom: -5px;
}
.TextTitleReseacrher {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  margin: 0;
  margin-top: 10px;
}
.TextInput {
  margin: 0;
  margin-bottom: 14px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin-top: 6px;
  margin-bottom: -5px;
}
.Input::placeholder {
  opacity: 0.5;
}
.Button {
  width: 103px;
  height: 48px;
  margin-top: 40px;
}

.Input {
  border-radius: 4px;
}
.TextField::placeholder {
  opacity: 0.5;
}

.MuiInputLabel-root {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  right: 22px;
  bottom: 0px;
}
