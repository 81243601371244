.SideCard {
  display: flex;
  flex-direction: column;

  align-items: flex-start;

  padding: 40px 30px;

  overflow-y: scroll;
overflow-x: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 570px;
  max-width: calc(100vw - 60px);
  background-color: white;
  box-shadow: 16px 0 32px -16px #000;
  z-index: 100;
box-sizing: border-box;
  h2 {
    margin-top: 0;
  }
}

.img{
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  transition: 0.3s;
}
.img:hover{
  opacity: 0.5;
  transition: 0.3s;
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 767px) {
  .SideCard {
  width: 320px;
  padding: 30px 15px;
  }
}